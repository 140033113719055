/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { checkIcon } from 'assets';
import Image from 'components/Image';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Link } from '@material-ui/core';
import styles from './styles.module.scss';

const buttonStyles = makeStyles({
  root: {
    marginTop: '20px',
    backgroundColor: '#1553D3',
    borderRadius: '24px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#0F3C9B',
      boxShadow: 'none',
    },
  },
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingTop: 2,
    paddingBottom: 2,
  },
});

const ProModal = ({ handleClose }) => {
  const buttonClasses = buttonStyles();

  return (
    <div className={styles.proContainer}>
      <h3 className={styles.proTitle}>Welcome to TroutRoutes PRO</h3>
      <p className={styles.proText}>With your new PRO membership, you can access all TroutRoutes has to offer. Get started by exploring a few features our users love the most.</p>
      <div className={styles.proDiv}>
        <Image src={checkIcon} alt="Guide Style" height={15} objectFit="cover" className={styles.proCheck} />
        <p className={styles.proText}><b>Browse the map and explore</b> over 50,000 trout streams across 48 states to start planning your next adventure.</p>
      </div>
      <div className={styles.proDiv}>
        <Image src={checkIcon} alt="Guide Style" height={15} objectFit="cover" className={styles.proCheck} />
        <p className={styles.proText}><b>Click on any stream</b> to view its TroutRoutes stream classification, public access points, stream flows, local and regional information including regulations, and more.</p>
      </div>
      <div className={styles.proDiv}>
        <Image src={checkIcon} alt="Guide Style" height={15} objectFit="cover" className={styles.proCheck} />
        <p className={styles.proText}><b>Explore our powerful Map Layers</b> to customize your map viewing experience using a selection of Map Modes and Base Maps.</p>
      </div>
      <div className={styles.proDiv}>
        <Image src={checkIcon} alt="Guide Style" height={15} objectFit="cover" className={styles.proCheck} />
        <p className={styles.proText}>View our
          <Link className={styles.proLink} underline="none" onClick={() => { window.open('https://troutroutes.com/users-guide/', '_blank'); }}>&nbsp;Users Guide&nbsp;</Link>
          and
          <Link className={styles.proLink} underline="none" onClick={() => { window.open('https://troutroutes.com/masterclasss/', '_blank'); }}>&nbsp;free Masterclasses&nbsp;</Link>
          .
        </p>
      </div>
      <Button
        disableRipple
        classes={buttonClasses}
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        onClick={handleClose}
      >
        Get Started
      </Button>
    </div>
  );
};

export default ProModal;
