import React from 'react';
import PropTypes from 'prop-types';
import { accountTypesEnum } from 'lib/constants';
import ProModal from './ProModal';
import BasicModal from './BasicModal';

const TrialNotification = ({
  startNotification, accountType, endNotification, endDate, onTrialClick, handleClose,
}) => {
  return (
    <>
      {/* <Image src={logoBasic} width="50%" className={styles.logo} /> */}
      {(startNotification || accountType === accountTypesEnum.trial) && (
        <div>{`You have a complimentary one-week free trial of TroutRoutes Pro! This trial expires ${endDate}. 
          You will not be automatically charged for a Pro account when this trial expires.`}
        </div>
      )}
      {(accountType === accountTypesEnum.basic && !endNotification) && (
        <BasicModal handleClose={handleClose} onTrialClick={onTrialClick} />
      )}
      {(accountType === accountTypesEnum.pro && !endNotification) && (
        <ProModal handleClose={handleClose} />
      )}
      {endNotification && (
        <div>Your TroutRoutes Pro trial has now expired. You can purchase a Pro account from our website.</div>
      )}

      {/* <div className={styles.buttons}>
        <Button label="Try Free Trial" className={styles.primary} onClick={onTrialClick} />
      </div> */}
    </>
  );
};

TrialNotification.propTypes = {
  startNotification: PropTypes.bool,
  endNotification: PropTypes.bool,
  onTrialClick: PropTypes.func,
  accountType: PropTypes.string,
  endDate: PropTypes.string,
};

export default TrialNotification;
