/* eslint-disable no-nested-ternary */
import React from 'react';
import Image from 'components/Image';
import { planPath1, planPath2, planPath3 } from 'assets';
import styles from './styles.module.scss';

const PlanPath = ({ error }) => {
  return (
    <>
      <div className={styles.timeline}>
        <Image src={planPath1} width={40} />
        <div className={styles.line} />
        <Image src={planPath2} width={40} />
        <div className={styles.line} />
        <Image src={planPath3} width={40} />
      </div>
      <div className={styles.timeline}>
        <h3>Today</h3>
        <h3>Day 5</h3>
        <h3>Day 7</h3>
      </div>
      <div className={styles.timeline}>
        <p>Get access to PRO right now. Free for 7 days.</p>
        <p>Receive an email reminder about your trial ending.</p>
        <p>You are charged $58.99 for your annual PRO plan.</p>
      </div>
    </>
  );
};

export default PlanPath;
