import React, { useState, useEffect } from 'react';
import CreateDialog from 'components/CreateDialog';
import { useSelector, useDispatch } from 'react-redux';
import { lineColors, lineWidths, lineTypes } from 'lib/constants';
import {
  resetCurrentArea, createArea, setDelayedPanelShown,
} from 'actions';
import ColorButton from 'components/CreateLine/ColorButton';
import SelectButton from 'components/CreateLine/SelectButton';
import { Slider } from '@material-ui/core';
import * as mapboxService from 'services/mapbox';
import useUpload from 'utils/useUpload';
import { PhotoDisplay, AddPhotoButton } from 'components/Photos';
import useIsPro from 'utils/useIsPro';
import { ConsoleView } from 'react-device-detect';

const CreateArea = () => {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const draw = useSelector((state) => state.map.draw);
  const map = useSelector((state) => state.map.map);
  const { tempArea } = useSelector((state) => state.map.area);

  const {
    images, handleChange, setImages, loading, handleUpload,
  } = useUpload();

  const [areaName, setAreaName] = useState('');
  const [currentColor, setCurrentColor] = useState(lineColors[0]);
  const [currentWidth, setCurrentWidth] = useState(lineWidths[1]);
  const [currentType, setCurrentType] = useState(lineTypes[0]);
  const [notes, setNotes] = useState('');
  const [opacity, setOpacity] = useState(0.5);
  const [outlineOpacity, setOutlineOpacity] = useState(1);

  const close = () => {
    mapboxService.clearAreaBorder(map, tempArea);
    dispatch(resetCurrentArea());
    setImages([]);
  };

  const onDeleteImage = (imageObject) => {
    setImages((prev) => prev.filter((v) => v !== imageObject));
  };

  const onSubmit = () => {
    const tempCoordinates = tempArea.geometry.coordinates[0];
    tempArea.geometry.coordinates = tempCoordinates;
    dispatch(createArea({
      colorIndex: currentColor.index, title: areaName, note: notes, geometry: tempArea.geometry, widthIndex: currentWidth.index, typeIndex: currentType.index, fillOpacity: opacity, outlineOpacity,
    }, null, (id) => {
      handleUpload(id, 'area');
      close();
    }));
  };

  useEffect(() => {
    if (!tempArea) {
      setCurrentColor(lineColors[0]);
      setCurrentWidth(lineWidths[1]);
      setCurrentType(lineTypes[0]);
      setOpacity(0.5);
      setOutlineOpacity(1);
      setNotes('');
      setAreaName('');
      dispatch(setDelayedPanelShown(false));
    } else {
      setTimeout(() => { dispatch(setDelayedPanelShown(true)); }, 600);
    }
  }, [tempArea]);

  useEffect(() => {
    if (tempArea) {
      draw.setFeatureProperty(tempArea.id, 'color', currentColor.color);
      draw.setFeatureProperty(tempArea.id, 'opacity', opacity);

      const area = draw.get(tempArea.id);
      draw.delete(tempArea.id);
      draw.add(area);
      mapboxService.clearAreaBorder(map, area);
      mapboxService.addAreaBorder(area, map, {
        color: currentColor.color, width: currentWidth.width, dashArray: currentType.dashArray, opacity: outlineOpacity,
      });
    }
  }, [currentColor, currentWidth, currentType, tempArea, draw, opacity, outlineOpacity]);

  if (!tempArea) return null;

  return (
    <CreateDialog title="Create New Shape" close={close}>
      <CreateDialog.Section title="Shape Name" widthConstrained>
        <CreateDialog.InputField
          name="name"
          onChange={(e) => { setAreaName(e.target.value); }}
          value={areaName}
          label="Name"
        />
      </CreateDialog.Section>

      <CreateDialog.Section title="Color">
        <CreateDialog.Selector items={lineColors} renderItem={(item) => (<ColorButton color={item.color} onClick={() => { setCurrentColor(item); }} key={item.color} active={item.color === currentColor.color} />)} />
      </CreateDialog.Section>

      <CreateDialog.Row>
        <CreateDialog.Section title="Outline Width" fitRow>
          <CreateDialog.Selector items={lineWidths}
            renderItem={(item) => (
              <SelectButton
                icon={item.icon}
                onClick={() => { setCurrentWidth(item); }}
                active={currentWidth.width === item.width}
                key={item.width}
              />
            )}
          />
        </CreateDialog.Section>
        <CreateDialog.Section title="Outline Type" fitRow>
          <CreateDialog.Selector items={lineTypes}
            renderItem={(item) => (
              <SelectButton
                icon={item.icon}
                onClick={() => { setCurrentType(item); }}
                active={currentType.dashArray === item.dashArray}
              />
            )}
          />
        </CreateDialog.Section>
      </CreateDialog.Row>

      <CreateDialog.Row>
        <CreateDialog.Section title="Fill Opacity" style={{ marginRight: 5 }}>
          <div>{opacity}</div>
          <Slider onChange={(e, val) => { setOpacity(val); }} value={opacity} min={0} max={1} step={0.01} />
        </CreateDialog.Section>

        <CreateDialog.Section title="Outline Opacity" style={{ marginLeft: 5 }}>
          <div>{outlineOpacity}</div>
          <Slider onChange={(e, val) => { setOutlineOpacity(val); }} value={outlineOpacity} min={0} max={1} step={0.01} />
        </CreateDialog.Section>
      </CreateDialog.Row>

      <CreateDialog.Section title="Notes">
        <CreateDialog.MultiLineField
          name="notes"
          label="Notes"
          value={notes}
          onChange={(e) => { setNotes(e.target.value); }}
        />
      </CreateDialog.Section>

      {isPro && (
        <CreateDialog.Section title="Photos" headerIcon={<AddPhotoButton id="create-marker" onChange={handleChange} />}>
          <PhotoDisplay images={images} onDelete={onDeleteImage} />
        </CreateDialog.Section>
      )}

      <CreateDialog.SubmitSection
        buttons={[{ label: 'Save', onClick: onSubmit }]}
        disabled={loading}
      />
    </CreateDialog>
  );
};

export default CreateArea;
