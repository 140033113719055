/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { checkIcon } from 'assets';
import Image from 'components/Image';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Link } from '@material-ui/core';
import styles from './styles.module.scss';

const buttonStyles = makeStyles({
  root: {
    marginTop: '10px',
    backgroundColor: '#1553D3',
    borderRadius: '24px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#0F3C9B',
      boxShadow: 'none',
    },
  },
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingTop: 2,
    paddingBottom: 2,
  },
});

const buttonStylesText = makeStyles({
  root: {
    marginTop: '5px',
    backgroundColor: 'transparent',
    borderRadius: '24px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingTop: 2,
    paddingBottom: 2,
    color: '#1553D3',
  },
});

const BasicModal = ({ handleClose, onTrialClick }) => {
  const buttonClasses = buttonStyles();
  const buttonTextClasses = buttonStylesText();

  return (
    <div className={styles.proContainer}>
      <div>
        <p className={styles.basicTitle}>Welcome to TroutRoutes</p>
        <h3 className={styles.proTitle}>Try PRO Free for 7 days</h3>
      </div>
      <p className={styles.proText}>As a Basic account holder, you’re missing out on TroutRoutes&apos;s top features. With PRO, you can:</p>
      <div className={styles.proDiv}>
        <Image src={checkIcon} alt="Guide Style" height={15} objectFit="cover" className={styles.proCheck} />
        <p className={styles.proText}>Access proprietary stream ratings</p>
      </div>
      <div className={styles.proDiv}>
        <Image src={checkIcon} alt="Guide Style" height={15} objectFit="cover" className={styles.proCheck} />
        <p className={styles.proText}>Find public access points</p>
      </div>
      <div className={styles.proDiv}>
        <Image src={checkIcon} alt="Guide Style" height={15} objectFit="cover" className={styles.proCheck} />
        <p className={styles.proText}>Save offline maps</p>
      </div>
      <div className={styles.proDiv}>
        <Image src={checkIcon} alt="Guide Style" height={15} objectFit="cover" className={styles.proCheck} />
        <p className={styles.proText}>Look up real-time stream conditions</p>
      </div>
      <div className={styles.proDiv}>
        <Image src={checkIcon} alt="Guide Style" height={15} objectFit="cover" className={styles.proCheck} />
        <p className={styles.proText}>View state-specific regulations</p>
      </div>
      <div className={styles.proDiv}>
        <Image src={checkIcon} alt="Guide Style" height={15} objectFit="cover" className={styles.proCheck} />
        <p className={styles.proText}>Customize map filter settings</p>
      </div>
      <div>
        <Button
          disableRipple
          classes={buttonClasses}
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={onTrialClick}
        >
          Try PRO Free for 7 Days
        </Button>
        <Button
          disableRipple
          classes={buttonTextClasses}
          variant="text"
          color="primary"
          fullWidth
          size="large"
          onClick={handleClose}
        >
          Continue with Basic
        </Button>
      </div>
    </div>
  );
};

export default BasicModal;
