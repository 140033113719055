/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  dismissEndNotification, dismissStartNotification, setIsPaymentModalShown, setIsSnapModalShown, setIsCancelModalShown, setPaymentError, clearCoupon, setIsUpdateModalShown,
} from 'actions';
import { accountTypesEnum } from 'lib/constants';
import usePrevious from 'utils/usePrevious';
import ModalTemplate from 'components/ModalTemplate';
import UpdateScreen from 'components/PaymentScreen/UpdateScreen';
import styles from './styles.module.scss';
import TrialNotification from './TrialNotification';
import PaymentScreen from '../PaymentScreen/Modal';
import CancelScreen from './CancelScreen';

const TrialModal = () => {
  const [isBasicModalShown, setIsBasicModalShown] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(0);
  const [isCloseDisabled, setIsCloseDisabled] = useState(false);
  const dispatch = useDispatch();
  const { startNotification, endNotification, endDate } = useSelector((state) => state.auth.trial);
  const { accountType } = useSelector((state) => state.auth.user);
  const { hasUpdatedAccountType, isRegistering } = useSelector((state) => state.auth);
  const { region } = useSelector((state) => state.user);
  const { isPaymentModalShown, isCancelModalShown, isUpdateModalShown } = useSelector((state) => state.payment);
  const hadLoaded = usePrevious(hasUpdatedAccountType);

  const handleClose = () => {
    if (endNotification) dispatch(dismissEndNotification());
    if (startNotification) dispatch(dismissStartNotification());
    if (!region) {
      dispatch(setIsSnapModalShown(true));
    }
    setIsBasicModalShown(false);
    dispatch(setIsPaymentModalShown(false));
    dispatch(setIsCancelModalShown(false));
    dispatch(setIsUpdateModalShown(false));
    dispatch(setPaymentError(''));
    dispatch(clearCoupon());
  };

  useEffect(() => {
    if (hasUpdatedAccountType && !hadLoaded && !isRegistering && (accountType === accountTypesEnum.basic || accountType === accountTypesEnum.trial)) {
      setIsBasicModalShown(true);
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const defaultPromoCode = params.get('promocode');
      const defaultMemberCode = params.get('membercode');
      if((defaultPromoCode != null && defaultPromoCode != '') || (defaultMemberCode != null && defaultMemberCode != '')) {
        setCurrentScreen(1);
      }
    }
  }, [accountType, hasUpdatedAccountType]);

  useEffect(() => {
    if (isPaymentModalShown) {
      setCurrentScreen(1);
      setIsBasicModalShown(true);
    } else {
      setIsBasicModalShown(false);
    }
  }, [isPaymentModalShown]);

  useEffect(() => {
    if (isCancelModalShown) {
      setCurrentScreen(2);
      setIsBasicModalShown(true);
    } else {
      setIsBasicModalShown(false);
    }
  }, [isCancelModalShown]);

  useEffect(() => {
    if (isUpdateModalShown) {
      setCurrentScreen(3);
      setIsBasicModalShown(true);
    } else {
      setIsBasicModalShown(false);
    }
  }, [isUpdateModalShown]);

  const open = startNotification || endNotification || isBasicModalShown;

  const onTrialClick = () => {
    setCurrentScreen(1);
  };

  return (
    <ModalTemplate close={handleClose} open={open}>
      <div className={styles.container}>
        {/* <button className={styles.close} type="button" onClick={handleClose} disabled={isCloseDisabled}><CloseIcon width={30} height={30} /></button> */}
        {currentScreen === 0 && <TrialNotification startNotification={startNotification} endNotification={endNotification} accountType={accountType} endDate={endDate} onTrialClick={onTrialClick} handleClose={handleClose} />}
        {currentScreen === 1 && <PaymentScreen close={handleClose} toggleCloseDisabled={setIsCloseDisabled} />}
        {currentScreen === 2 && <CancelScreen close={handleClose} toggleCloseDisabled={setIsCloseDisabled} />}
        {currentScreen === 3 && <UpdateScreen close={handleClose} toggleCloseDisabled={setIsCloseDisabled} isCloseDisabled={isCloseDisabled} />}
      </div>
    </ModalTemplate>
  );
};

export default TrialModal;
