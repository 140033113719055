import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from 'actions';

/**
 * Custom hook to share submit logic between the CreateAccount and Login forms.
 * @param {func} funcToDispatch Function to dispatch upon successful validation – should take email and password as parameters.
 * @param {func} validate Validation function.
 * @param {object} values Object of values. Must include email and password as keys.
 */
const useSubmitLogic = (funcToDispatch, validate, values) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const authError = useSelector((state) => state.auth.error);

  // Submit flow: first validate the user input, then attempt to sign in or register using Firebase and Redux.
  const trySubmit = (additionalValues = {}) => {
    if (validate()) {
      const { email, password } = values;
      setLoading(true);
      dispatch(funcToDispatch(email, password, { ...values, ...additionalValues }));
    }
  };

  // If there's an authentication error, turn off the loading indicator.
  useEffect(() => {
    setLoading(false);
  }, [authError]);

  // Clear all errors when the user modifies their input.
  useEffect(() => {
    dispatch({ type: ActionTypes.CLEAR_AUTH_ERROR });
  }, [values]);

  return { trySubmit, loading, authError };
};

export default useSubmitLogic;
