import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styles from './styles.module.scss';

const ContentDrawer = ({ visible, children }) => {
  return (
    <motion.div
      className={styles['content-drawer']}
      animate={{ height: visible ? 'auto' : 0 }}
      transition={{ type: visible ? 'spring' : '', ease: visible ? '' : 'easeOut', duration: visible ? 0.5 : 0.3 }}
    >
      {children}
    </motion.div>
  );
};

ContentDrawer.propTypes = {
  visible: PropTypes.bool,
};

export default ContentDrawer;
