/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { FormHelperText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { loadPrice } from 'actions';
import { Spinner } from 'react-activity';
import 'react-activity/dist/Spinner.css';
import styles from './styles.module.scss';

const PriceSection = ({
  percentOff, oldAnnualPrice, annualPrice, monthlyPrice,
}) => {
  if(percentOff) {
    return(
      <>
        <p style={{ fontSize: '16px' }} className={styles.oldPrice}>${oldAnnualPrice} / yr</p>
        <p style={{ fontSize: '16px' }} className={styles.originalPrice}>${annualPrice} / yr</p>
      </>
    );
  } else {
    return(
      <p style={{ fontSize: '16px' }} className={styles.originalPrice}>${annualPrice} / yr</p>
    );
  }
};

const InfoSection = ({ error, displayMembershipCard, hidden = false }) => {
  const dispatch = useDispatch();
  const { percentOff, error: paymentError, price } = useSelector((state) => state.payment);
  const { unit_amount: unitAmount } = price ?? {};

  const annualPrice = unitAmount ? (percentOff ? ((unitAmount / 100) * (1 - (percentOff / 100))).toFixed(2) : '58.99') : '';
  const monthlyPrice = unitAmount ? (percentOff ? ((unitAmount / 100 / 12) * (1 - (percentOff / 100))).toFixed(2) : '4.92') : '';
  const oldAnnualPrice = unitAmount ? (percentOff ? ((unitAmount / 100)).toFixed(2) : '') : '';

  useEffect(() => {
    dispatch(loadPrice());
  }, []);

  return (
    <div className={styles['info-section']}>
      {!hidden && <h4>Try free for 7 days, then</h4>}
      {/* <FormHelperText error>{paymentError || error || ''}</FormHelperText> */}

      <div className={styles['payment-details']}>
        <div className={styles['payment-circle']}>
          <div />
        </div>
        <p>PRO ANNUAL</p>
        <PriceSection annualPrice={annualPrice} percentOff={percentOff} oldAnnualPrice={oldAnnualPrice} />
        <p>{displayMembershipCard ? 'prepaid with' : 'billed annually'}</p>
        <p>{displayMembershipCard ? 'membership card' : `(${monthlyPrice} / month)`}</p>
      </div>

      <div style={{ width: '60%' }}>
        <p style={{
          fontSize: '12px', fontWeight: '400', color: '#464646', textAlign: 'center', marginBottom: '0px',
        }}
        >You will not be charged until after the 7 day trial period.  Cancel before then to avoid all charges.
        </p>
        <p style={{
          fontSize: '12px', fontWeight: '400', color: '#464646', textAlign: 'center', marginTop: '10px',
        }}
        >State and local tax rates may apply.
        </p>
      </div>
    </div>
  );
};

export default InfoSection;
