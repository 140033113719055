import React from 'react';
import {
  basicStyle, guideStyle, accessStyle, roadsLayer, satelliteLayer, topographyLayer,
} from 'assets';
import { layersEnum } from 'lib/constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateBaseLayer, updateMapStyle, setIsUpgradeModalShown } from 'actions';
import useIsPro from 'utils/useIsPro';
import Pane from '../Pane';
import LayersChunk from './LayersChunk';
import LayersSection from './LayersSection';
import styles from './styles.module.scss';

const LayersPane = () => {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const activeStyle = useSelector((state) => state.map.mapStyle);
  const activeLayer = useSelector((state) => state.map.baseLayer);

  const handleClick = (e) => {
    const { name } = e.currentTarget;
    switch (name) {
    case layersEnum.basic:
      dispatch(updateMapStyle(layersEnum.basic));
      break;
    case layersEnum.guide:
      if (isPro) dispatch(updateMapStyle(layersEnum.guide));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case layersEnum.access:
      if (isPro) dispatch(updateMapStyle(layersEnum.access));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case layersEnum.regulations:
      if (isPro) dispatch(updateMapStyle(layersEnum.regulations));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case layersEnum.roads:
      dispatch(updateBaseLayer(layersEnum.roads));
      break;
    case layersEnum.topography:
      dispatch(updateBaseLayer(layersEnum.topography));
      break;
    case layersEnum.satellite:
      dispatch(updateBaseLayer(layersEnum.satellite));
      break;
    default:
      break;
    }
  };

  return (
    <Pane title="Map Layers">
      <LayersSection title="Choose Map Style:">
        <LayersChunk image={basicStyle} caption="Basic" onClick={handleClick} name={layersEnum.basic} active={activeStyle === layersEnum.basic}>
          <p>The <strong>Basic Map Style</strong> display all stream lines and stream gages, but does not diplay any public land or access, and does not include stream classifications.
            This style is available to Free Accounts.
          </p>
        </LayersChunk>
        <LayersChunk image={guideStyle} caption="Stream Guide" onClick={handleClick} name={layersEnum.guide} active={activeStyle === layersEnum.guide} disabled={!isPro}>
          <p>The <strong>Guide Map Style</strong> is the most popular overlay. This style displays all public land and access, all streams color coded based on stream classification,
            trails, and many other layers when a stream is tapped.  This style is only available to Pro Users.
          </p>
        </LayersChunk>
        <LayersChunk image={accessStyle} caption="Public Access" onClick={handleClick} name={layersEnum.access} active={activeStyle === layersEnum.access} disabled={!isPro}>
          <p>The <strong>Access Map Style</strong> displays advanced insights on public access, such as fishing easements,
            nat’l and state parks, and other public land such as local parks. This style is also only available to Pro Users.
          </p>
        </LayersChunk>
        <LayersChunk image={accessStyle} caption="Regulations" onClick={handleClick} name={layersEnum.regulations} active={activeStyle === layersEnum.regulations} disabled={!isPro}>
          <p>The <strong>Regulations Map Style</strong> displays color-coded regulations for each stream by region. This style is also only available to Pro Users.
          </p>
        </LayersChunk>
      </LayersSection>

      <div className={styles.divider} />

      <LayersSection title="Choose Base Layer:">
        <LayersChunk image={roadsLayer} caption="Roads" onClick={handleClick} name={layersEnum.roads} active={activeLayer === layersEnum.roads}>
          <p>
            The <strong>Roads Base Layer</strong> shows the map with detail roads information. This layer is available to both Pro and Free users.
          </p>
        </LayersChunk>
        <LayersChunk image={topographyLayer} caption="Topography" onClick={handleClick} name={layersEnum.topography} active={activeLayer === layersEnum.topography}>
          <p>The <strong>Topography Base Layer</strong> shows the map with elevation contours and shading to give you a better insight on the topography of an area, such as mountains and canyons.
            This layer is available to both Pro and Free users.
          </p>
        </LayersChunk>
        <LayersChunk image={satelliteLayer} caption="Satellite" onClick={handleClick} name={layersEnum.satellite} active={activeLayer === layersEnum.satellite}>
          <p>
            The <strong>Satellite Base Layer</strong> shows the map usaing satellite imagery. This layer is available to both Pro and Free users.
          </p>
        </LayersChunk>
      </LayersSection>
    </Pane>
  );
};

export default LayersPane;
