/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as firebaseService from 'services/firebase';
import errorCodes from 'lib/error-codes';
import FormContainer from './FormContainer';
import styles from './styles.module.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  const tryReset = async () => {
    setInfo(null);
    setError(null);
    try {
      if (!email) {
        setError('Email is required.');
        return;
      }

      await firebaseService.sendPasswordResetEmail(email);
      setInfo('Please check your email for instructions on how to reset your password. If you don\'t see the email, please check your junk folder.');
    } catch (e) {
      const { code } = e;
      setError(errorCodes[code] || 'There was an error resetting your password.');
    }
  };

  return (
    <FormContainer title="Reset your Password">
      <form className={styles.form}>
        <div className={styles['password-box']}>
          <FormContainer.FormElement
            title="Email"
            name="email"
            onChange={(e) => { setEmail(e.target.value); }}
            value={email}
          />
          {info && <div className={styles.info}>{info}</div>}
          {error && <div className={styles.error}>{error}</div>}
          <FormContainer.SubmitButton title="Send Reset Email" onClick={tryReset} />
        </div>
      </form>

      <div className={styles.wrapper} style={{ paddingTop: 5 }}>
        <div className={styles.options}>
          <Link className={styles.bottomLink} underline="none" onClick={() => { history.push('/login'); }}>Back to Login</Link>
          <div className={styles['divider-vertical']} />
          <Link className={styles.bottomLink} underline="none" onClick={() => { history.push('/signup'); }}>Create Account</Link>
        </div>

        <FormContainer.BottomSection />
      </div>
    </FormContainer>
  );
};

export default ForgotPassword;
