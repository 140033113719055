/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveSidebar, setIsPaymentModalShown } from 'actions';
import {
  appIcon, contentIcon, layersIcon, profileIcon, filtersIcon, legendIcon, menuIcon,
} from 'assets';
import { accountTypesEnum, version } from 'lib/constants';
import { Menu, Close } from '@material-ui/icons';
import { updateMapLayers } from 'services/mapbox';
import LayersPane from './LayersPane';
import ContentPane from './ContentPane';
import SettingsPane from './SettingsPane';
import ProfilePane from './ProfilePane';
import LegendPane from './LegendPane';
import styles from './styles.module.scss';

const Logo = ({ onClick }) => {
  return (
    <button type="button" className={styles.logo} onClick={onClick}>
      <Image src={appIcon} width={48} objectFit="cover" style={{ borderRadius: 10 }} />
    </button>
  );
};

const AccountStatus = () => {
  const {
    accountType, email,
  } = useSelector((state) => state.auth.user);
  const { hasUpdatedAccountType } = useSelector(((state) => state.auth));
  const { map } = useSelector((state) => state.map);
  const dispatch = useDispatch();

  useEffect(() => {
    updateMapLayers(map, accountType);
  }, [accountType]);

  return (
    <div className={styles.account}>
      <div>Membership</div>
      {accountType === accountTypesEnum.basic && hasUpdatedAccountType && <div className={styles.basic}>BASIC</div>}
      {accountType === accountTypesEnum.trial && hasUpdatedAccountType && <div className={styles.trial}>TRIAL</div>}
      {accountType === accountTypesEnum.pro && hasUpdatedAccountType && <div className={styles.pro}>PRO</div>}
      {accountType !== accountTypesEnum.pro && <button className={styles.link} onClick={() => { dispatch(setIsPaymentModalShown(true)); }} type="button">Upgrade Now</button>}
    </div>
  );
};

const AccountInfo = () => {
  const {
    accountType, email,
  } = useSelector((state) => state.auth.user);
  const { hasUpdatedAccountType } = useSelector(((state) => state.auth));
  const { map } = useSelector((state) => state.map);
  const dispatch = useDispatch();

  useEffect(() => {
    updateMapLayers(map, accountType);
  }, [accountType]);

  return (
    <div className={styles.info}>
      <div className={styles.email}>{email}</div>
      <div className={styles.version}>{`Version ${version}`}</div>
    </div>
  );
};

const SidebarButton = ({
  title, icon, active, onClick,
}) => {
  return (
    <button className={active ? `${styles['side-button']} ${styles.active}` : styles['side-button']} type="button" onClick={onClick}>
      <Image src={icon} alt={title} height={24} objectFit="cover" />
      <h4 className={styles.title}>{title}</h4>
    </button>
  );
};

SidebarButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export const buttonEnum = {
  layers: 'layers',
  content: 'content',
  profile: 'profile',
  settings: 'settings',
  legend: 'legend',
};

const LeftSidebar = () => {
  const [isSidebarShown, setIsSidebarShown] = useState(window.innerWidth > 767);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const active = useSelector((state) => state.map.activeSidebar);

  const handleResize = useCallback(() => {
    setIsSidebarShown(window.innerWidth > 767);
    setWindowWidth(window.innerWidth);
  }, [setIsSidebarShown, setWindowWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const handleClick = (clicked) => {
    if (active === clicked) {
      dispatch(setActiveSidebar(''));
    } else {
      dispatch(setActiveSidebar(clicked));
    }
  };

  return (
    <>
      <button className={styles.menubutton} type="button" onClick={() => { setIsSidebarShown(true); }}>
        <Image src={menuIcon} height={58} />
      </button>
      {/* <button className={styles['sidebar-button']} type="button" onClick={() => { setIsSidebarShown(true); }}><Menu /></button> */}
      {/* animate={{ minWidth: isSidebarShown ? '100px' : '0px', width: isSidebarShown ? '8%' : '0px' }} */}
      <div className={styles.sidebar} style={{ left: isSidebarShown ? '0' : '-105px' }}>
        {windowWidth <= 1024 && <button className={styles['close-button']} type="button" onClick={() => { setIsSidebarShown(false); }}><Close /></button>}
        <Logo onClick={() => setIsSidebarShown(false)} />
        <AccountStatus />

        <SidebarButton title="Map Layers" icon={layersIcon} active={active === buttonEnum.layers} onClick={() => { handleClick(buttonEnum.layers); }} />
        <SidebarButton title="My Content" icon={contentIcon} active={active === buttonEnum.content} onClick={() => { handleClick(buttonEnum.content); }} />
        <SidebarButton title="My Profile" icon={profileIcon} active={active === buttonEnum.profile} onClick={() => { handleClick(buttonEnum.profile); }} />
        <SidebarButton title="Map Filters" icon={filtersIcon} active={active === buttonEnum.settings} onClick={() => { handleClick(buttonEnum.settings); }} />
        <SidebarButton title="Map Legend" icon={legendIcon} active={active === buttonEnum.legend} onClick={() => { handleClick(buttonEnum.legend); }} />

        <AccountInfo />
      </div>

      {active === buttonEnum.layers && <LayersPane />}
      {active === buttonEnum.content && <ContentPane toggle={() => { handleClick(buttonEnum.content); }} />}
      {active === buttonEnum.settings && <SettingsPane />}
      {active === buttonEnum.profile && <ProfilePane />}
      {active === buttonEnum.legend && <LegendPane />}

      <div className="font-preload" style={{ opacity: 0 }}><span style={{ visibility: 'hidden', fontFamily: 'Poppins' }} /></div>
    </>
  );
};

export default LeftSidebar;
