import axios from 'axios';
import { capitalizeSentence } from 'utils/string';
import { getGageIDList } from './firebase';

const API_URL_GAGE = 'https://troutroutes.netlify.app/.netlify/functions/gage';

const getUSGSURL = (stationId) => {
  return `https://waterservices.usgs.gov/nwis/iv/?format=json&site=${stationId}&parameterCd=00060,00065,00010&siteStatus=all`;
};

export const makeGageRequest = async (type, stationId, time) => {
  const { data } = await axios.get(`${API_URL_GAGE}?type=${type}&id=${stationId}&time=${time}`);
  return data[0];
};

export const getLatestGageData = async (stationId) => {
  const url = getUSGSURL(stationId);
  const result = await axios.get(url);

  const timeSeries = result?.data?.value?.timeSeries;
  let gageObject;

  if(timeSeries.length > 0) {
    gageObject = {
      title: capitalizeSentence(timeSeries[0]?.sourceInfo?.siteName),
      id: timeSeries[0]?.sourceInfo?.siteCode[0]?.value,
      coordinate: {
        latitude: timeSeries[0]?.sourceInfo?.geoLocation?.geogLocation?.latitude,
        longitude: timeSeries[0]?.sourceInfo?.geoLocation?.geogLocation?.longitude,
      },
    };
  }

  for(const series of timeSeries) {
    const valuesArray = [];
    const doubleArray = [];
    const type = series?.variable?.variableCode[0]?.value;

    for(const value of series?.values[0]?.value) {
      const tempObject = {
        value: value?.value || '-99',
        dateTime: value?.dateTime || '2000-01-01T00:00:00',
      };
      doubleArray.push(parseFloat(tempObject.value));
      valuesArray.push(tempObject);
    }

    switch(type) {
    case '00060':
      if(doubleArray.length < 1) gageObject.streamFlowAvailable = 0;
      else if (doubleArray[doubleArray.length - 1] < 0.0) {
        gageObject.streamFlowAvailable = 2;
      } else {
        const validArray = [];
        for(const value of valuesArray) {
          let validValue = '0.0';
          if(parseFloat(value?.value) >= 0.0) {
            validValue = value?.value;
          }
          validArray.push({ ...value, value: validValue });
        }
        gageObject.streamFlowAvailable = 1;
        gageObject.IVFlow = { ...validArray[0] };
      }
      break;
    case '00065':
      if(doubleArray.length < 1) gageObject.streamHeightAvailable = 0;
      else if (doubleArray[doubleArray.length - 1] < 0.0) {
        gageObject.streamHeightAvailable = 2;
      } else {
        const validArray = [];
        for(const value of valuesArray) {
          let validValue = '0.0';
          if(parseFloat(value?.value) >= 0.0) {
            validValue = value?.value;
          }
          validArray.push({ ...value, value: validValue });
        }
        gageObject.streamHeightAvailable = 1;
        gageObject.IVHeight = { ...validArray[0] };
      }
      break;
    case '00010':
      if(doubleArray.length < 1) gageObject.streamTempAvailable = 0;
      else {
        const validArray = [];
        let hasTemp = 0;
        for(const value of valuesArray) {
          const tempC = parseFloat(value?.value) || 0;
          const tempF = ((tempC * (9 / 5)) + 32).toFixed(1);
          if(tempF > 0) {
            hasTemp = 1;
            validArray.push({ ...value, value: String(tempF) });
          }
        }
        if(hasTemp === 1) {
          gageObject.streamTempAvailable = 1;
          gageObject.IVTemp = { ...validArray[0] };
        }
      }
      break;
    default:
      return;
    }
  }

  return gageObject;
};

export const retrieveGages = async (stream, currentHUC) => {
  const { stream_gid } = stream;
  const { nearbyGagesRiverIDs, nearbyGagesWatershedIDs, trueHuc } = await getGageIDList(stream_gid, currentHUC);
  const response = await Promise.allSettled(nearbyGagesRiverIDs?.map((id) => getLatestGageData(id)));
  const response2 = await Promise.allSettled(nearbyGagesWatershedIDs?.map((id) => getLatestGageData(id)));

  return {
    inCurrentRiver: response.filter((result) => result.status === 'fulfilled').map((result) => result.value),
    inCurrentWatershed: response2.filter((result) => result.status === 'fulfilled').map((result) => result.value),
    trueHuc,
  };
};
