import * as turf from '@turf/turf';
import {
  setRiverMilesDistance, setRiverMilesMarkers, setRiverMilesParentLine, setRiverMilesPrimnaryLabel, setRiverMilesSlicedLineCoordinates,
} from 'actions';
import { store } from '../index';
import * as firebaseService from './firebase';
import { createMilesMarker } from './mapbox';

function highlightStream(line, map) {
  const customStream = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: line.geometry.coordinates,
    },
    properties: {
      opacity: 1.0,
      color: '#0000FF',
      width: 5,
    },
  };
  map.addSource('customStream', {
    type: 'geojson',
    data: customStream,
  });

  map.addLayer({
    id: 'customStream',
    type: 'line',
    source: 'customStream',
    paint: {
      'line-color': customStream.properties.color,
      'line-width': customStream.properties.width,
    },
  });
}

export function removeHighlight(map) {
  if(map.getLayer('customSlice')) {
    map.removeLayer('customSlice');
    map.removeSource('customSlice');
  }
  if(map.getLayer('customStream')) {
    map.removeLayer('customStream');
    map.removeSource('customStream');
  }
}

export async function getNearestLinetoPoint(event, map, bbox, dispatch) {
  let strReturn = '';
  const maximumDistance = 1500;

  const spot = { x: event.point.x, y: event.point.y };
  const tapCoordinate = map.unproject(spot);

  const streamFeatures = map.queryRenderedFeatures(bbox, {
    layers: ['trout_section'],
  });
  let closestPt,
    closestDistance, highlightIndex;
  const pts = [];
  const lines = [];

  if (streamFeatures.length > 0) {
    const streamGid = streamFeatures[0].properties.stream_gid2;
    const coords = await firebaseService.getStreamCoordinates(streamGid);
    streamFeatures.forEach((stream) => {
      const line2 = turf.lineString(coords[0]);

      closestPt = turf.nearestPointOnLine(line2, [tapCoordinate.lng, tapCoordinate.lat]);

      const temp_point = {
        size: 8,
        borderWidth: 1,
        centerColor: 'cyan',
        coordinate: closestPt.geometry.coordinates,
        streamGID: stream.properties.stream_gid2 || '',
        title: stream.properties.PrimaryLabel || '',
      };
      pts.push(temp_point);
      lines.push(line2);
    });
  }

  pts.forEach((pt, index) => {
    const dist = turf.distance(turf.point([tapCoordinate.lng, tapCoordinate.lat]), turf.point([pt.coordinate[0], pt.coordinate[1]]));
    if (closestDistance === undefined || dist < closestDistance) {
      closestDistance = dist;
      strReturn = pt.streamGID;
      dispatch(setRiverMilesPrimnaryLabel(pt.title));
      closestPt = pt;
      highlightIndex = index;
    }
  });

  if (closestPt !== undefined) {
    closestPt.centerColor = 'yellow';
    closestPt.size = 20;
    closestPt.centerOpacity = 1.0;

    const startPoint = createMilesMarker({ lng: closestPt.coordinate[0], lat: closestPt.coordinate[1] }, map);
    dispatch(setRiverMilesMarkers({ startPoint }));
  }

  if (strReturn !== '') {
    dispatch(setRiverMilesSlicedLineCoordinates({ startPoint: closestPt.coordinate }));
    dispatch(setRiverMilesParentLine(lines[highlightIndex]));
    highlightStream(lines[highlightIndex], map);
  }

  return strReturn;
}

function createEndPoint(lng, lat, map, dispatch) {
  const { riverMilesMarkers } = store.getState().map;
  if(riverMilesMarkers.endPoint) {
    riverMilesMarkers.endPoint.remove();
  }
  const endPoint = createMilesMarker({ lng, lat }, map);
  dispatch(setRiverMilesMarkers({ endPoint }));
}

function calculateSliceDistance(slicedLine) {
  const coords = slicedLine.geometry.coordinates;
  let distance = 0.0;
  for (let i = 1; i < coords.length; i += 1) {
    const startCoord = turf.point(coords[i - 1]);
    const endCoord = turf.point(coords[i]);
    const distTurf = turf.distance(startCoord, endCoord);
    distance += distTurf;
  }

  return (distance * 0.621371).toFixed(2);
}

function showSlicedLine(map, dispatch) {
  const { riverMilesSlicedLineCoordinates, riverMilesStep, riverMilesParentLine } = store.getState().map;

  if(riverMilesStep === 2) {
    map.removeLayer('customSlice');
    map.removeSource('customSlice');
  }

  const mSlicedLine = turf.lineSlice(turf.point(riverMilesSlicedLineCoordinates.startPoint), turf.point(riverMilesSlicedLineCoordinates.endPoint), riverMilesParentLine);
  const dist = calculateSliceDistance(mSlicedLine);
  dispatch(setRiverMilesDistance(dist));

  const customSlice = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: mSlicedLine.geometry.coordinates,
    },
    properties: {
      opacity: 1.0,
      color: 'yellow',
      width: 8,
    },
  };

  map.addSource('customSlice', {
    type: 'geojson',
    data: customSlice,
  });

  map.addLayer({
    id: 'customSlice',
    type: 'line',
    source: 'customSlice',
    paint: {
      'line-color': customSlice.properties.color,
      'line-width': customSlice.properties.width,
    },
  });
}

export function getNearestPointfromTapToProcessLine(event, map, riverMilesParentLine, dispatch) {
  const spot = { x: event.point.x, y: event.point.y };
  const tapCoordinate = map.unproject(spot);
  const closestPt = turf.nearestPointOnLine(riverMilesParentLine, [tapCoordinate.lng, tapCoordinate.lat]);

  createEndPoint(closestPt.geometry.coordinates[0], closestPt.geometry.coordinates[1], map, dispatch);
  dispatch(setRiverMilesSlicedLineCoordinates({ endPoint: closestPt.geometry.coordinates }));

  showSlicedLine(map, dispatch);
}

