const errorCodes = {
  'auth/invalid-email': 'Email is invalid.',
  'auth/user-disabled': 'User disabled.',
  'auth/user-not-found': 'Email is invalid.',
  'auth/wrong-password': 'Password is incorrect.',
  'auth/email-already-in-use': 'Email has already been taken.',
  'auth/weak-password': 'Password must be at least 6 characters.',
};

export default errorCodes;
