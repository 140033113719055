import React from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import ThreeButtons from 'components/ThreeButtons';
import {
  distanceIcon, elevationIcon,
} from 'assets';
import styles from './styles.module.scss';

const PopupFlex = ({
  title, subtitle, type, distance, elevation, leftButton, middleButton, rightButton, iconURL, description, ext_link, link_title,
}) => {
  let desc = '';
  let linkTitle = '';

  if((description != 'undefined') && (description != null)) {
    desc = description;
  }
  if((link_title != 'undefined') && (link_title != null)) {
    linkTitle = link_title;
  } else {
    linkTitle = ext_link;
  }

  return (
    <div className={styles.popup}>
      <div className={styles.content}>
        {iconURL && <Image alt={title} src={iconURL} width="20%" objectFit="contain" />}
        <div className={styles.text}>
          <h5>{title}</h5>
          <h6>{subtitle}</h6>
          {type && <div>{type}</div>}
          {desc && <p>{desc}</p>}
          {ext_link && <a href={ext_link} target="_blank" rel="noreferrer" style={{ color: 'blue', overflow: 'hidden', width: '300px' }}>{linkTitle}</a>}
        </div>
      </div>
      <div className={styles.distances}>
        <div className={styles.distanceInner}>
          <Image alt="Distance" src={distanceIcon} width="20" />
          <p>{distance > 10 ? distance.toLocaleString('en-US', { minimumFractionDigits: 0 }) : distance.toLocaleString('en-US', { minimumFractionDigits: 1 })} mi. away</p>
        </div>
        <div className={styles.distanceInner}>
          <Image alt="Elevation" src={elevationIcon} width="20" />
          <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft elev.</p>
        </div>
      </div>

      <ThreeButtons leftButton={leftButton} middleButton={middleButton} rightButton={rightButton} />
    </div>
  );
};

PopupFlex.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  distance: PropTypes.number,
  elevation: PropTypes.string,
  leftButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  middleButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  rightButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  iconURL: PropTypes.string,
};

export default PopupFlex;
