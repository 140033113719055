/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { ActionTypes } from 'actions';
import errorCodes from 'lib/error-codes';
import * as firebaseService from 'services/firebase';
import * as paymentService from 'services/stripe';
import { sendAmplitudeEvent } from 'utils/amplitude';

/**
 * Logs in the user.
 * @param {string} email User email.
 * @param {string} password User password.
 */
export const login = (email, password, hideError = false) => {
  return async (dispatch) => {
    try {
      const user = await firebaseService.login(email, password);
      dispatch({ type: ActionTypes.AUTHENTICATE, payload: user });
    } catch (e) {
      if (hideError !== true) {
        dispatch({ type: ActionTypes.AUTH_ERROR, payload: e.message });
      } else if (e?.message === errorCodes['auth/wrong-password']) {
        dispatch({ type: ActionTypes.UPDATE_FLYSHOP_PASSWORD_MODAL_SHOWN, payload: true });
      }
    }
  };
};

export const loginFlyshopMode = (email, password) => {
  return async (dispatch) => {
    try {
      await firebaseService.updatePasswordFlyshop(email, password);
      await dispatch(login(email, password));
    } catch (e) {
      dispatch({ type: ActionTypes.AUTH_ERROR, payload: e.message });
    }
  };
};

/**
 * Attempts to register a user.
 * @param {string} email User email.
 * @param {string} password User password.
 * @param {object} values Optional. If it contains firstName and lastName, those are added to the user profile upon account creation.
 */
export const createAccount = (email, password, values) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ActionTypes.REGISTERING, payload: true });
      // const user = await firebaseService.register(email, password, values);
      dispatch({ type: ActionTypes.IS_AUTHENTICATED, payload: true });
      if (values.subscriptionId) {
        await paymentService.subscribeToRevenueCat(values.subscriptionId, undefined, `${values.firstName} ${values.lastName}`);
      } else if (values.purchaseId) {
        await paymentService.updateRevenueCatEntitlements(values.purchaseId, `${values.firstName} ${values.lastName}`, values.redemptionCode, values.duration);
      }
      firebaseService.verifyAccountStatus(dispatch);
    } catch (e) {
      const { isFlyshopMode } = getState().auth;

      if (!isFlyshopMode) {
        dispatch({ type: ActionTypes.AUTH_ERROR, payload: e.message });
      } else if (e.message === errorCodes['auth/email-already-in-use']) {
        try {
          await dispatch(login(email, password, true));
          await paymentService.subscribeToRevenueCat(values.subscriptionId, undefined, `${values.firstName} ${values.lastName}`);
          firebaseService.verifyAccountStatus(dispatch);
        } catch (e) {
          // dispatch({ type: ActionTypes.AUTH_ERROR, payload: e.message });
        }
      } else {
        dispatch({ type: ActionTypes.AUTH_ERROR, payload: e.message });
      }
    } finally {
      dispatch({ type: ActionTypes.REGISTERING, payload: false });
      dispatch({ type: ActionTypes.SET_SNAP_MODAL, payload: true });
    }
  };
};

/**
 * Logs out the user.
 */
export const logout = () => {
  return async (dispatch) => {
    try {
      await firebaseService.logout();
    } finally {
      sendAmplitudeEvent('web_logout');
      dispatch({ type: ActionTypes.LOGOUT });
    }
  };
};

/**
 * Updates the Redux store with relevant information about the user's account.
 * @param {string} accountType Pro, trial, or basic.
 * @param {object} info info object returned from the verifyAccountStatus function.
 */
export const updateAccountType = (accountType, info) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_USER_INFO, payload: { accountType } });

    if (info?.renewalDate) {
      const { renewalDate } = info;
      const formatted = `${renewalDate.getMonth() + 1}/${renewalDate.getDate()}/${renewalDate.getFullYear()}`;
      dispatch({ type: ActionTypes.UPDATE_ACCOUNT_INFO, payload: { renewalDate: formatted } });
    } else if (info?.showStartNotification) {
      dispatch({ type: ActionTypes.SHOW_START_NOTIFICATION, payload: { endDate: info.endDate, show: true } });
    } else if (info?.showEndNotification) {
      dispatch({ type: ActionTypes.SHOW_END_NOTIFICATION, payload: { show: true } });
    } else {
      dispatch({ type: ActionTypes.UPDATE_ACCOUNT_INFO, payload: { renewalDate: '' } });
    }
  };
};

/**
 * Updates the Redux store with information stored in the user profile database.
 * @param {object} userProfile Value of a snapshot from the user profile database.
 */
export const updateUserInfo = (userProfile) => {
  return (dispatch) => {
    const { user_dateaccountcreated, user_firstname, user_lastname } = userProfile;
    dispatch({ type: ActionTypes.UPDATE_ACCOUNT_INFO, payload: { registrationDate: user_dateaccountcreated } });

    if (user_firstname) dispatch({ type: ActionTypes.UPDATE_USER_INFO, payload: { firstName: user_firstname } });
    if (user_lastname) dispatch({ type: ActionTypes.UPDATE_USER_INFO, payload: { lastName: user_lastname } });
  };
};

export const dismissStartNotification = () => {
  return (dispatch) => {
    firebaseService.setStartNotificationShown();
    dispatch({ type: ActionTypes.SHOW_START_NOTIFICATION, payload: { show: false } });
  };
};

export const dismissEndNotification = () => {
  return (dispatch) => {
    firebaseService.setEndNotificationShown();
    dispatch({ type: ActionTypes.SHOW_END_NOTIFICATION, payload: { show: false } });
  };
};

export const updateMobileModalShown = (isMobileModalShown) => {
  return { type: ActionTypes.UPDATE_MOBILE_MODAL_SHOWN, payload: isMobileModalShown };
};

export const updateFlyshowMode = (flyshowString) => {
  return async (dispatch) => {
    const isFlyshowMode = await firebaseService.verifyFlyshowString(flyshowString);
    dispatch({ type: ActionTypes.UPDATE_FLYSHOP_MODE, payload: isFlyshowMode });
  };
};

export const updateFlyshopPasswordModalShown = (isFlyshopPasswordModalShown) => {
  return { type: ActionTypes.UPDATE_FLYSHOP_PASSWORD_MODAL_SHOWN, payload: isFlyshopPasswordModalShown };
};

export const updateDefaultPromocode = (promocodeString) => {
  return { type: ActionTypes.UPDATE_DEFAULT_PROMOCODE, payload: promocodeString };
};

export const updateDefaultMembercode = (membercodeString) => {
  return { type: ActionTypes.UPDATE_DEFAULT_MEMBERCODE, payload: membercodeString };
};
