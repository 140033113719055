/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button } from 'components/TwoButtons';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  FilledInput, FormHelperText, Button as MaterialButton, Checkbox, InputLabel,
  InputAdornment,
} from '@material-ui/core';
import {
  setPaymentError, initiatePayment, setPaymentLoading, tryCouponCode, loadPrice,
} from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { checkIfEmailExists } from 'services/firebase';
import useIsFlyshopMode from 'utils/useIsFlyshopMode';
import styles from './styles.module.scss';

const inputStyles = makeStyles({
  input: {
    paddingTop: 12,
    margin: 0,
    fontSize: '14px',
  },
  root: {
    padding: 2,
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: 'transparent',
    border: '2px solid #E2E2E2',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      border: '2px solid #A5A5A5',
    },
    '&.Mui-error': {
      border: '2px solid #DB0000',
    },
    '& .MuiFilledInput-input::placeholder': {
      color: '#333333',
      opacity: 1,
      fontSize: '14px',
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: 'none',
    },
  },
});

const inputStyleHalfField = makeStyles({
  input: {
    paddingTop: 12,
    margin: 0,
    fontSize: '14px',
  },
  root: {
    width: '48%',
    padding: 2,
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: 'transparent',
    border: '2px solid #E2E2E2',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      border: '2px solid #A5A5A5',
    },
    '&.Mui-error': {
      border: '2px solid #DB0000',
    },
    '& .MuiFilledInput-input::placeholder': {
      color: '#333333',
      opacity: 1,
      fontSize: '14px',
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: 'none',
    },
  },
});

const inputStyleRightHalfField = makeStyles({
  input: {
    paddingTop: 12,
    margin: 0,
    fontSize: '14px',
  },
  root: {
    float: 'right',
    width: '48%',
    padding: 2,
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: 'transparent',
    border: '2px solid #E2E2E2',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      border: '2px solid #A5A5A5',
    },
    '&.Mui-error': {
      border: '2px solid #DB0000',
    },
    '& .MuiFilledInput-input::placeholder': {
      color: '#333333',
      opacity: 1,
      fontSize: '14px',
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: 'none',
    },
  },
});

const couponTextStyles = makeStyles({
  root: {
    marginRight: 'auto',
    color: (error) => (error ? '#DB0000' : '#457F00'),
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
});

const labelStyles = makeStyles({
  root: {
    fontSize: '14px',
    color: '#333333',
  },
});

const getDefaultPromoCode = () => {
  const { defaultPromoCode } = useSelector((state) => state.auth);
  if (defaultPromoCode == null) {
    return '';
  } else {
    return defaultPromoCode;
  }
};

const InputSection = ({
  onLoadChange, email, name, onTrialCreate, tryLogin, checkEmail = false, displayMembershipCard, onMembershipCardChange,
}) => {
  // const [name, setName] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [address1, setAddress1] = useState('');
  const [city, setCity] = useState('');
  const [stateAbbrv, setStateAbbrv] = useState('');
  const [zip, setZip] = useState('');
  const isFlyshopMode = useIsFlyshopMode();
  const [errors, setErrors] = useState({});
  const {
    isPaymentLoading, coupon, couponMessage,
  } = useSelector((state) => state.payment);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const classes = inputStyles();
  const inputStyleHalfFieldClasses = inputStyleHalfField();
  const inputStyleRightHalfFieldClasses = inputStyleRightHalfField();
  const labelClasses = labelStyles();
  const currentDefaultPromoCode = getDefaultPromoCode();

  const isCouponErrorMsg = couponMessage && (couponMessage.includes('invalid') || couponMessage.includes('required'));
  const couponTextClasses = couponTextStyles(isCouponErrorMsg);
  const { percentOff, price } = useSelector((state) => state.payment);
  const { unit_amount: unitAmount } = price ?? {};
  const annualPrice = unitAmount ? (percentOff ? ((unitAmount / 100) * (1 - (percentOff / 100))).toFixed(2) : '58.99') : '';

  useEffect(() => {
    dispatch(loadPrice());
  }, []);

  const submit = async () => {
    /* if (!name) {
      setErrors((prev) => { return { ...prev, name: 'Required.' }; });
    } else { */
    let addressFailed = false;
    if (!address1) {
      setErrors((prev) => { return { ...prev, address1: 'Required.' }; });
      addressFailed = true;
    }
    if (!city) {
      setErrors((prev) => { return { ...prev, city: 'Required.' }; });
      addressFailed = true;
    }
    if (!stateAbbrv) {
      setErrors((prev) => { return { ...prev, stateAbbrv: 'Required.' }; });
      addressFailed = true;
    }
    if (!zip) {
      setErrors((prev) => { return { ...prev, zip: 'Required.' }; });
      addressFailed = true;
    }
    if (addressFailed) {
      return;
    }

    if (!validateStateAbbrv(stateAbbrv)) {
      setErrors((prev) => { return { ...prev, stateAbbrv: 'Required.' }; });
      return;
    }

    if (!validateZip(zip)) {
      setErrors((prev) => { return { ...prev, zip: 'Required.' }; });
      return;
    }

    if (!stripe || !elements) return;
    dispatch(setPaymentLoading(true));

    const cardElement = elements.getElement(CardElement);
    try {
      const doesEmailExist = await checkIfEmailExists(email);
      if (doesEmailExist && checkEmail) {
        dispatch(setPaymentError('User already exists'));
        dispatch(setPaymentLoading(false));
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        dispatch(setPaymentError(error.message || 'There was an error with your credentials. Please try again.'));
        dispatch(setPaymentLoading(false));
      } else {
        dispatch(initiatePayment(paymentMethod, name || '', email, address1, city, stateAbbrv, zip, annualPrice, (data, err) => {
          if (onTrialCreate && !err) {
            onTrialCreate(data);
          }

          if (err && isFlyshopMode && tryLogin) {
            tryLogin();
          }
        }, isFlyshopMode));
      }
    } catch (error) {
      dispatch(setPaymentError(error.message || 'There was an error with your credentials. Please try again.'));
      dispatch(setPaymentLoading(false));
    }
    // }
  };

  const validateStateAbbrv = (stateInput) => {
    const stateAbbrvList = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO',
      'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'VI', 'WA', 'WV', 'WI', 'WY'];

    const stateUpper = stateInput.toUpperCase();
    if(stateAbbrvList.indexOf(stateUpper) > -1) {
      return true;
    } else {
      return false;
    }
  };

  const validateZip = (zipInput) => {
    if(zipInput.length == 5) {
      return true;
    } else {
      return false;
    }
  };

  const onChange = (e) => {
    if (e && e.target.name === 'name') {
      // setName(e.target.value);
      setErrors((prev) => { return { ...prev, name: '' }; });
    } else if (e?.target?.name === 'couponCode') {
      setCouponCode(e.target.value);
      setErrors((prev) => { return { ...prev, couponCode: '' }; });
    } else if (e?.target.name === 'address-line1 address-search' || e?.target.name === 'address-line1') {
      setAddress1(e.target.value);
      setErrors((prev) => { return { ...prev, address1: '' }; });
    } else if (e?.target.name === 'address-level2') {
      setCity(e.target.value);
      setErrors((prev) => { return { ...prev, city: '' }; });
    } else if (e?.target.name === 'address-level1') {
      setStateAbbrv(e.target.value);
      setErrors((prev) => { return { ...prev, stateAbbrv: '' }; });
    } else if (e?.target.name === 'postal-code') {
      setZip(e.target.value);
      setErrors((prev) => { return { ...prev, zip: '' }; });
    }
    dispatch(setPaymentError(''));
  };

  const submitCoupon = () => {
    dispatch(tryCouponCode(couponCode));
  };

  useEffect(() => {
    if (currentDefaultPromoCode != '') {
      setCouponCode(currentDefaultPromoCode);
      dispatch(tryCouponCode(currentDefaultPromoCode));
    }
  }, [currentDefaultPromoCode]);

  // Weird workaround.
  useEffect(() => {
    if (document.getElementsByClassName('__PrivateStripeElement').length > 0) {
      document.getElementsByClassName('__PrivateStripeElement')[0].children[0].style = 'border: none !important; margin: 0px !important; padding: 0px !important; width: 100% !important; min-width: 100% !important; overflow: hidden !important; display: block !important; user-select: none !important; will-change: transform !important; height: 16.8px;';
    }
    if (document.getElementsByClassName('StripeElement').length > 0) {
      document.getElementsByClassName('StripeElement')[0].style = 'width: 100%;';
    }
  }, [stripe]);

  useEffect(() => {
    if (isPaymentLoading && onLoadChange) onLoadChange(true);
    else if (onLoadChange) onLoadChange(false);
  }, [isPaymentLoading]);

  const disabled = !stripe || isPaymentLoading;

  return (
    <>
      <div style={{
        display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <div className={styles.paymentLabelContainer}>
          <h5 className={styles.paymentHeaderLabel}>Payment Information</h5>
        </div>
        <div className={styles['cardholder-name']}>
          {/* <FilledInput classes={classes} value={name} onChange={onChange} error={!!errors.name} name="name" placeholder="Cardholder Name" fullWidth /> */}
          <FormHelperText error>{errors.name || ' '}</FormHelperText>
        </div>

        <div className={styles.card}>
          <CardElement onChange={() => onChange()}
            options={{
              style: {
                base: {
                  fontSize: '14px',
                  backgroundColor: 'white',
                  fontFamily: `Poppins, AvenirNext, Avenir Next, Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
                  border: '2px solid #E2E2E2',
                },
              },
            }}
          />
        </div>

        <div className={styles.coupon}>
          <div style={{ width: '100%', display: 'flex' }}>
            <FilledInput
              classes={classes}
              value={couponCode}
              onChange={onChange}
              error={!!errors.couponCode}
              name="couponCode"
              placeholder="Coupon Code"
              fullWidth
              endAdornment={(
                <InputAdornment position="end">
                  <MaterialButton
                    onClick={submitCoupon}
                    color="primary"
                    variant="text"
                    fullWidth
                    disabled={disabled}
                    style={{
                      background: 'white',
                      color: '#1553D3',
                    }}
                  >APPLY
                  </MaterialButton>
                </InputAdornment>
              )}
            />
          </div>
        </div>
        <FormHelperText error={isCouponErrorMsg} classes={couponTextClasses}>{couponMessage || ''}</FormHelperText>
      </div>
      <div className={styles['membership-code']}>
        <Checkbox
          name="membership"
          id="membership"
          color="primary"
          size="small"
          disableRipple
          checked={displayMembershipCard}
          onChange={(e) => onMembershipCardChange(e.target.checked)}
        />
        <InputLabel htmlFor="membership" classes={labelClasses}>Use Membership Card</InputLabel>
      </div>

      <div className={styles.paymentLabelContainer}>
        <h5 className={styles.paymentHeaderLabel}>Billing Address</h5>
      </div>
      <div style={{ width: '100%' }}>
        <form className="flex flex--column">
          <FilledInput
            autoComplete="address-line1"
            placeholder="Address"
            name="address-line1"
            required
            fullWidth
            value={address1}
            classes={classes}
            onChange={onChange}
            error={!!errors.address1}
          />
          <FilledInput
            autoComplete="address-level2"
            placeholder="City"
            name="address-level2"
            fullWidth
            value={city}
            classes={classes}
            onChange={onChange}
            error={!!errors.city}
            required
          />
          <div style={{ width: '100%' }}>
            <FilledInput
              autoComplete="address-level1"
              placeholder="State Abbr"
              name="address-level1"
              value={stateAbbrv}
              classes={inputStyleHalfFieldClasses}
              onChange={onChange}
              error={!!errors.stateAbbrv}
              inputProps={{ maxLength: 2 }}
              required
            />
            <FilledInput
              autoComplete="postal-code"
              placeholder="Zip"
              name="postal-code"
              value={zip}
              classes={inputStyleRightHalfFieldClasses}
              onChange={onChange}
              error={!!errors.zip}
              inputProps={{ maxLength: 5 }}
              required
            />
          </div>
        </form>
      </div>

      <Button label="Start Your Free Trial" className={`${styles.primary} ${styles.bright}`} disabled={disabled} onClick={submit} />
    </>
  );
};


export default InputSection;
