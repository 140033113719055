import { ActionTypes } from 'actions';
import { layersEnum, gageChartTimes, gageChartTypes } from 'lib/constants';

const MIN_ZOOM = 0;
const MAX_ZOOM = 22;

const initialState = {
  coords: {
    zoom: 10,
    lat: 42.9634,
    lng: -85.6681,
  },
  baseLayer: layersEnum.roads,
  mapStyle: layersEnum.basic,
  filters: {
    showEasements: false,
    showTailwaters: false,
    showPopular: false,
  },
  classes: {
    showClass0: true,
    showClass1: true,
    showClass2: true,
    showClass3: true,
  },
  otherFeatures: {
    counties: true,
    flyShops: true,
    gages: false,
    parking: true,
    boat: true,
    camp: true,
    hike: true,
    accessPoints: true,
    bridges: true,
    lakes: false,
  },
  elevation: null,
  stream: {},
  currentHUC: null,
  streamElevationData: [],
  streamGageData: [],
  streamUserData: {},
  streamGeography: {},
  elevationMarker: null,
  isElevationChartVisible: false,
  isRiverMilesVisible: false,
  riverMilesPrimaryLabel: null,
  riverMilesStep: 0,
  riverMilesMarkers: {
    startPoint: null,
    endPoint: null,
  },
  riverMilesParentLine: null,
  riverMilesSlicedLineCoordinates: {
    startPoint: null,
    endPoint: null,
  },
  riverMilesDistance: null,
  isSlopeChartVisible: false,
  searchResults: [],
  activeTools: {
    marker: false,
    line: false,
    area: false,
  },
  marker: {
    tempMarker: null,
    tapMarker: null,
    locationMarker: null,
    redMarker: null,
    markerId: '',
    currentMarkerType: 0,
  },
  line: {
    tempLine: null,
    lineId: '',
  },
  area: {
    tempArea: null,
    areaId: '',
  },
  map: null,
  draw: null,
  gage: {},
  gageData: {
    flow: [],
    height: [],
    flowId: '',
    heightId: '',
  },
  isGageChartShown: false,
  currentTime: gageChartTimes.month,
  currentChartType: gageChartTypes.flow,
  activeSidebar: '',
  delayedPanelShown: false,
  isUpgradeModalShown: false,
  isSnapModalShown: false,
  isFileUploadSectionShown: false,
  isConfirmUploadModalShown: false,
  streamRegulations: [],
  streamMetadata: [],
  streamFlyShops: [],
  supportedStates: [],
  isViewPOIDetails: false,
  trPOIFeature: {},
};

const MapReducer = (state = initialState, action) => {
  switch (action.type) {
  case ActionTypes.UPDATE_COORDINATES: {
    const { zoom, lat, lng } = action.payload;
    return {
      ...state, coords: { zoom, lat, lng },
    };
  }
  case ActionTypes.UPDATE_BASE_LAYER:
    return { ...state, baseLayer: action.payload };
  case ActionTypes.UPDATE_MAP_STYLE:
    return { ...state, mapStyle: action.payload };
  case ActionTypes.ZOOM_IN:
    return { ...state, coords: { ...state.coords, zoom: Math.min(parseInt(state.coords.zoom, 10) + 1, MAX_ZOOM) } };
  case ActionTypes.ZOOM_OUT:
    return { ...state, coords: { ...state.coords, zoom: Math.max(parseInt(state.coords.zoom, 10) - 1, MIN_ZOOM) } };
  case ActionTypes.UPDATE_FILTER:
    return { ...state, filters: { ...state.filters, ...action.payload } };
  case ActionTypes.UPDATE_CLASSES:
    return { ...state, classes: { ...state.classes, ...action.payload } };
  case ActionTypes.UPDATE_FEATURES:
    return { ...state, otherFeatures: { ...state.otherFeatures, ...action.payload } };
  case ActionTypes.UPDATE_CURRENT_STREAM:
    return { ...state, stream: action.payload };
  case ActionTypes.UPDATE_SEARCH_RESULTS:
    return { ...state, searchResults: action.payload };
  case ActionTypes.UPDATE_ACTIVE_TOOLS:
    return { ...state, activeTools: { ...state.activeTools, ...action.payload } };
  case ActionTypes.SET_TEMP_MARKER:
    return { ...state, marker: { ...state.marker, tempMarker: action.payload } };
  case ActionTypes.SET_TAP_MARKER:
    return { ...state, marker: { ...state.marker, tapMarker: action.payload } };
  case ActionTypes.SET_RED_MARKER:
    return { ...state, marker: { ...state.marker, redMarker: action.payload } };
  case ActionTypes.SET_LOCATION_MARKER:
    return { ...state, marker: { ...state.marker, locationMarker: action.payload } };
  case ActionTypes.SET_MAP:
    return { ...state, map: action.payload };
  case ActionTypes.SET_DRAW:
    return { ...state, draw: action.payload };
  case ActionTypes.SET_EDITING_MARKER_ID:
    return { ...state, marker: { ...state.marker, markerId: action.payload } };
  case ActionTypes.SET_TR_POI_FEATURE:
    return { ...state, trPOIFeature: { ...state, trPOIFeature: action.payload } };
  case ActionTypes.SET_MARKER_TYPE:
    return { ...state, marker: { ...state.marker, currentMarkerType: action.payload } };
  case ActionTypes.SET_TEMP_LINE:
    return { ...state, line: { ...state.line, tempLine: action.payload } };
  case ActionTypes.SET_EDITING_LINE_ID:
    return { ...state, line: { ...state.line, lineId: action.payload } };
  case ActionTypes.SET_TEMP_AREA:
    return { ...state, area: { ...state.area, tempArea: action.payload } };
  case ActionTypes.SET_EDITING_AREA_ID:
    return { ...state, area: { ...state.area, areaId: action.payload } };
  case ActionTypes.SET_CURRENT_HUC:
    return { ...state, currentHUC: action.payload };
  case ActionTypes.SET_STREAM_ELEVATION:
    return { ...state, streamElevationData: action.payload };
  case ActionTypes.SET_STREAM_GAGE_DATA:
    return { ...state, streamGageData: action.payload };
  case ActionTypes.SET_SLOPE_CHART_VISIBLITY:
    return { ...state, isSlopeChartVisible: action.payload };
  case ActionTypes.SET_ELEVATION_CHART_VISIBLITY:
    return { ...state, isElevationChartVisible: action.payload };
  case ActionTypes.SET_RIVER_MILES_VISIBLITY:
    return { ...state, isRiverMilesVisible: action.payload };
  case ActionTypes.SET_RIVER_MILES_PRIMARY_LABEL:
    return { ...state, riverMilesPrimaryLabel: action.payload };
  case ActionTypes.SET_RIVER_MILES_STEP:
    return { ...state, riverMilesStep: action.payload };
  case ActionTypes.SET_RIVER_MILES_MARKERS:
    return { ...state, riverMilesMarkers: { ...state.riverMilesMarkers, ...action.payload } };
  case ActionTypes.SET_RIVER_MILES_PARENT_LINE:
    return { ...state, riverMilesParentLine: action.payload };
  case ActionTypes.SET_RIVER_MILES_SLICED_LINE_COORDINATES:
    return { ...state, riverMilesSlicedLineCoordinates: { ...state.riverMilesSlicedLineCoordinates, ...action.payload } };
  case ActionTypes.SET_RIVER_MILES_DISTANCE:
    return { ...state, riverMilesDistance: action.payload };
  case ActionTypes.SET_ELEVATION_MARKER:
    return { ...state, elevationMarker: action.payload };
  case ActionTypes.SET_CURRENT_GAGE:
    return { ...state, gage: action.payload };
  case ActionTypes.SET_GAGE_DATA:
    return {
      ...state,
      gageData: {
        flow: typeof action.payload?.flow !== 'undefined' ? action.payload?.flow : state.gageData.flow,
        height: typeof action.payload?.height !== 'undefined' ? action.payload?.height : state.gageData.height,
        flowId: typeof action.payload?.flowId !== 'undefined' ? action.payload?.flowId : state.gageData.flowId,
        heightId: typeof action.payload?.heightId !== 'undefined' ? action.payload?.heightId : state.gageData.heightId,
      },
    };
  case ActionTypes.SET_IS_GAGE_CHART_SHOWN:
    return { ...state, isGageChartShown: action.payload };
  case ActionTypes.CLEAR_GAGE_DATA:
    return { ...state, gageData: { ...initialState.gageData } };
  case ActionTypes.SET_CURRENT_TIME:
    return { ...state, currentTime: action.payload };
  case ActionTypes.SET_CURRENT_CHART_TYPE:
    return { ...state, currentChartType: action.payload };
  case ActionTypes.SET_ACTIVE_SIDEBAR:
    return { ...state, activeSidebar: action.payload };
  case ActionTypes.SET_DELAYED_PANEL:
    return { ...state, delayedPanelShown: action.payload };
  case ActionTypes.SET_STREAM_USER_DATA:
    return { ...state, streamUserData: action.payload };
  case ActionTypes.SET_STREAM_GEOGRAPHY:
    return { ...state, streamGeography: action.payload };
  case ActionTypes.UPDATE_STREAM_GEOGRAPHY:
    return { ...state, streamGeography: { ...state.streamGeography, ...action.payload } };
  case ActionTypes.SET_UPGRADE_MODAL:
    return { ...state, isUpgradeModalShown: action.payload };
  case ActionTypes.SET_SNAP_MODAL:
    return { ...state, isSnapModalShown: action.payload };
  case ActionTypes.SET_GOOGLE_PLACES_UPLOAD:
    return { ...state, isFileUploadSectionShown: action.payload };
  case ActionTypes.SET_CONFIRM_UPLOAD_MODAL:
    return { ...state, isConfirmUploadModalShown: action.payload };
  case ActionTypes.SET_STREAM_REGULATIONS:
    return { ...state, streamRegulations: action.payload };
  case ActionTypes.SET_STREAM_METADATA:
    return { ...state, streamMetadata: action.payload };
  case ActionTypes.SET_STREAM_FLY_SHOPS:
    return { ...state, streamFlyShops: action.payload };
  case ActionTypes.SET_SUPPORTED_STATES:
    return { ...state, supportedStates: action.payload };
  case ActionTypes.UPDATE_ELEVATION:
    return { ...state, elevation: action.payload };
  default:
    return state;
  }
};

export default MapReducer;
