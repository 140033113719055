/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import styles from './styles.module.scss';

const PhotoDisplay = ({ images, onDelete = () => {} }) => {
  const [currentImage, setCurrentImage] = useState(-1);
  const currentImageComponent = images[currentImage];

  const onImageWillChange = (index) => {
    setCurrentImage(index);
  };

  const handleClose = () => setCurrentImage(-1);

  const styleSmall = () => {
    return ({
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    });
  };
  const styleTile = () => {
    return ({
      width: '163px',
      height: '160px',
    });
  };

  return (
    <>
      <Gallery
        className={styles.gallery}
        images={images.map(({ url }) => {
          return {
            src: url, width: 40, height: 40,
          };
        })}
        thumbnailStyle={styleSmall}
        onClick={onImageWillChange}
        enableImageSelection={false}
        tileViewportStyle={styleTile}
      />
      {!!currentImageComponent && (
        <Lightbox
          mainSrc={currentImageComponent.url}
          mainSrcThumbnail={currentImageComponent.url}
          onCloseRequest={handleClose}
          toolbarButtons={[<button type="button" className={styles.delete} onClick={() => { onDelete(images[currentImage]); }}><DeleteIcon /></button>]}
        />
      )}
    </>
  );
};

PhotoDisplay.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
  })),
  onDelete: PropTypes.func,
};

export default PhotoDisplay;
