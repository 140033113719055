import React, { useEffect } from 'react';
import Image from 'components/Image';
import {
  calender, characteristicRiver, clipboard, easementEmpty, easementFilled, popularEmpty, popularFilled, tailwaterEmpty,
  tailwaterFilled,
} from 'assets';
import { useDispatch, useSelector } from 'react-redux';
import Regulations from 'components/StreamLabel/Regulations';
import UpgradeBox from 'components/UpgradeBox';
import useIsPro from 'utils/useIsPro';
import { setStreamRegulationsForCurrentStream } from 'actions';
import styles from './styles.module.scss';

const riverCharacteristics = {
  popular: {
    no: 'This river is not considered to be popular by national standards. use our special filters to find \'Popular rivers\' for fly fishing',
    yes: 'This river is considered popular and well-known. Expect company on the water!',
  },
  tailwater: {
    no: 'This section is not a tailwater, but likely a spring fed or freestone river. Use our filters to find tailwater-specific opportunities.',
    yes: 'This section is considered to be a tailwater! These are unique fisheries that we\'ve hand curated; tap to learn more.',
  },
  easement: {
    no: 'This river does not have a fishing easement. Use our access map layers to find fishing easement boundaries and other public access.',
    yes: 'This river has a designated fishing easement! Use our access map layers to find fishing easement boundaries and other public access.',
  },
};

const proTitle = 'Local Information';
const proDescription = 'Unlock detailed and curated local information for this river, including river characteristics, regulations and local classifications, seasonality, and more.';


function LocalInfoTab() {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const { map } = useSelector((state) => state.map);
  const currentStream = useSelector((state) => state.map.stream);
  const {
    is_popular, is_tailwater, has_easement,
  } = currentStream;

  const streamMetadata = useSelector((state) => state.map.streamMetadata);
  const { gen_season } = streamMetadata.find((s) => s.gen_season !== undefined) || {};

  useEffect(() => {
    dispatch(setStreamRegulationsForCurrentStream(map));
  }, [currentStream]);

  return (
    <div className={styles.mainContainer}>
      {!isPro && <div className={styles.boxUpgrade}> <UpgradeBox title={proTitle} description={proDescription} /> </div>}

      <div className={`${styles.container} ${!isPro ? styles.blurBackground : ''}`}>
        <div>
          <div className={styles.titleContainer}>
            <Image src={calender} width={45} height={45} />
            <div className={styles.title}>General Season Guidance</div>
          </div>
          {gen_season && (
            <div className={styles.description}>{gen_season}
            </div>
          )}
        </div>
        <div>
          <div className={styles.titleContainer}>
            <Image src={characteristicRiver} width={45} height={45} />
            <div className={styles.title}>River Characteristics</div>
          </div>
          <div className={styles.characteristicContainer}>
            <div>
              <Image src={is_popular === 1 ? popularFilled : popularEmpty} width={85} height={35} className={styles.characteristicImage} />
              <div className={styles.characteristicDetail}>{is_popular === 1 ? riverCharacteristics.popular.yes : riverCharacteristics.popular.no}</div>
            </div>
            <div>
              <Image src={is_tailwater === 1 ? tailwaterFilled : tailwaterEmpty} width={85} height={35} className={styles.characteristicImage} />
              <div className={styles.characteristicDetail}>{is_tailwater === 1 ? riverCharacteristics.tailwater.yes : riverCharacteristics.tailwater.no}</div>
            </div>
            <div>
              <Image src={has_easement === 1 ? easementFilled : easementEmpty} width={85} height={35} className={styles.characteristicImage} />
              <div className={styles.characteristicDetail}>{has_easement === 1 ? riverCharacteristics.easement.yes : riverCharacteristics.easement.no}</div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.titleContainer}>
            <Image src={clipboard} width={45} height={45} />
            <div className={styles.title}>Special Regulations and Classifications</div>
          </div>
          <Regulations />
        </div>
      </div>
    </div>
  );
}

export default LocalInfoTab;
