import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { initAmplitude, startAmplitude } from 'utils/amplitude';
import { loadStripe } from '@stripe/stripe-js';
import { createStore, applyMiddleware } from 'redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import thunk from 'redux-thunk';
import reducers from 'reducers';
import mapboxgl from 'mapbox-gl';
import './styles/global.scss';
import './styles/fonts.scss';

import 'smartbanner.js/dist/smartbanner.min.css';
import 'smartbanner.js';
import App from './App';
import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const stripePromise = loadStripe(process.env.REACT_APP_DEV ? process.env.REACT_APP_STRIPE_KEY_DEV : process.env.REACT_APP_STRIPE_KEY);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3773B8',
    },
  },
  typography: {
    fontFamily: [
      'Raleway',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
});

export const store = createStore(reducers, applyMiddleware(thunk));

initAmplitude();
startAmplitude();

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
