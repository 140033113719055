import React from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import ThreeButtons from 'components/ThreeButtons';
import {
  distanceIcon, elevationIcon, closeIcon,
} from 'assets';
import CloseIcon from '@material-ui/icons/Close';
import { store } from '../../index';
import styles from './styles.module.scss';

const PopupMapTap = ({
  title, subtitle, type, distance, elevation, leftButton, middleButton, rightButton,
}) => {
  const onClose = async () => {
    const { map } = store.getState().map;
    const { tapMarker } = store.getState().map.marker;
    map.fire('closeAllPopups');
    if (tapMarker) {
      tapMarker.remove();
    }
  };
  return (
    <div className={styles.popup}>
      <div className={styles.close}
        role="button"
        onClick={onClose}
        tabIndex={0}
      >
        <CloseIcon />
        {/* <Image alt="Close" src={closeIcon} width="16" /> */}
      </div>
      <div className={styles.content}>
        <div className={styles.text}>
          <h5>{title}</h5>
          <h6>{subtitle}</h6>
          <p>{type}</p>
        </div>
      </div>
      <div className={styles.distances}>
        <div className={styles.distanceInner}>
          <Image alt="Distance" src={distanceIcon} width="20" />
          <p>{distance}</p>
        </div>
        <div className={styles.distanceInner}>
          <Image alt="Elevation" src={elevationIcon} width="20" />
          <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft elev.</p>
        </div>
      </div>

      <ThreeButtons leftButton={leftButton} middleButton={middleButton} rightButton={rightButton} />
    </div>
  );
};

PopupMapTap.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  distance: PropTypes.string,
  elevation: PropTypes.string,
  leftButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  middleButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  rightButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

export default PopupMapTap;
