/* eslint-disable no-undef */
import { Loader } from '@googlemaps/js-api-loader';
import * as firebaseService from './firebase';

const elevationDataPointLimit = 500;

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_ELEVATION,
  version: 'beta',
});
loader.load();

/**
 * Returns the Google elevation data associated with a list of coordinates.
 * @param {array[LatLng]} coordinates List of coordinates for which to derive elevation data.
 */
export const getElevationDataFromCoordinates = async (coordinates) => {
  let localCoords = [];

  // Trim the array if necessary.
  if (coordinates.length > elevationDataPointLimit) {
    const divisor = Math.ceil(coordinates.length / elevationDataPointLimit);
    coordinates.forEach((coord, i) => { if (i % divisor === 0) localCoords.push(coord); });
  } else {
    localCoords = [...coordinates];
  }

  if (google) {
    const elevator = new google.maps.ElevationService();
    const result = await elevator.getElevationAlongPath({ path: localCoords, samples: localCoords.length });
    return result;
  } else {
    return null;
  }
};

/**
 * Returns the elevation data associated with a stream ID.
 * @param {string} streamGid Elevation data of a stream.
 * @returns {array[elevationResult]} Array of elevationResults.
 */
export const getElevationData = async (streamGid) => {
  const coords = await firebaseService.getStreamCoordinates(streamGid);

  const normalizedCoords = [];
  coords.forEach((line) => {
    // This is now a list of coordinates.
    // Normalize the coordinates so they're LatLng objects.
    line.forEach(([lng, lat]) => normalizedCoords.push({ lng, lat }));
  });
  const { results } = await getElevationDataFromCoordinates(normalizedCoords);
  return results;
};

/**
 * Returns the elevation data converted to feet associated with coordinates.
 * @param {LatLng} coordinates Coordinates for which to derive elevation data.
 * @returns {Number} Elevation value in feet.
 */
export const getElevationPoint = async (coordinates) => {
  if (google) {
    const elevator = new google.maps.ElevationService();
    const result = await elevator.getElevationForLocations({ locations: [coordinates] });
    const elev = (result?.results[0]?.elevation) * 3.28084;
    const rounded = Number(elev).toFixed(0);
    return rounded;
  } else {
    return null;
  }
};
