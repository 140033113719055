import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const LayersSection = ({ title, children }) => {
  return (
    <div className={styles['layers-section']}>
      <h4>{title}</h4>
      {children}
    </div>
  );
};

LayersSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default LayersSection;
