import {
  Checkbox, FilledInput, FormHelperText, InputLabel, makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button } from 'components/TwoButtons';
import { verifyMembershipRedemptionCode, checkIfEmailExists } from 'services/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentInfo } from 'actions';
import { sendAmplitudeData } from 'utils/amplitude';
import styles from './styles.module.scss';

const inputStyles = makeStyles({
  input: {
    fontSize: '16px',
    paddingTop: 12,
    paddingBottom: 12,
    background: 'white',
    color: 'black',
  },
  root: {
    backgroundColor: 'white',
    border: '2px solid #E2E2E2',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      border: '2px solid #A5A5A5',
    },
    '&.Mui-error': {
      border: '2px solid red',
    },
    '& .MuiFilledInput-input::placeholder': {
      color: '#333333',
      opacity: 1,
      fontSize: '14px',
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: 'none',
    },
  },
});

const labelStyles = makeStyles({
  root: {
    fontSize: '14px',
    color: '#333333',
  },
});

const getDefaultMemberCode = () => {
  const { defaultMemberCode } = useSelector((state) => state.auth);
  if(defaultMemberCode == null) {
    return '';
  } else {
    return defaultMemberCode;
  }
};

const MembershipCard = ({
  createAccount, email, onMembershipCardChange, displayMembershipCard,
}) => {
  const classes = inputStyles();
  const labelClasses = labelStyles();
  const [membershipCode, setMembershipCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const currentDefaultMemberCode = getDefaultMemberCode();

  const tryMembershipCode = async (code) => {
    setErrorMessage('');
    if (!code) {
      setErrorMessage('Please enter a membership code.');
      return;
    }

    /*
    if (email) {
      const doesEmailExist = await checkIfEmailExists(email);
      if (doesEmailExist) {
        setErrorMessage('User already exists');
        return;
      }
    }
    */

    try {
      const verifiedCodeData = await verifyMembershipRedemptionCode(code);
      if (!verifiedCodeData) {
        setErrorMessage('Invalid membership code.');
        return;
      }

      // Custom Amplitude config
      const config = {
        card: code,
      };
      sendAmplitudeData('web_membership_card_code_used', config);

      dispatch(setPaymentInfo(verifiedCodeData));
      if (createAccount) createAccount(verifiedCodeData);
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const submit = async () => {
    tryMembershipCode(membershipCode);
  };

  useEffect(() => {
    if(currentDefaultMemberCode != '') {
      setMembershipCode(currentDefaultMemberCode);
      tryMembershipCode(currentDefaultMemberCode);
    }
  }, [currentDefaultMemberCode]);

  return (
    <div className={styles.membership}>
      <div style={{ width: '100%' }}>
        <FilledInput
          classes={classes}
          name="membershipCard"
          placeholder="Membership Card Number"
          error={!!errorMessage}
          fullWidth
          value={membershipCode}
          onChange={(e) => setMembershipCode(e.target.value)}
        />
      </div>
      <FormHelperText error style={{ marginRight: 'auto' }}>{errorMessage || ''}</FormHelperText>
      <div className={styles['membership-code']}>
        <Checkbox
          name="membership"
          id="membership"
          color="primary"
          disableRipple
          size="small"
          checked={displayMembershipCard}
          onChange={(e) => onMembershipCardChange(e.target.checked)}
        />
        <InputLabel htmlFor="membership" classes={labelClasses}>Use Membership Card</InputLabel>
      </div>

      <Button label="Redeem Card" onClick={submit} className={`${styles.primary} ${styles.bright} ${styles.start}`} />
    </div>
  );
};

export default MembershipCard;
