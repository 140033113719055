import React from 'react';
import ModalTemplate from 'components/ModalTemplate';
import TwoButtons from 'components/TwoButtons';
import { sendAmplitudeEvent } from 'utils/amplitude';
import { useSelector, useDispatch } from 'react-redux';
import { setIsUpgradeModalShown, setIsPaymentModalShown } from 'actions';

const UpgradeModal = () => {
  const dispatch = useDispatch();
  const { isUpgradeModalShown } = useSelector((state) => state.map);
  const { isPaymentModalShown } = useSelector((state) => state.payment);

  const close = () => { dispatch(setIsUpgradeModalShown(false)); };

  const openPayment = () => {
    sendAmplitudeEvent('web_paymentmodal_shown');
    dispatch(setIsPaymentModalShown(true));
    dispatch(setIsUpgradeModalShown(false));
  };

  return (
    <ModalTemplate close={close} open={isUpgradeModalShown} delayFade={isPaymentModalShown}>
      <div>This feature is only accessible to Pro users. To proceed, please upgrade your account with a TroutRoutes Pro subscription.</div>
      <TwoButtons leftButton={{ label: 'Upgrade', onClick: openPayment }} rightButton={{ label: 'Continue', onClick: close }} />
    </ModalTemplate>
  );
};

export default UpgradeModal;
