import React from 'react';
import styles from './styles.module.scss';

const Disclaimer = () => {
  return (
    <div className={styles.helper}>You will not be charged until after the 7 day trial period. Cancel before then to avoid charges.</div>
  );
};

export default Disclaimer;
