/* eslint-disable max-len */
import React from 'react';
import Popover from '@material-ui/core/Popover';
import styles from './styles.module.scss';

const classes = [
  {
    name: 'Class 3',
    color: '#0f6ff5',
    title: 'Class 3 - Marginal Trout Stream',
    description:
      'This stream has a Class 3 rating from TroutRoutes. Class 3 streams are designated trout streams with marginal public access and quality. In most cases, these are streams that likely contain trout (natural or stocked) but have minimal information on them or known to be limited in quality or public access.',
    index: 3,
  },
  {
    name: 'Class 2',
    color: '#22C6FC',
    title: 'Class 2 - Good Trout Stream',
    description:
      'This stream has a Class 2 rating from TroutRoutes. Class 2 streams are designated trout streams with decent public access and quality.',
    index: 2,
  },
  {
    name: 'Class 1',
    color: '#33cc33',
    title: 'Class 1 - Top Rated',
    description:
      'This stream has a Class 1 rating from TroutRoutes. Class 1 streams are top rated, second only to designated Gold Medal and Blue Ribbon streams. These are typically great for public access, have several access points, and are well known to be great quality in both abundance of trout and size.',
    index: 1,
  },
  {
    name: 'Gold',
    color: '#e8b923',
    title: 'Gold Medal / Blue Ribbon Trout Stream',
    description:
      'This stream is a Gold Medal or Blue Ribbon Trout stream from TroutRoutes, the top rated class available. Consider these streams the top trout streams in the country. These stream sections are typically designated by a qualified body such as the state\'s DNR, or occasionally designated by an organization such as Trout Unlimited. These streams have superior public access, trout reproduction, and overall habitat quality.',
    index: 0,
  },
];

const ClassDescriptions = ({ highlightedClass }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const selectedClass = classes.find(
    (streamClass) => streamClass.index === parseInt(highlightedClass, 10),
  ) || {};

  return (
    <>
      <div className={styles.classes}>
        <div className={styles.classContainer}>
          <div className={styles.classText}>Rating</div>
          <div
            aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            className={styles.class}
            key={selectedClass.name}
            style={{
              borderColor: selectedClass.color,
              backgroundColor: selectedClass.color,
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            {selectedClass.name}
          </div>
        </div>
        <Popover
          id="mouse-over-popover"
          className={styles.popover}
          classes={{
            paper: classes.paper,
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div className={styles['class-description']}>
            <h4>{selectedClass.title}</h4>
            <p>{selectedClass.description}</p>
          </div>
        </Popover>
      </div>
    </>
  );
};

export default ClassDescriptions;
