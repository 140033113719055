import { ActionTypes } from 'actions';

const initialState = {
  favorites: [],
  markers: {},
  markerObjects: [],
  lines: {},
  lineIds: {}, // This maps a line ID to its Firebase ID.
  areas: {},
  areaIds: {},
  region: -1,
};

const UserReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
  case ActionTypes.UPDATE_FAVORITES:
    return { ...state, favorites: payload };
  case ActionTypes.UPDATE_MARKERS:
    return { ...state, markers: payload };
  case ActionTypes.ADD_MARKER_OBJECT:
    return { ...state, markerObjects: state.markerObjects.concat([payload]) };
  case ActionTypes.CLEAR_MARKER_OBJECTS:
    return { ...state, markerObjects: [] };
  case ActionTypes.UPDATE_LINES:
    return { ...state, lines: payload };
  case ActionTypes.UPDATE_LINE_ID:
    return { ...state, lineIds: { ...state.lineIds, ...payload } };
  case ActionTypes.RESET_LINE_IDS:
    return { ...state, lineIds: {} };
  case ActionTypes.RESET_AREA_IDS:
    return { ...state, areaIds: {} };
  case ActionTypes.UPDATE_AREA_ID:
    return { ...state, areaIds: { ...state.areaIds, ...payload } };
  case ActionTypes.UPDATE_AREAS:
    return { ...state, areas: payload };
  case ActionTypes.UPDATE_REGION:
    return { ...state, region: payload };
  default:
    return state;
  }
};

export default UserReducer;
