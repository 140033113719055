import React from 'react';
import { setIsPaymentModalShown, setIsUpgradeModalShown } from 'actions';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

const UpgradeBox = ({ title, description }) => {
  const dispatch = useDispatch();
  const openPayment = () => {
    dispatch(setIsPaymentModalShown(true));
    dispatch(setIsUpgradeModalShown(false));
  };

  return (
    <div className={styles.upgradeBox}>
      <div className={styles.row1}>{title}</div>
      <div className={styles.row2}>
        <div className={styles.proBox}> PRO </div>
        <div className={styles.description}>
          {description}
        </div>
      </div>
      <div className={styles.row3}>
        <button type="button" onClick={openPayment}>Upgrade to PRO</button>
      </div>
    </div>
  );
};

export default UpgradeBox;
