import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Image from 'components/Image';
import toast from 'react-hot-toast';
import { mapMarkerTypes, directionsURL } from 'lib/constants';
import { setTempMarker, setMarkerType, setTRPOIFeature } from 'actions';
import {
  mapParking, markerBoundaryBlue, mapBoat, mapCamp, mapTrailhead, mapAccess, markerTypePOI, mapEvents, mapHabitat, mapStocking, mapDam, distanceIcon, elevationIcon, closeIcon, directionsIcon2, shareIcon2, createIcon2,
  mapLakes, mapRarewaters, mapSP, mapNP, riverIcon, mapShop,
} from 'assets';
import CloseIcon from '@material-ui/icons/Close';
import * as mapboxService from '../../services/mapbox';
import * as firebaseService from '../../services/firebase';
import { getElevationPoint } from '../../services/elevation';
import { createMarkerWithIcon, createMarkerWithoutIcon } from '../../services/mapbox';
import styles from './styles.module.scss';

const POIDetailsTR = () => {
  const dispatch = useDispatch();
  const { trPOIFeature } = useSelector((state) => state.map.trPOIFeature);
  const map = useSelector((state) => state.map.map);
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const [type, setType] = useState('');
  const [typeIndex, setTypeIndex] = useState(null);
  const [distance, setDistance] = useState();
  const [elevation, setElevation] = useState(0);
  const [description, setDescription] = useState('');
  const [coordinate, setCoordinate] = useState([]);
  const [link1, setLink1] = useState('');
  const [link2, setLink2] = useState('');
  const [actionLink, setActionLink] = useState('');
  const [iconURL, setIconURL] = useState('');
  const { tapMarker } = useSelector((state) => state.map.marker);

  useEffect(async () => {
    if(trPOIFeature && trPOIFeature.layer.id) {
      if(trPOIFeature.properties.GeoName) {
        setTitle(trPOIFeature.properties.GeoName);
      } else if (trPOIFeature.properties['Event Title']) {
        setTitle(trPOIFeature.properties['Event Title']);
      }

      if (trPOIFeature.properties.SecondaryLabel) {
        if (trPOIFeature.properties.SecondaryLabel.includes(',')) {
          setAddress(trPOIFeature.properties.SecondaryLabel.replace(',', ', '));
        } else {
          setAddress(trPOIFeature.properties.SecondaryLabel);
        }
      } else if (trPOIFeature.properties.Location) {
        setAddress(trPOIFeature.properties.Location);
      } else {
        setAddress('');
      }

      if(trPOIFeature.layer.id == 'access_parking') {
        setType('Parking');
        setTypeIndex(3);
        setIconURL(mapParking);
      } else if(trPOIFeature.layer.id == 'access_camp') {
        setType('Camping');
        setTypeIndex(2);
        setIconURL(mapCamp);
      } else if(trPOIFeature.layer.id == 'access_dam') {
        setType('Dam');
        setTypeIndex(9);
        setIconURL(mapDam);
      } else if(trPOIFeature.layer.id == 'access_access') {
        setType('Fishing Access Point');
        setTypeIndex(4);
        setIconURL(mapAccess);
      } else if(trPOIFeature.layer.id == 'access_trail') {
        setType('Trailhead');
        setTypeIndex(5);
        setIconURL(mapTrailhead);
      } else if(trPOIFeature.layer.id == 'access_boat') {
        setType('Boat Ramp');
        setTypeIndex(1);
        setIconURL(mapBoat);
      } else if(trPOIFeature.layer.id == 'access_habitat') {
        setType('Habitat Improvement Project');
        setTypeIndex(12);
        setIconURL(mapHabitat);
      } else if(trPOIFeature.layer.id == 'access_stock') {
        setType('Trout Stocking');
        setTypeIndex(0);
        setIconURL(mapStocking);
      } else if(trPOIFeature.layer.id == 'access_boundary') {
        setType('Tributary Boundary');
        setTypeIndex(0);
        setIconURL(markerBoundaryBlue);
      } else if(trPOIFeature.layer.id == 'TU_Events_Production') {
        setType('Trout Unlimited Event');
        setTypeIndex(0);
        setIconURL(mapEvents);
      } else if(trPOIFeature.layer.id == 'access_poi') {
        setType('Special Point of Interest');
        setTypeIndex(0);
        setIconURL(markerTypePOI);
      } else if(trPOIFeature.layer.id == 'trout_lakes') {
        setType('Trout Lakes');
        setTypeIndex(0);
        setIconURL(mapLakes);
      } else if(trPOIFeature.layer.id == 'rare_waters') {
        setType('Rare Waters');
        setTypeIndex(0);
        setIconURL(mapRarewaters);
      } else if(trPOIFeature.layer.id == 'access_parks' && trPOIFeature.properties.access_type == 106) {
        setType('State Park');
        setTypeIndex(0);
        setIconURL(mapSP);
      } else if(trPOIFeature.layer.id == 'access_parks' && trPOIFeature.properties.access_type == 105) {
        setType('National Park');
        setTypeIndex(0);
        setIconURL(mapNP);
      }

      // TODO: setDistance

      if(trPOIFeature.properties.elevation_ft) {
        setElevation(trPOIFeature.properties.elevation_ft);
      } else {
        const elevationInfo = await getElevationPoint({ lat: trPOIFeature.geometry.coordinates[1], lng: trPOIFeature.geometry.coordinates[0] });
        setElevation(elevationInfo);
      }

      setDistance(mapboxService.distanceFormat(trPOIFeature.geometry.coordinates, false));

      if (trPOIFeature.properties.desc) {
        setDescription(trPOIFeature.properties.desc);
      } else {
        setDescription('');
      }

      if (trPOIFeature.properties.ext_link) {
        setLink1(trPOIFeature.properties.ext_link);
      } else {
        setLink1('');
      }

      // if (trPOIFeature.properties.Weblink) {
      //   setLink1(trPOIFeature.properties.Weblink);
      // } else {
      //   setLink1('');
      // }

      if (trPOIFeature.properties.ext_link_2) {
        setLink2(trPOIFeature.properties.ext_link_2);
      } else {
        setLink2('');
      }

      const link = trPOIFeature.properties.Weblink;
      if(trPOIFeature.layer.id == 'TU_Events_Production') {
        setTitle(trPOIFeature.properties['Event Title']);
        let eventDate = trPOIFeature.properties.Date;
        let eventTime = trPOIFeature.properties.Time;
        let eventHostSite = trPOIFeature.properties.Location;

        if(!eventDate) {
          eventDate = 'TBD';
        }
        if(!eventTime) {
          eventTime = 'TBD';
        }
        if(!eventHostSite) {
          eventHostSite = 'location';
        }

        const description = `${eventDate} at ${eventTime} at ${eventHostSite}`;

        setDescription(description);
        setActionLink(link);
      } else if (link) {
        setActionLink(link);
      } else {
        setActionLink('');
      }

      setCoordinate(trPOIFeature.geometry.coordinates);

      map.flyTo({ center: trPOIFeature.geometry.coordinates }, { programmatic: true });
    }
  }, [trPOIFeature]);

  const actionLabel = () => {
    if (trPOIFeature) {
      if (trPOIFeature.layer.id == 'TU_Events_Production' || trPOIFeature.layer.id == 'rare_waters') {
        return 'Check it out';
      }
    }
    return 'Take Action';
  };

  const onClose = async () => {
    dispatch(setTRPOIFeature(null));
    if (tapMarker) {
      tapMarker.remove();
    }
  };

  const onShare = async (coordinates) => {
    const lng = coordinates[0];
    const lat = coordinates[1];
    try {
      const url = await firebaseService.generateDynamicLink(lat, lng);
      navigator.clipboard.writeText(url);
      toast.remove();
      toast('Copied link to clipboard');
    } catch (e) {
      console.log(e);
    }
  };

  const createMarker = (lngLat, type, map, dispatch) => {
    let marker;

    if (typeof type != 'undefined') {
      marker = createMarkerWithIcon(lngLat, map, mapMarkerTypes[type].img);
    } else {
      marker = createMarkerWithoutIcon({ lngLat }, map);
    }

    dispatch(setTempMarker(marker));
    if (typeof type != 'undefined') dispatch(setMarkerType(type));
    trPOIFeature.isCreate = true;
    dispatch(setTRPOIFeature(trPOIFeature));
  };

  if (!trPOIFeature) return null;
  if (!trPOIFeature.layer.id) return null;

  return (
    <>
      {!trPOIFeature.isCreate && (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.close}
              role="button"
              onClick={onClose}
              tabIndex={0}
            >
              <CloseIcon />
              {/* <Image alt="Close" src={closeIcon} width="16" /> */}
            </div>
            <div className={styles.text}>
              <div className={styles.type}>{iconURL && <Image alt={title} src={iconURL} width="20" />}<h6>{type}</h6></div>
              <h4>{title}</h4>
              <h5>{address}</h5>
            </div>
          </div>
          <div className={styles.scontent}>
            <div className={styles.distances}>
              <div className={styles.distanceInner}>
                <Image alt="Distance" src={distanceIcon} width="16" />
                <p>{distance}</p>
              </div>
              <div className={styles.distanceInner}>
                <Image alt="Elevation" src={elevationIcon} width="16" />
                <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft. elevation</p>
              </div>
            </div>
            { (description || link1 || link2 || actionLink)
            && (
              <div className={styles.body}>
                {description && <p>{description}</p>}
                {link1 && <div><a className={styles['ext-link']} href={link1} target="_blank" rel="noreferrer">{link1}</a></div>}
                {link2 && <div><a className={styles['ext-link']} href={link2} target="_blank" rel="noreferrer">{link2}</a></div>}
                {actionLink && <div><a className={styles['action-link']} href={actionLink} target="_blank" rel="noreferrer">{actionLabel()}</a></div>}
              </div>
            )}
          </div>

          <div className={styles.buttons}>
            <button className={styles.button} type="button" onClick={() => createMarker(coordinate, typeIndex, map, dispatch)}>
              <Image src={createIcon2} alt="Create Marker" />
              <div>Create Marker</div>
            </button>
            <button className={styles.button} type="button" onClick={() => window.open(`${directionsURL}${encodeURI(`${coordinate[1]}, ${coordinate[0]}`)}`)}>
              <Image src={directionsIcon2} alt="Directions" />
              <div>Directions</div>
            </button>
            <button className={styles.button} type="button" onClick={() => onShare(coordinate)}>
              <Image src={shareIcon2} alt="Share" />
              <div>Share</div>
            </button>
          </div>

        </div>
      )}
    </>
  );
};

export default POIDetailsTR;
