import React from 'react';
import LeftSidebar from 'components/LeftSidebar';
import Legend from 'components/Legend';
import ZoomControls from 'components/ZoomControls';
import SearchBar from 'components/SearchBar';
import StreamLabel from 'components/StreamLabel';
import CreateMarker from 'components/CreateMarker';
import CreateLine from 'components/CreateLine';
import CreateArea from 'components/CreateArea';
import ElevationChart from 'components/ElevationChart';
import GageChart from 'components/GageChart';
import EditMarkerDialog from 'components/EditMarkerDialog';
import EditLineDialog from 'components/EditLineDialog';
import EditAreaDialog from 'components/EditAreaDialog';
import TrialModal from 'components/TrialModal';
import BottomRightButton from 'components/BottomRightButton';
import PasswordModal from 'components/PasswordModal';
import Snapper from 'components/Snapper';
import UpgradeModal from 'components/UpgradeModal';
import SnapModal from 'components/SnapModal';
import MobilePrompt from 'components/MobilePrompt';
import FileUploadSection from 'components/FileUploadSection';
import ConfirmUploadModal from 'components/FileUploadSection/ConfirmUploadModal';
import RiverMiles from 'components/RiverMiles';
import POIDetailsTR from 'components/POIDetailsTR';

const MainLayout = () => {
  return (
    <>
      <LeftSidebar />
      <ZoomControls />
      <SearchBar />
      <StreamLabel />
      <CreateMarker />
      <CreateLine />
      <CreateArea />
      <ElevationChart />
      <RiverMiles />
      <GageChart />
      <EditMarkerDialog />
      <EditLineDialog />
      <EditAreaDialog />
      <TrialModal />
      <PasswordModal />
      <Snapper />
      <UpgradeModal />
      <SnapModal />
      <MobilePrompt />
      <FileUploadSection />
      <ConfirmUploadModal />
      <POIDetailsTR />
    </>
  );
};

export default MainLayout;
