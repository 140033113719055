import React from 'react';
import { useSelector } from 'react-redux';
import {
  regsDelayedHarvest,
  regsHeavilyStocked,
  regsSpecialRegs,
  regsTroutStream,
} from 'assets';
import LegendItem from './LegendItem';
import styles from './styles.module.scss';

const RegulationsLegend = () => {
  const { map, supportedStates } = useSelector((state) => state.map);

  const stateRegulations = [];
  const displayGeneralRegulationsLegend = ['ND', 'SD', 'NE', 'KS', 'WY', 'IL', 'OH', 'IN', 'KY', 'TN', 'CA', 'CT', 'ME', 'MA', 'NH', 'OR', 'RI', 'VT', 'WA'].some((state) => supportedStates.includes(state));
  const displayZoomLegend = map.getZoom() < 5;

  if (displayZoomLegend) {
    return (
      <p style={{ marginLeft: 30 }}>Please zoom into a particular region to see the legend for that area.</p>
    );
  }

  if (supportedStates.includes('GA')) {
    stateRegulations.push(
      <div className={styles.regulation} key="GA">
        <h4>Georgia Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem icon={regsSpecialRegs} label="Special Regulations" />
        <LegendItem icon={regsDelayedHarvest} label="Delayed Harvest" />
        <LegendItem icon={regsHeavilyStocked} label="Heavily Stocked" />
      </div>,
    );
  }

  if (supportedStates.includes('NC')) {
    stateRegulations.push(
      <div className={styles.regulation} key="NC">
        <h4>North Carolina Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem classTwoColor="#A2845E" label="Wild Trout/Natural Bait" />
        <LegendItem classTwoColor="#34C759" label="Hatchery Supported" />
        <LegendItem icon={regsSpecialRegs} label="Special Regulations" />
        <LegendItem classTwoColor="#D1D1D6" label="Delayed Harvest" />
        <LegendItem classTwoColor="#FF3B30" label="Catch and Release" />
      </div>,
    );
  }

  if (supportedStates.includes('IA')) {
    stateRegulations.push(
      <div className={styles.regulation} key="IA">
        <h4>Iowa Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem classTwoColor="#FF3B30" label="Catchable Stocked" />
        <LegendItem classTwoColor="#AF52DE" label="Natural Reproduction" />
        <LegendItem classTwoColor="#FF9500" label="Restrictive Regulation" />
      </div>,
    );
  }

  if (supportedStates.includes('VA')) {
    stateRegulations.push(
      <div className={styles.regulation} key="VA">
        <h4>Virginia Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem classTwoColor="#FFCC00" label="Special Regulations" />
        <LegendItem classTwoColor="#FF3B30" label="Delayed Harvest" />
        <LegendItem classTwoColor="#34C759" label="Put and Take" />
        <LegendItem classTwoColor="#FF8AD8" label="Youth Only" />
        <LegendItem classTwoColor="#FF9500" label="Fee Fishing" />
      </div>,
    );
  }

  if (supportedStates.includes('WV')) {
    stateRegulations.push(
      <div className={styles.regulation} key="WV">
        <h4>West Virginia Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem classTwoColor="#FF3B30" label="Catch and Release" />
        <LegendItem classTwoColor="#34C759" label="General Class" />
        <LegendItem classTwoColor="#FF8AD8" label="Children and Class Q" />
        <LegendItem classTwoColor="#FF9500" label="Fly Fishing Only" />
      </div>,
    );
  }

  if (supportedStates.includes('UT')) {
    stateRegulations.push(
      <div className={styles.regulation} key="UT">
        <h4>Utah Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem icon={regsSpecialRegs} label="Special Regulations" />
      </div>,
    );
  }

  if (supportedStates.includes('MO')) {
    stateRegulations.push(
      <div className={styles.regulation} key="MO">
        <h4>Missouri Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem classTwoColor="#30B0C7" label="Trout Park" />
        <LegendItem classTwoColor="#FF8AD8" label="Lake Taneycomo" />
        <LegendItem classTwoColor="#007AFF" label="Blue Ribbon" />
        <LegendItem classTwoColor="#D1D1D6" label="White Ribbon" />
        <LegendItem classTwoColor="#FF3B30" label="Red Ribbon" />
      </div>,
    );
  }

  if (supportedStates.includes('CO')) {
    stateRegulations.push(
      <div className={styles.regulation} key="CO">
        <h4>Colorado Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem icon={regsSpecialRegs} label="Special Regulations" />
        <LegendItem icon={regsHeavilyStocked} label="Gold Medal" />
        <LegendItem classTwoColor="#FF2F92" label="Special Species" />
        <LegendItem classTwoColor="#FF3B30" label="Closed to Fishing" />
      </div>,
    );
  }

  if (supportedStates.includes('MT')) {
    stateRegulations.push(
      <div className={styles.regulation} key="MT">
        <h4>Montana Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem classTwoColor="#FF2F92" label="Special Species" />
        <LegendItem icon={regsSpecialRegs} label="Special Regulations" />
        <LegendItem icon={regsHeavilyStocked} label="Float Restrictions" />
        <LegendItem classZeroColor="#FF3B30" label="Float Prohibitions" />
        <LegendItem classTwoColor="#FF3B30" label="Closed to Fishing" />

      </div>,
    );
  }

  if (supportedStates.includes('MI')) {
    stateRegulations.push(
      <div className={styles.regulation} key="MI">
        <h4>Michigan Regulations</h4>
        <LegendItem classTwoColor="#0096FF" label="Type 1" />
        <LegendItem classTwoColor="#FFCC00" label="Type 2" />
        <LegendItem classTwoColor="#FF9500" label="Type 3" />
        <LegendItem classTwoColor="#0433FF" label="Type 4" />
        <LegendItem classZeroColor="#AF52DE" label="Brook Trout Restoration" />
        <LegendItem classZeroColor="#AAAAAA" label="Research Area (Migratory)" />
        <LegendItem classTwoColor="#FF3B30" label="Gear Restrictions" />

      </div>,
    );
  }

  if (supportedStates.includes('PA')) {
    stateRegulations.push(
      <div className={styles.regulation} key="PA">
        <h4>Pennsylvania Regulations</h4>
        <LegendItem classTwoColor="#34C759" label="Class A Wild" />
        <LegendItem classTwoColor="#5856D6" label="Wilderness Trout" />
        <LegendItem classTwoColor="#C7C7CC" label="Delayed Harvest" />
        <LegendItem classTwoColor="#FF9500" label="Special Regulation" />
        <LegendItem classZeroColor="#FFCC00" label="Best Trout Opportunities" />
        <LegendItem classZeroColor="#FFFB00" label="Keystone Select Stocked" />
        <LegendItem classTwoColor="#FF2D55" label="Great Lakes Tributary" />
      </div>,
    );
  }

  if (supportedStates.includes('MN') || supportedStates.includes('WI')) {
    stateRegulations.push(
      <div className={styles.regulation} key="MNWI">
        <h4>Minnesota/Wisconsin Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem icon={regsSpecialRegs} label="Special Regulations" />
        <LegendItem classTwoColor="#FF2F92" label="Great Lakes Tributary" />
      </div>,
    );
  }

  if (supportedStates.includes('AZ')) {
    stateRegulations.push(
      <div className={styles.regulation} key="AZ">
        <h4>Arizona Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem icon={regsSpecialRegs} label="Special Regulations" />
        <LegendItem classTwoColor="#FF3B30" label="Closed" />
      </div>,
    );
  }

  if (supportedStates.includes('NM')) {
    stateRegulations.push(
      <div className={styles.regulation} key="NM">
        <h4>New Mexico Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem classTwoColor="#FF3B30" label="Red Chile" />
        <LegendItem classTwoColor="#AF52DE" label="X-Max Chile" />
        <LegendItem classTwoColor="#34C759" label="Green Chile" />
        <LegendItem icon={regsSpecialRegs} label="Special Regulations" />
      </div>,
    );
  }
  if (supportedStates.includes('NY')) {
    stateRegulations.push(
      <div className={styles.regulation} key="NY">
        <h4>New York Regulations</h4>
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem classTwoColor="#FF3B30" label="Stocked" />
        <LegendItem classTwoColor="#AF52DE" label="Stocked Extended" />
        <LegendItem classTwoColor="#34C759" label="Wild-Premier" />
        <LegendItem icon={regsSpecialRegs} label="Wild Quality" />
        <LegendItem classTwoColor="#ffffff" label="Other Regulation" />
      </div>,
    );
  }

  if (displayGeneralRegulationsLegend && stateRegulations.length === 0) {
    stateRegulations.push(
      <div className={styles.regulation} key="general">
        <LegendItem icon={regsTroutStream} label="Trout Stream" />
        <LegendItem icon={regsSpecialRegs} label="Special Regulations" />
      </div>,
    );
  }

  return (
    <>
      {stateRegulations}
    </>
  );
};

export default RegulationsLegend;
