/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import { anonymous } from 'assets';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles.module.scss';

export const linkStyles = makeStyles({
  button: {
    fontWeight: 500,
    fontSize: '0.9rem',
    color: '#0868E6',
  },
});

const ImageSection = ({ image = anonymous, isPro = false }) => {
  const classes = linkStyles();

  return (
    <div className={styles['image-section']}>
      <Image src={image} height={190} width={190} objectFit="cover" />

      {isPro && <div className={styles.pro}>PRO</div>}
      <Link underline="none" component="button" classes={classes}>{image === anonymous ? 'Add a Profile Photo' : 'Change Profile Photo'}</Link>
    </div>
  );
};

ImageSection.propTypes = {
  image: PropTypes.string,
  isPro: PropTypes.bool,
};

export default ImageSection;
