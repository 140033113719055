import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Section = ({
  title, children, widthConstrained, fitRow, headerIcon, style,
}) => {
  return (
    <div className={styles.section} style={{ ...{ width: fitRow ? '47%' : '100%' }, ...style }}>
      <div className={styles['section-title']} style={{ width: '100%' }}>
        {title}
        {headerIcon}
      </div>
      <div className={styles.divider} />

      <div style={{ width: widthConstrained ? '60%' : '100%' }}>
        {children}
      </div>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  widthConstrained: PropTypes.bool,
  fitRow: PropTypes.bool,
};

export default Section;
