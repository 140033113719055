import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalTemplate from 'components/ModalTemplate';
import { updateMobileModalShown } from 'actions';
import { isMobile } from 'react-device-detect';
import Image from 'components/Image';
import { appStore, googlePlay } from 'assets';
import styles from './styles.module.scss';

const MobilePrompt = () => {
  const dispatch = useDispatch();
  const { isMobileModalShown } = useSelector((state) => state.auth);

  const close = () => dispatch(updateMobileModalShown(false));

  useEffect(() => {
    if (isMobile) {
      dispatch(updateMobileModalShown(true));
    }
  }, [isMobile]);

  return (
    <ModalTemplate close={close} open={isMobileModalShown}>
      <div className={styles.container}>
        <h3 className={styles.title}>Thank you for using TroutRoutes.</h3>
        <p>We recommend using the TroutRoutes mobile app for the best experience.</p>
        <a href="https://apps.apple.com/us/app/troutroutes/id1423989574">
          <Image src={appStore} alt="Download from the App Store" width={750} height={259} maxWidth={750} responsive objectFit="cover" className={styles.link} />
        </a>

        <a href="https://play.google.com/store/apps/details?id=com.troutinsights.troutroutes">
          <Image src={googlePlay} alt="Download from Google Play" width={750} height={221} maxWidth={750} responsive objectFit="cover" className={styles.link} />
        </a>
      </div>
    </ModalTemplate>
  );
};

export default MobilePrompt;
