import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import { Checked, Unchecked } from 'assets';
import styles from './styles.module.scss';

const SettingsItem = ({
  graphic, title, active, toggleActive,
}) => {
  const [checked, setChecked] = useState(true);
  const toggle = () => { setChecked((c) => !c); };

  const controlledActive = typeof active === 'undefined' ? checked : active;
  const controlledToggle = typeof toggleActive === 'undefined' ? toggle : toggleActive;

  return (
    <div className={styles['settings-item']}>
      <div className={styles.row}>
        {graphic && <Image src={graphic} alt={title} height={30} minHeight={30} minWidth={40} objectFit="contain" />}
        <div className={styles['settings-title']}>{title}</div>
      </div>

      <button className="transparent" onClick={controlledToggle} type="button">
        {controlledActive ? <Checked width={30} height={30} /> : <Unchecked width={30} height={30} /> }
      </button>
    </div>
  );
};

SettingsItem.propTypes = {
  graphic: PropTypes.string,
  title: PropTypes.string,
};

export default SettingsItem;
