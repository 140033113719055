import { useState } from 'react';
import * as firebaseService from 'services/firebase';

const useUpload = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.files.length) {
      Array.from(e.target.files).forEach((file) => {
        setImages((prev) => prev.concat([{ file, url: URL.createObjectURL(file) }]));
      });
    }
  };

  const handleUpload = async (id, annotationType) => {
    setLoading(true);

    await Promise.all(images.map(async ({ file }) => {
      const url = await firebaseService.uploadImageForAnnotation(id, file, annotationType);
      await firebaseService.addImageUrl(id, url, annotationType);
    }));

    setLoading(false);
  };

  return {
    images, handleChange, setImages, loading, handleUpload,
  };
};

export default useUpload;
