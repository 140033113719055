import React from 'react';
import PropTypes from 'prop-types';
import FeatureButton from 'components/FeatureButton';
import { blueRibbon } from 'assets';

const SearchItem = ({
  title, location, icon, onClick, background = 'white', renderRibbon = false,
}) => {
  const icons = icon ? [icon] : [];
  if (renderRibbon) {
    icons.unshift(blueRibbon);
  }

  return (
    <FeatureButton onClick={onClick} title={title} subtitle={location} icons={icons} background={background} />
  );
};

SearchItem.propTypes = {
  title: PropTypes.string,
  location: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  background: PropTypes.string,
};

export default SearchItem;
