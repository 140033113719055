import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsSnapModalShown } from 'actions';
import ModalTemplate from 'components/ModalTemplate';
import { makeStyles } from '@material-ui/core/styles';
import { snapRegions, accountTypesEnum } from 'lib/constants';
import {
  Button,
  FormControl, MenuItem, Select, styled, withStyles,
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { stateMap } from 'assets';
import Image from 'components/Image';
import * as firebaseService from 'services/firebase';
import styles from './styles.module.scss';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: 'white',
    border: '2px solid #E2E2E2',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 5,
      border: '2px solid #A5A5A5',
      backgroundColor: 'white',
    },
    '&:selected': {
      backgroundColor: 'red',
    },
  },
}))(InputBase);

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 16,
  '&:hover': {
    backgroundColor: '#F5F5F5',
  },
  '&.Mui-selected': {
    backgroundColor: '#1553D333',
    '&:hover': {
      backgroundColor: '#d5d5d5',
    },
  },
}));

const CustomPaper = styled('div')(({ theme }) => ({
  maxHeight: 250,
  padding: 0,
  backgroundColor: 'white',
  border: '2px solid #E2E2E2',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'white',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#D9D9D9',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
  'scrollbar-width': 'thin',
  'scrollbar-color': '#D9D9D9 white',
}));

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  PaperProps: {
    component: CustomPaper,
  },
};

const buttonStyles = makeStyles({
  root: {
    backgroundColor: '#1553D3',
    borderRadius: '24px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#0F3C9B',
      boxShadow: 'none',
    },
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingTop: 2,
    paddingBottom: 2,
  },
});

const SnapModal = () => {
  const { isSnapModalShown, isUpgradeModalShown } = useSelector((state) => state.map);
  const { region } = useSelector((state) => state.user);
  const { hasUpdatedAccountType } = useSelector((state) => state.auth);
  const { accountType } = useSelector((state) => state.auth.user);
  const [value, setValue] = useState('');
  const buttonClasses = buttonStyles();
  const dispatch = useDispatch();

  const close = () => { dispatch(setIsSnapModalShown(false)); };

  const snap = (e) => {
    e.preventDefault();
    if (value) {
      const selectedRegion = snapRegions.find((r) => r.title === value);
      if (selectedRegion) {
        firebaseService.updateSnapRegion(selectedRegion.gid);
        close();
      }
    }
  };

  useEffect(() => {
    const isBasicModalShown = (accountType === accountTypesEnum.basic || accountType === accountTypesEnum.trial);

    if (!isUpgradeModalShown && !region && hasUpdatedAccountType && !isBasicModalShown) {
      dispatch(setIsSnapModalShown(true));
    }
  }, [isUpgradeModalShown, region]);

  return (
    <ModalTemplate open={isSnapModalShown} close={close}>
      <div className={styles['main-container']}>
        <h5 className={styles.title}>Select your Preferred State</h5>
        <span className={styles.text}>Select your preferred state so we can customize your experience. You can always explore all 48 states and change your preferred state any time.</span>
        <form onSubmit={snap} className={styles.form}>
          <FormControl>
            <Select
              value={value}
              onChange={(e) => { setValue(e.target.value); }}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              MenuProps={menuProps}
              input={<BootstrapInput />}
            >
              <CustomMenuItem value=""
                style={{ fontWeight: '500' }}
              >Select State
              </CustomMenuItem>
              {snapRegions.map(({ title }) => {
                return (
                  <CustomMenuItem value={title}
                    key={title}

                  >{title}
                  </CustomMenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Image src={stateMap} alt="State Map" objectFit="cover" />
          <Button classes={buttonClasses} type="submit" variant="contained" color="primary">
            Save State Preference
          </Button>
        </form>
      </div>
    </ModalTemplate>
  );
};

export default SnapModal;
