/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Image from 'components/Image';
import styles from './styles.module.scss';

const Header = ({ close, title, icon }) => {
  return (
    <div className={styles.header}>
      <div className={styles.tright}>
        <button className={`${styles.close} transparent`} type="button" onClick={close}><CloseIcon /></button>
      </div>
      <div className={styles['header-text']}>
        {icon && <Image src={icon} alt={title} height={24} />}
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};

Header.propTypes = {
  close: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.string,
};

export default Header;
