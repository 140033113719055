import React from 'react';
import ModalTemplate from 'components/ModalTemplate';
import { Button } from 'components/TwoButtons';

import styles from './styles.module.scss';

const ConfirmUploadModal = ({
  isShown, close, upload, numMarkers = 0,
}) => {
  return (
    <ModalTemplate close={close} open={isShown}>
      <h2 className={styles.title}>Upload Confirmation</h2>
      <div className={styles.confirmation}>
        {numMarkers === 0
          ? 'There was an error with the file you uploaded. Please ensure that it is valid GeoJSON, KML, or GPX.'
          : `Do you want to upload ${numMarkers} marker${numMarkers === 1 ? '' : 's'} to your account?`}
      </div>

      {numMarkers > 0 && (
        <Button label="Upload"
          className={styles.upload}
          onClick={() => {
            if (upload) upload();
            close();
          }}
        />
      )}
    </ModalTemplate>
  );
};


export default ConfirmUploadModal;
