import React from 'react';
import Image from 'components/Image';
import styles from './styles.module.scss';

function Button({
  icon, label, onClick, iconElement: IconElement, disabled, className,
}) {
  return (
    <button className={`${styles.button} ${className}`} type="button" onClick={onClick} disabled={disabled}>
      {IconElement && <IconElement className={styles.iconElement} />}
      {icon && <Image src={icon} alt={label} height="20" />}
      <div>{label}</div>
    </button>
  );
}

export default Button;
