import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BottomRightDialog from 'components/BottomRightDialog';
import { setElevationChartVisible, setTempElevationMarker, removeTempElevationMarker } from 'actions';
import { Line, Chart } from 'react-chartjs-2';

const FEET_CONVERSION = 3.28084;

export const ElevationLineChart = () => {
  const dispatch = useDispatch();
  const elevationData = useSelector((state) => state.map.streamElevationData);

  const data = {
    labels: [...elevationData.map((result) => result.location.toString())],
    datasets: [
      {
        label: 'Elevation',
        borderColor: 'rgb(86, 126, 237)',
        borderWidth: 1,
        backgroundColor: 'rgb(168, 197, 236)',
        pointRadius: 0,
        data: [...elevationData.map((result) => result.elevation * FEET_CONVERSION)],
      },
    ],
  };

  useEffect(() => {
    Chart.pluginService.register({
      afterDraw(chart, easing) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.controller.tooltip._active[0];
          const { ctx } = chart;
          const { x } = activePoint.tooltipPosition();
          const topY = chart.scales['y-axis-0'].top;
          const bottomY = chart.scales['y-axis-0'].bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = 'rgba(0,0,0,0.5)';
          ctx.stroke();
          ctx.restore();
        } else {
          dispatch(removeTempElevationMarker());
        }
      },
    });
  }, []);

  return (
    <Line
      data={data}
      options={{
        legend: { display: false },
        scales: {
          xAxes: [{
            display: false, // this will remove all the x-axis grid lines
          }],
        },
        tooltips: {
          enabled: true,
          intersect: false,
          callbacks: {
            title: (items) => {
              return `${parseFloat(items[0].yLabel).toFixed(0).toString()} ft`;
            },
            labelColor: () => { return null; },
            label: (item) => {
              const coords = item.label.replace('(', '').replace(')', '').split(',');
              dispatch(setTempElevationMarker([parseFloat(coords[1]), parseFloat(coords[0])]));
              return null;
            },
          },
        },
      }}
    />
  );
};

const ElevationChart = () => {
  const dispatch = useDispatch();
  const close = () => { dispatch(setElevationChartVisible(false)); };
  const isVisible = useSelector((state) => state.map.isElevationChartVisible);

  if (!isVisible) return null;

  return (
    <BottomRightDialog close={close} title="Elevation Chart (ft)" backgroundColor="white">
      <ElevationLineChart />
    </BottomRightDialog>
  );
};

export default ElevationChart;
