import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';

const Selector = ({ items, onSelect, value }) => {
  return (
    <ToggleButtonGroup value={value} exclusive onChange={(e, val) => { onSelect(val); }} size="small">
      {items.map((item) => {
        return (
          <ToggleButton value={item.value} key={item.value}>{item.label}</ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

Selector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  value: PropTypes.string,
  onSelect: PropTypes.func,
};

export default Selector;
