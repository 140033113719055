/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { setElevation, updateStreamWithGeocoding, setTRPOIFeature } from 'actions';
import {
  riverIcon, mapDam, mapBoat, mapCamp, mapAccess, mapShop,
} from 'assets';
import * as mapboxService from 'services/mapbox';
import { snapRegions } from 'lib/constants';
import { getElevationPoint } from 'services/elevation';
import SearchItem from './SearchItem';
import styles from './styles.module.scss';

const PANE_HEIGHT = '50vh';

const iconEnum = {
  1: riverIcon,
  9: mapShop,
  26: mapBoat,
  27: mapCamp,
  28: mapAccess,
  29: mapAccess,
  30: mapDam,
};

const SearchPane = ({ visible, results = [] }) => {
  const dispatch = useDispatch();
  const { map } = useSelector((state) => state.map);
  const { region: userRegion } = useSelector((state) => state.user);
  const [delayedVisible, setDelayedVisible] = useState(false);
  const regionObject = userRegion ? snapRegions.find((region) => region.gid == userRegion) : {};


  useEffect(() => {
    if (visible) setDelayedVisible(true);
    else setTimeout(() => { setDelayedVisible(false); }, 100);
  }, [visible]);

  const getElevationValue = async (stream) => {
    const coordinates = {
      lat: (stream?.GeoBoundMinY2 + stream?.GeoBoundMaxY2) / 2,
      lng: (stream?.GeoBoundMinX2 + stream?.GeoBoundMaxX2) / 2,
    };
    const elevation = await getElevationPoint(coordinates);
    dispatch(setElevation(elevation));
  };

  const handleClick = (result) => {
    if (result.TypeCode === 1) {
      dispatch(updateStreamWithGeocoding(result));
      getElevationValue(result);
    } else if (result.TypeCode === 9) {
      const coordinates = { lng: result.centerLong, lat: result.centerLat };
      map.flyTo({
        center: coordinates,
        zoom: 13,
      }, { programmatic: true });
      map.once('moveend', () => {
        setTimeout(() => {
          map.fire('click', {
            lngLat: coordinates,
            point: map.project(coordinates),
            originalEvent: {},
          });
        }, 200);
      });
    } else if (result.TypeCode === 26) {
      dispatch(setTRPOIFeature(result));
    } else if (result.TypeCode === 28 || result.TypeCode === 29) {
      dispatch(setTRPOIFeature(result));
    } else if (result.bbox) {
      mapboxService.processGeocodeClick(map, dispatch, result, [result.centerLong, result.centerLat], result.PrimaryLabel, result.bbox);
    } else if (result.TypeCode === 30) {
      dispatch(setTRPOIFeature(result));
    }

    if (result.TypeCode !== 1 && result.TypeCode !== 9 && (result.centerLong !== undefined)) {
      const coordinates = { lng: result.centerLong, lat: result.centerLat };
      map.flyTo({
        center: coordinates,
        zoom: 13,
      }, { programmatic: true });
      map.once('moveend', () => {
        setTimeout(() => {
          map.fire('click', {
            lngLat: coordinates,
            point: map.project(coordinates),
            originalEvent: {},
          });
        }, 200);
      });
    }
  };

  const applyFilter = (element, idx, array) => {
    if (element.TypeCode || element.bbox) {
      if (element.stream_gid2 === undefined) {
        const target = array.find((v, i) => i !== idx && v.PrimaryLabel === element.PrimaryLabel && v.SecondaryLabel === element.SecondaryLabel);
        const targetIndex = target ? array.indexOf(target) : -1;
        return targetIndex === -1;
      } else {
        const currentScore = getRelevence(element);
        const higherRankedSameStream = array.find((streamElement, index) => {
          if (index === idx) {
            return false;
          }
          if (streamElement.stream_gid2 === element.stream_gid2) {
            const searchedItemScore = getRelevence(streamElement);
            if (searchedItemScore < currentScore) {
              return false;
            } else if (searchedItemScore === currentScore) {
              if (index < idx) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          } else {
            return false;
          }
        });

        if (higherRankedSameStream === undefined) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  const getRelevence = (element) => {
    let relevance = 0;
    if (element.StreamClass) {
      relevance = 4 - parseInt(element.StreamClass, 10);
    } if (element.popular) {
      relevance += parseInt(element.popular, 10) * 2;
    } if (element.HasEasement) {
      relevance += parseInt(element.HasEasement, 10) * 0.5;
    }

    return relevance;
  };

  const finalResults = results
    .filter(applyFilter)
    .map((result) => {
      if (!userRegion) return result;
      const regionObject = snapRegions.find((region) => region.gid == userRegion);
      if (!regionObject) return result;
      result.inState = false;
      if ((result?.PrimaryLabel && result?.PrimaryLabel.includes(regionObject.title)) || (result?.SecondaryLabel && result?.SecondaryLabel.includes(regionObject.title))) {
        result.inState = true;
      } if ((result?.PrimaryLabel && result?.PrimaryLabel.includes(regionObject.abbrev)) || (result?.SecondaryLabel && result?.SecondaryLabel.includes(regionObject.abbrev))) {
        result.inState = true;
      }

      return result;
    })
    .map((result) => {
      if (result.relevance) return result;
      const { popular, StreamClass, HasEasement } = result;
      let relevance = 0;
      if (StreamClass) {
        relevance = 4 - parseInt(StreamClass, 10);
      } if (popular) {
        relevance += parseInt(popular, 10) * 2;
      }
      if (HasEasement) {
        relevance += parseInt(HasEasement, 10) * 0.5;
      }
      result.relevance = relevance;
      return result;
    });

  let inStateResults = finalResults
    .filter((result) => result.inState)
    .sort((a, b) => {
      return b.relevance - a.relevance;
    })
    .sort((a, b) => {
      if (a.TypeCode === 1 && b.TypeCode !== 1) return -1;
      if (a.TypeCode !== 1 && b.TypeCode === 1) return 1;
      return 0;
    });
  inStateResults = inStateResults.slice(0, 20);

  let outOfStateResults = finalResults
    .filter((result) => !result.inState)
    .sort((a, b) => {
      return b.relevance - a.relevance;
    })
    .sort((a, b) => {
      if (a.TypeCode === 1 && b.TypeCode !== 1) return -1;
      if (a.TypeCode !== 1 && b.TypeCode === 1) return 1;
      return 0;
    });
  outOfStateResults = outOfStateResults.slice(0, 20);

  const renderResult = (result) => {
    if (result.TypeCode === 1) {
      return (
        <SearchItem
          icon={riverIcon}
          title={result.PrimaryLabel}
          location={result.SecondaryLabel}
          onClick={() => { handleClick(result); }}
          key={result.stream_gid2 || `${result.PrimaryLabel}${result.SecondaryLabel}${result.centerLat}${result.centerLong}`}
          renderRibbon={result.relevance > 3}
        />
      );
    } else if (iconEnum[result.TypeCode]) {
      return (
        <SearchItem
          icon={iconEnum[result.TypeCode]}
          title={result.PrimaryLabel}
          location={result.SecondaryLabel}
          onClick={() => { handleClick(result); }}
          key={result.stream_gid2 || `${result.PrimaryLabel}${result.SecondaryLabel}${result.centerLat}${result.centerLong}`}
          // renderRibbon={result.relevance > 3}
        />
      );
    } else if (result.bbox) {
      return (
        <SearchItem
          title={result.PrimaryLabel}
          onClick={() => { handleClick(result); }}
          key={result.stream_gid2 || `${result.PrimaryLabel}${result.centerLat}${result.centerLong}`}
        />
      );
    } else return null;
  };


  return (
    <motion.div
      className={styles.pane}
      animate={{ height: delayedVisible ? PANE_HEIGHT : 0 }}
      transition={{ type: delayedVisible ? 'spring' : null, ease: delayedVisible ? null : 'easeOut', duration: delayedVisible ? 0.5 : 0.3 }}
    >
      {regionObject && inStateResults.length > 0 && (
        <div className={styles['pane-header']}>
          {regionObject.title}
        </div>
      )}
      {inStateResults.map(renderResult)}
      {outOfStateResults.length > 0 && (
        <div className={styles['pane-header']}>
          Everywhere Else
        </div>
      )}
      {outOfStateResults.map(renderResult)}
    </motion.div>
  );
};

SearchPane.propTypes = {
  visible: PropTypes.bool,
  results: PropTypes.arrayOf(PropTypes.object),
};

export default SearchPane;
