import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { layersEnum } from 'lib/constants';
import {
  goldMedal, class1, class2, class3, publicLand, publicAccess, trails, mapGage, mapShop, bridgeAccess, publicSection, easeSection, easementLand, stateParks,
  nationalForest,
} from 'assets';
import { getSupportedStates } from 'services/mapbox';
import Pane from '../Pane';
import LegendItem from './LegendItem';
import { buttonEnum } from '..';
import RegulationsLegend from './RegulationsLegend';

const LegendPane = () => {
  const { mapStyle } = useSelector((state) => state.map);

  return (
    <Pane title="Map Legend">
      {mapStyle === layersEnum.basic && (
        <>
          <LegendItem icon={bridgeAccess} label="Bridge Access" />
          <LegendItem icon={class3} label="Trout Stream" />
          <LegendItem icon={mapShop} label="Fly Shops" />
        </>
      )}
      {mapStyle === layersEnum.guide && (
        <>
          <LegendItem icon={goldMedal} label="Gold Medal / Blue Ribbon" />
          <LegendItem icon={class1} label="Class 1" />
          <LegendItem icon={class2} label="Class 2" />
          <LegendItem icon={class3} label="Class 3" />
          <LegendItem icon={publicLand} label="Public Land" imageStyle={{ borderRadius: 10, border: '3px solid #2CA786' }} />
          <LegendItem icon={publicAccess} label="Public Section" />
          <LegendItem icon={trails} label="Trails" />
          <LegendItem icon={mapGage} label="Stream Gage" />
        </>
      )}
      {mapStyle === layersEnum.access && (
        <>
          <LegendItem icon={easementLand}
            label="Easement Land"
            objectFit="cover"
            imageStyle={{
              borderRadius: 10,
              aspectRatio: '530/140',
              border: '3px solid #533D7F',
            }}
          />
          <LegendItem icon={stateParks}
            label="State / Nat'l Park"
            objectFit="cover"
            imageStyle={{
              borderRadius: 10,
              aspectRatio: '530/140',
              border: '3px solid #C35E00',
            }}
          />
          <LegendItem icon={publicLand} label="Other Public Land" imageStyle={{ borderRadius: 10, border: '3px solid #2CA786' }} />
          <LegendItem icon={nationalForest} label="National Forests" imageStyle={{ borderRadius: 10, border: '3px solid #3D9D40' }} />
          <LegendItem icon={easeSection} label="Easement Section" />
          <LegendItem icon={publicSection} label="Public Section" />
          <LegendItem icon={class3} label="Private Section" />
          <LegendItem icon={trails} label="Trails" />
        </>
      )}
      {mapStyle === layersEnum.regulations && (
        <RegulationsLegend />
      )}
    </Pane>
  );
};

export default LegendPane;
