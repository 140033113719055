import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import styles from './styles.module.scss';

export const Button = ({
  icon, label, onClick, iconElement: IconElement, disabled, className,
}) => {
  return (
    <button className={`${styles.button} ${className}`} type="button" onClick={onClick} disabled={disabled}>
      {IconElement && <IconElement className={styles['icon-el']} />}
      {icon && <Image src={icon} alt={label} height="25" className={styles.icon} />}
      {label}
    </button>
  );
};

const TwoButtons = ({ leftButton, rightButton }) => {
  return (
    <div className={styles.buttons}>
      {leftButton && (
        <Button onClick={leftButton?.onClick} label={leftButton.label} icon={leftButton.icon} />
      )}
      {rightButton && (
        <Button onClick={rightButton?.onClick} label={rightButton.label} icon={rightButton.icon} />
      )}
    </div>
  );
};

TwoButtons.propTypes = {
  leftButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
  }),
  rightButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
  }),
};

export default TwoButtons;
