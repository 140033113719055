import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import styles from './styles.module.scss';

export const Button = ({
  icon, label, onClick, iconElement: IconElement, disabled, className,
}) => {
  return (
    <button className={`${styles.button} ${styles[className]} `} type="button" onClick={onClick} disabled={disabled}>
      {IconElement && <IconElement className={styles['icon-el']} />}
      {icon && <Image src={icon} alt={label} height="20" className={styles.icon} />}
      <div>{label}</div>
    </button>
  );
};

const ThreeButtons = ({
  leftButton, middleButton, rightButton, fourthButton,
}) => {
  return (
    <div className={styles.buttons}>
      {leftButton && (
        <button className={styles.button} type="button" onClick={leftButton?.onClick}>
          <Image src={leftButton.icon} alt="" />
          <div>{leftButton.label}</div>
        </button>
      )}
      {middleButton && (
        <button className={styles.button} type="button" onClick={middleButton?.onClick}>
          <Image src={middleButton.icon} alt="" />
          <div>{middleButton.label}</div>
        </button>
      )}
      {rightButton && (
        <button className={styles.button} type="button" onClick={rightButton?.onClick}>
          <Image src={rightButton.icon} alt="" />
          <div>{rightButton.label}</div>
        </button>
      )}
      {fourthButton && (
        <button className={styles.button} type="button" onClick={fourthButton?.onClick}>
          <Image src={fourthButton.icon} alt="" />
          <div>{fourthButton.label}</div>
        </button>
      )}
    </div>
  );
};

ThreeButtons.propTypes = {
  leftButton: PropTypes.shape({
    label: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
  }),
  middleButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
  }),
  rightButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
  }),
  fourthButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
  }),
};

export default ThreeButtons;
