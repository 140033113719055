/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Button } from 'components/TwoButtons';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  setPaymentError, setPaymentLoading, updatePayment,
} from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';

const UpdateSection = ({
  onLoadChange, email,
}) => {
  const {
    isPaymentLoading,
  } = useSelector((state) => state.payment);
  const stripe = useStripe();

  const elements = useElements();
  const dispatch = useDispatch();

  const submit = async () => {
    if (!stripe || !elements) return;
    dispatch(setPaymentLoading(true));

    const cardElement = elements.getElement(CardElement);
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        dispatch(setPaymentError(error.message || 'There was an error with your credentials. Please try again.'));
        dispatch(setPaymentLoading(false));
      } else {
        dispatch(updatePayment(paymentMethod, email));
      }
    } catch (error) {
      dispatch(setPaymentError(error.message || 'There was an error with your credentials. Please try again.'));
      dispatch(setPaymentLoading(false));
    }
  };

  const onChange = (e) => {
    dispatch(setPaymentError(''));
  };

  // Weird workaround.
  useEffect(() => {
    if (document.getElementsByClassName('__PrivateStripeElement').length > 0) {
      document.getElementsByClassName('__PrivateStripeElement')[0].children[0].style = 'border: none !important; margin: 0px !important; padding: 0px !important; width: 100% !important; min-width: 100% !important; overflow: hidden !important; display: block !important; user-select: none !important; will-change: transform !important; height: 16.8px;';
    }
    if (document.getElementsByClassName('StripeElement').length > 0) {
      document.getElementsByClassName('StripeElement')[0].style = 'width: 100%;';
    }
  }, [stripe]);

  useEffect(() => {
    if (isPaymentLoading && onLoadChange) onLoadChange(true);
    else if (onLoadChange) onLoadChange(false);
  }, [isPaymentLoading]);

  const disabled = !stripe || isPaymentLoading;

  return (
    <>
      <div className={styles.card}>
        <CardElement onChange={() => onChange()}
          options={{
            style: {
              base: {
                fontSize: '1rem',
                fontFamily: `Poppins, AvenirNext, Avenir Next, Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
              },
            },
          }}
        />
      </div>

      <Button label="Update Payment Info" className={`${styles.primary} ${styles.bright} ${styles.update}`} disabled={disabled} onClick={submit} />
    </>
  );
};


export default UpdateSection;
