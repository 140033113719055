import React from 'react';
import { useSelector } from 'react-redux';
import { layersEnum } from 'lib/constants';
import {
  goldMedal, class1, class2, class3, publicLand, publicAccess, trails, mapGage,
} from 'assets';
import LegendItem from './LegendItem';
import styles from './styles.module.scss';

const Legend = () => {
  const { mapStyle } = useSelector((state) => state.map);

  return (
    <div className={styles.legend}>
      <h3 className={styles.title}>Map Legend</h3>

      <div className={styles.rows}>
        {mapStyle !== layersEnum.basic && (
          <>
            <LegendItem icon={goldMedal} label="Gold Medal / Blue Ribbon" />
            <LegendItem icon={class1} label="Class 1" />
            <LegendItem icon={class2} label="Class 2" />
            <LegendItem icon={class3} label="Class 3" />
            <LegendItem icon={publicLand} label="Public Land" />
            <LegendItem icon={publicAccess} label="Public Section" />
            <LegendItem icon={trails} label="Trails" />
            <LegendItem icon={mapGage} label="Stream Gage" />
          </>
        )}
      </div>
    </div>
  );
};

export default Legend;
