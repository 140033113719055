import React from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import TwoButtons from 'components/TwoButtons';
import styles from './styles.module.scss';

const Popup = ({
  title, subtitle, leftButton, rightButton, iconURL, ext_link,
}) => {
  return (
    <div className={styles.popup}>
      <div className={styles.content}>
        {iconURL && <Image alt={title} src={iconURL} width="20%" objectFit="contain" />}

        <div className={styles.text}>
          <h5>{title}</h5>
          <h6>{subtitle}</h6>
          {ext_link && <a href={ext_link} style={{ color: 'blue', overflow: 'hidden', width: '300px' }}>{ext_link}</a>}
        </div>
      </div>

      <TwoButtons leftButton={leftButton} rightButton={rightButton} />
    </div>
  );
};

// const PopupExt = ({
//   title, subtitle, description, leftButton, rightButton, iconURL,
// }) => {
//   return (
//     <div className={styles.popup}>
//       <div className={styles.content}>
//         {iconURL && <Image alt={title} src={iconURL} width="20%" objectFit="contain" />}

//         <div className={styles.text}>
//           <h5>{title}</h5>
//           <h6>{subtitle}</h6>
//           <h7>{description}</h7>
//         </div>
//       </div>

//       <TwoButtons leftButton={leftButton} rightButton={rightButton} />
//     </div>
//   );
// };

Popup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  leftButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  rightButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  iconURL: PropTypes.string,
};

export default Popup;
