import { combineReducers } from 'redux';
import AuthReducer from './auth';
import MapReducer from './map';
import UserReducer from './user';
import PaymentReducer from './payment';

const rootReducer = combineReducers({
  auth: AuthReducer,
  map: MapReducer,
  user: UserReducer,
  payment: PaymentReducer,
});

export default rootReducer;
