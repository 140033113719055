import React from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const FeatureButton = ({
  onClick, background, title, subtitle, icons, mainIcon,
}) => {
  const withButton = (element) => {
    if (!onClick) return element;
    return (
      <div role="button" className={styles['item-button']} onClick={onClick} tabIndex={0} style={{ background }}>{element}</div>
    );
  };

  return withButton((
    <div className={styles['search-item']}>
      <div className={styles.flex}>
        {mainIcon && <Image src={mainIcon} alt={title} height={40} width="auto" objectFit="contain" className={styles['main-icon']} />}
        <div className={styles.text}>
          <h5>{title || 'No Title'}</h5>
          <h6>{subtitle}</h6>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {icons.map((icon) => {
          return (
            <Image src={icon} alt={title} height={40} width="auto" objectFit="contain" key={icon} />
          );
        })}
      </div>
    </div>
  ));
};

FeatureButton.propTypes = {
  onClick: PropTypes.func,
  background: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icons: PropTypes.arrayOf(PropTypes.string),
  mainIcon: PropTypes.string,
};

export default FeatureButton;
