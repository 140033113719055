import { updateFlyshopPasswordModalShown } from 'actions';
import ModalTemplate from 'components/ModalTemplate';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';

const ChangePasswordModal = ({ onClick }) => {
  const { isFlyshopPasswordModalShown } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(updateFlyshopPasswordModalShown(false));
  };

  return (
    <ModalTemplate open={isFlyshopPasswordModalShown} close={close}>
      <h4 style={{ marginBottom: 20 }}>Change your password</h4>

      <div>On the previous screen, you entered a password that is different from the password currently associated with the account.
        If you want to change your password to this password, click the button to confirm below.
      </div>

      <button type="button" onClick={onClick} className={styles['confirm-button']}>Confirm</button>
    </ModalTemplate>
  );
};

export default ChangePasswordModal;
