/* eslint-disable */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  useParams,
} from 'react-router-dom';
import usePrevious from 'utils/usePrevious';
import mapboxgl from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatGuideStyle, formatBasicStyle, formatAccessStyle, setActiveTools, setTempMarker, setMap, setEditingMarkerId,
  updateLineId, resetLineIds, setDraw, resetAreaIds, updateAreaId, setDelayedPanelShown, setEditingLineId, setEditingAreaId, updateMapStyle, formatRegsStyle,
} from 'actions';
import {
  snapRegions, layersEnum, mapMarkerTypes, lineStyles, lineColors, lineWidths, lineTypes, areaStyles,
} from 'lib/constants';
import { background, devices, devices2x, devices3x, horizontalLogoWhite } from 'assets';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
// import theme from '@mapbox/mapbox-gl-draw/src/lib/theme';
import * as mapboxService from 'services/mapbox';
import * as firebaseService from 'services/firebase';
import useIsPro from 'utils/useIsPro';
import styles from './styles.module.scss';
import { Toaster } from 'react-hot-toast';
import { useMediaQuery } from '@material-ui/core';

// TR 4.7:
const roadsStyleURL = 'mapbox://styles/troutinsights/cljdiv9tc004q01qp843lapsr'; //proposed
const topoStyleURL = 'mapbox://styles/troutinsights/cljdn2oom005f01qrgwhu5yrl';
const satelliteStyleURL = 'mapbox://styles/troutinsights/cljdn2gpa005p01qidb8w4431';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX;
const Draw = new MapboxDraw({
  displayControlsDefault: false,
  userProperties: true,
  styles: [
    // ...theme,
    ...lineStyles,
    ...areaStyles,
  ],
});

const MapBackground = () => {
  const { regionName } = useParams();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
  const isLargeScreen = useMediaQuery('(min-width:961px)');

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const type = params.get('typecode');
  const uri = params.get('af_dp');
  const stype = params.get('stype');
  const customType = params.get('t');

  const [mapLoaded, setMapLoaded] = useState(false);
  const isPro = useIsPro();
  const dispatch = useDispatch();
  const {
    baseLayer, mapStyle, filters, classes, otherFeatures, stream, activeTools, isElevationChartVisible,
  } = useSelector((state) => state.map);
  const { tempMarker } = useSelector((state) => state.map.marker);
  const { tempLine, lineId } = useSelector((state) => state.map.line);
  const { tempArea, areaId } = useSelector((state) => state.map.area);
  const {
    markers, lines, lineIds, areas, areaIds,
  } = useSelector((state) => state.user);
  const markerObjects = useRef([]);
  const unsubscribeFunc = useRef(null);
  const prevMarker = usePrevious(tempMarker);
  const prevLine = usePrevious(tempLine);
  const prevArea = usePrevious(tempArea);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const mapContainer = useRef(null);
  const map = useRef(null);

  const getStyleURL = () => {
    switch (baseLayer) {
      case layersEnum.topography:
        return topoStyleURL;
      case layersEnum.roads:
        return roadsStyleURL;
      case layersEnum.satellite:
        return satelliteStyleURL;
      default:
        return roadsStyleURL;
    }
  };

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: getStyleURL(),
      center: [-85.6681, 42.9634],
      zoom: 10,
      // pitch: 15,
      bearing: 0,
    });

    map.current.on('style.load', () => {
      setMapLoaded(true);
      dispatch(setMap(map.current));
      dispatch(setDraw(Draw));
    });

    map.current.addControl(Draw, 'top-right');
  }, []);

  // Ensures Firebase syncs as the line or area moves.
  const drawUpdateHandler = useCallback((e) => {
    const { features } = e;

    features.forEach((feature) => {
      if (lineIds[feature.id]) {
        firebaseService.updateLineCoordinates(feature, lineIds[feature.id]);
      }
      if (areaIds[feature.id]) {
        firebaseService.updateAreaCoordinates(feature, areaIds[feature.id]);
      }
    });
  }, [lineIds, areaIds]);

  const markersUpdateHandler = () => {
    markerObjects.current.forEach((m) => m.remove());
    markerObjects.current = [];
    for (const [id, m] of Object.entries(markers)) {
      if (m?.geometry?.coordinates) {
        const pointType = (m.properties.pointType && m.properties.pointType < mapMarkerTypes.length) ? mapMarkerTypes[m.properties.pointType] : mapMarkerTypes[0];
        const icon = React.cloneElement(pointType.icon, { colorIndex: m?.properties?.pointColorIndex });
        const newMarker = mapboxService.createIconMarker(m.geometry.coordinates, map.current, icon, null, { dispatch, id });
        markerObjects.current.push(newMarker);
      }
    }
  };

  useEffect(() => {
    map.current.on('draw.update', drawUpdateHandler);
  }, [drawUpdateHandler]);

  useEffect(() => {
    map.current.on('zoomend', markersUpdateHandler);

    return () => {
      map.current.off('zoomend', markersUpdateHandler);
    };
  }, [markers]);

  // Updates the map style based on Redux.
  useEffect(() => {
    map.current.setStyle(getStyleURL());
    setMapLoaded(false);
  }, [baseLayer]);



  // Loads map functionality when the user logs in.
  useEffect(() => {
    if (isAuthenticated && mapLoaded) {
      try {
        mapboxService.onLoad(map.current, dispatch, Draw);
        const unsubscribe = mapboxService.setClickHandler(map, dispatch, Draw);
        unsubscribeFunc.current = unsubscribe;
      } catch (e) {
      }
    }
  }, [mapLoaded, isAuthenticated]);

  // Hooks into Redux to update map style.
  useEffect(() => {
    if (isAuthenticated && mapLoaded) {
      switch (mapStyle) {
        case layersEnum.basic:
          dispatch(formatBasicStyle(map.current));
          break;
        case layersEnum.guide:
          dispatch(formatGuideStyle(map.current));
          break;
        case layersEnum.access:
          dispatch(formatAccessStyle(map.current));
          break;
        case layersEnum.regulations:
          dispatch(formatRegsStyle(map.current));
          break;
        default:
          break;
      }

      if (uri) {
        if (uri == 'troutroutes://main/coordinates') {
          const long = params.get('long');
          const lat = params.get('lat');
          const point = [long, lat];
          mapboxService.jumpToPoint(map.current, point, dispatch);
        } else if (uri == 'troutroutes://main/stream') {
          const streamgid = params.get('streamGid');
          mapboxService.jumptToStreamFromGID(streamgid, dispatch);
        }
      } else if (customType) {
        if (customType == 's') {
          const streamgid = params.get('i');
          mapboxService.jumptToStreamFromGID(streamgid, dispatch);
        } else if (customType == 'c') {
          const lat = params.get('l');
          const long = params.get('o');
          const point = [long, lat];
          mapboxService.jumpToPoint(map.current, point, dispatch);
        }
      } else if (stype) {
        if (stype == 'coordinates') {
          const long = params.get('long');
          const lat = params.get('lat');
          const point = [long, lat];
          mapboxService.jumpToPoint(map.current, point, dispatch);
        } else if (stype == 'stream') {
          const streamgid = params.get('streamGid');
          mapboxService.jumptToStreamFromGID(streamgid, dispatch);
        }
      } else if (regionName) {
        const regionObject = snapRegions.find((region) => region.title == regionName);
        if (regionObject) {
          //Handle pre-defined region
          mapboxService.snapToRegion(map.current, regionObject);
        } else {
          firebaseService.getRegionAndSnap(dispatch);
        }
      } else if (type) {
        if (type == 1) {
          //Handle stream
          const streamgid = params.get('streamgid');
          const xmax = params.get('xmax');
          const xmin = params.get('xmin');
          const ymax = params.get('ymax');
          const ymin = params.get('ymin');
          if (streamgid && xmax && xmin && ymax && ymin) {
            mapboxService.jumpToStream({
              stream_gid2: streamgid, GeoBoundMaxX2: xmax, GeoBoundMaxY2: ymax, GeoBoundMinX2: xmin, GeoBoundMinY2: ymin,
            }, map.current);
          } else {
            firebaseService.getRegionAndSnap(dispatch);
          }
        } else if (type == 0) {
          //Handle dropped pin
          const long = params.get('longitude');
          const lat = params.get('latitude');
          const zoom = params.get('zoom');
          const point = [long, lat];
          mapboxService.jumpToPoint(map.current, point, dispatch, zoom);
        } else if (type == 2) {
          //Handle custom region
          const xmax = params.get('xmax');
          const xmin = params.get('xmin');
          const ymax = params.get('ymax');
          const ymin = params.get('ymin');
          if (xmax && xmin && ymax && ymin) {
            mapboxService.jumpToCustomRegion({
              GeoBoundMaxX2: xmax, GeoBoundMaxY2: ymax, GeoBoundMinX2: xmin, GeoBoundMinY2: ymin,
            }, map.current);
          } else {
            firebaseService.getRegionAndSnap(dispatch);
          }
        } else if (type == 3) {
          //Handle custom region
          const regionName = params.get('name');
          const regionObject = snapRegions.find((region) => region.title == regionName);
          if (regionObject) {
            //Handle pre-defined region
            mapboxService.snapToRegion(map.current, regionObject);
          } else {
            firebaseService.getRegionAndSnap(dispatch);
          }
        }
      } else {
        firebaseService.getRegionAndSnap(dispatch);
      }
    }
  }, [mapStyle, isAuthenticated, mapLoaded]);

  // Hooks into Redux to filter streams.
  useEffect(() => {
    if (isAuthenticated && mapLoaded) {
      mapboxService.filterStreams(map.current, filters, classes);
    }
  }, [filters, classes, isAuthenticated, mapLoaded]);

  // Hooks into Redux to toggle other features.
  useEffect(() => {
    if (isAuthenticated && mapLoaded) {
      mapboxService.updateOtherFeatures(map.current, otherFeatures);
    }
  }, [otherFeatures, isAuthenticated, mapLoaded]);

  // Jumps to the stream when the current stream changes.
  useEffect(() => {
    if (isAuthenticated && mapLoaded) {
      const {
        stream_gid, xmin, ymin, xmax, ymax, part_count,
      } = stream;

      let x1 = xmin;
      let x2 = xmax;
      let y1 = ymin;
      let y2 = ymax;

      if (part_count > 1 && !isElevationChartVisible) {
        const {
          GeoBoundMaxX, GeoBoundMaxY, GeoBoundMinX, GeoBoundMinY,
        } = stream;
        if (GeoBoundMaxX && GeoBoundMaxY && GeoBoundMinX && GeoBoundMinY) {
          x1 = GeoBoundMinX;
          x2 = GeoBoundMaxX;
          y1 = GeoBoundMinY;
          y2 = GeoBoundMaxY;
        }
      }

      mapboxService.jumpToStream({
        stream_gid2: stream_gid, GeoBoundMaxX2: x2, GeoBoundMaxY2: y2, GeoBoundMinX2: x1, GeoBoundMinY2: y1,
      }, map.current);
    }
  }, [stream, isAuthenticated, mapLoaded, isElevationChartVisible]);

  // Handles marker functionality.
  const markerHandler = useCallback((e) => {
    if (activeTools.marker) {
      const marker = mapboxService.createMarkerWithIcon(e.lngLat, map.current, mapMarkerTypes[0].img);
      dispatch(setTempMarker(marker));
    }
    setTimeout(() => {
      dispatch(setActiveTools({ marker: false }));
    }, 1);
    dispatch(setEditingMarkerId(''));
    map.current.off('click', markerHandler);
    map.current.off('touchend', markerHandler);
  }, [activeTools]);

  // Sets the click handler for the marker map tool.
  useEffect(() => {
    if (isAuthenticated && mapLoaded) {
      if (activeTools.marker) {
        map.current.on('click', markerHandler);
        map.current.on('touchend', markerHandler);
      } else {
        map.current.off('click', markerHandler);
        map.current.off('touchend', markerHandler);
      }

      if (activeTools.line) {
        Draw.changeMode(Draw.modes.DRAW_LINE_STRING);
        if (unsubscribeFunc.current) unsubscribeFunc.current();
      } else if (activeTools.area) {
        Draw.changeMode(Draw.modes.DRAW_POLYGON);
        if (unsubscribeFunc.current) unsubscribeFunc.current();
      } else {
        Draw.changeMode(Draw.modes.SIMPLE_SELECT);
        dispatch(setDelayedPanelShown(false));
        if (unsubscribeFunc.current) unsubscribeFunc.current();

        const unsubscribe = mapboxService.setClickHandler(map.current, dispatch, Draw);
        unsubscribeFunc.current = unsubscribe;
      }
    }

    return () => {
      map.current.off('click', markerHandler);
      map.current.off('touchend', markerHandler);
    };
  }, [isAuthenticated, mapLoaded, activeTools, markerHandler]);

  // Deletes old marker when a new one is created.
  useEffect(() => {
    if (prevMarker && isAuthenticated && mapLoaded) {
      prevMarker.remove();
    }
  }, [tempMarker, isAuthenticated, mapLoaded]);

  // Deletes old line when a new one is created.
  useEffect(() => {
    if (prevLine && isAuthenticated && mapLoaded) {
      Draw.delete(prevLine.id);
    }
  }, [tempLine, isAuthenticated, mapLoaded]);

  // Deletes old area when a new one is created.
  useEffect(() => {
    if (prevArea && isAuthenticated && mapLoaded) {
      Draw.delete(prevArea.id);
    }
  }, [tempArea, isAuthenticated, mapLoaded]);

  useEffect(() => {
    if (isAuthenticated && mapLoaded) {
      markersUpdateHandler();
    }
  }, [markers, isAuthenticated, mapLoaded]);

  // Redraws all the lines when they update.
  useEffect(() => {
    if (isAuthenticated && mapLoaded) {
      Draw.delete(Object.keys(lineIds));
      dispatch(resetLineIds());
      for (const [key, line] of Object.entries(lines)) {
        if (line.properties && line?.geometry?.coordinates) {
          const newLineId = Draw.add(line);
          Draw.setFeatureProperty(newLineId, 'color', line.properties.lineColorIndex ? lineColors[line.properties.lineColorIndex].color : lineColors[0].color);
          Draw.setFeatureProperty(newLineId, 'width', line.properties.lineWidthIndex ? lineWidths[line.properties.lineWidthIndex].width : lineWidths[0].width);
          Draw.setFeatureProperty(newLineId, 'dashType', line.properties.lineTypeIndex ? lineTypes[line.properties.lineTypeIndex].index : lineTypes[0].index);
          Draw.setFeatureProperty(newLineId, 'name', line.properties.title);
          const newLine = Draw.get(newLineId);

          Draw.delete(newLineId);
          Draw.add(newLine);

          if (key === lineIds[lineId]) {
            dispatch(setEditingLineId(newLineId[0]));
          }

          dispatch(updateLineId(key, newLineId[0]));
        }
      }
    }
  }, [lines, isAuthenticated, mapLoaded]);

  const getDevicesImg = () => {
    let img;
    if (isSmallScreen) {
      img = devices;
    } else if (isMediumScreen) {
      img = devices2x;
    } else if (isLargeScreen) {
      img = devices3x;
    }
    return img;
  }

  // Redraws all the areas when they update.
  useEffect(() => {
    if (isAuthenticated && mapLoaded) {
      Object.keys(areaIds).forEach((id) => {
        mapboxService.clearAreaBorder(map.current, { id });
        Draw.delete(id);
      });
      dispatch(resetAreaIds());

      for (const [key, area] of Object.entries(areas)) {
        if (area.properties && area?.geometry?.coordinates) {
          const addArea = { ...area };
          if (area.geometry.coordinates.length > 1) addArea.geometry.coordinates = [area.geometry.coordinates]; // Fixes nested coordinates bug.

          try {
            const newAreaId = Draw.add(addArea);
            Draw.setFeatureProperty(newAreaId, 'color', area.properties.fillColorIndex ? lineColors[area.properties.fillColorIndex].color : lineColors[0].color);
            Draw.setFeatureProperty(newAreaId, 'opacity', area.properties.fillOpacity);
            Draw.setFeatureProperty(newAreaId, 'name', area.properties.title);
            const newArea = Draw.get(newAreaId);

            Draw.delete(newAreaId);
            Draw.add(newArea);
            mapboxService.clearAreaBorder(map.current, newArea); // For some reason needed for proper border rendering?
            mapboxService.addAreaBorder(newArea, map.current, {
              color: area.properties.fillColorIndex ? lineColors[area.properties.fillColorIndex].color : lineColors[0].color,
              width: area.properties.lineWidthIndex ? lineWidths[area.properties.lineWidthIndex].width : lineWidths[0].width,
              dashArray: area.properties.lineTypeIndex ? lineTypes[area.properties.lineTypeIndex].dashArray : lineTypes[0].dashArray,
              opacity: area.properties.outlineOpacity,
            });

            if (key === areaIds[areaId]) {
              dispatch(setEditingAreaId(newAreaId[0]));
            }

            dispatch(updateAreaId(key, newAreaId[0]));
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
  }, [areas, isAuthenticated, mapLoaded]);

  useEffect(() => {
    if (isPro) {
      dispatch(updateMapStyle(layersEnum.guide));
    }
  }, [isPro]);

  return (
    <>
      {!isAuthenticated
        && (
          <div className={styles.header}>
            <div className={styles.nav}><img src={horizontalLogoWhite} alt="Map" style={{ transform: 'scale(0.78)' }} className={styles.imageTop} onClick={() => { window.location.href = 'https://troutroutes.com/'; }} /></div>
            <div className={styles['map-background-image']}>
              <img src={background} alt="Map" className={styles.image} />
              <div className={styles.overlay}>
                <div className={styles.overlayContent}>
                  <div className={styles.overlayTextOne}>The #1 Mapping Resource for Trout Anglers</div>
                  <div className={styles.overlayTextTwo}>50K Streams • 48 States • 350K Access Points • Custom & Offline Maps</div>
                  <img src={getDevicesImg()} alt="devices" className={styles.overlayImage} />
                </div>
              </div>
            </div>
          </div>
        )}
      <div data-tap-disabled="true" className={styles['map-background']} ref={mapContainer} style={{ visibility: isAuthenticated ? 'visible' : 'hidden' }} />
      <Toaster toastOptions={{ duration: 3000 }} />
    </>
  );
};

export default MapBackground;
