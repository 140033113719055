import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const SettingsGroup = ({ title, children }) => {
  return (
    <div className={styles['settings-group']}>
      <h4>{title}</h4>
      {children}
    </div>
  );
};

SettingsGroup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default SettingsGroup;
