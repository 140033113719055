/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import { Button } from 'components/TwoButtons';
import {
  Link, MenuItem, Select, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { linkStyles } from 'components/CreateDialog';
import { mapMarkerTypes } from 'lib/constants';
import styles from './styles.module.scss';
import Section from '../CreateDialog/Section';

const inputStyles = makeStyles({
  root: {
    backgroundColor: 'white !important',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  input: {
    backgroundColor: 'white',
    fontSize: '0.85rem',
    padding: '13px',
  },
});

const labelStyles = makeStyles({
  root: {
    marginTop: -5,
  },
  shrink: {
    marginTop: 0,
  },
});

const ContentDialog = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

ContentDialog.propTypes = {
  children: PropTypes.node,
};

ContentDialog.Header = ({
  icon,
  svgIcon = null,
  title,
  subtitle,
  isEditing,
  onChange,
  onIconChange,
  iconValue,
}) => {
  const renderIcon = () => {
    if (isEditing) {
      return (
        <ContentDialog.MapMarkerSelector
          onChange={onIconChange}
          value={iconValue}
        />
      );
    } else if (svgIcon) {
      return svgIcon;
    } else {
      return <Image src={icon} width={50} height={50} />;
    }
  };

  return (
    <div className={styles.header}>
      {renderIcon()}

      <div className={styles['header-text']}>
        {isEditing ? (
          <ContentDialog.InputField
            onChange={onChange}
            value={title}
            name="title"
            label="Title"
          />
        ) : (
          <h5>{title}</h5>
        )}
        {!isEditing && <h6>{subtitle}</h6>}
      </div>
    </div>
  );
};

ContentDialog.Header.propTypes = {
  icon: PropTypes.string,
  svgIcon: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func,
  onIconChange: PropTypes.func,
  iconValue: PropTypes.number,
};

ContentDialog.Text = ({ children }) => {
  return <div className={styles.text}>{children}</div>;
};

ContentDialog.Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ContentDialog.Buttons = ({ buttons, style, disabled }) => {
  return (
    <div className={styles.buttons} style={style}>
      {buttons
        && buttons.map(
          ({
            label,
            onClick,
            icon,
            iconElement,
            disabled: buttonDisabled,
            style: buttonStyle,
          }) => {
            return (
              <Button
                key={label}
                label={label}
                onClick={onClick}
                icon={icon}
                iconElement={iconElement}
                disabled={disabled || buttonDisabled}
                className={`${buttonDisabled ? styles.disabled : ''} ${
                  buttonStyle || ''
                }`}
              />
            );
          },
        )}
    </div>
  );
};

ContentDialog.Buttons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      error: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ),
  style: PropTypes.object,
};

ContentDialog.Heading = ({ children }) => {
  return <h4 className={styles.heading}>{children}</h4>;
};

ContentDialog.Heading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ContentDialog.SubmitSection = ({ buttons }) => {
  const linkClasses = linkStyles();

  return (
    <div className={styles['submit-container']}>
      {buttons
        && buttons.map(({ label, onClick, error }) => {
          return (
            <Link
              classes={linkClasses}
              component="button"
              underline="none"
              onClick={onClick}
              color={error ? 'error' : 'primary'}
              key={label}
            >
              {label}
            </Link>
          );
        })}
    </div>
  );
};

ContentDialog.SubmitSection.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      error: PropTypes.bool,
    }),
  ),
};

ContentDialog.InputField = ({
  onChange, value, name, label,
}) => {
  const inputClasses = inputStyles();
  const labelClasses = labelStyles();

  return (
    <TextField
      InputProps={{ classes: inputClasses }}
      inputProps={{ classes: inputClasses.input }}
      InputLabelProps={{ classes: labelClasses }}
      name={name}
      onChange={onChange}
      value={value}
      type="text"
      variant="outlined"
      label={label}
      fullWidth
    />
  );
};

ContentDialog.InputField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

ContentDialog.MultiLineField = ({
  onChange, value, name, label,
}) => {
  const inputClasses = inputStyles();

  return (
    <TextField
      classes={inputClasses}
      name={name}
      multiline
      InputProps={{ classes: inputClasses }}
      type="text"
      variant="filled"
      label={label}
      rows={2}
      rowsMax={6}
      fullWidth
      value={value}
      onChange={onChange}
    />
  );
};

ContentDialog.MultiLineField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

ContentDialog.MapMarkerSelector = ({ onChange, value }) => {
  return (
    <Select onChange={onChange} value={value}>
      {mapMarkerTypes.map((item, index) => {
        const { shortName, img } = item;

        return (
          <MenuItem key={shortName} value={index}>
            <Image height={20} width={20} src={img} />
            <span style={{ marginLeft: 10 }}>{shortName}</span>
          </MenuItem>
        );
      })}
    </Select>
  );
};

ContentDialog.MapMarkerSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};

ContentDialog.Section = Section;

ContentDialog.Selector = ({ items, renderItem }) => {
  return (
    <div className={styles.selector}>
      {items.map((item) => {
        return renderItem(item);
      })}
    </div>
  );
};

ContentDialog.Selector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  renderItem: PropTypes.func.isRequired,
};

export default ContentDialog;
