import React, { useEffect } from 'react';
import {
  setRiverMilesCardVisible, setRiverMilesDistance, setRiverMilesMarkers, setRiverMilesParentLine, setRiverMilesPrimnaryLabel, setRiverMilesSlicedLineCoordinates, setRiverMilesStep,
} from 'actions';
import BottomRightDialog from 'components/BottomRightDialog';
import { CheckCircle, Height, Widgets } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { removeHighlight } from 'services/riverMiles';
import { setRiverMilesFilters } from 'services/mapbox';
import styles from './styles.module.scss';

const steps = [
  'Step 1: Tap a point along a stream',
  'Step 2: Tap an end point',
  'Step 3: Done! Tap other end points',
];

const RiverMiles = () => {
  const dispatch = useDispatch();
  const {
    map, isRiverMilesVisible: isVisible, riverMilesPrimaryLabel, riverMilesStep, riverMilesMarkers, riverMilesDistance,
  } = useSelector((state) => state.map);

  const handleReset = () => {
    dispatch(setRiverMilesStep(0));
    dispatch(setRiverMilesPrimnaryLabel(null));
    dispatch(setRiverMilesDistance(null));
    if(riverMilesMarkers.startPoint) {
      riverMilesMarkers.startPoint.remove();
    }
    if(riverMilesMarkers.endPoint) {
      riverMilesMarkers.endPoint.remove();
    }
    dispatch(setRiverMilesMarkers({ startPoint: null, endPoint: null }));
    dispatch(setRiverMilesSlicedLineCoordinates({ startPoint: null, endPoint: null }));
    dispatch(setRiverMilesParentLine(null));
    setRiverMilesFilters(map);
    removeHighlight(map);
  };

  const close = () => {
    handleReset();
    dispatch(setRiverMilesCardVisible(false, map));
  };

  useEffect(() => {
    if (!isVisible) {
      handleReset();
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <BottomRightDialog close={close} title={`River Miles ${riverMilesPrimaryLabel ? `- ${riverMilesPrimaryLabel}` : ''}`} backgroundColor="white">
      <div className={styles.container}>
        <div className={styles.flexRow}>
          <div className={styles.steps}>{steps[riverMilesStep]}</div>
          <span className={styles.distance}>Distance (mi): <strong>{riverMilesDistance || 'N/A'}</strong></span>
        </div>
        <div className={styles.flexRow}>
          <div className={styles.breadcrumbs}>
            <div className={styles.numberContainer}>
              <span className={`${styles.number} ${riverMilesStep > 0 ? '' : styles.border}`}>{riverMilesStep > 0 ? <CheckCircle classes={{ root: styles.icon }} style={{ marginRight: '-4px' }} /> : '1'}</span>
              <span className={styles.line} style={{ borderColor: riverMilesStep > 0 ? 'blue' : 'black' }} />
              <span className={`${styles.number} ${riverMilesStep > 1 ? '' : styles.border}`}>{riverMilesStep > 1 ? <CheckCircle classes={{ root: styles.icon }} style={{ marginLeft: '-4px' }} /> : '2'}</span>
            </div>
            <div className={styles.textContainer}>
              <div><span>Starting</span><br /> <span>Point</span></div>
              <div><span>Ending</span> <br /><span>Point</span></div>
            </div>
          </div>
          <button type="button" onClick={handleReset} className={styles.reset}>Reset</button>
        </div>
      </div>
    </BottomRightDialog>
  );
};

export default RiverMiles;
