import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFilter, updateClasses, updateOtherFeatures, setIsUpgradeModalShown,
} from 'actions';
import {
  goldMedal, class1, class2, class3, mostPopular, easeSection, tailwaters, bridges, mapAccess, mapGage, mapShop, mapCamp, mapBoat, mapTrailhead, mapParking, mapLakes,
} from 'assets';
import useIsPro from 'utils/useIsPro';
import Pane from '../Pane';
import SettingsGroup from './SettingsGroup';
import SettingsItem from './SettingsItem';

const SettingsPane = () => {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const { filters, classes, otherFeatures } = useSelector((state) => state.map);
  const { showEasements, showTailwaters, showPopular } = filters;
  const {
    showClass0, showClass1, showClass2, showClass3,
  } = classes;
  const {
    counties, flyShops: flyShopsActive, gages, parking: parkingActive, boat, camp, hike, accessPoints: accessPointsActive, bridges: bridgesActive, lakes,
  } = otherFeatures;

  const handleClasses = (classNum) => {
    switch (classNum) {
    case 0:
      if (isPro) dispatch(updateClasses({ ...classes, showClass0: !showClass0 }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 1:
      if (isPro) dispatch(updateClasses({ ...classes, showClass1: !showClass1 }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 2:
      if (isPro) dispatch(updateClasses({ ...classes, showClass2: !showClass2 }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 3:
      if (isPro) dispatch(updateClasses({ ...classes, showClass3: !showClass3 }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    default:
      break;
    }
  };

  const handleFilters = (filter) => {
    switch (filter) {
    case 'easements':
      if (isPro) dispatch(updateFilter({ ...filters, showEasements: !showEasements }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 'tailwaters':
      if (isPro) dispatch(updateFilter({ ...filters, showTailwaters: !showTailwaters }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 'popular':
      if (isPro) dispatch(updateFilter({ ...filters, showPopular: !showPopular }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    default:
      break;
    }
  };

  const handleFeatures = (feature) => {
    switch (feature) {
    case 'parking':
      if (isPro) dispatch(updateOtherFeatures({ ...otherFeatures, parking: !parkingActive }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 'counties':
      dispatch(updateOtherFeatures({ ...otherFeatures, counties: !counties }));
      break;
    case 'flyShops':
      dispatch(updateOtherFeatures({ ...otherFeatures, flyShops: !flyShopsActive }));
      break;
    case 'gages':
      if (isPro) dispatch(updateOtherFeatures({ ...otherFeatures, gages: !gages }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 'boat':
      if (isPro) dispatch(updateOtherFeatures({ ...otherFeatures, boat: !boat }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 'camp':
      if (isPro) dispatch(updateOtherFeatures({ ...otherFeatures, camp: !camp }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 'hike':
      if (isPro) dispatch(updateOtherFeatures({ ...otherFeatures, hike: !hike }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 'accessPoints':
      if (isPro) dispatch(updateOtherFeatures({ ...otherFeatures, accessPoints: !accessPointsActive }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 'bridges':
      if (isPro) dispatch(updateOtherFeatures({ ...otherFeatures, bridges: !bridgesActive }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    case 'lakes':
      if (isPro) dispatch(updateOtherFeatures({ ...otherFeatures, lakes: !lakes }));
      else dispatch(setIsUpgradeModalShown(true));
      break;
    default:
      break;
    }
  };

  return (
    <Pane title="Map Filters & Settings">
      <SettingsGroup title="Stream Classes">
        <SettingsItem title="Gold Medal / Blue Ribbon" graphic={goldMedal} active={showClass0} toggleActive={() => { handleClasses(0); }} />
        <SettingsItem title="Class 1" graphic={class1} active={showClass1} toggleActive={() => { handleClasses(1); }} />
        <SettingsItem title="Class 2" graphic={class2} active={showClass2} toggleActive={() => { handleClasses(2); }} />
        <SettingsItem title="Class 3" graphic={class3} active={showClass3} toggleActive={() => { handleClasses(3); }} />
      </SettingsGroup>

      <SettingsGroup title="Special Filters">
        <SettingsItem title="Most popular only" graphic={mostPopular} active={showPopular} toggleActive={() => { handleFilters('popular'); }} />
        <SettingsItem title="Fishing Easements Only" graphic={easeSection} active={showEasements} toggleActive={() => { handleFilters('easements'); }} />
        <SettingsItem title="Tailwaters Only" graphic={tailwaters} active={showTailwaters} toggleActive={() => { handleFilters('tailwaters'); }} />
      </SettingsGroup>

      <SettingsGroup title="Stream Objects">
        <SettingsItem title="Designated Access Points" graphic={mapAccess} active={accessPointsActive} toggleActive={() => { handleFeatures('accessPoints'); }} />
        <SettingsItem title="Parking" graphic={mapParking} active={parkingActive} toggleActive={() => { handleFeatures('parking'); }} />
        <SettingsItem title="Trailheads" graphic={mapTrailhead} active={hike} toggleActive={() => { handleFeatures('hike'); }} />
        <SettingsItem title="Boat Ramps & Put-Ins" graphic={mapBoat} active={boat} toggleActive={() => { handleFeatures('boat'); }} />
        <SettingsItem title="Campgrounds & Camp Sites" graphic={mapCamp} active={camp} toggleActive={() => { handleFeatures('camp'); }} />
        <SettingsItem title="Bridges" graphic={bridges} active={bridgesActive} toggleActive={() => { handleFeatures('bridges'); }} />

      </SettingsGroup>

      <SettingsGroup title="Miscellaneous">
        <SettingsItem title="Fly Shops" graphic={mapShop} active={flyShopsActive} toggleActive={() => { handleFeatures('flyShops'); }} />
        <SettingsItem title="Stream Gages" graphic={mapGage} active={gages} toggleActive={() => { handleFeatures('gages'); }} />
        <SettingsItem title="Trout lakes" graphic={mapLakes} active={lakes} toggleActive={() => { handleFeatures('lakes'); }} />
        <SettingsItem title="County Boundaries" active={counties} toggleActive={() => { handleFeatures('counties'); }} />
      </SettingsGroup>
    </Pane>
  );
};

export default SettingsPane;
