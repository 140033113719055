import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Header from './Header';
import styles from './styles.module.scss';

const BottomRightDialog = ({
  backgroundColor, children, close, title, icon,
}) => {
  return (
    <motion.div className={styles.container} style={{ backgroundColor }} animate={{ height: 'auto' }} transition={{ duration: 0.4, type: 'spring' }} initial={{ height: 0 }}>
      <Header close={close} title={title} icon={icon} />
      {children}
    </motion.div>
  );
};

BottomRightDialog.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  close: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.string,
};

export default BottomRightDialog;
