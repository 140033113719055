/* eslint-disable no-mixed-operators */
/* eslint-disable no-constant-condition */
/* eslint-disable no-param-reassign */
/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import {
  mapboxDemSource, classThreeStreamColor, classGoldStreamColor, classOneStreamColor, classTwoStreamColor, directionsURL, mapMarkerTypes, accountTypesEnum,
} from 'lib/constants';
import {
  mapParking, mapGage, markerBoundaryBlue, markerBoundaryPurple, mapBoat, mapCamp, mapTrailhead, mapAccess, mapSP, mapNP, markerTypePOI, markerTypeHabitat, markerTypeDam, linePopupIcon, shapePopupIcon, flyshopIcon, markerTypeTrailhead, droppedPin, mapShopSolid, mapEvents, mapHabitat, mapStocking, mapDam, markerTypeRivermiles, directionsIcon, shareIcon, createIcon, carIcon, mapLakes, mapRarewaters,  directionsIcon2, websiteIcon2, callIcon2, shareIcon2, streetviewIcon2, createIcon2, chartIcon2,
} from 'assets';
import Popup from 'components/Popup';
import PopupExt from 'components/PopupExtend';
import Popup3 from 'components/Popup3';
import PopupFlex from 'components/PopupFlex';
import {sendAmplitudeData} from 'utils/amplitude'
import {
  updateStreamWithGeocoding, setTempMarker, setMarkerType, setTempLine, setTempArea, setActiveTools, setEditingLineId, setEditingAreaId, setActiveSidebar, setCurrentGage, setIsGageChartShown, clearMap, setEditingMarkerId, setSupportedStates, setIsUpgradeModalShown, setElevation,
  setCurrentHUC,
  setRiverMilesStep,
  updateMapCoordinates,
  createMarker,
  resetCurrentMarker,
  setTRPOIFeature,
  setTapMarker,
  setRedMarker,
  setLocationMarker,
  updateStreamGeography,
  resetCurrentLine,
  resetCurrentArea,
} from 'actions';
import {
  supportedStates,
} from 'lib/constants';
import toast from 'react-hot-toast';
import mapboxgl from 'mapbox-gl';
import * as firebaseService from './firebase';
import * as regulationsService from './regulations';
import {  getElevationPoint } from './elevation';
import {store} from '../index'
import { getNearestLinetoPoint, getNearestPointfromTapToProcessLine } from './riverMiles';
import PopupMapTap from 'components/PopupMapTap';
import { TapIcon, LocationIcon } from '../lib/Icons';

const layerHandlers = ['access_points', 'access_parking', 'access_parks','access_habitat', 'access_access', 'access_troutpark', 'access_boat', 'access_dam', 'access_camp', 'access_trail', 'flyshops', 'gage_station', 'access_boundary', 'access_stock','TU_Events_Production', 'access_poi', 'trout_lakes', 'rare_waters', 'landuse-parks'];
let currentStreamGID2 = '';
let tempTimer = null;
/**
 * Loads the images for fly box icons.
 * @param {object} geoJsonPointsFlyShops JSON representation of fly shop points after a call to Firebase.
 * @param {*} map Mapbox GL object.
 */
const loadFlyBoxImages = async (geoJsonPointsFlyShops, map) => {
  const fullPathGeneric = 'FlyShops/generic_cropped.png';
  const genericUrl = await firebaseService.getImageDownloadURL(fullPathGeneric);

  await Promise.all(geoJsonPointsFlyShops.features.map(async (shop) => {
    const id = shop.properties.gid;
    if (!id) {
      return;
    }
    const shopId = `shop_id_${id}`;
    const path = `icon_${id}_cropped.png`;
    const fullpath = `FlyShops/${path}`;

    try {
      const url = await firebaseService.getImageDownloadURL(fullpath);
      if (!map.hasImage(shopId)) {
        map.loadImage(
          url,
          (error, icon) => {
            if (!error) {
              map.addImage(shopId, icon);
            }
          },
        );
      }
    } catch (error) {
      map.loadImage(
        mapShopSolid,
        (e, icon) => {
          if (!e) {
            map.addImage(shopId, icon);
          }
        },
      );
    }
  }));
};

const setupSupportedStates = async (map, dispatch) => {
  map.on('moveend', () => {
    const supportedStates = getSupportedStates(map);
    dispatch(setSupportedStates(supportedStates));
  });
};


/**
 * Helper function to set up the flyshops layer.
 * @param {*} map Mapbox GL object.
 */
const setUpFlyShops = async (map) => {
  
  let count = 0;
  const maxTries = 5;
  while (count < maxTries) {
    try {

      map.addSource('flyshops-5-0-export-16-816csz', {
        type: "vector",
        url: "mapbox://troutinsights.1psr45yi"
      });

      map.addLayer({
        id: 'flyshops',
        type: 'symbol',
        source: 'flyshops-5-0-export-16-816csz',
        'source-layer': 'flyshops-5-0-export-16-816csz',
        layout: {
          'icon-image': 'tr_final_v1_shop',
        },
      });

      map.setLayoutProperty('flyshops', 'icon-allow-overlap', true);
      map.setPaintProperty('flyshops', 'icon-opacity', [
        'interpolate',
        ['linear', 0.5],
        ['zoom'],
        6, 0.0,
        7, 1.0]);
      map.setLayoutProperty('flyshops', 'icon-size', 0.05);

      map.setPaintProperty('county-outline', 'line-opacity', [
          'interpolate',
          ['linear', 0.5],
          ['zoom'],
          7, 0.0,
          8, 1.0]);
          map.setPaintProperty('county-label', 'text-opacity', [
            'interpolate',
            ['linear', 0.5],
            ['zoom'],
            7, 0.0,
            8, 1.0]);
      
      count = 4;
    } catch (e) {
      count += 1;
      if (count > maxTries) {
        break;
      }
    }
  }
};

export const updateMapLayers = async (map, accountType) => {
  if(accountType === 'pro') {
    map.setLayoutProperty('landuse-parks', 'visibility', 'visible');
    map.setLayoutProperty('access_parks', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('landuse-parks', 'visibility', 'none');
    map.setLayoutProperty('access_parks', 'visibility', 'none');
  }
}

function setupHUC10(map) {
  const HUC10TilesetURL = 'mapbox://troutinsights.5nxwcamr';
  const HUC10TilesetSourceID = 'Huc10Watersheds_01-0cmgen';
  
  // Add the vector tile source
  map.addSource(HUC10TilesetSourceID, {
    type: 'vector',
    url: HUC10TilesetURL
  });

  // Add the fill style layer for HUC10 watersheds
  map.addLayer({
    id: 'huc_10',
    type: 'fill',
    source: HUC10TilesetSourceID,
    'source-layer': HUC10TilesetSourceID,
    paint: {
      'fill-opacity': 0.01,
      'fill-color': 'purple'
    }
  });

  const huc = '0';
  const hucPred = ['==', 'huc10', huc];

  // Add the fill style layer for highlighted HUC10 watersheds
  map.addLayer({
    id: 'huc_10_layer_highlight',
    type: 'fill',
    source: HUC10TilesetSourceID,
    'source-layer': HUC10TilesetSourceID,
    paint: {
      'fill-opacity': 0.1,
      'fill-color': 'blue'
    },
    filter: hucPred
  });

  // Add the line style layer for highlighted HUC10 watersheds outline
  map.addLayer({
    id: 'huc_10_layer_highlight_outline',
    type: 'line',
    source: HUC10TilesetSourceID,
    'source-layer': HUC10TilesetSourceID,
    paint: {
      'line-opacity': 0.3,
      'line-width': 1,
      'line-color': 'blue'
    },
    filter: hucPred
  });
}

function setupTroutLakes(map) {
  const troutLakesTilesetURL = "mapbox://troutinsights.6bth3x6f";
  const troutLakesTilesetSourceID = "WY_lakes_test-79bvxg";
  const troutLakesLayerStyle = {
    id: 'trout_lakes',
    type: 'symbol',
    source: troutLakesTilesetSourceID,
    'source-layer': troutLakesTilesetSourceID,
    layout: {
      'icon-image': 'trout_lakes', // Name of the icon image
      'icon-size': 0.03, // Icon size
      'icon-allow-overlap': true, // Allow overlapping icons
      visibility: 'visible', // Initial visibility
    },
    minzoom: 9,
    maxzoom: 22,
  };

  // Add the vector tile source
  map.addSource(troutLakesTilesetSourceID, {
    type: 'vector',
    url: troutLakesTilesetURL
  });

  if (!map.hasImage('trout_lakes')) {
    map.loadImage(
      mapLakes,
      (error, imageLakes) => {
        if (!error) {
          map.addImage('trout_lakes', imageLakes);
        }
      },
    );
  }

  // Add style layer for TroutLakes  
  // const layerPosition = style.layers.find(layer => layer.id === 'access_poi') ? 'access_poi' : undefined;
  map.addLayer(troutLakesLayerStyle);
}

function setupRareWaters(map) {
  const rareWaterTilesetURL = "mapbox://troutinsights.93mvtos2";
  const rareWaterTilesetSourceID = "rare_waters_test_01-aeul7c";
  const rareWaterEventLayerStyle = {
    id: 'rare_waters',
    type: 'symbol',
    source: rareWaterTilesetSourceID,
    'source-layer': rareWaterTilesetSourceID,
    layout: {
      'icon-image': 'rare_waters', // Name of the icon image
      'icon-size': 0.028, // Icon size
      'icon-allow-overlap': true, // Allow overlapping icons
      visibility: 'visible', // Initial visibility
    },
    'paint': {
      'icon-opacity': [
        'interpolate',
        ['linear', 0.5],
        ['zoom'],
        6, 0.0,
        7, 1.0
      ],
    }
  };
  map.addSource(rareWaterTilesetSourceID, {
    type: 'vector',
    url: rareWaterTilesetURL,
  });

  // Add the rare_waters image
  if (!map.hasImage('rare_waters')) {
    map.loadImage(
      mapRarewaters,
      (error, imageRarewaters) => {
        if (!error) {
          map.addImage('rare_waters', imageRarewaters);
        }
      },
    );
  }

  // Add style layer for Rare waters  
  // const layerPosition = style.layers.find(layer => layer.id === 'access_poi') ? 'access_poi' : undefined;
  map.addLayer(rareWaterEventLayerStyle);
}

/**
 * Helper function to set up the Events layer.
 * @param {*} map Mapbox GL object.
 */
const setUpEventsLayer = async (map) => {
  if (!map.hasImage('TULogo')) {
    map.loadImage(
      mapEvents,
      (error, imageTULogo) => {
        if (!error) {
          map.addImage('TULogo', imageTULogo);
        }
      },
    );
  }

  map.addLayer({
    'id': 'TU_Events_Production',
    'source': {
      type: 'vector',
      url: 'mapbox://troutinsights.96woet0i'
    },
    'source-layer': 'TU_event_points-dtt702',
    'type': 'symbol',
    
    'layout': {
      'icon-image': 'TULogo',
      'icon-size': .05,
      'visibility': 'visible',
      'icon-allow-overlap': true,
    },

    'paint': {
      'icon-opacity': [
        'interpolate',
        ['linear', 0.5],
        ['zoom'],
        6, 0.0,
        7, 1.0
      ],
    }
  });
};

/**
 * Helper function to set up 3D mapping.
 * @param {*} map Mapbox GL object.
 */
export const setUp3DMaps = (map, pitch = 0, degree = 0, isZoom = false) => {
  if (!map.getSource('mapbox-dem')) {
    map.addSource('mapbox-dem', {
      type: 'raster-dem',
      url: mapboxDemSource,
      tileSize: 512,
      maxzoom: 14,
    });
  }
  // add the DEM source as a terrain layer with exaggerated height
  map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });

  // add a sky layer that will show when the map is highly pitched
  if (!map.getLayer('sky')) {
    map.addLayer({
      id: 'sky',
      type: 'sky',
      paint: {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [0.0, 0.0],
        'sky-atmosphere-sun-intensity': 15,
      },
    });
  }
  if (map.getZoom() < 14 && isZoom) {
    map.flyTo({ pitch: pitch ? pitch : 45, bearing: degree ? degree : 0, zoom: 14 });
  } else {
    map.flyTo({ pitch: pitch ? pitch : 45, bearing: degree ? degree : 0 });
  }
};

export const disable3DMaps = (map) => {
  map.setTerrain();
  if (map.getLayer('sky')) map.removeLayer('sky');
  map.flyTo({ pitch: 0, bearing: 0 });
};

/**
 * Sets up the initial paint properties for the map.
 * @param {*} map Mapbox GL object.
 */
const setUpPaintProperties = (map) => {
  map.setPaintProperty('trails_section', 'line-color', '#800080');
  map.setPaintProperty('trails_section', 'line-width', 2);

  map.setPaintProperty('trails_sections_background', 'line-color', '#800080');
  map.setPaintProperty('trails_sections_background', 'line-width', 1);
  map.setPaintProperty('trails_sections_background', 'line-opacity', 0.7);
  map.setPaintProperty('trails_sections_background', 'line-dasharray', [1, 1]);
  map.setPaintProperty('trails_section', 'line-dasharray', [1, 1]);

  map.setPaintProperty('pal_section', 'line-color', '#979797');
  // map.setPaintProperty('pal_section', 'line-width', [
  //   'match',
  //   ['get', 'StreamClass'],
  //   0,
  //   12,
  //   1,
  //   9,
  //   2,
  //   7,
  //   3,
  //   5, 2]);
  map.setPaintProperty('pal_section', 'line-width', [
    'match',
    ['get', 'StreamClass'],
    0,
    13,
    1,
    11,
    2,
    8,
    3,
    6, 2]);
  map.setPaintProperty('pal_section2', 'line-width', [
    'match',
    ['get', 'StreamClass'],
    0,
    12,
    1,
    9,
    2,
    7,
    3,
    5, 2]);

  map.setPaintProperty('pal_section', 'line-opacity', [
    'interpolate',
    ['linear', 0.5],
    ['zoom'],
    6,
    0.1,
    8,
    0.3,
    9,
    0.9,
    12,
    0.9]);
   
  map.setPaintProperty('pal_section2', 'line-opacity', [
    'interpolate',
    ['linear', 0.5],
    ['zoom'],
    6,
    0.1,
    8,
    0.3,
    9,
    0.9,
    12,
    0.9]);

  map.setPaintProperty('highlight', 'line-color', '#FFFFFF');
  map.setPaintProperty('highlight', 'line-width', 15);
  map.setPaintProperty('highlight', 'line-opacity', 0.5);

  map.setPaintProperty('easement_section', 'line-color', '#800080');
  map.setPaintProperty('easement_section', 'line-width', 3);
  const easement_filter = ['==', ['number', ['get', 'pal_type']], 1];
  map.setFilter('easement_section', ['all', easement_filter]);

  map.setPaintProperty('access_points', 'circle-opacity', 1.0);
  map.setPaintProperty('access_points', 'circle-radius', 5);
  map.setPaintProperty('access_points', 'circle-stroke-width', 1);
  map.setPaintProperty('access_points', 'circle-stroke-opacity', 1.0);
  map.setPaintProperty('access_points', 'circle-color', '#fbb03b');
  // map.setPaintProperty('access_points', 'stroke-color', '#ffffff');
  // map.setPaintProperty('access_points', 'circle-stroke-width', 1);
  map.setPaintProperty('county-outline', 'line-opacity', [
    'interpolate',
    ['linear', 0.5],
    ['zoom'],
    8, 0.0,
    10, 0.3,
    12, 0.8,
    14, 0.9]);
};

/**
 * Functional equivalent to 'highlighting' a stream.
 * @param {*} map Mapbox GL object.
 * @param {string} filterStreamGID ID of the stream being highlighted.
 */
const setFilters = (map, currentStreamGID = '') => {
  const filterStreamGID = ['==', ['string', ['get', 'stream_gid2']], currentStreamGID];
  const filterCamp = ['==', ['number', ['get', 'access_type']], 7];
  const filterBoat = ['==', ['number', ['get', 'access_type']], 6];
  const filterPark = ['==', ['number', ['get', 'access_type']], 5];
  const filterAccess = ['==', ['number', ['get', 'access_type']], 8];
  const filterTroutPark = ['==', ['number', ['get', 'access_type']], 9];
  const filterDam = ['==', ['number', ['get', 'access_type']], 10];
  const filterTrailhead = ['==', ['number', ['get', 'access_type']], 11];
  const filterTroutLakes = ['==', ['number', ['get', 'access_type']], 201];
  const filterSParks = ['==', ['number', ['get', 'access_type']], 106];

  const filterRoad = ['==', ['number', ['get', 'is_trail']], 0];
  const filterTrail = ['==', ['number', ['get', 'is_trail']], 2];
//5, 6, 7, 8, 9, 10, 11, 1-4
  

  if ((!currentStreamGID) || (currentStreamGID == "") || ((currentStreamGID == 0))){
    map.setPaintProperty('trout_section', 'line-opacity', 1.0);
    map.setPaintProperty('easement_section', 'line-opacity', 1.0);
    map.setPaintProperty('pal_section', 'line-opacity', 0.7);
    map.setPaintProperty('pal_section2', 'line-opacity', 0.7);
    map.setFilter('access_points', ['all', filterStreamGID]);
    map.setFilter('access_parking', ['all', filterPark]);
    map.setFilter('access_camp', ['all', filterCamp]);
    map.setFilter('access_boat', ['all', filterBoat]);
    map.setFilter('access_access', ['all', filterAccess]);
    map.setFilter('access_troutpark', ['all', filterTroutPark]);
    map.setFilter('access_trail', ['all', filterTrailhead]);
    map.setFilter('access_dam', ['all', filterDam]);
    map.setFilter('trout_lakes', ['all', filterTroutLakes]);
    map.setFilter('access_parks', ['all', filterSParks]);

    map.setFilter('highlight', ['all', filterStreamGID]);
    map.setFilter('trails_section', ['all', filterStreamGID, filterTrail]);
    map.setFilter('trails_sections_background', ['all', filterTrail]);
    map.setFilter('gage_station', null);

  } else {
    map.setFilter('access_points', ['all', filterStreamGID]);
    map.setFilter('access_parking', ['all', filterPark, filterStreamGID]);
    map.setFilter('access_camp', ['all', filterCamp, filterStreamGID]);
    map.setFilter('access_boat', ['all', filterBoat, filterStreamGID]);
    map.setFilter('access_access', ['all', filterAccess, filterStreamGID]);
    map.setFilter('access_troutpark', ['all', filterTroutPark]);
    map.setFilter('access_trail', ['all', filterTrailhead, filterStreamGID]);
    map.setFilter('access_dam', ['all', filterDam, filterStreamGID]);
    map.setFilter('access_parks', ['all', filterSParks, filterStreamGID]);

    map.setFilter('highlight', ['all', filterStreamGID]);
    map.setFilter('trails_section', ['all', filterStreamGID, filterTrail]);
    map.setFilter('trails_sections_background', ['all', filterTrail]);
    map.setFilter('gage_station', ['all', filterStreamGID]);

    map.setPaintProperty('trout_section', 'line-opacity', [
      'match',
      ['get', 'stream_gid2'],
      currentStreamGID,
      1.0, 0.3]);
    map.setPaintProperty('easement_section', 'line-opacity', [
      'match',
      ['get', 'stream_gid2'],
      currentStreamGID,
      1.0, 0.3]);
    map.setPaintProperty('pal_section', 'line-opacity', [
      'match',
      ['get', 'stream_gid2'],
      currentStreamGID,
      1.0, 0.3]);
    map.setPaintProperty('pal_section2', 'line-opacity', [
      'match',
      ['get', 'stream_gid2'],
      currentStreamGID,
      1.0, 0.3]);
  }
};

export const setRiverMilesFilters = (map, currentStreamGID = '') => {
  const filterStreamGID = ['==', ['string', ['get', 'stream_gid2']], currentStreamGID];
  const filterCamp = ['==', ['number', ['get', 'access_type']], 7];
  const filterBoat = ['==', ['number', ['get', 'access_type']], 6];
  const filterPark = ['==', ['number', ['get', 'access_type']], 5];
  const filterAccess = ['==', ['number', ['get', 'access_type']], 8];
  const filterTroutPark = ['==', ['number', ['get', 'access_type']], 9];
  const filterDam = ['==', ['number', ['get', 'access_type']], 10];
  const filterTrailhead = ['==', ['number', ['get', 'access_type']], 11];
  const filterTrail = ['==', ['number', ['get', 'is_trail']], 2];
  const filterTroutLakes = ['==', ['number', ['get', 'access_type']], 201];
  const filterSParks = ['==', ['number', ['get', 'access_type']], 106];

  if ((!currentStreamGID) || (currentStreamGID == "") || ((currentStreamGID == 0))){
    map.setPaintProperty('trout_section', 'line-opacity', 1.0);
    map.setPaintProperty('easement_section', 'line-opacity', 1.0);
    map.setPaintProperty('pal_section', 'line-opacity', 0.7);
    map.setPaintProperty('pal_section2', 'line-opacity', 0.7);
    map.setFilter('access_points', ['all', filterStreamGID]);
    map.setFilter('access_parking', ['all', filterPark]);
    map.setFilter('access_camp', ['all', filterCamp]);
    map.setFilter('access_boat', ['all', filterBoat]);
    map.setFilter('access_access', ['all', filterAccess]);
    map.setFilter('access_troutpark', ['all', filterTroutPark]);
    map.setFilter('access_trail', ['all', filterTrailhead]);
    map.setFilter('access_dam', ['all', filterDam]);
    map.setFilter('trout_lakes', ['all', filterTroutLakes]);
    map.setFilter('access_parks', ['all', filterSParks]);

    map.setFilter('highlight', ['all', filterStreamGID]);
    map.setFilter('trails_section', ['all', filterStreamGID, filterTrail]);
    map.setFilter('trails_sections_background', ['all', filterTrail]);
    map.setFilter('gage_station', null);

  } else {
    map.setFilter('access_points', false);
    map.setFilter('access_parking', false);
    map.setFilter('access_camp', false);
    map.setFilter('access_boat', false);
    map.setFilter('access_access', false);
    map.setFilter('access_troutpark', false);
    map.setFilter('access_trail', false);
    map.setFilter('access_dam', false);
    map.setFilter('trout_lakes', false);
    map.setFilter('access_parks', false);

    map.setFilter('highlight', ['all', filterStreamGID]);
    map.setFilter('trails_section', false);
    map.setFilter('trails_sections_background', false);
    map.setFilter('gage_station',false);

    map.setPaintProperty('trout_section', 'line-opacity', [
      'match',
      ['get', 'stream_gid2'],
      currentStreamGID,
      1.0, 0.3]);
    map.setPaintProperty('easement_section', 'line-opacity', [
      'match',
      ['get', 'stream_gid2'],
      currentStreamGID,
      1.0, 0.3]);
    map.setPaintProperty('pal_section', 'line-opacity', [
      'match',
      ['get', 'stream_gid2'],
      currentStreamGID,
      1.0, 0.3]);
    map.setPaintProperty('pal_section2', 'line-opacity', [
      'match',
      ['get', 'stream_gid2'],
      currentStreamGID,
      1.0, 0.3]);
  }
};

/**
 * Sets up the initial filters for the map.
 * @param {*} map Mapbox GL object.
 */
const setInitialFilters = (map) => {
  setFilters(map);
};

/**
 * Sets up other icons on the map.
 * @param {*} map Mapbox GL object.
 */
const setUpIcons = (map) => {
  // Parking.
  if (!map.hasImage('park')) {
    map.loadImage(
      mapParking,
      (error, imagePark) => {
        if (!error) {
          map.addImage('park', imagePark);
        }
      },
    );
  }
  if (!map.hasImage('trail')) {
    map.loadImage(
      mapTrailhead,
      (error, imageTrail) => {
        if (!error) {
          map.addImage('trail', imageTrail);
        }
      },
    );
  }

  map.setLayoutProperty('gage_station', 'visibility', 'visible');

  // Boat icon.
  if (!map.hasImage('boat')) {
    map.loadImage(
      mapBoat,
      (error, imageBoat) => {
        if (!error) {
          map.addImage('boat', imageBoat);
        }
      },
    );
  }

  // Camp icon.
  if (!map.hasImage('camp')) {
    map.loadImage(
      mapCamp,
      (error, imageTent) => {
        if (!error) {
          map.addImage('camp', imageTent);
        }
      },
    );
  }

  // Dam icon.
  if (!map.hasImage('dam')) {
    map.loadImage(
      markerTypeDam,
      (error, imageDam) => {
        if (!error) {
          map.addImage('dam', imageDam);
        }
      },
    );
  }

  if (!map.hasImage('access')) {
    map.loadImage(
      mapAccess,
      (error, imageAccess) => {
        if (!error) {
          map.addImage('access', imageAccess);
        }
      },
    );
  }
};

/**
 * Formats the map pursuant to the basic style.
 * @param {*} map Mapbox GL object.
 */
export const formatBasicStyle = (map) => {

  map.setLayoutProperty('gage_station', 'visibility', 'none');
  map.setLayoutProperty('national_lands_fill', 'visibility', 'none');
  map.setLayoutProperty('national_lands_outline', 'visibility', 'none');
  map.setLayoutProperty('access_access', 'visibility', 'none');
  map.setLayoutProperty('access_troutpark', 'visibility', 'none');
  map.setLayoutProperty('access_boat', 'visibility', 'none');
  map.setLayoutProperty('access_dam', 'visibility', 'none');
  map.setLayoutProperty('access_camp', 'visibility', 'none');
  map.setLayoutProperty('access_trail', 'visibility', 'none');
  map.setLayoutProperty('access_parking', 'visibility', 'none');
  map.setLayoutProperty('access_habitat', 'visibility', 'none');
  map.setLayoutProperty('access_stock', 'visibility', 'none');
  map.setLayoutProperty('trout_lakes', 'visibility', 'none');

  map.setLayoutProperty('TU_Events_Production', 'visibility', 'visible');

  map.setLayoutProperty('easement_section', 'visibility', 'none');
  map.setLayoutProperty('pal_section', 'visibility', 'none');
  map.setLayoutProperty('pal_section2', 'visibility', 'none');
  map.setLayoutProperty('trails_section', 'visibility', 'none');
  map.setLayoutProperty('trails_sections_background', 'visibility', 'none');

  map.setPaintProperty('trout_section', 'line-width', 3);
  map.setPaintProperty('trout_section', 'line-color', classThreeStreamColor);
  
  map.setPaintProperty('access_points', 'circle-radius', [
    'match',
    ['get', 'has_streetview'],
    0,
    5,
    1,
    8, 5]);
  map.setPaintProperty('access_points', 'circle-color', [
    'match',
    ['get', 'has_streetview'],
    0,
    '#4D7FF6',
    1,
    '#FFA500', '#4D7FF6']);

  if (map.getLayer('national_lands_fill')) {
    try {
      map.setLayoutProperty('national_lands_fill', 'visibility', 'none');
      map.setLayoutProperty('national_lands_outline', 'visibility', 'none');
    } catch (e) {
      console.log(e);
    }
  }

  map.setLayoutProperty('trout_regs', 'visibility', 'visible');
  map.setPaintProperty('trout_regs', 'line-opacity', 0);
  map.setPaintProperty('trout_regs', 'line-width', 0);

  map.setLayoutProperty('trout_regs_highlight', 'visibility', 'none');
  map.setLayoutProperty('trout_regs_boat', 'visibility', 'none');
  map.setLayoutProperty('trout_regs_special_species', 'visibility', 'none');

};

/**
 * Formats the map pursuant to the guide style.
 * @param {*} map Mapbox GL object.
 */
export const formatGuideStyle = (map) => {
  map.setLayoutProperty('gage_station', 'visibility', 'visible');

  map.setLayoutProperty('national_lands_fill', 'visibility', 'visible');
  map.setLayoutProperty('national_lands_outline', 'visibility', 'visible');
  map.setLayoutProperty('access_access', 'visibility', 'visible');
  map.setLayoutProperty('access_troutpark', 'visibility', 'visible');
  map.setLayoutProperty('access_boat', 'visibility', 'visible');
  map.setLayoutProperty('access_dam', 'visibility', 'visible');
  map.setLayoutProperty('access_camp', 'visibility', 'visible');
  map.setLayoutProperty('access_trail', 'visibility', 'visible');
  map.setLayoutProperty('TU_Events_Production', 'visibility', 'visible');
  map.setLayoutProperty('access_habitat', 'visibility', 'visible');
  map.setLayoutProperty('access_stock', 'visibility', 'visible');
  map.setLayoutProperty('trout_lakes', 'visibility', 'visible');

  map.setLayerZoomRange('national_lands_fill',7.5,18)
  map.setLayerZoomRange('national_lands_outline',8.0,18)
  map.setLayerZoomRange('trout_section',5,18)
  map.setLayerZoomRange('pal_section',6,18)
  map.setLayerZoomRange('pal_section2',6,18)
  map.setLayerZoomRange('easement_section',6,18)
  map.setLayerZoomRange('trails_section',9,18)
  map.setLayerZoomRange('trails_sections_background',9,18)
  
  map.setPaintProperty('pal_section', 'line-color', '#979797');

  map.setPaintProperty('access_points', 'circle-radius', [
    'match',
    ['get', 'has_streetview'],
    0,
    5,
    1,
    8, 5]);

  const bridgeColors = [
    'match',
    ['get', 'access_type'],
    1,
    '#4D7FF6',
    2,
    '#13F57C',
    3,
    '#D522E2',
    4,
    '#D522E2', '#D522E2']

  map.setPaintProperty('access_points', 'circle-color', [
    'match',
    ['get', 'has_streetview'],
    0,
    bridgeColors,
    1,
    '#FFA500', bridgeColors]);

  map.setPaintProperty('national_lands_fill', 'fill-color', [
    'match',
    ['get', 'pal_type'],
    0, 
    '#3dcf42',
    1, 
    '#3dcf42',
    15,
    '#e0c91a',
    16,
    '#3dcf42',
    17,
    '#65ba67',
    18,
    '#911cb8',
    19,
    '#549a55',
    20,
    '#69b7e8',
    30, 
    '#3dcf42',
    31, 
    '#3dcf42',
    '#549a55',
  ]);
  map.setPaintProperty('national_lands_fill', 'fill-opacity', [
    'match',
    ['get', 'pal_type'],
    0, 0.25,
    1, 0.4,
    15, 0.4,
    16, 0.4,
    17, 0.3,
    18, 0.5,
    19, 0.3,
    20, 0.4,
    30, 0.3,
    31, 0.2,
    0.3,
  ]);
 
  map.setPaintProperty('national_lands_outline', 'line-color', [
    'match',
    ['get', 'pal_type'],
    0, 
    '#232323',
    1, 
    '#232323',
    15,
    '#ffffff',
    16,
    '#232323',
    17,
    '#232323',
    18,
    '#232323',
    19,
    '#232323',
    20,
    '#232323',
    30,
    '#232323',
    31,
    '#232323',
    '#232323',
  ]);
  map.setPaintProperty('national_lands_outline', 'line-width', [
    'match',
    ['get', 'pal_type'],
    0, 0.04,
    1, 0.2,
    15, 1,
    16, 0.01,
    17, 0.01,
    18, 1,
    19, 0.01,
    20, 1,
    0.2,
  ]);
  // map.setPaintProperty('national_lands_outline', 'line-width', [
  //   'match',
  //   ['get', 'pal_type'],
  //   0, 0.04,
  //   1, 0.2,
  //   15, 1,
  //   16, 0.01,
  //   17, 0.01,
  //   18, 2,
  //   19, 0.01,
  //   20, 0.2,
  //   30, 0.2,
  //   31, 0.2,
  //   3,
  // ]);


  map.setPaintProperty('trout_section', 'line-color', [
    'match',
    ['get', 'StreamClass'],
    0,
    classGoldStreamColor,
    1,
    classOneStreamColor,
    2,
    classTwoStreamColor,
    3,
    classThreeStreamColor,
    '#ccc',
  ]);

  map.setPaintProperty('trout_section', 'line-width', [
    'match',
    ['get', 'StreamClass'],
    0,
    5,
    1,
    4,
    2,
    3,
    3,
    2, 2]);

    map.setPaintProperty('trout_section', 'line-opacity', [
      'interpolate',
      // Set the exponential rate of change to 0.5
      ['exponential', 0.5],
      ['zoom'],
      6,
      [
          'match',
          ['get', 'StreamClass'],
          0,
          1.0,
          1,
          1.0,
          2,
          0.0,
          3,
          0.0,
          0.0,
        ],
      7,
      1.0
      ]);
    
      map.setPaintProperty('pal_section', 'line-opacity', [
        'interpolate',
        // Set the exponential rate of change to 0.5
        ['exponential', 0.5],
        ['zoom'],
        8,
        [
            'match',
            ['get', 'StreamClass'],
            0,
            1.0,
            1,
            1.0,
            2,
            0.0,
            3,
            0.0,
            0.0,
          ],
        9,
        1.0
        ]);
      
  map.setLayoutProperty('pal_section', 'visibility', 'visible');
  
  map.setLayoutProperty('trails_section', 'visibility', 'visible');
  map.setLayoutProperty('trails_sections_background', 'visibility', 'visible');

  map.setLayoutProperty('pal_section2', 'visibility', 'none');
  map.setLayoutProperty('easement_section', 'visibility', 'none');

  if (map.getLayer('national_lands_fill')) {
    try {
      map.setPaintProperty('national_lands_fill', 'fill-opacity', [
        'interpolate',
        ['linear', 0.5],
        ['zoom'],
        8,
        0.1,
        10,
        0.3,
        15,
        0.4]);
    } catch (e) {
      console.log(e);
    }
  }
  map.setLayoutProperty('trout_regs', 'visibility', 'visible');
  map.setPaintProperty('trout_regs', 'line-opacity', 0);
  map.setPaintProperty('trout_regs', 'line-width', 0);
  map.setLayoutProperty('trout_regs_highlight', 'visibility', 'none');
  map.setLayoutProperty('trout_regs_boat', 'visibility', 'none');
  map.setLayoutProperty('trout_regs_special_species', 'visibility', 'none');
};

// /**
//  * Formats the map pursuant to the access style.
//  * @param {*} map Mapbox GL object.
//  */
//  export const formatSupportedStates = (map) => {
//   var filter = ['in', 'gid'];

//   for(var i = 0; i < supportedStates.length; i += 1) {
//       filter.push(supportedStates[i]);
//   }

//   // var states_filter = ['==', ['number', ['get', 'gid']], concat(supportedStates)];

//   map.setFilter('supported_states', ['none', filter]);

//  };

/**
 * Formats the map pursuant to the access style.
 * @param {*} map Mapbox GL object.
 */
export const formatAccessStyle = (map) => {
  if (map.getLayer('national_lands_fill')) {
    try {
      map.setLayoutProperty('national_lands_fill', 'visibility', 'visible');
      map.setLayoutProperty('national_lands_outline', 'visibility', 'visible');
    } catch (e) {
      console.log(e);
    }
  }

  map.setLayerZoomRange('national_lands_fill',7.5,18)
  map.setLayerZoomRange('national_lands_outline',8.0,18)
  map.setLayerZoomRange('trout_section',5,18)
  map.setLayerZoomRange('pal_section',6,18)
  map.setLayerZoomRange('pal_section2',6,18)
  map.setLayerZoomRange('easement_section',6,18)
  map.setLayerZoomRange('trails_section',9,18)
  map.setLayerZoomRange('trails_sections_background',9,18)

  map.setPaintProperty('access_points', 'circle-radius', [
    'match',
    ['get', 'has_streetview'],
    0,
    5,
    1,
    8, 5]);

  const bridgeColors = [
    'match',
    ['get', 'access_type'],
    1,
    '#4D7FF6',
    2,
    '#13F57C',
    3,
    '#D522E2',
    4,
    '#D522E2', '#D522E2']

  map.setPaintProperty('access_points', 'circle-color', [
    'match',
    ['get', 'has_streetview'],
    0,
    bridgeColors,
    1,
    '#FFA500', bridgeColors]);
  map.setLayoutProperty('gage_station', 'visibility', 'visible');

  map.setLayoutProperty('national_lands_fill', 'visibility', 'visible');
  map.setLayoutProperty('national_lands_outline', 'visibility', 'visible');
  map.setLayoutProperty('access_access', 'visibility', 'visible');
  map.setLayoutProperty('access_troutpark', 'visibility', 'visible');
  map.setLayoutProperty('access_boat', 'visibility', 'visible');
  map.setLayoutProperty('access_dam', 'visibility', 'visible');
  map.setLayoutProperty('access_camp', 'visibility', 'visible');
  map.setLayoutProperty('access_trail', 'visibility', 'visible');
  map.setLayoutProperty('TU_Events_Production', 'visibility', 'visible');
  map.setLayoutProperty('access_habitat', 'visibility', 'visible');
  map.setLayoutProperty('access_stock', 'visibility', 'visible');
  map.setLayoutProperty('access_poi', 'visibility', 'visible');
  map.setLayoutProperty('trout_lakes', 'visibility', 'visible');

  map.setPaintProperty('national_lands_fill', 'fill-color', [
    'match',
    ['get', 'pal_type'],
    0, 
    '#3dcf42',
    1,
    '#bc07f2',
    15,
    '#e0c91a',
    16,
    '#1cca36',
    17,
    '#1cca36',
    18,
    '#4126ee',
    19,
    '#1cca36',
    20,
    '#69b7e8',
    '#549a55',
  ]);
  map.setPaintProperty('national_lands_fill', 'fill-opacity', [
    'match',
    ['get', 'pal_type'],
    0, 0.3,
    1, 0.4,
    15, 0.4,
    16, 0.4,
    17, 0.3,
    18, 0.5,
    19, 0.3,
    20, 0.4,
    30, 0.4,
    1.0,
  ]);
 
  map.setPaintProperty('national_lands_outline', 'line-color', [
    'match',
    ['get', 'pal_type'],
    0, '#232323',
    1, '#232323',
    15,
    '#ffffff',
    16,
    '#232323',
    17,
    '#232323',
    18,
    '#232323',
    19,
    '#232323',
    '#232323',
  ]);
  map.setPaintProperty('national_lands_outline', 'line-width', [
    'match',
    ['get', 'pal_type'],
    0, 0.04,
    1, 0.2,
    15, 0.2,
    16, 0.01,
    17, 0.01,
    18, 1,
    19, 0.01,
    20, 0.2,
    0.1,
  ]);

  map.setLayoutProperty('easement_section', 'visibility', 'visible');
  map.setLayoutProperty('pal_section', 'visibility', 'visible');
  map.setLayoutProperty('pal_section2', 'visibility', 'none');
  
  map.setLayoutProperty('trails_section', 'visibility', 'visible');
  map.setLayoutProperty('trails_sections_background', 'visibility', 'visible');
  map.setPaintProperty('trout_section', 'line-color', classThreeStreamColor);
  map.setPaintProperty('trout_section', 'line-width', 3);
  map.setLayoutProperty('easement_section', 'visibility', 'visible');
  
  const easement_filter = ['==', ['number', ['get', 'pal_type']], 1];
  map.setFilter('easement_section', ['all', easement_filter]);

    map.setLayoutProperty('trout_regs', 'visibility', 'visible');
    map.setPaintProperty('trout_regs', 'line-opacity', 0);
    map.setPaintProperty('trout_regs', 'line-width', 0);
    map.setLayoutProperty('trout_regs_highlight', 'visibility', 'none');
    map.setLayoutProperty('trout_regs_boat', 'visibility', 'none');
    map.setLayoutProperty('trout_regs_special_species', 'visibility', 'none');
    // formatSupportedStates();
};

export const formatRegsStyle = (map) => {
  map.setLayoutProperty('easement_section', 'visibility', 'none');

  map.setLayerZoomRange('national_lands_fill',7.5,18)
  map.setLayerZoomRange('national_lands_outline',8.0,18)
  map.setLayerZoomRange('trout_section',5,18)
  map.setLayerZoomRange('pal_section',6,18)
  map.setLayerZoomRange('pal_section2',6,18)
  map.setLayerZoomRange('easement_section',6,18)
  map.setLayerZoomRange('trails_section',9,18)
  map.setLayerZoomRange('trails_sections_background',9,18)

  if (map.getLayer('national_lands_fill')) {
    try {
      map.setLayoutProperty('national_lands_fill', 'visibility', 'visible');
      map.setLayoutProperty('national_lands_outline', 'visibility', 'visible');
    } catch (e) {
      console.log(e);
    }
  }
  map.setPaintProperty('access_points', 'circle-radius', [
    'match',
    ['get', 'has_streetview'],
    0,
    5,
    1,
    8, 5]);

  const bridgeColors = [
    'match',
    ['get', 'access_type'],
    1,
    '#4D7FF6',
    2,
    '#13F57C',
    3,
    '#D522E2',
    4,
    '#D522E2', '#D522E2']

  map.setPaintProperty('access_points', 'circle-color', [
    'match',
    ['get', 'has_streetview'],
    0,
    bridgeColors,
    1,
    '#FFA500', bridgeColors]);
  map.setLayoutProperty('gage_station', 'visibility', 'visible');

  map.setLayoutProperty('national_lands_fill', 'visibility', 'visible');
  map.setLayoutProperty('national_lands_outline', 'visibility', 'visible');
  map.setLayoutProperty('access_access', 'visibility', 'visible');
  map.setLayoutProperty('access_troutpark', 'visibility', 'visible');
  map.setLayoutProperty('access_boat', 'visibility', 'visible');
  map.setLayoutProperty('access_dam', 'visibility', 'visible');
  map.setLayoutProperty('access_camp', 'visibility', 'visible');
  map.setLayoutProperty('access_trail', 'visibility', 'visible');
  map.setLayoutProperty('TU_Events_Production', 'visibility', 'visible');
  map.setLayoutProperty('access_poi', 'visibility', 'visible');
  map.setLayoutProperty('trout_lakes', 'visibility', 'visible');

  map.setLayoutProperty('pal_section', 'visibility', 'none');
  map.setLayoutProperty('pal_section2', 'visibility', 'none');

  map.setPaintProperty('national_lands_fill', 'fill-color', [
    'match',
    ['get', 'pal_type'],
    0, 
    '#3dcf42',
    1,
    '#bc07f2',
    15,
    '#e0c91a',
    16,
    '#1cca36',
    17,
    '#1cca36',
    18,
    '#4126ee',
    19,
    '#1cca36',
    20,
    '#69b7e8',
    '#549a55',
  ]);
  map.setPaintProperty('national_lands_fill', 'fill-opacity', [
    'match',
    ['get', 'pal_type'],
    0, 0.3,
    1, 0.4,
    15, 0.4,
    16, 0.4,
    17, 0.3,
    18, 0.5,
    19, 0.3,
    20, 0.4,
    1.0,
  ]);
 
  map.setPaintProperty('national_lands_outline', 'line-color', [
    'match',
    ['get', 'pal_type'],
    0, '#232323',
    1, '#232323',
    15,
    '#ffffff',
    16,
    '#232323',
    17,
    '#232323',
    18,
    '#232323',
    19,
    '#232323',
    '#232323',
  ]);
  map.setPaintProperty('national_lands_outline', 'line-width', [
    'match',
    ['get', 'pal_type'],
    0, 0.04,
    1, 0.2,
    15, 0.2,
    16, 0.01,
    17, 0.01,
    18, 1,
    19, 0.01,
    20, 0.2,
    0.1,
  ]);

  map.setLayoutProperty('trails_section', 'visibility', 'visible');
  map.setLayoutProperty('trails_sections_background', 'visibility', 'visible');
  map.setPaintProperty('trout_section', 'line-color', classThreeStreamColor);
  map.setPaintProperty('trout_section', 'line-width', 3);

  map.setLayoutProperty('trout_regs', 'visibility', 'visible');
  map.setLayoutProperty('trout_regs_highlight', 'visibility', 'visible');
  map.setLayoutProperty('trout_regs_boat', 'visibility', 'visible');
  map.setLayoutProperty('trout_regs_special_species', 'visibility', 'visible');
  map.setPaintProperty('trout_regs', 'line-opacity', 1);
  map.setPaintProperty('trout_regs_highlight', 'line-opacity', 1);
  map.setPaintProperty('trout_regs_boat', 'line-opacity', 1);
  map.setPaintProperty('trout_regs_special_species', 'line-opacity', 1);
  map.setPaintProperty('trout_regs', 'line-width', 4);
}

/**
 * Function that updates the filters and streams on the map.
 * @param {*} map Mapbox GL object.
 * @param {object} filters Object delineating special filters.
 * @param {object} classes Object delineating which classes to show/hide.
 */
export const filterStreams = (map, { showEasements, showTailwaters, showPopular }, {
  showClass0, showClass1, showClass2, showClass3,
}) => {
  const easementsFilter = showEasements ? ['==', ['number', ['get', 'has_easement']], 1] : ['!=', ['number', ['get', 'has_easement']], 2];
  const tailwatersFilter = showTailwaters ? ['==', ['number', ['get', 'is_tailwater']], 1] : ['!=', ['number', ['get', 'is_tailwater']], 2];
  const popularFilter = showPopular ? ['==', ['number', ['get', 'is_popular']], 1] : ['!=', ['number', ['get', 'is_popular']], 2];

  let class0_int = -1;
  let class1_int = -1;
  let class2_int = -1;
  let class3_int = -1;

  let class0 = [];
  let class1 = [];
  let class2 = [];
  let class3 = [];
  const classFilters = [];

  if (showClass0) {
    class0_int = 0;
    class0 = ['==', ['number', ['get', 'StreamClass']], 0];
    classFilters.push(class0);
  } else {
    class0_int = -1;
  }
  if (showClass1) {
    class1_int = 1;
    class1 = ['==', ['number', ['get', 'StreamClass']], 1];
    classFilters.push(class1);
  } else {
    class1_int = -2;
  }
  if (showClass2) {
    class2_int = 2;
    class2 = ['==', ['number', ['get', 'StreamClass']], 2];
    classFilters.push(class2);
  } else {
    class2_int = -3;
  }
  if (showClass3) {
    class3_int = 3;
    class3 = ['==', ['number', ['get', 'StreamClass']], 3];
    classFilters.push(class3);
  } else {
    class3_int = -4;
  }

  const specialFilter = ['all', easementsFilter, popularFilter, tailwatersFilter];
  const classfilter = ['match', ['get', 'StreamClass'], [class0_int, class1_int, class2_int, class3_int], true, false];
  const filterCamp = ['==', ['number', ['get', 'access_type']], 7];
  const filterBoat = ['==', ['number', ['get', 'access_type']], 6];
  const filterPark = ['==', ['number', ['get', 'access_type']], 5];
  const filterAccess = ['==', ['number', ['get', 'access_type']], 8];
  const filterTroutPark = ['==', ['number', ['get', 'access_type']], 9];
  const filterDam = ['==', ['number', ['get', 'access_type']], 10];
  const filterTrailhead = ['==', ['number', ['get', 'access_type']], 11];
  const filterTroutLakes = ['==', ['number', ['get', 'access_type']], 201];
  const filterSParks = ['==', ['number', ['get', 'access_type']], 106];


  map.setFilter('trout_section', ['all', specialFilter, classfilter]); 
  map.setFilter('pal_section', ['all', specialFilter, classfilter]); 
  map.setFilter('pal_section2', ['all', specialFilter, classfilter]); 
  map.setFilter('easement_section', ['all', specialFilter, classfilter]); 



  //Points?
  map.setFilter('access_parking', ['all', specialFilter, filterPark, classfilter]);
  map.setFilter('access_camp', ['all', specialFilter, filterCamp, classfilter]);
  map.setFilter('access_boat', ['all', specialFilter, filterBoat, classfilter]);
  map.setFilter('access_access', ['all', specialFilter, filterAccess, classfilter]);
  map.setFilter('access_troutpark', ['all', specialFilter, filterTroutPark, classfilter]);
  map.setFilter('access_trail', ['all', specialFilter, filterTrailhead, classfilter]);
  map.setFilter('access_dam', ['all', specialFilter, filterDam, classfilter]);
  map.setFilter('trout_lakes', ['all', specialFilter, filterTroutLakes, classfilter]);
  map.setFilter('access_parks', ['all', specialFilter, filterSParks, classfilter]);

};

/**
 * Jumps to a particular stream on the map.
 * @param {object} stream Geocoded stream object (with GeoBoundMax2, GeoBoundMin2, etc.)
 * @param {*} map MapGL object.
 */
 export const jumpToCustomRegion = (region, map) => {
  const {
    GeoBoundMaxX2, GeoBoundMaxY2, GeoBoundMinX2, GeoBoundMinY2,
  } = region;
  
  const nw = [GeoBoundMaxX2, GeoBoundMaxY2];
  const se = [GeoBoundMinX2, GeoBoundMinY2];

  
  map.fitBounds([nw, se], {
    padding: {
      top: 5, bottom: 5, left: 5, right: 5,
    },
  }, { programmatic: true });
};

/**
 * Jumps to a particular stream on the map.
 * @param {object} stream Geocoded stream object (with GeoBoundMax2, GeoBoundMin2, etc.)
 * @param {*} map MapGL object.
 */
export const jumpToStream = (stream, map) => {
  if (!stream?.stream_gid2) {
    setFilters(map);
    return;
  }
  // Custom Amplitude config
  const config = {
    stream_gid: stream?.stream_gid2,
  };
  sendAmplitudeData('web_clicked_stream', config);

  const {
    stream_gid2, GeoBoundMaxX2, GeoBoundMaxY2, GeoBoundMinX2, GeoBoundMinY2,
  } = stream;
  const currentStreamGID = stream_gid2;

  setFilters(map, currentStreamGID);
  if(stream_gid2 == currentStreamGID2) {
    return;
  }

  currentStreamGID2 = stream_gid2;
  const nw = [GeoBoundMaxX2, GeoBoundMaxY2];
  const se = [GeoBoundMinX2, GeoBoundMinY2];

  try {
    map.fitBounds([nw, se], {
      padding: {
        top: 110, bottom: 5, left: 5, right: 260,
      },
    }, { programmatic: true });
  } catch (e) {
    console.log(e);
  }
};

export const jumptToStreamFromGID = async (streamGID, dispatch) => {
  const snapshot = await firebaseService.getGeoFeaturesByStreamGID(streamGID);
  const stream = {}
  snapshot.forEach((data) => {
    const type = data.child('properties').child('TypeCode').val();
    if (type == 1 && Object.keys(stream).length === 0) {
      stream.PrimaryLabel = data.child('properties').child('PrimaryLabel').val();
      stream.SecondaryLabel = data.child('properties').child('SecondaryLabel').val();
      stream.part_count = data.child('properties').child('part_count').val();
      stream.SectionName = data.child('properties').child('SectionName').val();
      stream.StreamClass = data.child('properties').child('StreamClass').val();
      stream.stream_gid2 = data.child('properties').child('stream_gid2').val();
      stream.GeoBoundMaxX = data.child('properties').child('GeoBoundMaxX').val();
      stream.GeoBoundMaxY = data.child('properties').child('GeoBoundMaxY').val();
      stream.GeoBoundMinX = data.child('properties').child('GeoBoundMinX').val();
      stream.GeoBoundMinY = data.child('properties').child('GeoBoundMinY').val();
      stream.GeoBoundMaxX2 = data.child('properties').child('GeoBoundMaxX').val();
      stream.GeoBoundMinX2 = data.child('properties').child('GeoBoundMinX').val();
      stream.GeoBoundMaxY2 = data.child('properties').child('GeoBoundMaxY').val();
      stream.GeoBoundMinY2 = data.child('properties').child('GeoBoundMinY').val();
      stream.is_popular = data.child('properties').child('is_popular')?.val() || data.child('properties').child('popular')?.val();
      stream.is_tailwater = data.child('properties').child('is_tailwater')?.val();
      stream.has_easement = data.child('properties').child('has_easement')?.val() || data.child('properties').child('HasEasement')?.val();
      stream.len = data.child('properties').child('len')?.val();
    }
    
  });
  if (Object.keys(stream).length != 0) {
    dispatch(updateStreamWithGeocoding(stream));
  }
};

const toProperCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};

const checkOtherFeaturesOnClick = (e, map) => {
  const bbox = [
    [e.point.x - 5, e.point.y - 5],
    [e.point.x + 5, e.point.y + 5],
  ];
  const flyShops = map.queryRenderedFeatures(bbox, { layers: ['flyshops'] });
  const gages = map.queryRenderedFeatures(bbox, { layers: ['gage_station'] });

  const features = map.queryRenderedFeatures(bbox, {
    layers: ['trout_section'],
  });
  const other = map.queryRenderedFeatures(bbox, { layers: layerHandlers.filter((el) => !['access_points'].includes(el)) });

  return flyShops.length > 0 || gages.length > 0 || (features.length > 0 && other.length > 0);
};

const createAndDisplayMarker = (lngLat, type, map, dispatch) => {
  let marker;

  if (type !== null) {
    marker = createMarkerWithIcon(lngLat, map, mapMarkerTypes[type].img);
  } else {
    marker = createMarkerWithoutIcon({ lngLat }, map);
  }
  dispatch(setTempMarker(marker));
  if (type !== null) dispatch(setMarkerType(type));
};

const createMapTapMarker = (lngLat, name, type, map, dispatch) => {
  
  dispatch(createMarker({
    lngLat: {lat: lngLat[1], lng: lngLat[0]}, name: name, markerType: mapMarkerTypes[type], notes: "", pointColorIndex: 0,
  }, null, (id) => {
    map.fire('closeAllPopups');
    dispatch(resetCurrentMarker());
  }));
};

/**
 * Processes a click on a Mapbox geocoded entry.
 * @param {*} map MapboxGL map object.
 * @param {*} dispatch Dispatch object.
 * @param {LngLat} coordinate Coordinates of the object. Should be an array with the longitude first.
 * @param {string} name Name of the element.
 * @param {array} bbox Bounding box to navigate to.
 */
export const processGeocodeClick = async (map, dispatch, property, coordinate, name, bbox) => {
  map.fire('closeAllPopups');
  
  var subtitle = "";
  var elevation = 0;
  var away = distanceFormat(coordinate, false);

  if (property.SecondaryLabel) {
    subtitle = property.SecondaryLabel;
  }

  if(property.elevation_ft) {
    elevation = property.elevation_ft;
  } else {
    elevation = await getElevationPoint({lat: coordinate[1], lng: coordinate[0]});
  }
  
  const placeholder = document.createElement('div');
  ReactDOM.render((<Popup3
    title={name}
    subtitle={subtitle}
    elevation={elevation}
    distance={away}
    leftButton={{ label: 'Directions', icon: directionsIcon2, onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinate[1]}, ${coordinate[0]}`)}`); } }}
    middleButton={{ label: 'Share', icon: shareIcon2, onClick: () => { onShare(coordinate); } }}
    rightButton={{ label: 'Create', icon: createIcon, onClick: () => { createAndDisplayMarker(coordinate, null, map, dispatch); } }}
  />), placeholder);

  if (coordinate?.length === 2) {
    globalPopup.setLngLat(coordinate)
      .setDOMContent(placeholder)
      .addTo(map);
    map.on('closeAllPopups', () => {
      globalPopup.remove();
    });
      map.flyTo({
        center: coordinate,
        zoom: 14,
      }, { programmatic: true });
  }
};

export const toggleAccessPoints = (toggle, map) => {
  if(toggle) {
    map.off('click', 'access_points', accessHandler);
    map.off('click', 'access_boundary', clickTRPOIHandler);
    map.off('click', 'access_parking', clickTRPOIHandler);
    map.off('click', 'access_stock', clickTRPOIHandler);
    map.off('click', 'access_access', clickTRPOIHandler);
    map.off('click', 'access_parks', clickTRPOIHandler);
    map.off('click', 'access_habitat', clickTRPOIHandler);
    map.off('click', 'access_poi', clickTRPOIHandler);
    map.off('click', 'access_troutpark', troutHandler);
    map.off('click', 'access_boat', clickTRPOIHandler);
    map.off('click', 'access_trail', clickTRPOIHandler);
    map.off('click', 'access_dam', clickTRPOIHandler);
    map.off('click', 'access_camp', clickTRPOIHandler);
    map.off('click', 'TU_Events_Production', clickTRPOIHandler);
    map.off('click', 'trout_lakes', clickTRPOIHandler);
    map.off('click', 'rare_waters', clickTRPOIHandler);
  } else {
    map.on('click', 'access_points', accessHandler);
    map.on('click', 'access_boundary', clickTRPOIHandler);
    map.on('click', 'access_parking', clickTRPOIHandler);
    map.on('click', 'access_stock', clickTRPOIHandler);
    map.on('click', 'access_access', clickTRPOIHandler);
    map.on('click', 'access_parks', clickTRPOIHandler);
    map.on('click', 'access_habitat', clickTRPOIHandler);
    map.on('click', 'access_poi', clickTRPOIHandler);
    map.on('click', 'access_troutpark', troutHandler);
    map.on('click', 'access_boat', clickTRPOIHandler);
    map.on('click', 'access_trail', clickTRPOIHandler);
    map.on('click', 'access_dam', clickTRPOIHandler);
    map.on('click', 'access_camp', clickTRPOIHandler);
    map.on('click', 'TU_Events_Production', clickTRPOIHandler);
    map.on('click', 'trout_lakes', clickTRPOIHandler);
    map.on('click', 'rare_waters', clickTRPOIHandler);
  }
}

export const processRiverMilesClick = async (e, bbox, map, dispatch, draw, riverMilesStep) => {
  try {
    dispatch(clearMap(draw));
    if(riverMilesStep === 0) {
      const nearestGID = await getNearestLinetoPoint(e, map, bbox, dispatch);
      if(nearestGID === '') {
        toast('No stream found! Tap a point along a stream.');
      } else {
        map.fire('closeAllPopups');
        setFilters(map, nearestGID);
        dispatch(setRiverMilesStep(1));
      }
      
    } else if(riverMilesStep === 1 || riverMilesStep === 2) {
      const { riverMilesParentLine } = store.getState().map;
      getNearestPointfromTapToProcessLine(e, map, riverMilesParentLine, dispatch);
      dispatch(setRiverMilesStep(2));
    }
  } catch (error) {
    toast('Reset and try again.');
    const {riverMilesMarkers} = store.getState().map;
    if(riverMilesMarkers.startPoint) {
      riverMilesMarkers.startPoint.remove();
    }
    if(riverMilesMarkers.endPoint) {
      riverMilesMarkers.endPoint.remove();
    }
    dispatch(setRiverMilesStep(0));
  }
}

// Access points hover handler.
const accessHandler = async (e) => {
  const { map } = store.getState().map;
  const { dispatch } = store;
  const { accountType } = store.getState().auth.user;
  const isPro = ( accountType === accountTypesEnum.trial || accountType === accountTypesEnum.pro);

  // Change the cursor style as a UI indicator.
  map.getCanvas().style.cursor = 'pointer';

  // Populate the popup and set its coordinates based on the feature.
  const feature = e.features[0];

  const coordinate = feature.geometry.coordinates;
  const type = feature.properties.access_type;
  const has_streetview = feature.properties.has_streetview;
  const streetViewLink = feature.properties.ext_link;
  let streetViewButtonOnClick = null;

  const color = feature.layer.paint['circle-color'];
  const colorString = `rgb(${color.r*255},${color.g*255},${color.b*255})`;
  let typeString = '';
  if (type == 1) {
    typeString = 'Private Road';
  } else if (type == 2) {
    typeString = 'Public Road';
  } else if (type == 3) {
    typeString = 'Trail Bridge';
  } else if (type == 4) {
    typeString = 'Trail Bridge';
  }

  if (isPro && has_streetview == 1) {
    streetViewButtonOnClick = () => { window.open(`${streetViewLink}`);};
  } else if (has_streetview == 1){
    streetViewButtonOnClick = () => { dispatch(setIsUpgradeModalShown(true));};
  }

  var subtitle = "";
  var elevation = 0;
  var away = distanceFormat(coordinate, false);

  if (feature.properties.SecondaryLabel) {
    subtitle = feature.properties.SecondaryLabel;
  } else {
    var [county, state] = getCurrentCountyAndState(map, e.features);
    subtitle = county + ", " + state;
  }

  if(feature.properties.elevation_ft) {
    elevation = feature.properties.elevation_ft;
  } else {
    elevation = await getElevationPoint({lat: coordinate[1], lng: coordinate[0]});
  }

  const { stream } = store.getState().map;
  stream.isDetail = true;
  dispatch(updateStreamGeography({ isDetail: true }));
  const placeholder = document.createElement('div');
  if (has_streetview) {
    ReactDOM.render((<Popup3
      title={feature.properties.GeoName}
      subtitle={subtitle}
      type={typeString}
      elevation={elevation}
      distance={away}
      leftButton={{ label: 'Create', icon: createIcon2, onClick: () => { createAndDisplayMarker(e.lngLat, null, map, dispatch); } }}
      middleButton={{ label: 'Street View', icon: streetviewIcon2, onClick: () =>  streetViewButtonOnClick() }}
      rightButton={{ label: 'Directions', icon: directionsIcon2, onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinate[1]}, ${coordinate[0]}`)}`); } }}
      fourthButton={{ label: 'Share', icon: shareIcon2, onClick: () => { onShare(coordinate) }}}
      iconURL={carIcon}
      dispatch={dispatch}
      color={colorString}
    />), placeholder);
  } else {
    ReactDOM.render((<Popup3
      title={feature.properties.GeoName}
      subtitle={subtitle}
      type={typeString}
      elevation={elevation}
      distance={away}
      leftButton={{ label: 'Create', icon: createIcon2, onClick: () => { createAndDisplayMarker(e.lngLat, null, map, dispatch); } }}
      middleButton={{ label: 'Directions', icon: directionsIcon2, onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinate[1]}, ${coordinate[0]}`)}`); } }}
      rightButton={{ label: 'Share', icon: shareIcon2, onClick: () => { onShare(coordinate) }}}
      iconURL={carIcon}
      dispatch={dispatch}
      color={colorString}
    />), placeholder);
  }

  if (coordinate?.length === 2) {
    globalPopup.setLngLat(coordinate)
      .setDOMContent(placeholder)
      .addTo(map);
    // map.on('closeAllPopups', () => {
    //   globalPopup.remove();
    // });

    // zoomToPoint(map, coordinate)

  }
};

// TR POI click handler
const clickTRPOIHandler = async (e) => {
  const { map } = store.getState().map;
  const { dispatch } = store;
  map.getCanvas().style.cursor = 'pointer';
  map.fire('closeAllPopups');
  
  const feature = e.features[0];
  dispatch(setTRPOIFeature(feature));
  createMarkerHighlight(feature.geometry.coordinates, map);
};


  // State park click handler.
const stateparksHandler = async (e) => {
  const { map } = store.getState().map;
  const { dispatch } = store;
  map.getCanvas().style.cursor = 'pointer';
  // if (checkOtherFeaturesOnClick(e, map)) return;
  
  const feature = e.features[0];
  const coordinate = feature.geometry.coordinates;
  const name = feature.properties.GeoName;
  const type = feature.properties.access_type;
  var url = mapParking;

  createMarkerHighlight(coordinate, map);
  if(type == 106) {
    url = mapSP;
  } else {
    url = mapNP;
  }

  var subtitle = "";
  var elevation = 0;
  var away = distanceFormat(coordinate, false);

  if (feature.properties.SecondaryLabel) {
    subtitle = feature.properties.SecondaryLabel;
  } else {
    var [county, state] = getCurrentCountyAndState(map, e.features);
    subtitle = county + ", " + state;
  }

  if(feature.properties.elevation_ft) {
    elevation = feature.properties.elevation_ft;
  } else {
    elevation = await getElevationPoint({lat: coordinate[1], lng: coordinate[0]});
  }

  dispatch(setTRPOIFeature(feature));
  dispatch(setEditingMarkerId(''));
  const placeholder = document.createElement('div');
  ReactDOM.render((<Popup3
    title={`${name}`}
    subtitle={subtitle}
    type="State and National Parks"
    elevation={elevation}
    distance={away}
    leftButton={{ label: 'Create', icon: createIcon2, onClick: () => { createAndDisplayMarker(e.lngLat, 0, map, dispatch); } }}
    middleButton={{ label: 'Directions', icon: directionsIcon2, onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinate[1]}, ${coordinate[0]}`)}`); } }}
    rightButton={{ label: 'Share', icon: shareIcon2, onClick: () => { onShare(coordinate) }}}
    iconURL={url}
  />), placeholder);

  if (coordinate?.length === 2) {
    globalPopup.setLngLat(coordinate)
      .setDOMContent(placeholder)
      .addTo(map);
    map.on('closeAllPopups', () => {
      globalPopup.remove();
    });
    map.flyTo({ center: coordinate }, { programmatic: true });
    // zoomToPoint(map, coordinate)

  }
};

const troutHandler = async (e) => {
  const { map } = store.getState().map;
  const { dispatch } = store;
  map.getCanvas().style.cursor = 'pointer';
  // if (checkOtherFeaturesOnClick(e, map)) return;

  const feature = e.features[0];
  const coordinate = feature.geometry.coordinates;
  const name = feature.properties.GeoName;

  var subtitle = "";
  var elevation = 0;
  var away = distanceFormat(coordinate, false);

  if (feature.properties.SecondaryLabel) {
    subtitle = feature.properties.SecondaryLabel;
  } else {
    var [county, state] = getCurrentCountyAndState(map, e.features);
    subtitle = county + ", " + state;
  }

  if(feature.properties.elevation_ft) {
    elevation = feature.properties.elevation_ft;
  } else {
    elevation = await getElevationPoint({lat: coordinate[1], lng: coordinate[0]});
  }

  const placeholder = document.createElement('div');
  ReactDOM.render((<Popup3
    title={`${name} Lot`}
    subtitle={subtitle}
    type="Trout Park"
    elevation={elevation}
    distance={away}
    leftButton={{ label: 'Directions', icon: directionsIcon2, onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinate[1]}, ${coordinate[0]}`)}`); } }}
    middleButton={{ label: 'Share', icon: shareIcon2, onClick: () => { onShare(coordinate) }}}
    rightButton={{ label: 'Create', icon: createIcon, onClick: () => { createAndDisplayMarker(e.lngLat, 0, map, dispatch); } }}
    iconURL={mapAccess}
  />), placeholder);

  if (coordinate?.length === 2) {
    globalPopup.setLngLat(coordinate)
      .setDOMContent(placeholder)
      .addTo(map);
    map.on('closeAllPopups', () => {
      globalPopup.remove();
    });
    zoomToPoint(map, coordinate)

  }
};

const onShare = async (coordinates) => {
  const lng = coordinates[0];
  const lat = coordinates[1];
  try {
    const url = await firebaseService.generateDynamicLink(lat, lng);
    navigator.clipboard.writeText(url);
    toast.remove();
    toast('Copied link to clipboard');
  } catch (e) {
    console.log(e);
  }
};

const getCurrentCountyAndState = (map, baseItems) => {
  try {
    var county = "";
    var state = "";
    var baseItemCounty = baseItems.filter((el) => ['county', 'county_fill', 'county_label', 'county_outline'].includes(el.sourceLayer));
    if (baseItemCounty[0] && baseItemCounty[0].properties.name) {
      county = baseItemCounty[0].properties.name;
      var state_gid = baseItemCounty[0].properties.state_gid;
      const supportedRegions = map.queryRenderedFeatures(undefined, { layers: ['supported_states'] });
      var selectedRegions = supportedRegions.filter((el) => el.properties.GID == state_gid );
      state = selectedRegions[0].properties.name;
    }
    return [county, state];
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sets the click handler for the map.
 * @param {*} map MapGL object.
 * @param {*} dispatch Dispatch function to connect to Redux.
 */
export const setClickHandler = (map, dispatch, draw) => {
  const globalHandler = async (e) => {
    
    dispatch(updateMapCoordinates({zoom: map.getZoom(), ...map.getCenter()}))

    if (e.point == null) {
      return null;
    }
    const bbox = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ];

    const { activeTools } = store.getState().map;

    const { marker } = store.getState().map;
    if (marker && marker.tapMarker) {
      marker.tapMarker.remove();
    }
    if (marker && marker.redMarker) {
      marker.redMarker.remove();
    }
    // const { tempArea } = store.getState().map.area;
    // const { tempLine } = store.getState().map.area;
    // if (tempLine) dispatch(resetCurrentLine());
    // if (tempArea) {
    //   clearAreaBorder(map, tempArea);
    //   dispatch(resetCurrentArea());
    // }
    dispatch(setEditingLineId(''));
    dispatch(setEditingAreaId(''));
    dispatch(setTRPOIFeature(null));
    dispatch(setEditingMarkerId(''));
    if (!activeTools.marker)
      dispatch(resetCurrentMarker());
    if (document.getElementsByClassName('mapboxgl-popup').length > 0)
      document.getElementsByClassName('mapboxgl-popup')[0].remove();

    const {isRiverMilesVisible, riverMilesStep} = store.getState().map;
    if(isRiverMilesVisible) {
      processRiverMilesClick(e, bbox, map, dispatch, draw, riverMilesStep);  

    } else {
      const flyShops = map.queryRenderedFeatures(bbox, { layers: ['flyshops'] });
      const gages = map.queryRenderedFeatures(bbox, { layers: ['gage_station'] });
      const hucs = map.queryRenderedFeatures(bbox, { layers: ['huc_10']});
      if(hucs.length > 0) {
        dispatch(setCurrentHUC(hucs[0]?.properties?.huc10))
      }

      const features = map.queryRenderedFeatures(bbox, {
        layers: ['trout_section'],
      });
      const other = map.queryRenderedFeatures(bbox, { layers: layerHandlers });
      const otherNonAccess = map.queryRenderedFeatures(bbox, { layers: layerHandlers.filter((el) => !['access_points'].includes(el)) });

      if (otherNonAccess.length > 0) {
        dispatch(updateStreamWithGeocoding({}));
      }

      // Handle flyshops click.
      if (flyShops.length > 0) {
        const name = flyShops[0].properties['Title']
        const address = flyShops[0].properties['Address']
        const website = flyShops[0].properties['Website']
        const gid = flyShops[0].properties['GID']
        const googleMapsUrl = flyShops[0].properties['Google Maps URL']
        const { coordinates } = flyShops[0].geometry;

        var subtitle = "";
        var type = 0;
        var elevation = 0;
        var away = distanceFormat(coordinates, false);
        
        if (flyShops[0].properties.SecondaryLabel) {
          subtitle = flyShops[0].properties.SecondaryLabel;
        }

        if(flyShops[0].properties.elevation_ft) {
          elevation = flyShops[0].properties.elevation_ft;
        } else {
          elevation = await getElevationPoint({lat: coordinates[1], lng: coordinates[0]});
        }

        createMarkerHighlight(coordinates, map);
        const placeholder = document.createElement('div');
        try {
          const iconURL = await firebaseService.getImageDownloadURL(`FlyShops/icon_${gid}_cropped.png`);
          ReactDOM.render((<Popup3
            title={name}
            subtitle={address}
            type={flyShops[0].properties['Number']}
            elevation={elevation}
            distance={away}
            leftButton={{ label: 'Directions', icon: directionsIcon2, onClick: () => { window.open(googleMapsUrl); } }}
            middleButton={{ label: 'Website', icon: websiteIcon2, onClick: () => { window.open(website, '_blank'); }}}
            rightButton={{ label: 'Call', icon: callIcon2, onClick: () => { window.open('tel:' + flyShops[0].properties['Number']); } }}
            iconURL={iconURL}
          />), placeholder);
        } catch (error) {
          ReactDOM.render((<Popup3
            title={name}
            subtitle={address}
            type={flyShops[0].properties.Number}
            elevation={elevation}
            distance={away}
            leftButton={{ label: 'Directions', icon: directionsIcon2, onClick: () => { window.open(googleMapsUrl); } }}
            middleButton={{ label: 'Website', icon: websiteIcon2, onClick: () => { onShare(coordinates) }}}
            rightButton={{ label: 'Call', icon: callIcon2, onClick: () => { window.open('tel:' + flyShops[0].properties['Number']); } }}
            iconURL={mapShopSolid}
          />), placeholder);
        }
        

        globalPopup.setLngLat(coordinates)
          .setDOMContent(placeholder)
          .addTo(map);
        map.on('closeAllPopups', () => {
          globalPopup.remove();
        });
        map.flyTo({ center: coordinates }, { programmatic: true });
        // zoomToPoint(map, coordinates)


      } else if (gages.length > 0) {
        const gage = gages[0];
        const { STANAME } = gage.properties;
        const { coordinates } = gage.geometry;
        const placeholder = document.createElement('div');
        
        var subtitle = "";
        var type = 0;
        var elevation = 0;
        var away = 0;
        
        if (gage.properties.SecondaryLabel) {
          subtitle = gage.properties.SecondaryLabel;
        }

        if(gage.properties.elevation_ft) {
          elevation = gage.properties.elevation_ft;
        } else {
          elevation = await getElevationPoint({lat: coordinates[1], lng: coordinates[0]});
        }

        createMarkerHighlight(coordinates, map);
        ReactDOM.render((<Popup3
          title={toProperCase(STANAME)}
          subtitle={subtitle}
          type="Gage Station"
          elevation={elevation}
          distance={away}
          leftButton={{ label: 'Directions', icon: directionsIcon2, onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinates[1]}, ${coordinates[0]}`)}`); } }}
          middleButton={{ label: 'Share', icon: shareIcon2, onClick: () => { onShare(coordinates) }}}
          rightButton={{ label: 'Open Charts', icon: chartIcon2, onClick: () => { dispatch(setIsGageChartShown(true)); } }}
          iconURL={mapGage}
        />), placeholder);

        globalPopup.setLngLat(coordinates)
          .setDOMContent(placeholder)
          .addTo(map);
        map.on('closeAllPopups', () => {
          globalPopup.remove();
        });
        map.flyTo({ center: coordinates }, { programmatic: true });
        // zoomToPoint(map, coordinates)


        dispatch(setCurrentGage(gage));
      } else if (features.length > 0) {
        const elevation = await getElevationPoint(map.getCenter())
        dispatch(setElevation(elevation))
        if (other.length > 0) return;

        dispatch(setCurrentGage({}));
        dispatch(setIsGageChartShown(false));
        map.fire('closeAllPopups');
        const accessPoints = map.queryRenderedFeatures(bbox, { layers: ['access_points'] });

        if (accessPoints.length === 0) {
          const feature = features[0];
          map.fire('closeAllPopups');
          dispatch(clearMap(draw));
          dispatch(updateStreamWithGeocoding(feature.properties));
          // regulationsService.retrieveRegsInfoForStream(map, bbox, dispatch);
        }
      } else {

        let mapboxbaseQueryLayers = [
          "parks_label",
          "small_city_label",
          "big_city_label",
          "water_label",
          "mountain_peak_label",
          "poi_label",
          "settlement_major_label",
          "settlement_minor_label",
          "waterway_label",
          "airport_label",
          "water_line_label",
          "water_point_label",
          "natural_label",
          "natural_point_label"
        ];

        var baseItems = map.queryRenderedFeatures(bbox);
        var baseItemFiltered = baseItems.filter((el) => mapboxbaseQueryLayers.includes(el.sourceLayer));

        if (baseItemFiltered.length > 0) {
          const { coordinates } = baseItemFiltered[0].geometry;
          var name = "Unknown";
          var subtitle = "";
          var type = 0;
          var category = "";
          var maki = "";
          var elevation = 0;

          if (baseItemFiltered[0].properties.name) {
            name = baseItemFiltered[0].properties.name;
          } else if (baseItemFiltered[0].properties.category_en) {
            name = baseItemFiltered[0].properties.category_en;
          }

          if (baseItemFiltered[0].properties.SecondaryLabel) {
            subtitle = baseItemFiltered[0].properties.SecondaryLabel;
          } else {
            var [county, state] = getCurrentCountyAndState(map, baseItems);
            subtitle = county + ", " + state;
          }

          if (baseItemFiltered[0].properties.category_en && baseItemFiltered[0].properties.maki) {
            category = baseItemFiltered[0].properties.category_en;
            maki = baseItemFiltered[0].properties.maki;

            if(category.includes("Toilets")) {
              type = 8;
            } else if(maki.includes("boat") || maki.includes("slipway")) {
              type = 1;
            } else if (category.includes("Restaurant") || category.includes("Bar") || maki.includes("fast-food")) {
              type = 7;
            } else if ((maki.includes("lodging")) || (maki.includes("campsite")) || (category.includes("Campground")) || (category.includes("Camp")) || (name.includes("Campground"))) {
              type = 2;
            } else if (category.includes("Gas Station")) {
              type = 10;
            } else if (maki.includes("parking")) {
              type = 3;
            }
          } else if (baseItemFiltered[0].properties.maki) {
              maki = baseItemFiltered[0].properties.maki;
              category = maki
              if(maki.includes("mountain")) {
                category = "Mountain / Peak";
              } else if (maki.includes("Toilet") || maki.includes("Bathroom") || maki.includes("Restroom")) {
                type = 8;
              }
          }
          
          if (baseItemFiltered[0].properties.class) {
            var txtClass = baseItemFiltered[0].properties.class;
              if(txtClass.includes("water")) {
                category = "Lake / Reservoir"
                type = 11;
              }
              if(category == "") {
                  if(txtClass == "store_like"){
                    category = "Store"
                  } else {
                    category = txtClass
                    category = category.replaceAll("_", " ");
                  }
                  
                  if(category == "Settlement") {
                    category = "Town/City"
                  }
              }
          }
          
          if(name.includes("Airport")) {
            category = "Airport"
          }

          if(baseItemFiltered[0].properties.elevation_ft) {
            elevation = baseItemFiltered[0].properties.elevation_ft;
          } else {
            elevation = await getElevationPoint({lat: coordinates[1], lng: coordinates[0]});
          }
          var away = distanceFormat(coordinates, false);

          createMarkerHighlight(coordinates, map);

          const placeholder = document.createElement('div');
          ReactDOM.render((<PopupMapTap
            title={name}
            subtitle={subtitle}
            type={category}
            elevation={elevation}
            distance={away}
            leftButton={{ label: 'Directions', icon: directionsIcon2, onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinates[1]}, ${coordinates[0]}`)}`); } }}
            middleButton={{ label: 'Share', icon: shareIcon2, onClick: () => { onShare(coordinates) }}}
            rightButton={{ label: 'Create', icon: createIcon2, onClick: () => { createMapTapMarker(coordinates, name, type, map, dispatch); } }}            
          />), placeholder);

          globalPopup.setLngLat(coordinates)
            .setDOMContent(placeholder)
            .addTo(map);
          map.on('closeAllPopups', () => {
            globalPopup.remove();
          });
          map.flyTo({ center: coordinates }, { programmatic: true });
          zoomToPoint(map, coordinates)

        } else {
          dispatch(setCurrentGage({}));

          if (otherNonAccess.length === 0) {
            map.fire('closeAllPopups');
            dispatch(clearMap(draw));
          }
        }
        
      }

      dispatch(setActiveSidebar(''));
    }
  };

  map.on('click', globalHandler);
  map.on('touchend', globalHandler);
  map.on('click', 'access_points', accessHandler);
  map.on('touchend', 'access_points', accessHandler);

  const regsHandler = (e) => {
    const feature = e.features[0];

    const coordinate = e.lngLat;

    const title = feature.properties.stream_name || feature.properties.water_name || '';
    let description = '';
    if (feature.properties.reg_descr) {
      description += `Regulations: ${feature.properties.reg_descr}\n`;
    }
    if (feature.properties.loc_descr) {
      description += `Location: ${feature.properties.loc_descr}\n`;
    }
    if (feature.properties.gear_descr) {
      description += `Gear: ${feature.properties.gear_descr}\n`;
    }
    if (feature.properties.season_desc) {
      description += `Season: ${feature.properties.season_desc}\n`;
    }
    if (feature.properties.spec_season_desc) {
      description += `Special season: ${feature.properties.spec_season_desc}\n`;
    }
    if (feature.properties.boat_reg) {
      description += `Boat restrictions: ${feature.properties.boat_reg}\n`;
    }

    const placeholder = document.createElement('div');
    ReactDOM.render((<Popup
      title={title}
      subtitle={description}
      rightButton={feature.properties.ext_link ? { label: 'Open URL', onClick: () => { window.open(feature.properties.ext_link); } } : undefined}

    />), placeholder);

    globalPopup.setLngLat(coordinate)
        .setDOMContent(placeholder)
        .addTo(map);
      map.on('closeAllPopups', () => {
        globalPopup.remove();
      });

      zoomToPoint(map, coordinate)

  }

  // map.on('click', 'trout_regs', regsHandler);
  // map.on('touchend', 'trout_regs', regsHandler);

  map.on('click', 'access_parking', clickTRPOIHandler);
  map.on('touchend', 'access_parking', clickTRPOIHandler);

  map.on('click', 'access_boundary', clickTRPOIHandler);
  map.on('touchend', 'access_boundary', clickTRPOIHandler);

  // Parking hover handler.

  map.on('click', 'access_parks', clickTRPOIHandler);
  map.on('touchend', 'access_parks', clickTRPOIHandler);

  map.on('click', 'access_access', clickTRPOIHandler);
  map.on('touchend', 'access_access', clickTRPOIHandler);

  map.on('click', 'access_stock', clickTRPOIHandler);
  map.on('touchend', 'access_stock', clickTRPOIHandler);

  map.on('click', 'access_habitat', clickTRPOIHandler);
  map.on('touchend', 'access_habitat', clickTRPOIHandler);

  map.on('click', 'access_poi', clickTRPOIHandler);
  map.on('touchend', 'access_poi', clickTRPOIHandler);
  
  map.on('click', 'access_troutpark', troutHandler);
  map.on('touchend', 'access_troutpark', troutHandler);

  map.on('click', 'access_boat', clickTRPOIHandler);
  map.on('touchend', 'access_boat', clickTRPOIHandler);

  map.on('click', 'access_trail', clickTRPOIHandler);
  map.on('touchend', 'access_trail', clickTRPOIHandler);

  map.on('click', 'access_dam', clickTRPOIHandler);
  map.on('touchend', 'access_dam', clickTRPOIHandler);

  map.on('click', 'access_camp', clickTRPOIHandler);
  map.on('touchend', 'access_camp', clickTRPOIHandler);

  map.on('click', 'TU_Events_Production', clickTRPOIHandler);
  map.on('touchend', 'TU_Events_Production', clickTRPOIHandler);

  map.on('click', 'trout_lakes', clickTRPOIHandler);
  map.on('touchend', 'trout_lakes', clickTRPOIHandler);

  map.on('click', 'rare_waters', clickTRPOIHandler);
  map.on('touchend', 'rare_waters', clickTRPOIHandler);

  return () => {
    map.off('click', globalHandler);
    map.off('click', 'access_points', accessHandler);
    map.off('click', 'access_parking', clickTRPOIHandler);
    map.off('click', 'access_access', clickTRPOIHandler);
    map.off('click', 'access_habitat', clickTRPOIHandler);
    map.off('click', 'access_stock', clickTRPOIHandler);
    map.off('click', 'access_troutpark', troutHandler);
    map.off('click', 'access_boat', clickTRPOIHandler);
    map.off('click', 'access_camp', clickTRPOIHandler);
    map.off('click', 'access_dam', clickTRPOIHandler);
    map.off('click', 'access_trail', clickTRPOIHandler);
    map.off('click', 'TU_Events_Production', clickTRPOIHandler);
    map.off('click', 'trout_lakes', clickTRPOIHandler);
    map.off('click', 'rare_waters', clickTRPOIHandler);

    map.off('touchend', globalHandler);
    map.off('touchend', 'access_points', accessHandler);
    map.off('touchend', 'access_parking', clickTRPOIHandler);
    map.off('touchend', 'access_access', clickTRPOIHandler);
    map.off('touchend', 'access_habitat', clickTRPOIHandler);
    map.off('touchend', 'access_stock', clickTRPOIHandler);
    map.off('touchend', 'access_troutpark', troutHandler);
    map.off('touchend', 'access_boat', clickTRPOIHandler);
    map.off('touchend', 'access_camp', clickTRPOIHandler);
    map.off('touchend', 'access_dam', clickTRPOIHandler);
    map.off('touchend', 'access_trail', clickTRPOIHandler);
    map.off('touchend', 'TU_Events_Production', clickTRPOIHandler);
    map.off('touchend', 'trout_lakes', clickTRPOIHandler);
    map.off('touchend', 'rare_waters', clickTRPOIHandler);
  };
};

const globalPopup = new mapboxgl.Popup({ className: 'popup', closeButton: false, maxWidth: 'none', closeOnClick: false });

Math.radians = (degrees) => {
  return degrees * Math.PI / 180;
};

export const distance = ([lon1, lat1], [lon2, lat2], isMeter=true) => {
  var R = 6371000; // metres
  if (!isMeter) {
    R = 3958.755866;
  }
  const φ1 = Math.radians(lat1);
  const φ2 = Math.radians(lat2);
  const Δφ = Math.radians(lat2 - lat1);
  const Δλ = Math.radians(lon2 - lon1);

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2)
      + Math.cos(φ1) * Math.cos(φ2)
      * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;
  return d;
};

export const distanceFormat = ([lon1, lat1], isMeter=true) => {
  const myLocation = localStorage.getItem('myLocation') ? localStorage.getItem('myLocation').split(',') : null;
  if (myLocation === null) {
    return '-';
  } else {
    var dist = Math.round(100 * distance([lon1, lat1], myLocation, isMeter)) / 100;
    dist = dist > 10 ? dist.toLocaleString('en-US', { minimumFractionDigits: 0 }) : dist.toLocaleString('en-US', { minimumFractionDigits: 1 });
    return `${dist} mi. away`;
  }
};

/**
 * Finds the closest point to the mouse cursor.
 * @param {*} lngLat The point to use as the origin.
 * @param {array} arrayOfPoints Array of LngLat objects [lng, lat].
 */
const findClosestPointInArray = (lngLat, arrayOfPoints) => {
  const { point } = arrayOfPoints.reduce((accumulator, curValue) => {
    if (curValue.length !== 2) return accumulator;

    const { dist } = accumulator;
    const newDist = distance(lngLat, curValue);
    if (newDist < dist || dist === -1) return { point: curValue, dist: newDist };
    else return accumulator;
  }, { dist: -1 });
  return point;
};

/**
 * Sets the hover handlers for the map.
 * @param {*} map Mapbox GL object.
 */
const setHoverHandlers = (map) => {
  map.on('mousemove', () => {
    map.getCanvas().style.cursor = 'pointer';
  });
};

/**
 * Displays a popup for a line.
 * @param {Feature} feature Line feature object.
 * @param {*} map Mapbox GL object.
 * @param {func} dispatch The Redux dispatch function.
 */
export const displayLinePopup = (feature, map, dispatch) => {
  const placeholder = document.createElement('div');
  const coordinates = feature.geometry.coordinates[0];
  ReactDOM.render((<Popup
    title={feature.properties.name}
    subtitle="Line"
    leftButton={{ label: 'Edit Line', onClick: () => { dispatch(setEditingLineId(feature.id)); } }}
    rightButton={{ label: 'Directions', onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinates[1]}, ${coordinates[0]}`)}`); } }}
    iconURL={linePopupIcon}
  />), placeholder);

  const popup = new mapboxgl.Popup({ className: 'popup', closeButton: false, maxWidth: 'none' })
    .setLngLat([coordinates[0], coordinates[1]])
    .setDOMContent(placeholder)
    .addTo(map);
  map.on('closeAllPopups', () => {
    popup.remove();
  });
};

/**
 * Displays a popup for a polygon.
 * @param {Feature} feature Polygon feature object.
 * @param {*} map Mapbox GL object.
 * @param {func} dispatch The Redux dispatch function.
 */
export const displayPolygonPopup = (feature, map, dispatch) => {
  const placeholder = document.createElement('div');
  const coordinates = feature.geometry.coordinates[0][0];
  ReactDOM.render((<Popup
    title={feature.properties.name}
    subtitle="Shape"
    leftButton={{ label: 'Edit Shape', onClick: () => { dispatch(setEditingAreaId(feature.id)); } }}
    rightButton={{ label: 'Directions', onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinates[1]}, ${coordinates[0]}`)}`); } }}
    iconURL={shapePopupIcon}
  />), placeholder);

  const popup = new mapboxgl.Popup({ className: 'popup', closeButton: false, maxWidth: 'none' })
    .setLngLat([coordinates[0], coordinates[1]])
    .setDOMContent(placeholder)
    .addTo(map);
  map.on('closeAllPopups', () => {
    popup.remove();
  });
};

/**
 * Helper function to set draw handlers.
 * @param {*} map Mapbox GL object.
 * @param {func} dispatch The Redux dispatch function.
 */
const setDrawHandlers = (map, dispatch) => {
  map.on('draw.create', (e) => {
    const { features } = e;
    const feature = features[0];

    if (feature.geometry.type === 'LineString') {
      dispatch(setTempLine(feature));
      dispatch(setActiveTools({ line: false }));
    } else if (feature.geometry.type === 'Polygon') {
      dispatch(setTempArea(feature));
      dispatch(setActiveTools({ area: false }));
    }
  });

  map.on('draw.selectionchange', (e) => {
    const { features } = e;
    if (features.length > 0) {
      const feature = features[0];
      map.fire('closeAllPopups');
      dispatch(clearMap());

      if (feature.geometry.type === 'LineString') {
        dispatch(setEditingLineId(feature.id));
        map.flyTo({ center: feature.geometry.coordinates[Math.floor(feature.geometry.coordinates.length / 2)], zoom: 12 }, { programmatic: true });
        dispatch(setEditingAreaId(''));
        dispatch(setEditingMarkerId(''));
      } else if (feature.geometry.type === 'Polygon') {
        dispatch(setEditingAreaId(feature.id));
        map.flyTo({ center: feature.geometry.coordinates[Math.floor(feature.geometry.coordinates.length / 2)][0], zoom: 12 }, { programmatic: true });
        dispatch(setEditingLineId(''));
        dispatch(setEditingMarkerId(''));
      } else {
        dispatch(setEditingLineId(''));
        dispatch(setEditingAreaId(''));
        dispatch(setEditingMarkerId(''));
      }
    }
  });

  map.on('draw.update', (e) => {
    const { features } = e;
    if (features.length > 0) {
      const feature = features[0];
      if (feature.geometry.type === 'Polygon') {
        clearAreaBorder(map, feature);
        addAreaBorder(feature, map, {});
      }
    }
  });
};

/**
 * Runs setup functions after the map's styles have loaded.
 * @param {*} map Mapbox GL object.
 * @param {*} dispatch Dispatch function to connect to Redux.
 * @param {*} draw The draw object.
 */
export const onLoad = (map, dispatch, draw) => {
  setupHUC10(map);
  setupTroutLakes(map);
  setupRareWaters(map);
  setupSupportedStates(map, dispatch);
  setUpFlyShops(map);
  setUpEventsLayer(map);
  setUpIcons(map);
  setUpPaintProperties(map);
  setInitialFilters(map);
  setHoverHandlers(map, dispatch);
  setDrawHandlers(map, dispatch);
};

/**
 * Function that updates the filters and streams on the map.
 * @param {*} map Mapbox GL object.
 * @param {object} otherFeatures Object delineating map features.
 */
export const updateOtherFeatures = (map, {
  counties, flyShops, gages, parking, boat, camp, hike, accessPoints, bridges, lakes,
}) => {
  if (counties) {
    map.setLayoutProperty('county-label', 'visibility', 'visible');
    map.setLayoutProperty('county-outline', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('county-label', 'visibility', 'none');
    map.setLayoutProperty('county-outline', 'visibility', 'none');
  }

  if (flyShops) {
    map.setLayoutProperty('flyshops', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('flyshops', 'visibility', 'none');
  }

  if (gages) {
    map.setLayoutProperty('gage_station', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('gage_station', 'visibility', 'none');
  }

  if (parking) {
    map.setLayoutProperty('access_parking', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('access_parking', 'visibility', 'none');
  }

  if (boat) {
    map.setLayoutProperty('access_boat', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('access_boat', 'visibility', 'none');
  }

  if (camp) {
    map.setLayoutProperty('access_camp', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('access_camp', 'visibility', 'none');
  }

  if (hike) {
    map.setLayoutProperty('access_trail', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('access_trail', 'visibility', 'none');
  }

  if (accessPoints) {
    map.setLayoutProperty('access_access', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('access_access', 'visibility', 'none');
  }

  if (bridges) {
    map.setLayoutProperty('access_points', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('access_points', 'visibility', 'none');
  }
  
  if (lakes) {
    map.setLayoutProperty('trout_lakes', 'visibility', 'visible');
  } else {
    map.setLayoutProperty('trout_lakes', 'visibility', 'none');
  }
};

// my location
export const createMarkerLocation = ( lngLat, map ) => {
  const { marker } = store.getState().map;
  if (marker && marker.locationMarker) {
    marker.locationMarker.remove();
  }

  const el = document.createElement('div');
  const icon = React.cloneElement(LocationIcon());
  const svgString = ReactDOMServer.renderToString(icon);

  el.innerHTML = svgString;
  el.style.cursor = 'pointer';

  const locationMarker = new mapboxgl.Marker({ element: el }).setLngLat(lngLat).setOffset([-1, 2]).addTo(map);
  const { dispatch } = store;
  dispatch(setLocationMarker(locationMarker));
};

// highlight selected
export const createMarkerHighlight = ( lngLat, map ) => {
  const { marker } = store.getState().map;
  if (marker && marker.tapMarker) {
    marker.tapMarker.remove();
  }

  const el = document.createElement('div');
  const icon = React.cloneElement(TapIcon());
  const svgString = ReactDOMServer.renderToString(icon);

  el.innerHTML = svgString;
  el.style.cursor = 'pointer';

  const tapMarker = new mapboxgl.Marker({ element: el }).setLngLat(lngLat).setOffset([-1, 2]).addTo(map);
  const { dispatch } = store;
  dispatch(setTapMarker(tapMarker));
};

/**
 * Default click handler to set a new marker.
 * @param {object} e The event object originally passed to the handler.
 * @param {*} map Mapbox GL object.
 */
export const createMarkerWithoutIcon = ({ lngLat }, map) => {
  const marker = new mapboxgl.Marker({
    color: 'red',
  }).setLngLat(lngLat).addTo(map);
  return marker;
};

export const createMilesMarker = ( lngLat , map) => {
  const el = document.createElement('div');
  const zoom = map.getZoom();
  el.style.backgroundImage = `url('${markerTypeRivermiles}')`;
  el.style.backgroundSize = 'cover';
  let size = Math.min(30, 200 - (zoom * 1));
  let size2 = Math.max(size, 50);
  el.style.width = `${size2}px`;
  el.style.height = `${size2}px`;
  el.style.cursor = 'pointer';

  const marker = new mapboxgl.Marker({ element: el}).setLngLat(lngLat).setOffset([0, -20]).addTo(map);
  return marker;

}

/**
 * Creates a marker with a custom icon.
 * @param {LngLat} lngLat LngLat object.
 * @param {*} map Mapbox GL object.
 * @param {string} icon URL to the icon image.
 * @param {Element} popup A reference to a JSX element to render when the marker is clicked.
 * @param {object} dispatchFunction Optional. Must contain the dispatch function and the ID of the marker.
 */
export const createMarkerWithIcon = (lngLat, map, icon, popup, dispatchFunction) => {
  const el = document.createElement('div');
  const zoom = map.getZoom();
  el.style.backgroundImage = `url('${icon}')`;
  el.style.backgroundSize = 'contain';

  let size = Math.min(30, 200 - (zoom * 1));
  let size2 = Math.max(size, 44);

  el.style.width = `${size2}px`;
  el.style.height = `${size2}px`;
  el.style.cursor = 'pointer';

  const marker = new mapboxgl.Marker({ element: el}).setLngLat(lngLat).setOffset([2, -22]).addTo(map);

  if (popup) {
    const placeholder = document.createElement('div');
    ReactDOM.render(popup, placeholder);
    el.addEventListener('click', (e) => {
      e.stopPropagation();
      const popupmb = new mapboxgl.Popup({ className: 'popup', closeButton: false, maxWidth: 'none' })
        .setLngLat(lngLat)
        .setDOMContent(placeholder)
        .addTo(map);
      map.on('closeAllPopups', () => {
        popupmb.remove();
      });
      map.flyTo({ center: lngLat, zoom: 14 }, { programmatic: true });
    });
  }

  if (dispatchFunction) {
    const { dispatch, id } = dispatchFunction;
    el.addEventListener('click', (e) => {
      e.stopPropagation();
      map.fire('closeAllPopups');
      dispatch(clearMap());
      dispatch(setEditingMarkerId(id));
      dispatch(setEditingAreaId(''));
      dispatch(setEditingLineId(''));

      map.flyTo({ center: lngLat, zoom: 14 }, { programmatic: true });
    });
  }
  return marker;
};


/**
 * Creates a marker with a custom icon.
 * @param {LngLat} lngLat LngLat object.
 * @param {*} map Mapbox GL object.
 * @param {string} icon URL to the icon image.
 * @param {Element} popup A reference to a JSX element to render when the marker is clicked.
 * @param {object} dispatchFunction Optional. Must contain the dispatch function and the ID of the marker.
 */
export const createIconMarker = (lngLat, map, icon, popup, dispatchFunction) => {
  const el = document.createElement('div');
  const svgString = ReactDOMServer.renderToString(icon);

  el.innerHTML = svgString;
  el.style.cursor = 'pointer';

  const marker = new mapboxgl.Marker({ element: el }).setLngLat(lngLat).setOffset([0, -20]).addTo(map);

  if (popup) {
    const placeholder = document.createElement('div');
    ReactDOM.render(popup, placeholder);
    el.addEventListener('click', (e) => {
      e.stopPropagation();
      const popupmb = new mapboxgl.Popup({ className: 'popup', closeButton: false, maxWidth: 'none' })
        .setLngLat(lngLat)
        .setDOMContent(placeholder)
        .addTo(map);
      map.on('closeAllPopups', () => {
        popupmb.remove();
      });
      map.flyTo({ center: lngLat, zoom: 14 }, { programmatic: true });
    });
  }

  if (dispatchFunction) {
    const { dispatch, id } = dispatchFunction;
    const { tapMarker } = store.getState().map.marker;
    el.addEventListener('click', (e) => {
      e.stopPropagation();
      map.fire('closeAllPopups');
      dispatch(clearMap());
      if (tapMarker) {
        tapMarker.remove();
      }
      dispatch(setEditingMarkerId(id));
      dispatch(setEditingAreaId(''));
      dispatch(setEditingLineId(''));
      dispatch(setTRPOIFeature(null));

      map.flyTo({ center: lngLat }, { programmatic: true });
    });
  }
  return marker;
};

/**
 * Adds a border to a Mapbox GL polygon.
 * @param {Feature} area Area to add a border to.
 * @param {*} map Mapbox GL object.
 * @param {object} options Line options for the border.
 */
export const addAreaBorder = (area, map, {
  color, width, dashArray, opacity,
}) => {
  const { coordinates } = area.geometry;
  const lineSource = {};
  lineSource.data = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: coordinates[0],
    },
  };
  lineSource.type = 'geojson';

  const layerName = `polygon-border-${area.id}`;

  let oldColor, oldWidth, oldArray, oldOpacity;

  if (map.getLayer(layerName)) {
    const oldLayer = map.getLayer(layerName);
    // Addresses weird structuring issue in a layer object.
    oldColor = oldLayer?.paint?._values['line-color']?.value?.value;
    oldWidth = oldLayer?.paint?._values['line-width']?.value?.value;
    oldArray = oldLayer?.paint?._values['line-dasharray']?.from;
    oldOpacity = oldLayer?.paint?._values['line-opacity']?.value?.value;

    map.removeLayer(layerName);
  }
  if (map.getSource(layerName)) {
    map.removeSource(layerName);
  }

  map.addSource(layerName, lineSource);
  map.addLayer({
    id: layerName,
    type: 'line',
    source: layerName,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': color || `rgba(${oldColor?.r * 255},${oldColor?.g * 255},${oldColor?.b * 255},${oldColor?.a * 255})`,
      'line-width': width || oldWidth,
      'line-dasharray': dashArray || oldArray,
      'line-opacity': opacity || oldOpacity,
    },
  });
};

/**
 * Clears the border around an area.
 * @param {*} map Mapbox GL object.
 * @param {Feature} area Area associated with the border to clear.
 */
export const clearAreaBorder = (map, area) => {
  if (area.id) {
    const layerName = `polygon-border-${area?.id}`;

    if (map.getLayer(layerName)) {
      map.removeLayer(layerName);
    }
    if (map.getSource(layerName)) {
      map.removeSource(layerName);
    }
  }
};

//This function will pan and zoom the map to a point. Zooming can be annoying if the user is zoomed in and it auto zooms out, so lets not bother zooming if they are past 16
export const zoomToPoint = (map, point) => {
  const coordinate = point;
  const zoom = 14.0;
  const currentZoom = map.getZoom();
  if(map.getZoom() < zoom) {
    map.flyTo({
      center: coordinate,
      zoom,
    }, { programmatic: true });
  } else {
    map.flyTo({
      center: coordinate,
      currentZoom,
    }, { programmatic: true });
  }
}

export const jumpToPoint = async (map, point, dispatch, zoom = 14) => {
  clearTimeout(tempTimer);
  tempTimer = setTimeout(async () => {
    point[0] = point[0] * 1;
    if (point.length > 1) point[1] = point[1] * 1;
    const coordinate = point;
    const name = 'Dropped Pin';
    const { redMarker } = store.getState().map.marker;
    if (redMarker) redMarker.remove();
    
    const redPin = new mapboxgl.Marker({
      color: 'red',
    }).setLngLat(point).addTo(map);
    dispatch(setRedMarker(redPin));

    const onCreateMaker = () => {
      if (redPin) redPin.remove();
      createAndDisplayMarker(point, 0, map, dispatch);
    };
    var away = distanceFormat(coordinate, false);
    var elevation = await getElevationPoint({lat: coordinate[1], lng: coordinate[0]});
    const placeholder = document.createElement('div');
    ReactDOM.render((<Popup3
      title={name}
      subtitle="Dropped Pin"
      elevation={elevation}
      distance={away}
      leftButton={{ label: 'Create Marker', icon: createIcon2, onClick: onCreateMaker }}
      rightButton={{ label: 'Directions', icon: directionsIcon2, onClick: () => { window.open(`${directionsURL}${encodeURI(`${coordinate[1]}, ${coordinate[0]}`)}`); } }}
      iconURL={droppedPin}
    />), placeholder);
  
    if (coordinate?.length === 2 || coordinate[0]?.length === 2) {
      globalPopup.setLngLat(coordinate.length > 2 ? coordinate[0] : coordinate)
        .setDOMContent(placeholder)
        .addTo(map);
      map.on('closeAllPopups', () => {
        globalPopup.remove();
      });
      
      map.flyTo({
        center: coordinate,
        zoom,
      }, { programmatic: true });
    }
  }, 800);
};

export const snapToRegion = (map, region) => {
  map.setCenter(region.bounds);
  if (region?.zoom) map.setZoom(region.zoom);
};

export const flyToPoint = (map, point, zoom = 14) => {
 const coordinate = point;
 if (coordinate?.length === 2 || coordinate[0]?.length === 2) {
   map.flyTo({
     center: coordinate,
     zoom,
    }, { programmatic: true });
  }
}

export const getSupportedStates = (map) => {
  const supportedStates = map.queryRenderedFeatures(undefined, { layers: ['supported_states'] });
  const stateNames = supportedStates.filter((state) => !!state && state.properties).map((state) => state.properties.short_name);
  return stateNames;
}

export const showHUC = (map, huc) => {
  const hucPred = ['==', 'huc10', huc];
  map.setFilter('huc_10_layer_highlight', hucPred);
  map.setFilter('huc_10_layer_highlight_outline', hucPred);
} 

export const hideHUC = (map) => {
  const hucPred = ['==', 'huc10', 'N/A'];
  map.setFilter('huc_10_layer_highlight', hucPred);
  map.setFilter('huc_10_layer_highlight_outline', hucPred);
} 