import React, { useState, useEffect, useCallback } from 'react';
import {
  InputLabel, Select, MenuItem, FormControl, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'components/Image';
import { snapRegions } from 'lib/constants';
import * as mapboxService from 'services/mapbox';
import * as firebaseService from 'services/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { updateRegion } from 'actions';
import { zoomTo, chevronDown } from 'assets';
import styles from './styles.module.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: window.innerWidth > 620 ? 6 : 0,
    paddingTop: window.innerWidth > 620 ? 3 : 6,
    paddingBottom: window.innerWidth > 620 ? 3 : 0,
  },
  select: {
    width: window.innerWidth > 620 ? 140 : 80,
    opacity: window.innerWidth > 620 ? 1 : 0,
    paddingRight: '0px!important',
    fontSize: '16px',
    background: 'white!important',
    fontWeight: '500',
    fontFamily: 'Poppins',
  },
  paper: {
    borderRadius: 10,
    marginTop: 60,
    marginLeft: window.innerWidth > 620 ? -14 : 0,
    width: window.innerWidth > 620 ? 174 : 150,
  },
}));

const useStylesMobile = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingTop: 6,
    paddingBottom: 0,
  },
  select: {
    width: 80,
    opacity: 0,
    paddingRight: '0px!important',
    fontSize: '16px',
    background: 'white!important',
    fontWeight: '500',
    fontFamily: 'Poppins',
  },
  paper: {
    borderRadius: 10,
    marginTop: 60,
    marginLeft: 0,
    width: 150,
  },
}));

export const Selector = ({
  value, onChange, children, classes,
}) => {
  const otherClasses = useStyles();
  const otherClassesMobile = useStylesMobile();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [window.innerWidth]);

  return (
    <FormControl className={windowWidth > 620 ? otherClasses.formControl : otherClassesMobile.formControl}>
      {/* <InputLabel id="label">Region</InputLabel> */}
      <Select
        labelId="label"
        id="select"
        value={value}
        onChange={onChange}
        classes={{ root: windowWidth > 620 ? otherClasses.select : otherClassesMobile.select, ...classes }}
        disableUnderline
        MenuProps={{ classes: { paper: windowWidth > 620 ? otherClasses.paper : otherClassesMobile.paper } }}
        IconComponent={(props) => (
          <Image src={chevronDown} {...props} className={`material-icons ${props.className}`} style={{ marginTop: 4, display: window.innerWidth > 620 ? 'inline-block' : 'none' }} />
        )}
      >
        {snapRegions.map(({ title }) => {
          return (
            <MenuItem value={title} key={title} className={styles.mitem}>{title}</MenuItem>
          );
        })}
      </Select>
      {children}
    </FormControl>
  );
};

const Snapper = () => {
  const [value, setValue] = useState('');
  const { map } = useSelector((state) => state.map);
  const dispatch = useDispatch();
  const { region: userRegion } = useSelector((state) => state.user);

  const onSnap = () => {
    if (value) {
      const region = snapRegions.find((r) => r.title === value);
      if (region) {
        mapboxService.snapToRegion(map, region);
        firebaseService.updateSnapRegion(region.gid);
        dispatch(updateRegion(region.gid));
      }
    }
  };

  const changeRegion = (val) => {
    setValue(val);
    if (window.innerWidth <= 620 && val) {
      const region = snapRegions.find((r) => r.title === val);
      if (region) {
        mapboxService.snapToRegion(map, region);
        firebaseService.updateSnapRegion(region.gid);
        dispatch(updateRegion(region.gid));
      }
    }
  };

  useEffect(() => {
    if (userRegion) {
      const regionObject = snapRegions.find((region) => region.gid == userRegion);
      if (regionObject) {
        mapboxService.snapToRegion(map, regionObject);
        setValue(regionObject.title);
      }
    }
  }, [userRegion]);

  return (
    <div className={styles.snapper}>
      <Selector value={value} onChange={(e) => { changeRegion(e.target.value); }}>
        <div>
          <IconButton className={styles.goButton} aria-label="snap" onClick={onSnap}>
            <Image src={zoomTo} />
          </IconButton>
        </div>
      </Selector>
      <IconButton className={styles.goButton1} aria-label="snap">
        <Image src={zoomTo} />
      </IconButton>
    </div>
  );
};

export default Snapper;
