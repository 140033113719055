/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { horizontalLogo, logo2 } from 'assets';
import {
  FormControl,
  FilledInput,
  InputAdornment,
  IconButton,
  Button,
  Link,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import styles from './styles.module.scss';

const inputStyles = makeStyles({
  input: {
    paddingLeft: 10,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 0,
    fontSize: '14px',
  },
  root: {
    backgroundColor: 'transparent',
    border: '2px solid #E2E2E2',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      border: '2px solid #A5A5A5',
    },
    '&.Mui-error': {
      border: '2px solid #DB0000',
    },
    '& .MuiFilledInput-input::placeholder': {
      color: '#333333',
      opacity: 1,
      fontSize: '14px',
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: 'none',
    },
  },

});

const buttonStyles = makeStyles({
  root: {
    backgroundColor: '#1553D3',
    borderRadius: '24px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#0F3C9B',
      boxShadow: 'none',
    },
  },
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
    paddingTop: 2,
    paddingBottom: 2,
  },
});

const identityButtonStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
    backgroundColor: '#EBEBEB',
    borderRadius: '24px',
    color: 'black',
    border: 'none',
    fontWeight: 700,
    marginTop: '5px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#BDBDBD',
      boxShadow: 'none',
      border: 'none',
    },
    paddingTop: 11,
    paddingBottom: 11,
  },
  label: {
    fontSize: '16px',
    gap: '5px',
    '@media (max-width: 400px)': {
      fontSize: '0.75rem',
    },
  },
  icon: {
    '@media (max-width: 430px)': {
      height: 20,
      width: 20,
    },
  },
  text: {
    lineHeight: '1.2',
    '@media (max-width: 430px)': {
      fontSize: '0.75rem',
    },
  },
});

export const linkStyles = makeStyles({
  button: {
    fontWeight: 700,
    fontSize: '16px',
    color: '#1553D3',
    marginLeft: 5,
  },
  buttonScondary: {
    fontSize: '12px',
    color: '#1553D3',
  },
});

export const linkStylesSkip = makeStyles({
  button: {
    fontWeight: 700,
    fontSize: '16px',
    color: '#1553D3',
    alignContent: 'center',
  },
});

export const loadingStyles = makeStyles({
  root: { marginLeft: 10 },
});

const helperTextStyles = makeStyles({
  root: {
    color: '#DB0000',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
});

const FormContainer = ({ children, title }) => {
  const [isSmallerScreen, setIsSmallerScreen] = useState(window.innerWidth <= 1000);
  const handleResize = useCallback(() => {
    setIsSmallerScreen(window.innerWidth <= 1000);
  }, [setIsSmallerScreen]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div className={styles.container}>
      <div className={styles['login-logo-image']}>
        {isSmallerScreen
          ? (<img src={horizontalLogo} width={220} alt="Logo" className="" onClick={() => { window.location.href = 'https://troutroutes.com/'; }} />)
          : (<img src={logo2} width={70} height={56} alt="Logo" className="" onClick={() => { window.location.href = 'https://troutroutes.com/'; }} />)}
      </div>
      {/* <Image src={loginLogo} alt="Logo" width={500} height={100} minHeight={189} objectFit="cover" /> */}
      <h1 className={styles.title}>{title}</h1>
      {children}
    </div>
  );
};

FormContainer.FormElement = ({
  title,
  name,
  error,
  isPassword,
  onChange,
  value = '',
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggle = () => {
    setShowPassword((pw) => !pw);
  };
  const inputClasses = inputStyles();
  const helperTextClasses = helperTextStyles();

  return (
    <FormControl className={styles.element} fullWidth>
      <FilledInput
        id={name}
        placeholder={title}
        classes={inputClasses}
        name={name}
        onChange={onChange}
        value={value}
        error={!!error}
        type={isPassword && !showPassword ? 'password' : 'text'}
        endAdornment={
          isPassword && (
            <InputAdornment position="end">
              <IconButton
                disableRipple
                aria-label="toggle password visibility"
                onClick={toggle}
                onMouseDown={toggle}
              >
                {!showPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          )
        }
      />
      <FormHelperText classes={helperTextClasses}>{error || ''}</FormHelperText>
    </FormControl>
  );
};

FormContainer.SubmitButton = ({ title, onClick, loading }) => {
  const buttonClasses = buttonStyles();
  const loadingClasses = loadingStyles();
  return (
    <Button
      disableRipple
      classes={buttonClasses}
      variant="contained"
      color="primary"
      fullWidth
      size="large"
      onClick={onClick}
    >
      {title}
      {loading && <CircularProgress classes={loadingClasses} size={30} />}
    </Button>
  );
};

FormContainer.OnxIdentityButton = ({ onClick, loading }) => {
  const buttonClasses = identityButtonStyles();
  const loadingClasses = loadingStyles();
  return (
    <Button
      disableRipple
      classes={buttonClasses}
      variant="outlined"
      color="primary"
      fullWidth
      size="large"
      onClick={onClick}
    >
      <svg
        className={buttonClasses.icon}
        width="25"
        height="25"
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 14.875C0 6.65977 6.65977 0 14.875 0H53.125C61.3402 0 68 6.65977 68 14.875V53.125C68 61.3402 61.3402 68 53.125 68H14.875C6.65977 68 0 61.3402 0 53.125V14.875Z"
          fill="#222"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.1135 43.5869L43.2359 49.5756L34.0134 40.1789L24.7901 49.5756L18.9124 43.5869L28.1349 34.1902L18.9124 24.7926L24.7901 18.8047L34.0134 28.2005L43.2359 18.8047L49.1135 24.7926L39.8911 34.1902L49.1135 43.5869Z"
          fill="white"
        />
      </svg>
      <div className={buttonClasses.text}>
        Continue with onX
      </div>
      {loading && <CircularProgress classes={loadingClasses} size={30} />}
    </Button>
  );
};

FormContainer.GoogleIdentityButton = ({ onClick, loading }) => {
  const buttonClasses = identityButtonStyles();
  const loadingClasses = loadingStyles();
  return (
    <Button
      disableRipple
      classes={buttonClasses}
      variant="outlined"
      color="primary"
      fullWidth
      size="large"
      onClick={onClick}
    >
      <svg
        className={buttonClasses.icon}
        xmlns="http://www.w3.org/2000/svg"
        height="25"
        width="25"
        viewBox="0 0 24 24"
      >
        <path
          d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          fill="#4285F4"
        />
        <path
          d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
          fill="#34A853"
        />
        <path
          d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
          fill="#FBBC05"
        />
        <path
          d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
          fill="#EA4335"
        />
        <path d="M1 1h22v22H1z" fill="none" />
      </svg>
      <div className={buttonClasses.text}>
        Log In with Google
      </div>
      {loading && <CircularProgress classes={loadingClasses} size={30} />}
    </Button>
  );
};

FormContainer.BottomSection = ({ text, buttonTitle, onClick = undefined }) => {
  const classes = linkStyles();

  return (
    <div className={styles['bottom-section']}>
      <div className={styles['horizontal-text']}>
        <p>{text}</p>
        <Link
          onClick={onClick}
          underline="none"
          classes={classes}
          component="button"
        >
          {buttonTitle}
        </Link>
      </div>
    </div>
  );
};

FormContainer.SwitchToSignIn = ({ text, buttonTitle, onClick = undefined }) => {
  const classes = linkStyles();

  return (
    <div className={styles['horizontal-text']}>
      <p>{text}&nbsp;</p>
      <Link
        onClick={onClick}
        underline="none"
        classes={classes}
        component="button"
      >
        {buttonTitle}
      </Link>
    </div>
  );
};

FormContainer.ContinueAsBasic = ({ buttonTitle, onClick = undefined }) => {
  const classes = linkStylesSkip();

  return (
    <div>
      <Link
        onClick={onClick}
        underline="none"
        classes={classes}
        component="button"
      >
        {buttonTitle}
      </Link>
    </div>
  );
};

FormContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

FormContainer.FormElement.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  isPassword: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

FormContainer.SubmitButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

FormContainer.BottomSection.propTypes = {
  text: PropTypes.string,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func,
};

export default FormContainer;
