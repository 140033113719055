/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const AddPhotoButton = ({ id, onChange }) => {
  return (
    <div className={styles.padd}>
      <label htmlFor={id} className={styles.add}><AddIcon /></label>
      <label>Add</label>
      <input type="file" style={{ display: 'none' }} id={id} onChange={onChange} accept="image/png, image/jpeg" />
    </div>
  );
};

export default AddPhotoButton;
