const firebaseConfig = {
  apiKey: 'AIzaSyCBZcOZhbZ1fg8NzzpXopoZ_Db5evGELX8',
  authDomain: 'auth.troutroutes.com',
  databaseURL: 'https://user-favorites-v2.firebaseio.com/',
  projectId: 'mapbox-search-example',
  storageBucket: 'mapbox-search-example.appspot.com',
  messagingSenderId: '850985490128',
  appId: '1:850985490128:web:3341ee99f0aed09d5770d5',
  measurementId: 'G-M4WYSB0M0B',
};

export default firebaseConfig;
