/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormHelperText, Link } from '@material-ui/core';
import styles from './styles.module.scss';
import UpdateSection from './UpdateSection';

const UpdateScreen = ({ close, toggleCloseDisabled, isCloseDisabled }) => {
  const { result, error: paymentError } = useSelector((state) => state.payment);
  const { email } = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (result?.paymentMethodId) close();
  }, [result]);

  return (
    <div className={styles.payment}>
      <div className={styles['info-section']} style={{ marginBottom: 30 }}>
        <h4>Update your payment details</h4>
        <FormHelperText error>{paymentError || ' '}</FormHelperText>
      </div>

      <UpdateSection onLoadChange={toggleCloseDisabled} email={email} />
      <Link underline="none" component="button" onClick={close} disabled={isCloseDisabled}>Cancel</Link>
    </div>
  );
};

export default UpdateScreen;
