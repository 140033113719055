import React from 'react';
import { HeightOutlined, VerticalAlignTopOutlined, CallMadeOutlined } from '@material-ui/icons';
import { ElevationLineChart } from 'components/ElevationChart';
import { useSelector } from 'react-redux';
import useIsPro from 'utils/useIsPro';
import UpgradeBox from 'components/UpgradeBox';
import styles from './styles.module.scss';

const proTitle = 'River Geometry';
const proDescription = 'Unlock our river geometry data, including interactive Elevation Charts, geometry characteristics and slope information.';

function GeographyTab() {
  const isPro = useIsPro();
  const streamMetadata = useSelector((state) => state.map.streamGeography);
  const {
    length, peakElevation, elevChange, slope, riverDistanceMiles,
  } = streamMetadata;
  const currentStream = useSelector((state) => state.map.stream);

  return (
    <div className={styles.mainContainer}>
      {!isPro && <div className={styles.boxUpgrade}> <UpgradeBox title={proTitle} description={proDescription} /> </div>}

      <div className={`${styles.container} ${!isPro ? styles.blurBackground : ''}`}>
        <div className={styles.cardContainer}>
          <div>
            <div className={styles.cardTitle}>Length</div>
            <div className={styles.cardValueContainer}>
              <div className={`${styles.rotate90} ${styles.cardIcon}`}><HeightOutlined /></div>
              <div className={styles.cardValue}><span>{currentStream.length || length || riverDistanceMiles}</span> mi</div>
            </div>
          </div>
          <div>
            <div className={styles.cardTitle}>Peak Elev.</div>
            <div className={styles.cardValueContainer}>
              <div className={styles.cardIcon}><VerticalAlignTopOutlined /></div>
              <div className={styles.cardValue}><span>{peakElevation}</span> ft.</div>
            </div>
          </div>
          <div>
            <div className={styles.cardTitle}>Elev. Change</div>
            <div className={styles.cardValueContainer}>
              <div className={styles.cardIcon}><HeightOutlined /></div>
              <div className={styles.cardValue}><span>{elevChange}</span> ft.</div>
            </div>
          </div>
          <div>
            <div className={styles.cardTitle}>Slope</div>
            <div className={styles.cardValueContainer}>
              <div className={`${styles.rotate90} ${styles.cardIcon}`}><CallMadeOutlined /></div>
              <div className={styles.cardValue}><span>{slope}</span> &deg;</div>
            </div>
          </div>
        </div>
        <div className={styles.chartContainer}>
          <div className={styles.chartHeader}>
            <div className={styles.chartTitle}>Elevation Chart (Ft.)</div>
          </div>
          <ElevationLineChart />
        </div>
      </div>
    </div>
  );
}

export default GeographyTab;
