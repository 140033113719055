/* eslint-disable max-len */
export const colors = {
  orange: 'rgb(255, 127, 0)',
  systemPink: '#FF2D55',
  blue: 'rgb(0, 0, 255)',
  black: 'rgb(0, 0, 0)',
  red: 'rgb(255, 0, 0)',
  systemGreen: 'rgb(51, 199, 89)',
  green: 'rgb(0, 255, 0)',
  classTwoStreamColor: '',
  darkGray: 'rgb(85, 85, 85)',
  purple: 'rgb(128, 0, 128)',
  yellow: 'rgb(255, 255, 0)',
};

const regCards = {
  MOBlueRibbon: { title: 'Blue Ribbon', description: 'Missouri MDC: Blue Ribbon trout areas provide excellent trout habitat and smaller streams that support naturally reproducing rainbow trout.', color: colors.blue },
  MOWhiteRibbon: { title: 'White Ribbon', description: 'Missouri MDC: White Ribbon trout areas are coldwater streams capable of supporting trout populations year-round. All white ribbons trout areas receive periodic stockings of rainbow trout, and some also receive brown trout.', color: colors.black },
  MORedRibbon: { title: 'Red Ribbon', description: 'Missouri MDC: Red Ribbon trout areas provide high-quality habitat stocked primarily with browns.', color: colors.red },
  MOTroutPark: { title: 'Trout Park', description: 'Missouri MDC: Missouri\'s trout parks and large trout lake provide high quality trout fishing year \'round.', color: colors.systemGreen },
  MOLakeTaneycomo: { title: 'Lake Taneycomo', Description: 'Missouri MDC: This is a unique tailwater waterbody that is technically labeled a lake but is actually a river that flows below the Bull Shoals dam on Table Rock Lake. Trophy trout are often the target here.', color: colors.systemPink },
  WIClassOne: { title: 'Class I', description: 'WI DNR Classification: High-quality trout waters that have sufficient natural reproduction to sustain populations of wild trout, at or near carry capacity. Consequently, streams in this category require no stocking of hatchery trout. These streams or stream sections are often small and may contain small or slow-growing trout, especially in the headwaters.', color: colors.green },
  WIClassTwo: { title: 'Class II', description: 'WI DNR Classification: Streams in this classification may have some natural reproduction, but not enough to utilize available food and space. Therefore, stocking is required to maintain a desirable sport fishery. These streams have good survival and carryover of adult trout, often producing some fish larger than average size.', color: colors.classTwoStreamColor },
  WIClassThree: { title: 'Class III', description: 'WI DNR Classification: These waters are marginal trout habitat with no natural reproduction occurring. They require annual stocking of trout to provide trout fishing. Generally, there is no carryover of trout from one year to the next.', color: colors.blue },
  IAPutGrow: { title: 'Put & Grow', description: 'IA DNR Classification: Put and Grow Waters.', color: colors.darkGray },
  IACatchable: { title: 'Catchable Stocked Stream', description: 'IA DNR Classification: Catchable Stocked Stream.', color: colors.red },
  IARestrictive: { title: 'Restrictive Regulation', description: 'IA DNR Classification: Restrictive Regulations.', color: colors.orange },
  IANatural: { title: 'Natural Reproduction', description: 'IA DNR Classification: Fingerling Stocked or Natural Reproduction.', colors: colors.purple },
  MIType1: { title: 'Type 1', description: 'MI DNR Classification: Type 1 Trout Stream.', color: colors.green },
  MIType2: { title: 'Type 2', description: 'MI DNR Classification: Type 2 Trout Stream.', color: colors.yellow },
  MIType3: { title: 'Type 3', description: 'MI DNR Classification: Type 3 Trout Stream.', color: colors.orange },
  MIType4: { title: 'Type 4', description: 'MI DNR Classification: Type 4 Trout Stream.', color: colors.blue },
  MIGR: { title: 'GR Designated', description: 'MI DNR Classification: Gear Restricted.', color: colors.red },
  MIRA: { title: 'RA Designated', description: 'MI DNR Classification: Research Area - Salmon.', color: colors.systemPink },
  GearRestrictions: { title: 'Gear Restrictions', description: 'Gear Restrictions present on this section or stream. Please see regulations below.', color: colors.red },
  SpecialRegulations: { title: 'Special Regulations', description: 'Special Regulations apply to sections on this stream. Please read below for further information.', Color: colors.orange },
  DelayedHarvest: { title: 'Delayed Harvest', description: 'Delayed Harvest Section - Special Regulations apply to sections on this stream. Please read below for further information.', color: colors.orange },
  BTRA: { title: 'BTRA', description: 'Brook Trout Restoration Area, special regulations apply. Please see regulations below for more information.', color: colors.purple },
  GreatLakesTrib: { title: 'Great Lakes Tributary', description: 'Great Lakes Tributary Special Regulations apply on portions of this stream. See regulations below.', color: colors.systemPink },
};

const gearRestrictionCard = {
  title: regCards.GearRestrictions.title,
  localDescription: regCards.GearRestrictions.description,
  color: regCards.GearRestrictions.color,
  textColor: 'white',
};

const btraCard = {
  title: regCards.BTRA.title,
  localDescription: regCards.BTRA.description,
  color: regCards.BTRA.color,
  textColor: 'white',
};

const processRegulation = (reg) => {
  const { properties } = reg;
  if (!properties || !properties.reg_code) return;

  const {
    reg_code, reg_title, reg_descr, ext_link, season_desc, class_title, stream_name, water_name,
  } = properties;

  const regs = [{
    color: '',
    title: '',
    localDescription: '',
    ext_link,
    reg_title,
    reg_descr,
    reg_code,
    showSpecialRegs: false,
    textColor: 'black',
    borderColor: 'black',
    season_desc,
    stream_name,
    water_name,
  }];

  const regInfo = regs[0];

  switch (parseInt(reg_code, 10)) {
  case 8:
  case 16:
    regInfo.title = regCards.GreatLakesTrib.title;
    regInfo.localDescription = regCards.GreatLakesTrib.description;
    regInfo.textColor = 'white';
    regInfo.showSpecialRegs = true;
    regInfo.color = regCards.GreatLakesTrib.color;
    break;
  case 20:
    regInfo.title = regCards.IACatchable.title;
    regInfo.localDescription = regCards.IACatchable.description;
    regInfo.borderColor = regCards.IACatchable.color;
    break;
  case 21:
    regInfo.title = regCards.IANatural.title;
    regInfo.localDescription = regCards.IANatural.description;
    regInfo.borderColor = regCards.IANatural.color;
    break;
  case 22:
    regInfo.title = regCards.IARestrictive.title;
    regInfo.localDescription = regCards.IARestrictive.description;
    regInfo.borderColor = regCards.IARestrictive.color;
    break;
  case 23:
    regInfo.title = regCards.IAPutGrow.title;
    regInfo.localDescription = regCards.IAPutGrow.description;
    regInfo.borderColor = regCards.IAPutGrow.color;
    break;
  case 25:
    regInfo.title = regCards.MIType1.title;
    regInfo.localDescription = regCards.MIType1.description;
    regInfo.borderColor = regCards.MIType1.color;
    break;
  case 26:
    regInfo.title = regCards.MIType4.title;
    regInfo.localDescription = regCards.MIType4.description;
    regInfo.borderColor = regCards.MIType4.color;
    break;
  case 27:
    regInfo.title = regCards.MIType3.title;
    regInfo.localDescription = regCards.MIType3.description;
    regInfo.borderColor = regCards.MIType3.color;
    break;
  case 28:
    regInfo.title = regCards.MIGR.title;
    regInfo.localDescription = regCards.MIGR.description;
    regInfo.borderColor = regCards.MIGR.color;

    regs.push(gearRestrictionCard);
    break;
  case 29:
    regInfo.title = regCards.MIType4.title;
    regInfo.localDescription = regCards.MIType4.description;
    regInfo.borderColor = regCards.MIType4.color;

    regs.push(gearRestrictionCard);
    break;
  case 36:
    regInfo.title = regCards.MIRA.title;
    regInfo.localDescription = regCards.MIRA.description;
    regInfo.borderColor = regCards.MIRA.color;
    break;
  case 35:
    regInfo.title = regCards.MIType2.title;
    regInfo.localDescription = regCards.MIType2.description;
    regInfo.borderColor = regCards.MIType2.color;

    regs.push(gearRestrictionCard);
    break;
  case 34:
    regInfo.title = regCards.MIType1.title;
    regInfo.localDescription = regCards.MIType1.description;
    regInfo.borderColor = regCards.MIType1.color;

    regs.push(gearRestrictionCard);
    break;
  case 33:
    regInfo.title = regCards.MIGR.title;
    regInfo.localDescription = regCards.MIGR.description;
    regInfo.borderColor = regCards.MIGR.color;

    regs.push(btraCard);
    break;
  case 32:
    regInfo.title = regCards.MIType1.title;
    regInfo.localDescription = regCards.MIType1.description;
    regInfo.borderColor = regCards.MIType1.color;

    regs.push(btraCard);
    break;
  case 31:
    regInfo.title = regCards.MIType4.title;
    regInfo.localDescription = regCards.MIType4.description;
    regInfo.borderColor = regCards.MIType4.color;

    regs.push(btraCard);
    break;
  case 30:
    regInfo.title = regCards.MIType2.title;
    regInfo.localDescription = regCards.MIType2.description;
    regInfo.borderColor = regCards.MIType2.color;
    break;
  case 40:
    regInfo.title = regCards.MOBlueRibbon.title;
    regInfo.localDescription = regCards.MOBlueRibbon.description;
    regInfo.borderColor = regCards.MOBlueRibbon.color;
    break;
  case 41:
    regInfo.title = regCards.MOTroutPark.title;
    regInfo.localDescription = regCards.MOTroutPark.description;
    regInfo.borderColor = regCards.MOTroutPark.color;
    break;
  case 42:
    regInfo.title = regCards.MOWhiteRibbon.title;
    regInfo.localDescription = regCards.MOWhiteRibbon.description;
    regInfo.borderColor = regCards.MOWhiteRibbon.color;
    break;
  case 43:
    regInfo.title = regCards.MOLakeTaneycomo.title;
    regInfo.localDescription = regCards.MOLakeTaneycomo.description;
    regInfo.borderColor = regCards.MOLakeTaneycomo.color;
    break;
  case 44:
    regInfo.title = regCards.MORedRibbon.title;
    regInfo.localDescription = regCards.MORedRibbon.description;
    regInfo.borderColor = regCards.MORedRibbon.color;
    break;
  case 50:
    regInfo.title = 'Keystone Select Stocked Stream';
    regInfo.localDescription = reg_descr;
    regInfo.borderColor = colors.yellow;
    regInfo.backgroundColor = colors.white;
    break;
  case 51:
    regInfo.title = 'Best Trout Opportunities';
    regInfo.localDescription = reg_descr;
    regInfo.borderColor = colors.orange;
    regInfo.backgroundColor = colors.orange;
    break;
  case 67:
    regInfo.title = class_title;
    regInfo.localDescription = reg_descr;
    regInfo.borderColor = colors.green;
    regInfo.backgroundColor = colors.white;
    break;
  case 68:
    regInfo.title = class_title;
    regInfo.localDescription = reg_descr;
    regInfo.borderColor = colors.purple;
    regInfo.backgroundColor = colors.white;
    break;
  case 88:
    regInfo.title = 'Heavily Stocked Stream';
    regInfo.localDescription = 'Designated Heavily Stocked Section by Georgia Department of Natural Resources (DNR)';
    regInfo.borderColor = colors.yellow;
    break;
  case 80:
    regInfo.title = 'Wild Trout Stream';
    regInfo.localDescription = 'Designated Wild Trout Stream of Tennessee. TN TWRA: Tennessee is fortunate to have an abundant wild trout resource.  The Appalachian Mountain range in East Tennessee has approximately 845 miles of stream that support wild populations of Brook, Rainbow, and Brown trout. Most of these streams can be found on public land within the Cherokee National Forest (420 miles of stream) and Great Smoky Mountains National Park (245 miles of stream).  The remainder occurs on private property.';
    regInfo.borderColor = colors.blue;
    regInfo.backgroundColor = colors.white;
    break;
  case 74:
    regInfo.title = 'Wild Trout Waters';
    regInfo.localDescription = 'Designated Wild Trout Stream of North Carolina.';
    regInfo.borderColor = colors.blue;
    regInfo.backgroundColor = colors.white;
    break;
  case 75:
    regInfo.title = 'Wild/Natural Bait Waters';
    regInfo.localDescription = 'Designated Wild/Natural Bait Watersof North Carolina.';
    regInfo.borderColor = colors.brown;
    regInfo.backgroundColor = colors.brown;
    break;
  case 72:
    regInfo.title = 'Hatchery Supported Trout Waters';
    regInfo.localDescription = 'Designated Hatchery Supported Trout Waters of North Carolina.';
    regInfo.borderColor = colors.green;
    regInfo.backgroundColor = colors.green;
    break;
  case 77:
    regInfo.title = 'Catch and Release';
    regInfo.localDescription = 'Designated Catch and Release Section. Restrictions and regulations apply. Read regulations below.';
    regInfo.borderColor = colors.red;
    regInfo.backgroundColor = colors.red;
    break;
  case 53:
    regInfo.title = 'Delayed Harvest';
    regInfo.localDescription = 'Designated Delayed Harvest Section. Stocking and fishing restrictions apply. See regulations below.';
    regInfo.backgroundColor = colors.red;
    break;
  case 73:
    regInfo.title = 'Catch and Release';
    regInfo.localDescription = 'Designated Catch and Release Section. Restrictions and regulations apply. Read regulations below.';
    regInfo.borderColor = colors.red;
    break;
  case 81:
    regInfo.title = 'Tailwater Trout Stream';
    regInfo.localDescription = 'Designated Wild Trout Stream of Tennessee. TN TWRA: Tennessee is fortunate to have an abundant wild trout resource.  The Appalachian Mountain range in East Tennessee has approximately 845 miles of stream that support wild populations of Brook, Rainbow, and Brown trout. Most of these streams can be found on public land within the Cherokee National Forest (420 miles of stream) and Great Smoky Mountains National Park (245 miles of stream).  The remainder occurs on private property.';
    regInfo.borderColor = colors.blue;
    regInfo.backgroundColor = colors.white;
    break;
  case 90:
    regInfo.title = 'Delayed Harvest';
    regInfo.localDescription = 'Delayed Harvest Designation - October 1 through May 31. From Virginia DWR: The delayed harvest program was launched in 1989 with the intent to incorporate aspects of both catch-and-release and put-and-take trout fishing. Streams in this program are generally large and provide good trout habitat. However, water temperature often limits trout survival through the summer months. Catchable-size trout are stocked typically once in fall, winter, and spring. From October 1 through the following May 31, only artificial lures may be used and all fish must be released unharmed. From June 1 through September 30, general trout regulations are in effect, and trout may be harvested. A trout license is required to fish these waters from October 1 through June 15. There are currently 14 streams in the delayed harvest program.';
    regInfo.showSpecialRegs = true;
    regInfo.backgroundColor = colors.red;
    break;
  case 91:
    regInfo.title = 'Fee Fishing';
    regInfo.localDescription = 'State of Virginia Fee Fishing Designation. Typically for frequently stocked bodies of water that require additional local fees for fishing. From Virginia DWR: The fee fishing, or “pay-as-you-go” trout program, began in 1964 when the Clinch Mountain Fee Fishing Area opened. Today, DWR manages three fee-fishing areas located in western Virginia: Clinch Mountain, Crooked Creek, and the Douthat Lake Fee Fishing Areas. The primary goal of the fee-fishing program was to provide inexpensive trout fishing opportunities for vacationers, both resident and non-resident. Today, these areas also provide anglers an opportunity to experience a more traditional “opening day” as well as to fish for frequently stocked trout throughout the season.\n \nDuring the fee season, anglers may fish one of the fee areas with a basic freshwater fishing license and a daily fishing permit ($8), which is required of all anglers over the age of 12. Revenue generated from the daily permit sales are used to offset the cost of trout production and stocking at the fee areas. Outside of the fee season, these areas revert to designated stocked trout waters, and a trout license is required instead of a daily permit. See more details at: virginiawildlife.gov/fishing/trout/fee-fishing-areas/ \n \nPopular with anglers, the program provides a quality experience for catchable stocked trout, particularly through the summer after stocking has ceased on the general designated trout waters.';
    regInfo.backgroundColor = colors.orange;
    break;
  case 92:
    regInfo.title = 'Put and Take';
    regInfo.localDescription = 'State of Virginia Put and Take Stocking Designation. Put-and-take stocked trout fishing—where anglers are free to “take” trout from waters into which trout have been stocked, or “put”—draws the most interest and attention from anglers because of the availability of catchable-size trout, liberal gear restrictions, and ease of capture.  Eighty-nine percent of the waters stocked with catchable-size trout currently fall under the put-and-take category.';
    regInfo.backgroundColor = colors.green;
    break;
  case 93:
    regInfo.title = 'Youth Only';
    regInfo.localDescription = 'State of Virginia Youth Fishing Program. Special Regulations Apply: The waters listed below have been designated by the Director and will only be considered Youth-Only Stocked Trout Waters from April 1 through June 15. Only youth age 15 and under will be allowed to fish during that time period. Adults can assist youth only by baiting the hook, casting, and removing the fish from the hook. Adults cannot assist with setting the hook or retrieving the fish. The daily creel limit shall be 3 trout. Adults assisting youth are not required to have a fishing license or a trout license. Parking or access fees may be required at some locations. \n \nYouth-Only Waters will be stocked 3 times between April 1 and June 1. Stocking times may vary depending on the distance from the hatchery to the receiving water. DWR will strive to stock between 10:00 am and 2:00 pm. DWR may postpone or change the date of any of these stocking events due to circumstances that compromise the resource or public safety.';
    regInfo.backgroundColor = colors.systemPink;
    break;
  case 95:
    regInfo.title = 'Fly Fishing Only';
    regInfo.localDescription = 'West Virginia - Fly Fishing Only Designation. The following rules apply to FF Only Sections: \n1. Fishing permitted during daylight hours only.\n2. Only artificial flies and streamers may be used or in possession on any of the fly fishing-only streams.\n3. Only conventional fly fishing tackle may be used.\n4. Open-face and closed-face spinning or spincast reels are prohibited.\n5. All fish caught must be returned to water at once.\n6. No trout shall be in the angler’s possession while on stream sections designated as fly-fishing-only streams.\n7. No lure or fly with any scent, oil, or edible enticement added onto, or impregnated into (regardless if the scent is added in the manufacturing process or applied afterward), may be used or possessed on any fly-fishing-only trout stream.\n8. Powerbait, Gulp and other manufactured scented baits are considered illegal under this section and may not be used or possessed on catch-and-release streams.';
    regInfo.backgroundColor = colors.orange;
    break;
  case 96:
    regInfo.title = 'General Stream Class';
    regInfo.localDescription = 'West Virginia - General Trout Designation';
    regInfo.backgroundColor = colors.green;
    regInfo.borderColor = colors.black;
    break;
  case 97:
    regInfo.title = 'Catch and Release';
    regInfo.localDescription = 'West Virginia - Catch and Release Designation. For these waters, the following rules apply: \n1. Fishing permitted during daylight hours only, except that on the three North Branch of the Potomac River Catch-and-Release Trout Fishing Areas fishing is permitted between the hours of 5:30 a.m. and 10 p.m. only.\n2. Only artificial flies and lures made of metal, wood, feathers, hair, or synthetic material may be used or possessed on catch-and-release trout streams. No lure or fly with any scent, oil, or edible enticement added onto, or impregnated into (regardless if the scent is added in the manufacturing process or applied afterward), may be used or possessed on any catch-and-release trout stream. \n3. Multiple hook lures must have barbless hooks (except on the North Branch of the Potomac River Catch & Release areas).\n4. Single hook lures may have barbed hooks.\n5. All trout caught must be returned to the water at once.\n6. No trout shall be in the angler’s possession while on stream sections designated as catch-and-release streams.n7. Powerbait, Gulp and other manufactured scented baits are considered illegal under this section and may not be used or possessed on catch-and-release streams.';
    regInfo.backgroundColor = colors.red;
    regInfo.borderColor = colors.black;
    break;
  case 98:
    regInfo.title = 'Children and Q Program';
    regInfo.localDescription = 'West Virginia - Children Fishing Program. Restrictions Apply. From WVDNR: These sections are intended for children and those with physical limitations. Specifically, the following rules apply. \n1. Areas are managed under special regulations March-May.\n2. During this period fishing is restricted to children 14 years of age or under and to people with disabilities holding a Class Q permit.\n3. Fishing permitted during daylight hours only.\n4. Creel limit is four trout.\n5. Another person may assist with baiting hooks and removing fish.';
    regInfo.backgroundColor = colors.purple;
    break;
  default:
    regInfo.showSpecialRegs = true;
    break;
  }

  return regs;
};

export const retrieveRegsInfoForStream = (map, bbox) => {
  const regs = map.queryRenderedFeatures(bbox, { layers: ['trout_regs'] });
  return regs.map((reg) => processRegulation(reg)).flat();
};

const processSeasons = (seasons) => {
  const filteredSeasons = seasons.filter((season) => season.properties.layer_type !== 2);
  const seasonInfo = [];

  if(filteredSeasons.length > 0) {
    filteredSeasons.forEach((s) => {
      seasonInfo.push({
        name: s.properties.name,
        gen_season: s.properties.gen_season,
        access_summary: s.properties.access_summary,
        access_streambed: s.properties.access_streambed,
        access_floating: s.properties.access_floating,
        access_portage: s.properties.access_portage,
        access_navigability: s.properties.access_navigability,
        reglink: s.properties.reglink,
      });
    });
  }

  return seasonInfo;
};

const processFlyShops = (shops) => {
  const filteredShops = shops.filter((shop, index, self) => shop.properties.layer_type === 2 && index === self.findIndex((t) => (
    t.properties.id === shop.properties.id
  )));
  const flyShops = [];

  if(filteredShops.length > 0) {
    filteredShops.forEach((s) => {
      flyShops.push(s.properties);
    });
  }

  return flyShops;
};

export const retrieveStreamMetadata = (map, bbox) => {
  const result = map.queryRenderedFeatures(bbox, { layers: ['supported_states'] });
  return { seasons: processSeasons(result), flyShops: processFlyShops(result) };
};
