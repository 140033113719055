import { useSelector } from 'react-redux';
import { accountTypesEnum } from 'lib/constants';

const useIsPro = () => {
  const { accountType } = useSelector((state) => state.auth.user);

  return accountType === accountTypesEnum.trial || accountType === accountTypesEnum.pro;
};

export default useIsPro;
