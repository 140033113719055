/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import { triangleDown, triangleUp } from 'assets';
import ContentDrawer from './ContentDrawer';
import styles from './styles.module.scss';

const ContentSection = ({
  icon, title, count, children,
}) => {
  const [active, setActive] = useState(false);
  const toggle = () => { setActive((a) => !a); };

  return (
    <>
      <div className={styles['content-section']} onClick={toggle}>
        <div className={styles.row}>
          <Image src={icon} alt={title} width={43} height={30} minHeight={30} />
          <div className={styles['content-title']}>{`${title} (${count})`}</div>
        </div>

        {active ? (
          <Image
            src={triangleUp}
            alt={title}
            height={20}
            minHeight={20}
            objectFit="cover"
            className={styles.triangle}
          />
        ) : (
          <Image
            src={triangleDown}
            alt={title}
            height={20}
            minHeight={20}
            objectFit="cover"
            className={styles.triangle}
          />
        )}
      </div>
      <div className={styles['content-divider']} />

      <ContentDrawer visible={active}>
        {children}
      </ContentDrawer>
    </>
  );
};

ContentSection.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  count: PropTypes.number,
  children: PropTypes.node,
};

export default ContentSection;
