import { ActionTypes } from 'actions';

const initialState = {
  isPaymentLoading: false,
  error: '',
  result: {},
  isPaymentModalShown: false,
  isCancelModalShown: false,
  isUpdateModalShown: false,
  coupon: {},
  couponMessage: '',
  percentOff: null,
  price: null,
};

const PaymentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
  case ActionTypes.SET_PAYMENT_ERROR:
    return { ...state, error: payload };
  case ActionTypes.SET_PAYMENT_LOADING:
    return { ...state, isPaymentLoading: payload };
  case ActionTypes.SET_PAYMENT_RESULT:
    return { ...state, result: payload };
  case ActionTypes.SET_IS_PAYMENT_MODAL_SHOWN:
    return { ...state, isPaymentModalShown: payload };
  case ActionTypes.SET_IS_CANCEL_MODAL_SHOWN:
    return { ...state, isCancelModalShown: payload };
  case ActionTypes.SET_IS_UPDATE_MODAL_SHOWN:
    return { ...state, isUpdateModalShown: payload };
  case ActionTypes.SET_COUPON:
    return { ...state, coupon: payload };
  case ActionTypes.SET_COUPON_MESSAGE:
    return { ...state, couponMessage: payload };
  case ActionTypes.SET_PERCENT_OFF:
    return { ...state, percentOff: payload };
  case ActionTypes.SET_PRICE:
    return { ...state, price: payload };
  default:
    return state;
  }
};

export default PaymentReducer;
