import React from 'react';
import { useSelector } from 'react-redux';
import { colors } from 'services/regulations';
import styles from './styles.module.scss';

const specialRegulation = {
  title: 'Special Regulations',
  localDescription: 'Special Regulations apply to sections on this stream. Please read below for further information.',
  color: colors.orange,
};

const compareStrings = (streamName, queryString) => {
  if (!queryString || !streamName) return false;

  const streamNameLower = streamName.toLowerCase();
  const queryStringLower = queryString.toLowerCase();
  const keywords = ['little', 'branch', 'tributary', 'west', 'east', 'south', 'north', 'river'];
  keywords.forEach((keyword) => {
    if ((streamNameLower.includes(keyword) && !queryStringLower.includes(keyword))
    || (!streamNameLower.includes(keyword) && queryStringLower.includes(keyword))) {
      return false;
    }
  });
  const queryStrings = queryStringLower.split(' ');
  for (let i = queryStrings.length - 1; i >= 0; i -= 1) {
    if (keywords.concat(['stream', 'CR']).includes(queryStrings[i])) {
      queryStrings.splice(i, 1);
    }
  }
  return queryStrings.some((queryString) => streamNameLower.includes(queryString));
};

const Regulations = () => {
  const { streamRegulations, stream } = useSelector((state) => state.map);
  const { primaryLabel } = stream;
  const showSpecialRegs = streamRegulations.some((reg) => reg.showSpecialRegs === true);

  const filteredRegs = streamRegulations
    .filter((reg) => compareStrings(primaryLabel, reg.stream_name) || compareStrings(primaryLabel, reg.water_name))
    .filter((reg, i) => streamRegulations.findIndex((r) => r.title === reg.title) === i)
    .filter((reg) => !!reg.title);

  const filteredDetailRegs = streamRegulations
    .filter((reg) => compareStrings(primaryLabel, reg.stream_name) || compareStrings(primaryLabel, reg.water_name))
    .filter((reg, i) => streamRegulations.findIndex((r) => r.reg_title === reg.reg_title) === i);

  return (
    <div className={styles['stream-regs']}>
      {filteredRegs
        .map((reg) => {
          return (
            <div className={styles.reg} key={reg.title}>
              <div className={styles['reg-box']} style={{ backgroundColor: reg.color, borderColor: reg.borderColor }}>{reg.title}</div>
              <div className={styles['reg-description']}>{reg.localDescription}</div>
            </div>
          );
        })}

      {showSpecialRegs && (
        <div className={styles.reg}>
          <div className={styles['reg-box']} style={{ backgroundColor: specialRegulation.color }}>{specialRegulation.title}</div>
          <div className={styles['reg-description']}>{specialRegulation.localDescription}</div>
        </div>
      )}

      <h4>Regulation Description</h4>
      {filteredDetailRegs.map((reg, i) => {
        const regStreamName = reg.stream_name || reg.water_name;

        return (
          <div style={{ marginBottom: 20 }} key={regStreamName}>
            {!!reg.reg_title && <p>{`${regStreamName} - ${reg.reg_title}`}</p>}
            {!!reg.reg_descr && <p>{`Regulation: ${reg.reg_descr}`}</p>}
            {!!reg.season_desc && <p>{`Normal Season: ${reg.season_desc}`}</p>}
            {!!reg.ext_link && filteredDetailRegs.findLastIndex((r) => r.ext_link === reg.ext_link) === i
            && (
              <div>
                <p style={{ marginBottom: 0, marginTop: 10 }}>More Info:</p>
                <p style={{ overflowWrap: 'break-word', margin: 0 }}><a href={reg.ext_link} target="_blank" rel="noreferrer">{reg.ext_link}</a></p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Regulations;
