import React, { useState, useEffect } from 'react';
import CreateDialog from 'components/CreateDialog';
import { useSelector, useDispatch } from 'react-redux';
import { lineColors, lineWidths, lineTypes } from 'lib/constants';
import {
  resetCurrentLine, createLine, setDelayedPanelShown,
} from 'actions';
import useUpload from 'utils/useUpload';
import { PhotoDisplay, AddPhotoButton } from 'components/Photos';
import useIsPro from 'utils/useIsPro';
import ColorButton from './ColorButton';
import SelectButton from './SelectButton';

const CreateLine = () => {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const draw = useSelector((state) => state.map.draw);
  const { tempLine } = useSelector((state) => state.map.line);

  const {
    images, handleChange, setImages, loading, handleUpload,
  } = useUpload();

  const [lineName, setLineName] = useState('');
  const [currentColor, setCurrentColor] = useState(lineColors[0]);
  const [currentWidth, setCurrentWidth] = useState(lineWidths[1]);
  const [currentType, setCurrentType] = useState(lineTypes[0]);
  const [notes, setNotes] = useState('');

  const close = () => {
    dispatch(resetCurrentLine());
    setImages([]);
  };

  const onDeleteImage = (imageObject) => {
    setImages((prev) => prev.filter((v) => v !== imageObject));
  };

  const onSubmit = () => {
    dispatch(createLine({
      colorIndex: currentColor.index, title: lineName, note: notes, geometry: tempLine.geometry, widthIndex: currentWidth.index, typeIndex: currentType.index,
    }, null, (id) => {
      handleUpload(id, 'line');
      close();
    }));
  };

  useEffect(() => {
    if (!tempLine) {
      setCurrentColor(lineColors[0]);
      setCurrentWidth(lineWidths[1]);
      setCurrentType(lineTypes[0]);
      setNotes('');
      setLineName('');
      dispatch(setDelayedPanelShown(false));
    } else {
      setTimeout(() => { dispatch(setDelayedPanelShown(true)); }, 600);
    }
  }, [tempLine]);

  useEffect(() => {
    if (tempLine) {
      draw.setFeatureProperty(tempLine.id, 'color', currentColor.color);
      draw.setFeatureProperty(tempLine.id, 'width', currentWidth.width);
      draw.setFeatureProperty(tempLine.id, 'dashType', currentType.index);

      const line = draw.get(tempLine.id);
      draw.delete(tempLine.id);
      draw.add(line);
    }
  }, [currentColor, currentWidth, currentType, tempLine, draw]);

  if (!tempLine) return null;

  return (
    <CreateDialog title="Create Line" close={close}>
      <CreateDialog.Section title="Line Name" widthConstrained>
        <CreateDialog.InputField
          name="name"
          onChange={(e) => { setLineName(e.target.value); }}
          value={lineName}
          label="Name"
        />
      </CreateDialog.Section>

      <CreateDialog.Section title="Color">
        <CreateDialog.Selector items={lineColors} renderItem={(item) => (<ColorButton color={item.color} onClick={() => { setCurrentColor(item); }} key={item.color} active={currentColor.color === item.color} />)} />
      </CreateDialog.Section>

      <CreateDialog.Row>
        <CreateDialog.Section title="Width" fitRow>
          <CreateDialog.Selector items={lineWidths}
            renderItem={(item) => (
              <SelectButton
                icon={item.icon}
                onClick={() => { setCurrentWidth(item); }}
                active={currentWidth.width === item.width}
                key={item.width}
              />
            )}
          />
        </CreateDialog.Section>
        <CreateDialog.Section title="Line Type" fitRow>
          <CreateDialog.Selector items={lineTypes}
            renderItem={(item) => (
              <SelectButton
                icon={item.icon}
                onClick={() => { setCurrentType(item); }}
                active={currentType.dashArray === item.dashArray}
              />
            )}
          />
        </CreateDialog.Section>
      </CreateDialog.Row>

      <CreateDialog.Section title="Notes">
        <CreateDialog.MultiLineField
          name="notes"
          label="Notes"
          value={notes}
          onChange={(e) => { setNotes(e.target.value); }}
        />
      </CreateDialog.Section>

      {isPro && (
        <CreateDialog.Section title="Photos" headerIcon={<AddPhotoButton id="create-marker" onChange={handleChange} />}>
          <PhotoDisplay images={images} onDelete={onDeleteImage} />
        </CreateDialog.Section>
      )}

      <CreateDialog.SubmitSection
        buttons={[{ label: 'Save', onClick: onSubmit }]}
        disabled={loading}
      />
    </CreateDialog>
  );
};

export default CreateLine;
