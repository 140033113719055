import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { droppedPin } from 'assets';
import { mapMarkerTypes } from 'lib/constants';

import CreateDialog from 'components/CreateDialog';
import IconButton from 'components/CreateMarker/IconButton';
import styles from './styles.module.scss';
import UploadZone from './UploadZone';

const FileUploadSection = () => {
  const [selectedMarkerTypeIndex, setSelectedMarkerTypeIndex] = useState(0);
  const dispatch = useDispatch();
  const { isFileUploadSectionShown } = useSelector((state) => state.map);
  const [files, setFiles] = useState([]);

  if (!isFileUploadSectionShown) return null;

  return (
    <>
      <div className={styles['file-upload-section']}>
        <div className={styles.header}>
          <img src={droppedPin} alt="Dropped Pin" className={styles['dropped-pin-icon']} />
          <h4>Import Data from External Files</h4>
        </div>
        <p>Add markers, lines, and areas from a GPS, watch, or other apps.</p>

        <CreateDialog.Section title="Marker Type">
          <CreateDialog.Selector
            items={[...mapMarkerTypes].sort((a, b) => a.displayPosition - b.displayPosition)}
            renderItem={(item) => (
              <IconButton
                key={item.shortName}
                name={item.shortName}
                icon={item.img}
                onClick={() => { setSelectedMarkerTypeIndex(item.index); }}
                isSelected={selectedMarkerTypeIndex === item.index}
              />
            )}
          />
        </CreateDialog.Section>

        <div className={styles.files}>
          {files.map((file) => (
            <div key={file.path} className={styles.file}>
              <p>{file.name}</p>
            </div>
          ))}
        </div>
        <div className={styles.uploadContent}>
          <UploadZone selectedMarkerType={selectedMarkerTypeIndex} />
        </div>
      </div>
    </>
  );
};

export default FileUploadSection;
