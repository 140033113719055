import React, { useEffect } from 'react';
import BottomRightDialog from 'components/BottomRightDialog';
import ClipLoader from 'react-spinners/ClipLoader';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsGageChartShown, setCurrentGageUSGSData, setChartTime, setChartType,
} from 'actions';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { gageChartTimes, gageChartTypes } from 'lib/constants';
import Selector from './Selector';
import { store } from '../../index';
import styles from './styles.module.scss';

const GageChart = () => {
  const dispatch = useDispatch();
  const {
    isGageChartShown, gage, gageData, currentTime, currentChartType,
  } = useSelector((state) => state.map);
  const isFlow = currentChartType === gageChartTypes.flow;
  const close = () => {
    const { tapMarker } = store.getState().map.marker;
    const { map } = store.getState().map;
    map.fire('closeAllPopups');
    if (tapMarker) {
      tapMarker.remove();
    }
    dispatch(setIsGageChartShown(false));
  };

  const timeFilter = (result) => {
    switch (currentTime) {
    case gageChartTimes.day: {
      const diff = new Date().getTime() - new Date(result.dateTime).getTime();
      const minutes = diff / (1000 * 60);
      return minutes < 1440;
    }
    default:
      return true;
    }
  };

  useEffect(() => {
    if (gage && gage?.properties?.STAID !== gageData.flowId) {
      dispatch(setCurrentGageUSGSData());
    }
  }, [gage, gageData]);

  if (!isGageChartShown) return null;

  if (isGageChartShown && (gage?.properties?.STAID !== gageData.flowId || gage?.properties?.STAID !== gageData.heightId)) {
    return (
      <BottomRightDialog close={close} title="USGS Charts" backgroundColor="white">
        <div className={styles.progress}>
          <ClipLoader color="#3773B8" loading size={80} />
        </div>
      </BottomRightDialog>
    );
  }

  const data = {
    labels: isFlow
      ? [...gageData.flow.filter((result) => result.value !== '-999999').filter(timeFilter).map((result) => moment(result.dateTime).format('MM/DD/yyyy HH:mm:ss'))]
      : [...gageData.height.filter((result) => result.value !== '-999999').filter(timeFilter).map((result) => moment(result.dateTime).format('MM/DD/yyyy HH:mm:ss'))],
    datasets: [
      {
        label: isFlow ? 'Flow' : 'Height',
        borderColor: 'rgb(168, 197, 236)',
        backgroundColor: 'rgb(238, 244, 251)',
        pointRadius: 0,
        data: isFlow
          ? [...gageData.flow.filter((result) => result.value !== '-999999').filter(timeFilter).map((result) => result.value)]
          : [...gageData.height.filter((result) => result.value !== '-999999').filter(timeFilter).map((result) => result.value)],
      },
    ],
  };

  return (
    <BottomRightDialog close={close} title="USGS Charts" backgroundColor="white">
      <div className={styles['top-selector']}>
        <Selector
          items={[{ label: 'Month', value: gageChartTimes.month }, { label: 'Day', value: gageChartTimes.day }]}
          onSelect={(val) => { dispatch(setChartTime(val)); }}
          value={currentTime}
        />
        <Selector
          items={[{ label: 'Flow', value: gageChartTypes.flow }, { label: 'Height', value: gageChartTypes.height }]}
          value={currentChartType}
          onSelect={(val) => { dispatch(setChartType(val)); }}
        />
      </div>

      <Line data={data}
        options={{
          legend: { display: false },
          scales: {
            xAxes: [{
              display: false, // this will remove all the x-axis grid lines
            }],
          },
          tooltips: {
            enabled: true,
            intersect: false,
            callbacks: {
              label: (item) => { return `${item.yLabel} ${isFlow ? 'cfs' : 'ft'}`; },
            },
          },
        }}
      />
    </BottomRightDialog>
  );
};

export default GageChart;
