import React from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import ThreeButtons from 'components/ThreeButtons';
import {
  distanceIcon, elevationIcon,
} from 'assets';
import styles from './styles.module.scss';

// const Popup = ({
//   title, subtitle, leftButton, rightButton, iconURL,
// }) => {
//   return (
//     <div className={styles.popup}>
//       <div className={styles.content}>
//         {iconURL && <Image alt={title} src={iconURL} width="20%" objectFit="contain" />}

//         <div className={styles.text}>
//           <h5>{title}</h5>
//           <h6>{subtitle}</h6>
//         </div>
//       </div>

//       <TwoButtons leftButton={leftButton} rightButton={rightButton} />
//     </div>
//   );
// };

const PopupExt = ({
  title, subtitle, type, distance, elevation, description, leftButton, middleButton, rightButton, iconURL, ext_link,
}) => {
  return (
    <div className={styles.popup}>
      <div className={styles.content}>
        {iconURL && <Image alt={title} src={iconURL} width="20%" objectFit="contain" />}

        <div className={styles.text}>
          <h5>{title}</h5>
          <h6>{subtitle}</h6>
          <p>{type}</p>
          {description && <h7>{description}</h7>}
          {ext_link && <div><a className={styles['ext-link']} href={ext_link}>{ext_link}</a></div>}
        </div>
      </div>
      <div className={styles.distances}>
        <div className={styles.distanceInner}>
          <Image alt="Distance" src={distanceIcon} width="20" />
          <p>{distance > 10 ? distance.toLocaleString('en-US', { minimumFractionDigits: 0 }) : distance.toLocaleString('en-US', { minimumFractionDigits: 1 })} mi. away</p>
        </div>
        <div className={styles.distanceInner}>
          <Image alt="Elevation" src={elevationIcon} width="20" />
          <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft elev.</p>
        </div>
      </div>

      <ThreeButtons leftButton={leftButton} middleButton={middleButton} rightButton={rightButton} />
    </div>
  );
};

PopupExt.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  distance: PropTypes.number,
  elevation: PropTypes.string,
  leftButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  middleButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  rightButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  iconURL: PropTypes.string,
};

export default PopupExt;
