import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Header from './Header';
import styles from './styles.module.scss';

const BottomRightButton = ({ onClick, title }) => {
  return (
    <button type="button" onClick={onClick}>
      <h3 className={styles.title}>{title}</h3>
    </button>
  );
};

BottomRightButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default BottomRightButton;
