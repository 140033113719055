import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cancelSubscription } from 'actions';
import { FormHelperText } from '@material-ui/core';
import styles from './styles.module.scss';

const CancelScreen = ({ close, toggleCloseDisabled }) => {
  const dispatch = useDispatch();
  const { error, result, isPaymentLoading } = useSelector((state) => state.payment);

  useEffect(() => {
    if (result?.message) close();
  }, [result]);

  useEffect(() => {
    if (isPaymentLoading) toggleCloseDisabled(true);
    else toggleCloseDisabled(false);
  }, [isPaymentLoading]);

  let errorMessage = error;
  if (error === 'User not found in subscriptions.') errorMessage = 'If you purchased this subscription from our main site, visit https://troutinsights.com/my-account/subscriptions/ to cancel.';

  return (
    <div className={styles.payment}>
      <h4 className={styles['cancel-title']}>Cancel Subscription</h4>
      <FormHelperText error>{errorMessage || ' '}</FormHelperText>
      <p className={styles['cancel-desc']}>Are you sure you want to cancel your TroutRoutes Pro subscription?</p>
      <p className={styles['cancel-desc']}>Your account will immediately revert to a Basic account and your payment information will be removed.</p>

      <button className={styles['cancel-button']} type="button" disabled={isPaymentLoading} onClick={() => { dispatch(cancelSubscription()); }}>Cancel</button>
    </div>
  );
};

export default CancelScreen;
