import { ActionTypes } from 'actions';
import * as stripeService from 'services/stripe';
import * as firebaseService from 'services/firebase';
import ReactGA from 'react-ga4';

export const initiatePayment = (paymentMethod, name, emailAddress, address, city, state, zip, price, callback, hideError = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ActionTypes.CLEAR_AUTH_ERROR });
      dispatch({ type: ActionTypes.SET_PAYMENT_LOADING, payload: true });

      let email;
      if (emailAddress) {
        email = emailAddress;
      } else {
        const { email: emailAddress } = getState().auth.user;
        email = emailAddress;
      }

      const { coupon } = getState().payment;
      if (!email) throw new Error('Invalid user credentials.');

      const info = await stripeService.makePayment(paymentMethod, name, email, coupon?.id, address, city, state, zip, price);
      dispatch({ type: ActionTypes.SET_PAYMENT_RESULT, payload: info });
      // await firebaseService.addPurchase({
      //   email, firstName: `${name.split(' ')[0]}`, lastName: `${name.split(' ')[name.split(' ').length - 1] || ''}`, id: info.subscriptionId,
      // });
      ReactGA.event('purchase_made');
      firebaseService.analytics.logEvent('purchase_completed', {
        subscription_id: info.subscriptionId,
        customer_id: info.customerId,
      });
      callback({ subscriptionId: info.subscriptionId });
    } catch (e) {
      if (!hideError || e?.response?.status !== 401) dispatch({ type: ActionTypes.SET_PAYMENT_ERROR, payload: e?.response?.data?.error?.message || e.message });
      callback(null, e);
    } finally {
      dispatch({ type: ActionTypes.SET_PAYMENT_LOADING, payload: false });
    }
  };
};

export const updatePayment = (paymentMethod, emailAddress) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ActionTypes.SET_PAYMENT_LOADING, payload: true });

      let email;
      if (emailAddress) {
        email = emailAddress;
      } else {
        const { email: emailAddress } = getState().auth.user;
        email = emailAddress;
      }

      if (!email) throw new Error('Invalid user credentials.');

      const info = await stripeService.updatePayment(paymentMethod, email);
      dispatch({ type: ActionTypes.SET_PAYMENT_RESULT, payload: info });
    } catch (e) {
      dispatch({ type: ActionTypes.SET_PAYMENT_ERROR, payload: e?.response?.data?.error?.message || e.message });
    } finally {
      dispatch({ type: ActionTypes.SET_PAYMENT_LOADING, payload: false });
    }
  };
};

export const cancelSubscription = (useSubscription) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ActionTypes.SET_PAYMENT_LOADING, payload: true });
      let data;
      if (useSubscription) {
        data = await stripeService.cancelSubscription(null, getState().payment.result.subscriptionId);
      } else {
        data = await stripeService.cancelSubscription();
      }

      dispatch({ type: ActionTypes.SET_PAYMENT_RESULT, payload: data });
    } catch (e) {
      dispatch({ type: ActionTypes.SET_PAYMENT_ERROR, payload: e?.response?.data?.error?.message || e.message });
    } finally {
      dispatch({ type: ActionTypes.SET_PAYMENT_LOADING, payload: false });
    }
  };
};

export const setPaymentError = (error) => {
  return { type: ActionTypes.SET_PAYMENT_ERROR, payload: error };
};

export const setPaymentLoading = (loading) => {
  return { type: ActionTypes.SET_PAYMENT_LOADING, payload: loading };
};

export const setIsPaymentModalShown = (isShown) => {
  return { type: ActionTypes.SET_IS_PAYMENT_MODAL_SHOWN, payload: isShown };
};

export const setIsCancelModalShown = (isShown) => {
  return { type: ActionTypes.SET_IS_CANCEL_MODAL_SHOWN, payload: isShown };
};

export const setIsUpdateModalShown = (isShown) => {
  return { type: ActionTypes.SET_IS_UPDATE_MODAL_SHOWN, payload: isShown };
};

export const clearCoupon = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_COUPON, payload: {} });
    dispatch({ type: ActionTypes.SET_COUPON_MESSAGE, payload: '' });
  };
};

export const tryCouponCode = (code) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.SET_PAYMENT_LOADING, payload: true });

      const coupon = await stripeService.retrieveCoupon(code);
      dispatch({ type: ActionTypes.SET_COUPON, payload: coupon.code });
      dispatch({ type: ActionTypes.SET_COUPON_MESSAGE, payload: coupon.message });
      dispatch({ type: ActionTypes.SET_PERCENT_OFF, payload: coupon?.code?.coupon?.percent_off });
    } catch (e) {
      dispatch({ type: ActionTypes.SET_COUPON_MESSAGE, payload: e?.response?.data?.error?.message || e.message });
    } finally {
      dispatch({ type: ActionTypes.SET_PAYMENT_LOADING, payload: false });
    }
  };
};

export const loadPrice = () => {
  return async (dispatch) => {
    try {
      const price = await stripeService.retrievePrice();
      dispatch({ type: ActionTypes.SET_PRICE, payload: price });
    } catch (e) {
      dispatch({ type: ActionTypes.SET_PAYMENT_ERROR, payload: e?.response?.data?.error?.message || e.message });
    }
  };
};

export const setPaymentInfo = (info) => {
  return { type: ActionTypes.SET_PAYMENT_RESULT, payload: info };
};
