/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import styles from './styles.module.scss';

const SelectButton = ({ onClick, active, icon }) => {
  return (
    <button className={`${styles['select-button']} transparent ${active ? styles.active : ''}`} type="button" onClick={onClick}>
      <Image src={icon} />
    </button>
  );
};

SelectButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  icon: PropTypes.string,
};

export default SelectButton;
