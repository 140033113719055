/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ColorButton = ({ color, onClick, active }) => {
  const withBorder = (node) => {
    if (!active) {
      return (
        <div className={styles['inactive-container']}>
          {node}
        </div>
      );
    }

    return (
      <div className={styles['active-container']}>
        {node}
      </div>
    );
  };

  return withBorder(
    <button className={`${styles['color-button']} transparent`} style={{ backgroundColor: color }} type="button" onClick={onClick} />,
  );
};

ColorButton.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default ColorButton;
