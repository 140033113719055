/* eslint-disable */
import React, { useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import MapBackground from 'components/MapBackground';
import MainLayout from 'components/MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes, logout, updateFlyshowMode, updateDefaultPromocode, updateDefaultMembercode } from 'actions';
import { LoginForm, CreateAccountForm, ForgotPassword } from 'components/Login';
import firebase, { registerEventListeners, cleanupListeners, verifyAccountStatus } from 'services/firebase';
import LinkGenerator from 'components/LinkGenerator';
import ReactGA from 'react-ga4';

const App = () => {
  // const [width, setWindowWidth] = useSelector(0);
  const isAppLoaded = useSelector((state) => state.auth.isAppLoaded);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isRegistering = useSelector((state) => state.auth.isRegistering);
  const userInfo = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const search = window.location.search;
  const TRACKING_ID = 'G-E7LW0VP5HZ';

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  
  useEffect(() => {
    dispatch(updateFlyshowMode(queryParams.get('flyshowid')));
  }, [queryParams]);

  useEffect(() => {
    dispatch(updateDefaultPromocode(queryParams.get('promocode')));
  }, [queryParams]);

  useEffect(() => {
    dispatch(updateDefaultMembercode(queryParams.get('membercode')));
  }, [queryParams]);

  useEffect(() => {
    // Automatically sign the user out after 3 seconds if Firebase can't find a user.
    const reset = setTimeout(() => {
      localStorage.removeItem('firebaseToken');
      dispatch({ type: ActionTypes.APP_LOAD });
    }, 3000);

    // Auth listener on mount to sign in the user if they have an existing Firebase session.
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user && !isRegistering) {
        clearTimeout(reset);
        dispatch({ type: ActionTypes.AUTHENTICATE, payload: user });
        dispatch({ type: ActionTypes.APP_LOAD });
      } else if (!localStorage.getItem('firebaseToken')) {
        clearTimeout(reset);
        dispatch({ type: ActionTypes.APP_LOAD });
      }
    });

    return () => {
      clearTimeout(reset);
      unsubscribe();
    };
  }, [isRegistering]);

  /**
   * Registers and cleans up relevant event listeners.
   */
  useEffect(() => {
    if (isAuthenticated) {
      try {
        verifyAccountStatus(dispatch);
        registerEventListeners(dispatch);
      } catch (e) {
        console.log(e);
      }
    } else {
      cleanupListeners();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  const renderLogout = () => {
    dispatch(logout());
    return <Redirect to="/" />;
  };

  // Deprecated function to lock use on mobile devices. 
  /* if (isMobile) {
    return (
      <Router>
        <Switch>
          <Route>
            <MobilePage />
          </Route>
        </Switch>
      </Router>
    );
  } */

  return (
    <Router>
      {isAppLoaded && !isAuthenticated && (
        <Switch>
          <Route exact path="/tools/customlinks" />
          <Route path="/signup">
            <CreateAccountForm />
          </Route>
          <Route path="/login">
            <LoginForm />
          </Route>
          <Route path="/forgot">
            <ForgotPassword />
          </Route>
          <Route path="/">
            <CreateAccountForm />
          </Route>
          <Route path="/logout" render={renderLogout} />
        </Switch>
      )}
      {isAppLoaded && isAuthenticated && (
        <Switch>
          <Route exact path="/tools/customlinks" />
          <Route path="/logout" render={renderLogout} />
          <Route path="/">
            <MainLayout />
          </Route>
        </Switch>
      )}
      <Switch>
        <Route exact path="/tools/customlinks">
          <LinkGenerator />
        </Route>
        <Route path="/:regionName">
          <MapBackground />
        </Route>
        <Route path="/">
          <MapBackground />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
