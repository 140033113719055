import React from 'react';
import Image from 'components/Image';
import {
  boat, boot, information, signPost,
} from 'assets';
import { useSelector } from 'react-redux';
import UpgradeBox from 'components/UpgradeBox';
import useIsPro from 'utils/useIsPro';
import styles from './styles.module.scss';

const proTitle = 'Stream Access Laws';
const proDescription = 'Unlock curated and detailed stream access laws for each state, helping you understand where you stand with legal public access while you’re on the water.';


function AccessLawsTab() {
  const isPro = useIsPro();
  const streamMetadata = useSelector((state) => state.map.streamMetadata);
  const { name } = streamMetadata.find((s) => s.name !== undefined) || {};
  const { access_summary } = streamMetadata.find((s) => s.access_summary !== undefined) || {};
  const { access_streambed } = streamMetadata.find((s) => s.access_streambed !== undefined) || {};
  const { access_floating } = streamMetadata.find((s) => s.access_floating !== undefined) || {};
  const { access_portage } = streamMetadata.find((s) => s.access_portage !== undefined) || {};
  const { access_navigability } = streamMetadata.find((s) => s.access_navigability !== undefined) || {};
  const { reglink } = streamMetadata.find((s) => s.reglink !== undefined) || {};


  return (
    <div className={styles.mainContainer}>
      {!isPro && <div className={styles.boxUpgrade}> <UpgradeBox title={proTitle} description={proDescription} /> </div>}

      <div className={`${styles.container} ${!isPro ? styles.blurBackground : ''}`}>
        <div className={styles.title}>{name} State Access Laws</div>
        <div className={styles.sectionWrapper}>
          <div className={styles.section}>
            <div className={styles.header}>
              <Image src={signPost} width={38} height={38} style={{ opacity: 0.7 }} />
              <div>Summary</div>
            </div>
            {access_summary && (
              <div className={styles.description}>
                <span className={styles.descriptionTitle}>Access Laws in a Nutshell: </span>
                <span>{access_summary}</span>
              </div>
            )}
          </div>

          <div className={styles.section}>
            <div className={styles.header}>
              <Image src={boot} width={38} height={38} style={{ opacity: 0.7 }} />
              <div>Walk & Wade</div>
            </div>
            {access_streambed && (
              <div className={styles.description}>
                <span className={styles.descriptionTitle}>Streambed access through private lands: </span>
                <span>{access_streambed}</span>
              </div>
            )}
          </div>

          <div className={styles.section}>
            <div className={styles.header}>
              <Image src={boat} width={38} height={38} style={{ opacity: 0.7 }} />
              <div>Floating</div>
            </div>
            {access_floating && (
              <div className={styles.description}>
                <span className={styles.descriptionTitle}>Public floating access through private lands: </span>
                <span>{access_floating}</span>
              </div>
            )}
            {access_portage && (
              <div className={styles.description}>
                <span className={styles.descriptionTitle}>Right to portage above high water mark: </span>
                <span>{access_portage}</span>
              </div>
            )}
            {access_navigability && (
              <div className={styles.description}>
                <span className={styles.descriptionTitle}>Definition of navigability: </span>
                <span>{access_navigability}</span>
              </div>
            )}
          </div>

          <div className={styles.section}>
            <div className={styles.header}>
              <Image src={information} width={38} height={38} style={{ opacity: 0.7 }} />
              <div>More Information</div>
            </div>
            <div className={styles.subheading}>State Website:</div>
            <a href={reglink} target="_blank" rel="noreferrer">{reglink}</a>
            <div className={styles.subheading}>Source of this information:</div>
            <a href="https://www.backcountryhunters.org/public_lands" target="_blank" rel="noreferrer">https://www.backcountryhunters.org/public_lands</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessLawsTab;
