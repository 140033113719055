/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import * as paymentService from 'services/stripe';
import { verifyAccountStatus } from 'services/firebase';
import styles from './styles.module.scss';
import InfoSection from './InfoSection';
import InputSection from './InputSection';
import MembershipCard from './MembershipCard';
import PlanPath from './PlanPath';

const PaymentScreen = ({ close, toggleCloseDisabled }) => {
  const { result } = useSelector((state) => state.payment);
  const { region } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { firstName, lastName, email } = useSelector(
    (state) => state.auth.user,
  );
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const defaultMemberCode = params.get('membercode');
  const defaultMemberCodeExists = defaultMemberCode != null && defaultMemberCode != '';
  const [displayMembershipCard, setDisplayMembershipCard] = useState(
    defaultMemberCodeExists,
  );

  useEffect(() => {
    if (result?.subscriptionId) {
      paymentService.subscribeToRevenueCat(
        result.subscriptionId,
        region,
        `${firstName} ${lastName}`,
      );
      verifyAccountStatus(dispatch);
      close();
    }
    if (result?.purchaseId) {
      paymentService
        .updateRevenueCatEntitlements(
          result.purchaseId,
          `${firstName} ${lastName}`,
          result.redemptionCode,
          result.duration,
        )
        .then(() => {
          verifyAccountStatus(dispatch);
          close();
        });
    }
  }, [result]);

  const onMembershipCardChange = (value) => {
    setDisplayMembershipCard(value);
  };

  return (
    <div className={styles.paymentModal}>
      <div className={styles.paymentTitle}>
        <h3>Try PRO Free for 7 Days</h3>
        <p>You won&apos;t be billed until after your trial ends. No commitment, cancel any time.</p>
      </div>
      {!displayMembershipCard && <InfoSection hidden />}
      {!displayMembershipCard && <div className={styles.paymentPath}><PlanPath /></div>}
      {!displayMembershipCard && (
        <InputSection
          onMembershipCardChange={onMembershipCardChange}
          displayMembershipCard={displayMembershipCard}
          onLoadChange={toggleCloseDisabled}
          name={`${firstName} ${lastName}`}
          email={email}
        />
      )}

      {displayMembershipCard && (
        <MembershipCard
          onMembershipCardChange={onMembershipCardChange}
          displayMembershipCard={displayMembershipCard}
        />
      )}
      {/* <Disclaimer /> */}
      <div style={{ paddingTop: '15px' }}>
        <Link underline="none" component="button" className={styles.linkButton} onClick={close}>
          Cancel
        </Link>
      </div>
    </div>
  );
};

export default PaymentScreen;
