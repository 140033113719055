import React, { useEffect } from 'react';
import ContentDialog from 'components/ContentDialog';
import { notepad, photo } from 'assets';
import {
  Add, Edit, CheckOutlined, CloseOutlined,
} from '@material-ui/icons';
import { getStreamUserData } from 'actions';
import useIsPro from 'utils/useIsPro';
import { PhotoDisplay } from 'components/Photos';
import Image from 'components/Image';
import useUpload from 'utils/useUpload';
import { useSelector, useDispatch } from 'react-redux';
import * as firebaseService from 'services/firebase';
import styles from './styles.module.scss';

function MyContentTab() {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const streamUserData = useSelector((state) => state.map.streamUserData);
  const currentStream = useSelector((state) => state.map.stream);
  const { stream_gid: streamGid } = currentStream;
  const {
    images: tempImages, handleChange, setImages: setTempImages, loading, handleUpload,
  } = useUpload();

  const [isNoteEditing, setIsNoteEditing] = React.useState(false);
  const [notes, setNotes] = React.useState('');
  const [isPhotoUploading, setIsPhotoUploading] = React.useState(false);
  const [images, setImages] = React.useState([]);

  useEffect(() => {
    if (streamGid) {
      dispatch(getStreamUserData(streamGid));
    } else {
      dispatch(getStreamUserData());
    }
  }, [streamGid]);

  useEffect(() => {
    if (streamUserData?.note) {
      setNotes(streamUserData.note);
    } else {
      setNotes('');
    }
    if (streamUserData?.imageURLs) {
      firebaseService.getImageURLsForAnnotation(streamGid, streamUserData?.imageURLs || [], 'stream').then((results) => setImages(results));
    } else {
      setImages([]);
    }
  }, [streamUserData]);

  const saveNote = () => {
    firebaseService.updateStream(streamGid, { note: notes });
    setIsNoteEditing(false);
  };

  const cancelNote = () => {
    setNotes(streamUserData.note);
    setIsNoteEditing(false);
  };

  const handlePhotoChange = (e) => {
    setIsPhotoUploading(true);
    handleChange(e);
  };

  const savePhoto = () => {
    setIsPhotoUploading(true);
    handleUpload(streamGid, 'stream');
    setTempImages([]);
  };

  const cancelPhoto = () => {
    setTempImages([]);
    setIsPhotoUploading(false);
  };

  useEffect(() => {
    if (!loading) {
      setIsPhotoUploading(false);
      setIsNoteEditing(false);
    }
  }, [loading]);

  const onDeleteImage = (imageObject) => {
    const { id } = imageObject;

    if (id) {
      firebaseService.deleteImage(streamGid, id, 'stream');
      setImages((prev) => prev.filter((v) => v.id !== id));
    } else {
      setTempImages((prev) => prev.filter((v) => v !== imageObject));
    }
  };

  return (
    <div>
      <div className={styles.section}>
        <Image src={notepad} width={36} height={36} style={{ opacity: 0.7 }} />
        <div className={styles.title}>Notes</div>
        {isNoteEditing ? (
          <>
            <button type="button" aria-label="Save" onClick={saveNote} className={styles.iconButton}><CheckOutlined className={styles.icon} /></button>
            <button type="button" aria-label="Cancel" onClick={cancelNote} className={styles.iconButton}><CloseOutlined className={styles.icon} /></button>
          </>
        )
          : <button type="button" aria-label="Edit Note" onClick={() => { setIsNoteEditing(true); }} className={styles.iconButton}><Edit className={styles.icon} /></button>}
      </div>
      {isNoteEditing
        ? <ContentDialog.MultiLineField onChange={(e) => { setNotes(e.target.value); }} value={notes} name="notes" label="Notes" />
        : <ContentDialog.Text>{notes || 'No notes yet. Tap \'edit\' to create one!'}</ContentDialog.Text>}

      {isPro && (
        <>
          <div className={styles.section}>
            <Image src={photo} width={36} height={36} style={{ opacity: 0.7 }} />
            <div className={styles.title}>Photos</div>
            {isPhotoUploading && loading && <div className={styles.loader} />}
            {isPhotoUploading ? (
              <>
                <button type="button" aria-label="Save Photo" onClick={savePhoto} className={styles.iconButton}><CheckOutlined className={styles.icon} /></button>
                <button type="button" aria-label="Cancel Photo" onClick={cancelPhoto} className={styles.iconButton}><CloseOutlined className={styles.icon} /></button>
              </>
            ) : (
              <>
                <label htmlFor="photo-upload" aria-label="Add Photo" className={styles.iconButton}><Add className={styles.icon} /></label>
                <input type="file" style={{ display: 'none' }} id="photo-upload" onChange={handlePhotoChange} accept="image/png, image/jpeg" />
              </>
            )}
          </div>
          <br />
          <PhotoDisplay images={[...images, ...tempImages]} onDelete={onDeleteImage} />
        </>
      )}
    </div>
  );
}

export default MyContentTab;
