import React, { useState, useEffect } from 'react';
import ModalTemplate from 'components/ModalTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { updatePasswordModalShown } from 'actions';
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import errorCodes from 'lib/error-codes';
import * as firebaseService from 'services/firebase';
import styles from './styles.module.scss';

const PasswordModal = () => {
  const dispatch = useDispatch();
  const { isPasswordModalShown } = useSelector((state) => state.auth);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const close = () => {
    dispatch(updatePasswordModalShown(false));
  };

  const tryResetPassword = async () => {
    try {
      setLoading(true);

      if (!currentPassword) {
        setError('Current password is required.');
        return;
      }
      if (!newPassword) {
        setError('New password is required.');
        return;
      }

      await firebaseService.resetPassword(currentPassword, newPassword);
      close();
      setCurrentPassword('');
      setNewPassword('');
    } catch (e) {
      const { code } = e;
      setError(errorCodes[code] || 'There was an error with the request.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setError('');
  }, [currentPassword, newPassword]);

  return (
    <ModalTemplate close={close} open={isPasswordModalShown}>
      <h2 className={styles.title}>Update Password</h2>
      {error && <div className={styles.error}>{error}</div>}

      <div className={styles.group}>
        <h4 className={styles.label}>Current password</h4>
        <TextField
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
          value={currentPassword}
          fullWidth
          variant="outlined"
          type={showCurrentPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowCurrentPassword((p) => !p);
                  }}
                  onMouseDown={() => {
                    setShowCurrentPassword((p) => !p);
                  }}
                >
                  {!showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div className={styles.group}>
        <h4 className={styles.label}>New password</h4>
        <TextField
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          value={newPassword}
          fullWidth
          variant="outlined"
          type={showNewPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowNewPassword((p) => !p);
                  }}
                  onMouseDown={() => {
                    setShowNewPassword((p) => !p);
                  }}
                >
                  {!showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div className={styles['button-container']}>
        <div style={{ width: '20%' }}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={tryResetPassword}
            disabled={loading}
          >
            Submit
          </Button>
        </div>
      </div>
    </ModalTemplate>
  );
};

export default PasswordModal;
