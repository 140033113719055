import { ActionTypes } from 'actions';

export const updateFavorites = (favorites) => {
  return { type: ActionTypes.UPDATE_FAVORITES, payload: favorites };
};

export const updateMarkers = (markers) => {
  return { type: ActionTypes.UPDATE_MARKERS, payload: markers };
};

export const updateLines = (lines) => {
  return { type: ActionTypes.UPDATE_LINES, payload: lines };
};

export const updateAreas = (areas) => {
  return { type: ActionTypes.UPDATE_AREAS, payload: areas };
};

export const addMarkerObject = (marker) => {
  return { type: ActionTypes.ADD_MARKER_OBJECT, payload: marker };
};

export const clearMarkerObjects = () => {
  return { type: ActionTypes.CLEAR_MARKER_OBJECTS };
};

export const updateLineId = (firebaseId, lineId) => {
  const payload = {};
  payload[lineId] = firebaseId;
  return { type: ActionTypes.UPDATE_LINE_ID, payload };
};

export const updateAreaId = (firebaseId, areaId) => {
  const payload = {};
  payload[areaId] = firebaseId;
  return { type: ActionTypes.UPDATE_AREA_ID, payload };
};

export const resetLineIds = () => {
  return { type: ActionTypes.RESET_LINE_IDS };
};

export const resetAreaIds = () => {
  return { type: ActionTypes.RESET_AREA_IDS };
};

export const updatePasswordModalShown = (isShown) => {
  return { type: ActionTypes.UPDATE_PASSWORD_MODAL_SHOWN, payload: isShown };
};

export const updateRegion = (region) => {
  return { type: ActionTypes.UPDATE_REGION, payload: region };
};
