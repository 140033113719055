import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'components/Image';
import {
  ThreeD, Plus, Minus, toolMarker, toolArea, toolLine, toolMeasure, toolLocation, tool2d, tool3d, toolPlus, toolMinus, toolUp, toolDown, toolCompass, loadingLocation,
} from 'assets';
import { sendAmplitudeData, sendAmplitudeEvent } from 'utils/amplitude';
import ExploreIcon from '@material-ui/icons/Explore';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  setRiverMilesCardVisible, zoomIn, zoomOut, setIsUpgradeModalShown, setActiveTools, setTRPOIFeature, setEditingMarkerId, resetCurrentMarker,
} from 'actions';
import { StraightenOutlined } from '@material-ui/icons';
import * as mapboxService from 'services/mapbox';
import useIsPro from 'utils/useIsPro';
import { accountTypesEnum } from 'lib/constants';
import ZoomButton from './ZoomButton';
import styles from './styles.module.scss';
import TiltButton from './TiltButton';

const ZoomControls = () => {
  const dispatch = useDispatch();
  const { map } = useSelector((state) => state.map);
  const [threeDEnabled, setThreeDEnabled] = useState(false);
  const [isOverlay, setIsOverlay] = useState(false);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [overlayTitle, setOverlayTitle] = useState('');
  const [overlayText, setOverlayText] = useState('');
  const [rotateDegree, setRotateDegree] = useState(0);
  const { isRiverMilesVisible } = useSelector((state) => state.map);
  const isPro = useIsPro();
  const activeTools = useSelector((state) => state.map.activeTools);
  const { accountType } = useSelector((state) => state.auth.user);

  useEffect(() => {
    map.on('rotate', () => {
      setRotateDegree(-1 * map.getBearing());
    });
  }, []);

  useEffect(() => {
    let isDrag = false;
    let isMouse = false;
    map.on('mousedown', () => {
      isMouse = true;
    });
    map.on('mouseup', () => {
      setTimeout(() => {
        isMouse = false;
      }, 100);
    });
    map.on('rotatestart', () => {
      isDrag = true;
    });
    map.on('pitchstart', () => {
      isDrag = true;
    });
    map.on('rotateend', () => {
      goTo3d();
      isDrag = false;
    });
    map.on('pitchend', () => {
      goTo3d();
      isDrag = false;
    });
    const goTo3d = () => {
      const userType = document.getElementById('usertype').innerHTML;
      if (!threeDEnabled && isDrag && isMouse && !map.getLayer('sky')) {
        if (userType != 'pro') {
          // dispatch(setIsUpgradeModalShown(true));
        } else {
          mapboxService.setUp3DMaps(map, map.getPitch(), map.getBearing());
          setThreeDEnabled(true);
        }
      }
    };
  }, []);

  const markerClick = () => {
    dispatch(setActiveTools({ marker: !activeTools.marker, line: false, area: false }));
  };

  const lineClick = () => {
    if (isPro) {
      dispatch(setActiveTools({ marker: false, area: false, line: !activeTools.line }));
    } else {
      dispatch(setIsUpgradeModalShown(true));
    }
  };

  const areaClick = () => {
    if (isPro) {
      dispatch(setActiveTools({ marker: false, line: false, area: !activeTools.area }));
    } else {
      dispatch(setIsUpgradeModalShown(true));
    }
  };

  const handleClick = (name) => {
    switch (name) {
    case 'zoomIn':
      map.zoomIn();
      break;
    case 'zoomOut':
      map.zoomOut();
      break;
    case 'compass':
      map.setBearing(0);
      break;
    default:
      break;
    }
  };

  const orient = () => {
    map.rotateTo(0);
  };

  const tiltUp = () => {
    const pitch = map.getPitch();
    map.flyTo({ pitch: pitch - 5 });
  };

  const tiltDown = () => {
    const pitch = map.getPitch();
    map.flyTo({ pitch: pitch + 5 });
  };

  const toggle3D = () => {
    if (!threeDEnabled) {
      if (!isPro) {
        dispatch(setIsUpgradeModalShown(true));
      } else {
        mapboxService.setUp3DMaps(map, 0, 0, true);
        setThreeDEnabled(true);
      }
    } else {
      mapboxService.disable3DMaps(map);
      setThreeDEnabled(false);
    }
  };

  const openRiverMiles = () => {
    if(isPro) {
      sendAmplitudeEvent('web_rivermilestoole_start');

      dispatch(setRiverMilesCardVisible(!isRiverMilesVisible, map));
    } else { dispatch(setIsUpgradeModalShown(true)); }
  };

  const MyLocation = () => {
    if (isLoadingLocation) {
      return;
    }
    setIsLoadingLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latlng = { lon: position.coords.longitude, lat: position.coords.latitude };
        const myLocation = `${position.coords.longitude},${position.coords.latitude}`;
        localStorage.setItem('myLocation', myLocation);
        dispatch(setTRPOIFeature(null));
        dispatch(setEditingMarkerId(''));
        dispatch(resetCurrentMarker());
        if (document.getElementsByClassName('mapboxgl-popup').length > 0) {
          document.getElementsByClassName('mapboxgl-popup')[0].remove();
        }
        mapboxService.zoomToPoint(map, latlng);
        mapboxService.createMarkerLocation(latlng, map);
        setTimeout(() => {
          setIsLoadingLocation(false);
        }, 2000);
      }, () => {
        setOverlayTitle('Could Not Determine Location');
        setOverlayText('We were unable to determine your location due one or more system issues. Please try again later.');
        setIsOverlay(true);
        setIsLoadingLocation(false);
      });
    } else {
      setOverlayTitle('Location Access Denied');
      setOverlayText('In order to use this feature, we need access to your location. Please enable location permissions in your internet browser settings and your device settings.');
      setIsOverlay(true);
      setIsLoadingLocation(false);
    }
  };

  const onOverlayClose = () => {
    setIsOverlay(false);
  };

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: '#fff',
      top: '8px !important',
    },
    tooltip: {
      backgroundColor: '#fff',
      color: '#333',
      fontSize: '12px',
      borderRadius: '10px',
      boxShadow: '3px 1px 6px 1px rgba(0,0,0,0.15)',
      padding: '10px 15px',
      marginRight: '1px',
    },
  }));

  const BootstrapTooltip = (props) => {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
  };

  return (
    <>
      <div className={styles.controls}>
        <div id="usertype" style={{ display: 'none' }}>{accountType}</div>
        <div className={`${styles.group} ${styles.groupTool}`} style={{ paddingBottom: '0px', marginBottom: 'auto' }}>
          <div className={styles.tools}>Tools</div>
          <div title="Measure River Distance">
            <button className={`${styles.tbutton} ${isRiverMilesVisible ? styles.tbutton_sel : ''}`} type="button" onClick={openRiverMiles}>
              <Image src={toolMeasure} />
              <div>Button</div>
            </button>
          </div>
          <div title="Create Custom Marker">
            <button className={`${styles.tbutton} ${activeTools.marker ? styles.tbutton_sel : ''}`} type="button" onClick={markerClick}>
              <Image src={toolMarker} />
              <div>Button</div>
            </button>
          </div>
          <div title="Create Custom Line">
            <button className={`${styles.tbutton} ${activeTools.line ? styles.tbutton_sel : ''}`} type="button" onClick={lineClick}>
              <Image src={toolLine} />
              <div>Button</div>
            </button>
          </div>
          <div title="Create Custom Shape">
            <button className={`${styles.tbutton} ${activeTools.area ? styles.tbutton_sel : ''}`} type="button" onClick={areaClick}>
              <Image src={toolArea} />
              <div>Button</div>
            </button>
          </div>
        </div>

        {threeDEnabled && (
          <div className={styles.group}>
            <button className={styles.tbutton} type="button" onClick={tiltUp}>
              <Image src={toolUp} />
              <div>Button</div>
            </button>
            <div className={styles.divider} />
            <button className={styles.tbutton} type="button" onClick={tiltDown}>
              <Image src={toolDown} />
              <div>Button</div>
            </button>
          </div>
        )}

        <button className={styles.compass} type="button" onClick={() => { handleClick('compass'); }}>
          <Image src={toolCompass} style={{ margin: 0, transition: 'all .3s', transform: `rotate(${rotateDegree}deg)` }} />
          <div>Button</div>
        </button>

        <div className={styles.group}>
          <button className={styles.tbutton} type="button" onClick={() => { handleClick('zoomIn'); }}>
            <Image src={toolPlus} />
            <div>Button</div>
          </button>
          <div className={styles.divider} />
          <button className={styles.tbutton} type="button" onClick={() => { handleClick('zoomOut'); }}>
            <Image src={toolMinus} />
            <div>Button</div>
          </button>
        </div>

        <div className={styles.group}>
          <button className={styles.tbutton} type="button" onClick={toggle3D}>
            {threeDEnabled && (<Image src={tool2d} style={{ padding: '12px 8px' }} />)}
            {!threeDEnabled && (<Image src={tool3d} style={{ padding: '12px 8px' }} />)}
            <div>Button</div>
          </button>
        </div>

        <div className={styles.group}>
          <button className={styles.tbutton} type="button" onClick={MyLocation}>
            {!isLoadingLocation && (<Image src={toolLocation} style={{ padding: '6px' }} />)}
            {isLoadingLocation && (<Image src={loadingLocation} style={{ padding: '12px', margin: 0 }} />)}
            <div>Button</div>
          </button>
        </div>

      </div>

      {isOverlay && (
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            <h5>{overlayTitle}</h5>
            <p>{overlayText}</p>
            <div className={styles.overlayButton} onClick={onOverlayClose} role="presentation">Ok</div>
          </div>
        </div>
      )}
    </>
  );
};

export default ZoomControls;
