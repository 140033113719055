import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContentDialog from 'components/ContentDialog';
import { shareIos, closeIcon } from 'assets';
import CloseIcon from '@material-ui/icons/Close';
import Image from 'components/Image';
import {
  setStreamElevationDataWithId, setElevationChartVisible, removeTempElevationMarker, getStreamUserData, setIsUpgradeModalShown, setStreamRegulations, setStreamRegulationsForCurrentStream,
  setStreamMetadata, setStreamFlyShops, setIsPaymentModalShown,
  updateStreamWithGeocoding,
} from 'actions';
import { addFavoriteStream, removeFavoriteStream } from 'services/firebase';
import { directionCoordinateURL } from 'lib/constants';
import { BookmarkBorder, Bookmark } from '@material-ui/icons';
import ExploreIcon from '@material-ui/icons/Explore';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import * as firebaseService from 'services/firebase';
import useUpload from 'utils/useUpload';
import toast from 'react-hot-toast';
import useIsPro from 'utils/useIsPro';
import CardTabs from 'components/CardTabs';
import styles from './styles.module.scss';
import ClassDescriptions from './ClassDescriptions';

const StreamLabel = () => {
  const dispatch = useDispatch();
  const isPro = useIsPro();
  const currentStream = useSelector((state) => state.map.stream);
  const { isElevationChartVisible, map, coords } = useSelector((state) => state.map);
  const favorites = useSelector((state) => state.user.favorites);
  const {
    primaryLabel, secondaryLabel, stream_gid: streamGid, SectionName: sectionLabel, StreamClass: highlightedClass, xmin, ymin, xmax, ymax,
  } = currentStream;
  const [isFavorite, setIsFavorite] = useState(!!favorites.find((item) => item.stream_gid === streamGid));
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState('');
  const [images, setImages] = useState([]);

  const {
    images: tempImages, handleChange, setImages: setTempImages, loading, handleUpload,
  } = useUpload();

  const elevationData = useSelector((state) => state.map.streamElevationData);
  const streamUserData = useSelector((state) => state.map.streamUserData);
  const elevation = useSelector((state) => state.map.elevation);

  const onDeleteImage = (imageObject) => {
    const { id } = imageObject;

    if (id) {
      firebaseService.deleteImage(streamGid, id, 'stream');
      setImages((prev) => prev.filter((v) => v.id !== id));
    } else {
      setTempImages((prev) => prev.filter((v) => v !== imageObject));
    }
  };

  const reset = () => {
    setTempImages([]);
    setNotes(streamUserData.note);
  };

  const onCancel = () => {
    reset();
    setIsEditing(false);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onSubmit = () => {
    firebaseService.updateStream(streamGid, { note: notes });
    handleUpload(streamGid, 'stream');
  };

  useEffect(() => {
    if (!loading) setIsEditing(false);
  }, [loading]);

  useEffect(() => {
    if (streamUserData?.note) {
      setNotes(streamUserData.note);
    } else {
      setNotes('');
    }
    if (streamUserData?.imageURLs) {
      firebaseService.getImageURLsForAnnotation(streamGid, streamUserData?.imageURLs || [], 'stream').then((results) => setImages(results));
    } else {
      setImages([]);
    }
  }, [streamUserData]);

  useEffect(() => {
    if (streamGid) {
      setIsFavorite(!!favorites.find((item) => item.stream_gid === streamGid));
      dispatch(setStreamElevationDataWithId(streamGid));
      dispatch(getStreamUserData(streamGid));
      dispatch(setStreamRegulationsForCurrentStream(map));
    } else {
      dispatch(updateStreamWithGeocoding({}));
      dispatch(setElevationChartVisible(false));
      dispatch(removeTempElevationMarker());
      dispatch(getStreamUserData());
      dispatch(setStreamRegulations([]));
      dispatch(setStreamMetadata([]));
      dispatch(setStreamFlyShops([]));
    }
  }, [favorites, streamGid]);

  if (!currentStream || !primaryLabel || isElevationChartVisible) return null;

  const toggleFavorite = () => {
    if (isFavorite) {
      removeFavoriteStream(streamGid);
    } else {
      addFavoriteStream(currentStream);
    }
  };

  const openElevationChart = () => {
    if (isPro) {
      dispatch(setElevationChartVisible(true));
      map.fire('closeAllPopups');
    } else {
      dispatch(setIsUpgradeModalShown(true));
    }
  };

  const hasSection = sectionLabel && sectionLabel.length > 2 && sectionLabel !== 'Insert Section Name';

  const onShare = async () => {
    try {
      const url = await firebaseService.generateDynamicLinkForStream(streamGid);
      navigator.clipboard.writeText(url);
      toast.remove();
      toast('Copied link to clipboard');
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpgrade = () => {
    dispatch(setIsPaymentModalShown(true));
    dispatch(setIsUpgradeModalShown(false));
  };

  const onClose = async () => {
    dispatch(updateStreamWithGeocoding({}));
  };

  return (
    <>
      {!currentStream.isDetail && (
        <ContentDialog>
          <div className={styles.close}
            role="button"
            onClick={onClose}
            tabIndex={0}
          >
            <CloseIcon />
            {/* <Image alt="Close" src={closeIcon} width="16" /> */}
          </div>
          <div className={styles.gridContainer}>
            <div>
              <div className={styles.primary}>{primaryLabel}</div>
              {hasSection && <div className={styles.secondary}>{sectionLabel}</div>}
              <div className={styles.secondary}>{secondaryLabel}</div>
              {elevation && (
                <div className={styles.elev}>
                  <span><RadioButtonChecked className={styles.elevIcon} /> </span>
                  <span className={styles.elevValue}>{elevation} ft. elev.</span>
                </div>
              )}
            </div>
            <div className={styles.rightColumn}>
              {isPro ? <ClassDescriptions highlightedClass={highlightedClass} /> : <button type="button" onClick={handleUpgrade} className={styles.upgrade}>Upgrade</button>}
            </div>
          </div>

          <ContentDialog.Buttons buttons={[
            {
              label: 'Share', icon: shareIos, onClick: onShare, style: styles.button,
            },
            {
              label: 'Bookmark', iconElement: isFavorite ? Bookmark : BookmarkBorder, onClick: toggleFavorite, style: styles.button,
            },
            {
              label: 'Directions',
              iconElement: ExploreIcon,
              onClick: () => { window.open(`${directionCoordinateURL}${encodeURI(`${coords.lat}, ${coords.lng}`)}`); },
              style: styles.blueButton,
            },
          ]}
          />
          <div className={styles.divider} />


          <CardTabs />


          {/* <ContentDialog.Heading>Notes</ContentDialog.Heading> */}

          {/* {isEditing
            ? <ContentDialog.MultiLineField onChange={(e) => { setNotes(e.target.value); }} value={notes} name="notes" label="Notes" />
            : <ContentDialog.Text>{notes || 'No notes yet. Tap \'edit\' to create one!'}</ContentDialog.Text>} */}

          {/* {isPro
          && (
            <>
              <ContentDialog.Heading>
                <div>Photos</div>
                {isEditing && <AddPhotoButton id="line-upload" onChange={handleChange} />}
              </ContentDialog.Heading>
              <PhotoDisplay images={[...images, ...tempImages]} onDelete={onDeleteImage} />
            </>
          ) } */}
        </ContentDialog>
      )}
    </>
  );
};

export default StreamLabel;
