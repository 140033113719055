import React from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const LayersChunk = ({
  image, caption, children, active = false, onClick, name, disabled,
}) => {
  return (
    <button className={`${styles['layers-chunk']} transparent ${active ? styles.active : ''} ${disabled ? styles.disabled : ''}`} type="button" onClick={onClick} name={name}>
      <div className={styles['image-section']}>
        <Image src={image} alt={caption} width={95} height={80} responsive />
        <div>{caption}</div>
      </div>

      <div>
        {children}
      </div>
    </button>
  );
};

LayersChunk.propTypes = {
  image: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.node,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default LayersChunk;
