/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable */
import firebaseConfig from 'lib/firebase-config';
import firebase from 'firebase/compat/app';
import errorCodes from 'lib/error-codes';
import {
  updateAccountType, updateUserInfo, updateFavorites, updateMarkers, updateLines, updateAreas, updateRegion,
  setCurrentHUC,
} from 'actions';
import {
  supportedStates, profileDatabaseURL, eCommerceDatabaseURL, editableUserFields, accountTypesEnum, geoSearchDatabaseURL, elevationDatabaseURL, wordpressDatabaseURL, gageListDatabaseURL
} from 'lib/constants';
import generateRandomId from 'utils/generateRandomId';
import axios from 'axios';
import * as paymentService from 'services/stripe';
import moment from 'moment';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';
import ReactGA from 'react-ga4';


let app;

if (firebase.apps.length < 1) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.apps[0]
}

const defaultDatabase = firebase.database().ref('users/');
const profileDatabase = app.database(profileDatabaseURL).ref();
const eCommerceDatabase = app.database(eCommerceDatabaseURL).ref();
const gageListDatabase = app.database(gageListDatabaseURL).ref('features/');
const geoSearchDatabase = app.database(geoSearchDatabaseURL).ref('features/');
const elevationDatabase = app.database(elevationDatabaseURL).ref('features/');
const wordpressDatabase = app.database(wordpressDatabaseURL).ref('redemption_codes/');

const storage = firebase.storage().ref();
const analytics = firebase.analytics();

/**
 * Determines whether or not an account is valid based on its creation date.
 * @param {string} date Date to test.
 */
const isAccountValid = (date) => {
  // let formattedDate = '';
  // if (date.length > 11) {
  //   formattedDate = date.split(' ')[0];
  // } else {
  //   formattedDate = date;
  // }
  const formattedDate = date.split(' ')[0];
  const d = new Date(formattedDate);
  const now = new Date();
  const diffDays = Math.round((now - d) / (24 * 60 * 60 * 1000));

  if (diffDays <= 365) return true;
  else return false;
};

/**
 * Returns the renewal date (one year from the purchase) for any given date.
 * @param {string} date Date to process.
 */
const getRenewalDate = (date) => {
  // let formattedDate = '';
  // if (date.length > 11) {
  //   formattedDate = date.split(' ')[0];
  // } else {
  //   formattedDate = date;
  // }
  const formattedDate = date.split(' ')[0];
  const d = new Date(formattedDate);
  d.setFullYear(d.getFullYear() + 1);
  return d;
};

/**
 * Helper function to determine the status of a given account.
 * Automatically updates the user profile record if the account has been upgraded to pro status.
 * @param {string} email Email of the user to check.
 * @param {string} uid ID of the user to check.
 * @param {object} snapshot Optional snapshot to use to avoid excessive calls to the database.
 * @returns {array} First element is 'basic', 'trial', or 'pro'. If 'pro', the second element contains an object with additional information about the account.
 */
export const verifyAccountStatus = async (dispatch) => {
  try {
    const uid = firebase.auth().currentUser.uid;
    const { data: accountData } = await axios.get(`${paymentService.API_URL}/revenuecat/getStatus?uid=${uid}`);
    const { status } = accountData;

    if (status === accountTypesEnum.pro) {
      const info = { renewalDate: new Date(accountData.renewalDate) };

      if (dispatch) {
        dispatch(updateAccountType(accountTypesEnum.pro, info));
      }
      return [accountTypesEnum.pro, info];
    }

    if (dispatch) {
      dispatch(updateAccountType(accountTypesEnum.basic));
    }

    return [accountTypesEnum.basic];
  } catch (err) {
    return [accountTypesEnum.basic];
  }
};

/**
 * Helper function to register event listeners upon successful authentication.
 * @param {func} dispatch Dispatch function to connect to the Redux store.
 */
export const registerEventListeners = (dispatch) => {
  const { uid } = firebase.auth().currentUser;

  // Subscribe to changes in the user profile.
	const userProfile = profileDatabase.child("users").child(uid);
  userProfile.on("value", (snapshot) => {
    if (snapshot.exists()) {
      dispatch(updateUserInfo(snapshot.val()));
    }
  });

  // Subscribe to changes in the main database.
  const userEntry = defaultDatabase.child(uid);
  userEntry.on('value', (snapshot) => {
    if (snapshot) {
      dispatch(updateFavorites(snapshot.child('favorites').exists() ? Object.values(snapshot.child('favorites').val()) : []));
      dispatch(updateMarkers(snapshot.child('annotations').exists() ? snapshot.child('annotations').val() : []));
      dispatch(updateLines(snapshot.child('lines').exists() ? snapshot.child('lines').val() : []));
      dispatch(updateAreas(snapshot.child('polygons').exists() ? snapshot.child('polygons').val() : []));
    }
  });
};

/**
 * Helper function to register event listeners upon successful authentication and get snap region.
 * @param {func} dispatch Dispatch function to connect to the Redux store.
 */
export const getRegionAndSnap = (dispatch) => {
  const { uid } = firebase.auth().currentUser;
  const userEntry = defaultDatabase.child(uid).child('region');
  userEntry.on('value', async (snapshot) => {
    const val = snapshot.val();
    if (val) {
      dispatch(updateRegion(val));
    } else {
      dispatch(updateRegion(null));
    }
  });
};

/**
 * Detaches Firebase event listeners.
 */
export const cleanupListeners = () => {
  const uid = firebase.auth().currentUser?.uid;
  const proAccounts = profileDatabase.child('ProAccounts');
  proAccounts.off();
  if (uid) {
    const userProfile = profileDatabase.child('users').child(uid);
    userProfile.off();

    const userEntry = defaultDatabase.child(uid);
    userEntry.off();
  }
};

/**
 * Helper function to ensure proper ID token storage and unified auth across all Firebase app instances.
 * @param {string} email User email.
 * @param {string} password User password.
 * @param {boolean} register If true, registers the user instead of signing them up.
 */
const signInHelper = async (email, password, register = false) => {
  const { user } = register ? await firebase.auth().createUserWithEmailAndPassword(email, password) : await firebase.auth().signInWithEmailAndPassword(email, password);
  const idToken = await user.getIdToken();
  localStorage.setItem('firebaseToken', idToken);
  return user;
};

/**
 * Logs a user in using the Firebase service.
 * @param {string} email Valid user email.
 * @param {string} password Valid user password.
 */
export const login = async (email, password) => {
  try {
    const user = await signInHelper(email, password);
    return user;
  } catch (e) {
    throw new Error(errorCodes[e.code] || 'There was an error logging in.');
  }
};

/**
 * Logs a user out using the Firebase service.
 */
export const logout = async () => {
  try {
    localStorage.removeItem('firebaseToken');
    await firebase.auth().signOut();
  } catch (e) {
    throw new Error(errorCodes[e.code] || 'There was an error logging out.');
  }
};

/**
 * Signs a user up using the Firebase service.
 * @param {string} email New user email.
 * @param {string} password Valid password.
 * @param {object} values Optional. If it contains firstName and lastName, those are added to the user profile upon account creation.
 */
export const register = async (email, password, values = {}) => {
  try {
    const user = await signInHelper(email, password, true);
    const { uid } = user;

    const currentUser = await profileDatabase.child('users').child(uid).get();

    // First, confirm the UID branch doesn't already exist (this should never happen).
    if (currentUser.exists()) {
      throw new Error('A user with this ID already exists.');
    }

    // Next, calculate the dates for the trial period and account creation.
    const start = new Date();

    let accountCreated = start.toLocaleString('en-US', { timeZone: 'America/Chicago' });
    accountCreated = accountCreated.slice(0, accountCreated.length - 3);
    accountCreated = accountCreated.replace(',', '');

    // Save the user's information to the database.
    await profileDatabase.child(`users/${uid}`).set({
      user_email: email,
      user_accounttype: 'basic',
      user_dateaccountcreated: accountCreated,
      user_firstname: values.firstName || '',
      user_lastname: values.lastName || '',
    });

    await defaultDatabase.child(uid).set({
      accountType: 0,
      email,
    });

    ReactGA.event('tr_account_created');
    
    return user;
  } catch (e) {
    throw new Error((e.code && errorCodes[e.code]) || e.message || 'There was an error logging out.');
  }
};

/**
 * Updates the entry in a user's profile with acceptable fields.
 * @param {object} info Object with keys that correspond to entries in a user profile.
 */
export const updateUserProfile = async (info) => {
  const { uid } = firebase.auth().currentUser;
  const userRef = profileDatabase.child('users').child(uid);
  for (const key of Object.keys(info)) {
    if (editableUserFields.includes(key)) {
      await userRef.child(key).set(info[key]);
    }
  }
};

/**
 * Function that queries Firestore and returns a download URL for a fly shops image.
 * @param {string} path Firebase cloud storage path.
 */
export const getImageDownloadURL = async (path) => {
  const pathReference = firebase.storage().ref(path);
  const url = await pathReference.getDownloadURL();
  return url;
};

const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

/**
 * Returns a Snapshot of all geofeatures in the database that match the given query.
 * @param {string} text Text to search by.
 */
export const getGeoFeatures = (text) => new Promise((resolve, reject) => {
  const titleString = titleCase(text);
  geoSearchDatabase.orderByChild('properties/GeoName').startAt(titleString).endAt(`${titleString}\uF7FF`).limitToFirst(400).once('value', (snapshot) => {
    resolve(snapshot);
  }, (error) => {
    reject(error);
  });
});

export const getGeoFeaturesByStreamGID = (streamGID) => new Promise((resolve, reject) => {
  geoSearchDatabase.orderByChild('properties/stream_gid2').startAt(streamGID).endAt(`${streamGID}\uF8FF`).limitToFirst(100).once('value', (snapshot) => {
    resolve(snapshot);
  }, (error) => {
    reject(error);
  });
});

/**
 * Adds a stream to this user's favorites.
 * @param {object} stream The stream object.
 */
export const addFavoriteStream = (stream) => {
  const { uid } = firebase.auth().currentUser;
  const newStream = { ...stream };
  if (stream.stream_gid2) {
    newStream.stream_gid = stream.stream_gid2;
    delete newStream.stream_gid2;
  }
  delete newStream.part_count;
  delete newStream.GeoBoundMaxX;
  delete newStream.GeoBoundMaxY;
  delete newStream.GeoBoundMinX;
  delete newStream.GeoBoundMinY;
  if(!newStream.is_popular) {
    delete newStream.is_popular;
  }
  if(!newStream.is_tailwater) {
    delete newStream.is_tailwater;
  }
  if(!newStream.has_easement) {
    delete newStream.has_easement;
  }
  if(!newStream.length) {
    delete newStream.length;
  }

  defaultDatabase.child(uid).child('favorites').push(newStream);
};

/**
 * Adds a stream to this user's favorites.
 * @param {object} stream The stream object.
 */
export const removeFavoriteStream = async (streamId) => {
  const { uid } = firebase.auth().currentUser;
  const favoritesRef = defaultDatabase.child(uid).child('favorites');
  const favorites = await favoritesRef.get();
  favorites.forEach((snapshot) => {
    if (snapshot.child('stream_gid').val() === streamId) {
      favoritesRef.child(snapshot.key).remove();
    }
  });
};

/**
 * Returns the current user's email.
 */
export const getUserEmail = () => {
  const { email } = firebase.auth().currentUser;
  return email;
};

/**
 * Returns the current user's ID.
 */
 export const getUserId = () => {
  const { uid } = firebase.auth().currentUser;
  return uid;
};

/**
 * Adds a marker to Firebase.
 * @param {object} markerObject Marker object conforming with API specifications about representing a marker.
 */
export const addMarker = (markerObject) => {
  const { uid } = firebase.auth().currentUser;
  const annotationsRef = defaultDatabase.child(uid).child('annotations');
  const { key } = annotationsRef.push(markerObject);
  return key;
};

/**
 * Updates a stream object.
 * @param {string} streamId ID of the stream to update.
 * @param {object} streamObject The keys and values to update.
 */
export const updateStream = (streamId, streamObject) => {
  const { uid } = firebase.auth().currentUser;
  const streamsRef = defaultDatabase.child(uid).child('streams');
  for (const [key, value] of Object.entries(streamObject)) {
    streamsRef.child(streamId).child(key).set(value);
  }
};

/**
 * Updates a marker in Firebase.
 * @param {object} markerObject Marker object conforming with API specifications about representing a marker.
 * @param {string} markerId ID of the marker reference to replace.
 */
export const updateMarker = async (markerObject, markerId) => {
  const { uid } = firebase.auth().currentUser;
  const annotationsRef = defaultDatabase.child(uid).child('annotations').child(markerId);
  await annotationsRef.child('geometry').set(markerObject.geometry);
  await annotationsRef.child('type').set(markerObject.type);

  for (const [key, value] of Object.entries(markerObject.properties)) {
    await annotationsRef.child('properties').child(key).set(value);
  }
};

/**
 * Deletes a marker with a specific ID.
 * @param {string} markerId Marker ID.
 */
export const deleteMarker = (markerId) => {
  const { uid } = firebase.auth().currentUser;
  defaultDatabase.child(uid).child('annotations').child(markerId).remove();
};

/**
 * Deletes a line with a specific ID.
 * @param {string} lineId Line ID.
 */
export const deleteLine = (lineId) => {
  const { uid } = firebase.auth().currentUser;
  defaultDatabase.child(uid).child('lines').child(lineId).remove();
};

/**
 * Deletes an area with a specific ID.
 * @param {string} areaId Area ID.
 */
export const deleteArea = (areaId) => {
  const { uid } = firebase.auth().currentUser;
  defaultDatabase.child(uid).child('polygons').child(areaId).remove();
};

/**
 * Adds a line to Firebase.
 * @param {object} lineObject The object representing the line.
 */
export const addLine = (lineObject) => {
  const { uid } = firebase.auth().currentUser;
  const linesRef = defaultDatabase.child(uid).child('lines');
  const { key } = linesRef.push(lineObject);
  return key;
};

/**
 * Updates a line in Firebase.
 * @param {object} lineObject The object representing the line.
 * @param {string} lineId ID of the line to replace.
 */
export const updateLine = async (lineObject, lineId) => {
  const { uid } = firebase.auth().currentUser;
  const linesRef = defaultDatabase.child(uid).child('lines').child(lineId);
  await linesRef.child('geometry').set(lineObject.geometry);
  await linesRef.child('type').set(lineObject.type);

  for (const [key, value] of Object.entries(lineObject.properties)) {
    await linesRef.child('properties').child(key).set(value);
  }
};

/**
 * Adds an area to Firebase.
 * @param {object} areaObject The object representing the area.
 */
export const addArea = (areaObject) => {
  const { uid } = firebase.auth().currentUser;
  const areaRef = defaultDatabase.child(uid).child('polygons');
  const { key } = areaRef.push(areaObject);
  return key;
};

/**
 * Updates an area in Firebase.
 * @param {object} areaObject The object representing the area.
 * @param {string} areaId ID of the area to replace.
 */
export const updateArea = async (areaObject, areaId) => {
  const { uid } = firebase.auth().currentUser;
  const areaRef = defaultDatabase.child(uid).child('polygons').child(areaId);
  await areaRef.child('geometry').set(areaObject.geometry);
  await areaRef.child('type').set(areaObject.type);

  for (const [key, value] of Object.entries(areaObject.properties)) {
    await areaRef.child('properties').child(key).set(value);
  }
};

/**
 * Updates the coordinates of a particular line.
 * @param {object} feature Feature as GeoJSON object.
 */
export const updateLineCoordinates = async (feature, firebaseId) => {
  const { uid } = firebase.auth().currentUser;
  const linesRef = defaultDatabase.child(uid).child('lines');
  const line = await linesRef.child(firebaseId).get();
  if (line.exists()) {
    linesRef.child(firebaseId).child('geometry').set(feature.geometry);
  }
};

/**
 * Updates the coordinates of a particular area.
 * @param {object} feature Feature as GeoJSON object.
 */
export const updateAreaCoordinates = async (feature, firebaseId) => {
  const { uid } = firebase.auth().currentUser;
  const areasRef = defaultDatabase.child(uid).child('polygons');
  const area = await areasRef.child(firebaseId).get();
  if (area.exists()) {
    areasRef.child(firebaseId).child('geometry').set(feature.geometry);
  }
};

/**
 * Retrieves the coordinates associated with a particular stream.
 * @param {string} streamGid The ID of the stream.
 * @returns {[[ [lng, lat] ]]} Array of LngLat.
 */
export const getStreamCoordinates = async (streamGid) => {
  const snapshot = await elevationDatabase.orderByChild('properties/stream_gid2').equalTo(streamGid).once('value');
  if (!snapshot.exists()) return [];

  const val = snapshot.val();
  return Object.values(val)[0]?.geometry?.coordinates;
};

const convertAnnotationTypeToPath = (type) => {
  switch (type) {
  case 'line':
    return 'lines';
  case 'area':
    return 'polygons';
  case 'marker':
    return 'annotations';
  case 'stream':
    return 'streams';
  default:
    return null;
  }
};

/**
 * Uploads an image for the given ID. Returns the image URL string.
 * @param {string} id ID of the annotation for which to upload this image.
 * @param {File} file The File object, returned by Javascript.
 * @param {string} annotationType One of 'line', 'area', or 'marker'.
 */
export const uploadImageForAnnotation = (id, file, annotationType) => new Promise((resolve, reject) => {
  const { uid } = firebase.auth().currentUser;
  const url = generateRandomId(16);

  const ref = storage.child('users').child(uid).child(convertAnnotationTypeToPath(annotationType)).child(id)
    .child('images')
    .child(url)
    .child(`image.${file.type.split('/')[1]}`);

  ref.put(file).then((snapshot) => {
    resolve(url);
  }, (err) => reject(err));
});

/**
 * Adds an image URL to the Firebase database.
 * @param {string} id ID of the annotation for which to upload this image.
 * @param {string} url URL of the image to reference.
 * @param {string} annotationType One of 'line', 'area', or 'marker'.
 */
export const addImageUrl = async (id, url, annotationType) => {
  const { uid } = firebase.auth().currentUser;
  const ref = defaultDatabase.child(uid).child(convertAnnotationTypeToPath(annotationType)).child(id)
    .child('properties').child('imageURLs');
  const snapshot = await ref.get();
  const val = snapshot.val();

  ref.set(val ? [...val, url] : [url]);
};

/**
 * Given an array of image IDs and the annotation type, returns a download URL to the image.
 * @param {string} annotationId The ID of the relevant annotation.
 * @param {array} imageIds The IDs of the images to retrieve.
 * @param {string} annotationType One of 'line', 'area', or 'marker'.
 */
export const getImageURLsForAnnotation = async (annotationId, imageIds, annotationType) => {
  const { uid } = firebase.auth().currentUser;
  const ref = storage.child('users').child(uid).child(convertAnnotationTypeToPath(annotationType)).child(annotationId)
    .child('images');

  const urls = [];

  await Promise.all(imageIds.map(async (id) => {
    const imageRef = ref.child(id);
    const list = await imageRef.listAll();

    if (list.items.length > 0) {
      const url = await list.items[0].getDownloadURL();
      urls.push({ url, id });
    }
  }));

  return urls;
};

/**
 * Deletes an image reference from an annotation.
 * @param {string} annotationId The ID of the relevant annotation.
 * @param {number} index ID of the image.
 * @param {string} annotationType One of 'line', 'area', or 'marker'.
 */
export const deleteImage = async (annotationId, id, annotationType) => {
  const { uid } = firebase.auth().currentUser;

  const ref = defaultDatabase.child(uid).child(convertAnnotationTypeToPath(annotationType)).child(annotationId)
    .child('properties').child('imageURLs');

  const snapshot = await ref.get();
  const ids = snapshot.val();
  await ref.set([...ids].filter((v) => v !== id));
};

/**
 * Retrieves a user's stream data from Firebase.
 * @param {string} streamGid ID of the stream.
 */
export const getStreamData = async (streamGid) => {
  const { uid } = firebase.auth().currentUser;
  const snapshot = await defaultDatabase.child(uid).child('streams').once('value');
  const val = snapshot.val();

  if (!val) return null;

  return val[streamGid];
};

export const setStartNotificationShown = () => {
  const { uid } = firebase.auth().currentUser;
  profileDatabase.child('users').child(uid).child('trial').child('start_notification')
    .set(0);
};

export const setEndNotificationShown = () => {
  const { uid } = firebase.auth().currentUser;
  profileDatabase.child('users').child(uid).child('trial').child('end_notification')
    .set(0);
};

export const resetPassword = async (currentPassword, newPassword) => {
  const { email } = firebase.auth().currentUser;
  const user = await signInHelper(email, currentPassword);
  await user.updatePassword(newPassword);
};

export const sendPasswordResetEmail = async (email) => {
  await firebase.auth().sendPasswordResetEmail(email);
};

export const updateSnapRegion = (regionGid) => {
  const { uid } = firebase.auth().currentUser;
  const regionRef = defaultDatabase.child(uid).child('region');
  regionRef.set(regionGid);

  paymentService.updateRevenueCatAttributes({regionId: regionGid});
};

export const getAuthToken = async () => {
  return firebase.auth().currentUser.getIdToken();
};

export const addPurchase = async ({
  email, firstName, lastName, id,
}) => {
  const emailLC = email.toLowerCase(); 
  const a = moment().format("DD/MM/YYYY hh:mmaa");
  const d = moment().format('L');
  const t = moment().format('LTS');
  const date = `${d} ${t}`;
  const date2 = `${a}`;
  await wordpressDatabase.child(id).set({
    emailLC,
    first_name: firstName,
    last_name: lastName,
    date,
    product_type: 0,
  });
  await profileDatabase.child('ProAccounts').push({
    Email: emailLC,
    productType: 0,
    purchaseDate: date,
  });
};

export const generateDynamicLink = async (latitude, longitude, pcode) => {
  const baseUrl = 'https://troutroutes.com/applinks?t=c&';
  const latParam = 'l=' + latitude;
  const longParam = 'o=' + longitude;
  var url;

  if (pcode === "" || pcode ===  null) {
    url = baseUrl + latParam + '&' + longParam;
  } else {
    const pcodeParam = 'pcode=' + pcode;
    url = baseUrl + pcodeParam + '&' + latParam + '&' + longParam;
  }

  return url;
}

export const generateDynamicLinkForStream = async (streamGid, pcode) => {
  const baseUrl = 'https://troutroutes.com/applinks?t=s&';
  const streamGIDParam = 'i='+streamGid;
  var url;

  if (pcode === "" || pcode ===  null) {
    url = baseUrl + streamGIDParam;
  } else {
    const pcodeParam = 'pcode=' + pcode;
    url = baseUrl + pcodeParam + '&' + streamGIDParam;
  }

  return url;
}

export const updatePasswordFlyshop = async (email, newPassword) => {
  await axios.post(`${paymentService.API_URL}/update-password`, {
    email,
    password: newPassword,
  }, { headers: { flyshop: 'true' } });
};

export const verifyFlyshowString = async (flyshowString) => {
  const expos = (await eCommerceDatabase.child('expos').once('value')).val();
  if (!expos || !expos[flyshowString]) return false;

  const expo = expos[flyshowString];
  const { startDate, endDate } = expo;
  const now = moment();

  return now.isBetween(startDate, endDate);
}

export const verifyMembershipRedemptionCode = async (code) => {
  const purchases = (await wordpressDatabase.once('value')).val()
  let purchaseData = null;

  Object.entries(purchases).forEach(([id, purchase]) => {
    if (purchase.redemption_code === code) {
      const isRedeemed = purchase.redeemed === 1;
      if (isRedeemed) throw new Error('Redemption code has already been redeemed');

      let entitlementDuration = "";
      switch (purchase.product_type) {
        case 0:
          entitlementDuration = "yearly";
          break;
        case 1:
          entitlementDuration = "lifetime";
          break;
        case 2:
          entitlementDuration = "daily";
          break;
        case 3:
          entitlementDuration = "three_day";
          break;
        case 4:
          entitlementDuration = "weekly";
          break;
        case 5:
          entitlementDuration = "monthly";
          break;
        case 6:
          entitlementDuration = "six_month";
          break;
        case 7:
          entitlementDuration = "three_month";
          break;
        default:
          break;
      }

      purchaseData = {
        isLifetime: purchase.product_type === 1,
        date: purchase.date,
        purchaseId: id,
        redemptionCode: code,
        duration: entitlementDuration,
      };
      
      return purchaseData;
    } else if (purchase.redemption_codes && typeof purchase.redemption_codes === 'object') {
      if (Object.keys(purchase.redemption_codes).includes(code)) {
        const isRedeemed = purchase.redemption_codes[code] === 1;
        const isMultiUse = purchase.multiuse === 1;
 
        if (!isMultiUse && isRedeemed) {
          throw new Error('Redemption code has already been redeemed');
        } else if (isMultiUse) {
          const expireDate = Date.parse(purchase.expire_date);
          const today = Date.parse(Date());
          if (!purchase.expire_date || expireDate < today){
            throw new Error('Redemption code has expired');
          }
        }
        let entitlementDuration = "";
        switch (purchase.product_type) {
          case 0:
            entitlementDuration = "yearly";
            break;
          case 1:
            entitlementDuration = "lifetime";
            break;
          case 2:
            entitlementDuration = "daily";
            break;
          case 3:
            entitlementDuration = "three_day";
            break;
          case 4:
            entitlementDuration = "weekly";
            break;
          case 5:
            entitlementDuration = "monthly";
            break;
          case 6:
            entitlementDuration = "six_month";
            break;
          case 7:
            entitlementDuration = "three_month";
            break;
          default:
            break;
        }
        purchaseData = {
          isLifetime: purchase.product_type === 1,
          date: purchase.date,
          purchaseId: id,
          redemptionCode: code,
          duration: entitlementDuration,
        };
        return purchaseData;
      }
    }
  });

  return purchaseData;
}

export const checkIfEmailExists = async (email) => {
  const { data } = await axios.get(`${paymentService.API_URL}/user-exists?email=${email}`);
  if (data.result) {
    return true;
  } return false;
}

export const getGageIDList = async (streamGid, currentHUC) => {
  const data = await gageListDatabase.orderByChild('properties/stream_gid2').equalTo(streamGid).once('value');
  let nearbyGagesRiverIDs = [];
  let trueHuc = "";
  if(data.val()) {
    const streams = Object.values(data.val());
    trueHuc = streams.find((gage) => gage?.properties?.stream_gid2 === streamGid && gage?.properties?.huc10 !== "").properties.huc10;
    nearbyGagesRiverIDs = streams.map((s) => {
      if(s?.properties?.stream_gid2 === streamGid) {
        return s?.properties?.STAID;
      }
    })
    nearbyGagesRiverIDs = [... new Set(nearbyGagesRiverIDs.filter((item) => item !== undefined))];
  }

  const hucForWatershed = trueHuc === '' ? currentHUC : trueHuc;
  const data2 = await gageListDatabase.orderByChild('properties/huc10').equalTo(hucForWatershed).once('value');
  let nearbyGagesWatershedIDs = [];
  if(data2.val()) {
    const shades = Object.values(data2.val());
    nearbyGagesWatershedIDs = shades.map((s) => {
      if(s?.properties?.huc10 === hucForWatershed && !nearbyGagesRiverIDs.includes(s?.properties?.STAID)) {
        return s?.properties?.STAID
      }
    })
    nearbyGagesWatershedIDs = [... new Set(nearbyGagesWatershedIDs.filter((item) => item !== undefined))];
  }
  
  return {nearbyGagesRiverIDs, nearbyGagesWatershedIDs, trueHuc};
}


export const checkUserProfileExists = async () => {
	const { uid } = firebase.auth().currentUser;

	const userProfileRef = profileDatabase.child("users").child(uid);
	const v = await userProfileRef.get();
	return v.exists();
}

export const updateUserProfileInfo = async (key, value) => {
	const { uid, email, displayName } = firebase.auth().currentUser;

	await profileDatabase.child(`users/${uid}`).set({
		user_email: email,
	});
	paymentService.updateRevenueCatAttributes({
		email,
		name: displayName,
	})
}

export { analytics };
export default firebase;
