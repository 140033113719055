import React, { useState } from 'react';
import Image from 'components/Image';
import {
  InputLabel, Select, MenuItem, FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { loginLogo } from 'assets';
import QRCode from 'react-qr-code';
import { generateDynamicLink } from 'services/firebase';
import FormContainer from 'components/Login/FormContainer';
import styles from './styles.module.scss';

const typecodes = {
  point: '0',
  // stream: '1',
  app: '4',
};
const codeDescriptions = {
  point: 'A specific point',
  // stream: 'A specific stream',
  app: 'Share the app',
};

const useStyles = makeStyles((theme) => ({
  select: {
    width: '50vw',
    alignSelf: 'center',
  },
}));

const LinkGenerator = () => {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState('');
  const [values, setValues] = useState({
    latitude: '', longitude: '', zoom: '', pcode: '',
  });
  const [errors, setErrors] = useState({
    latitude: '', longitude: '', zoom: '', pcode: '',
  });
  const [result, setResult] = useState('');

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const submit = () => {
    const errors = {};
    // if (!values.latitude) errors.latitude = 'Required';
    // if (!values.longitude) errors.longitude = 'Required';
    // if (!values.zoom) errors.zoom = 'Required';
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (selectedType === typecodes.point) {
        generateDynamicLink(values.latitude.trim(), values.longitude.trim(), values.pcode.trim())
          .then((link) => setResult(link))
          .catch((e) => console.log(e));
      } else {
        setResult('https://troutroutes.com/applinks');
      }
    }
  };

  return (
    <div className={styles['link-container']}>
      <Image src={loginLogo} alt="Logo" width={500} minHeight={189} objectFit="cover" />
      <h1>Dynamic Link Generator</h1>

      <p className={styles.description}>
        The PCODE is the unique identifier of the referral account.
        If you have not been assigned this or are not sure, contact Zach Pope at <a href="mailto:zpope@troutinsights.com">zpope@troutinsights.com</a>.
      </p>

      <form className={styles['link-form']}>
        <FormContainer.FormElement
          title="PCODE"
          name="pcode"
          value={values.pcode}
          onChange={onChange}
          error={errors.pcode}
        />

        <p className={styles.description}>What do you want to share?</p>
        <FormControl style={{ marginBottom: 15 }}>
          <InputLabel id="label">Type</InputLabel>
          <Select labelId="label" id="select" classes={{ root: classes.select }} value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
            {Object.entries(typecodes).map(([code, type]) => {
              return (
                <MenuItem value={type} key={code}>{codeDescriptions[code]}</MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {selectedType === typecodes.point && (
          <>
            <FormContainer.FormElement
              title="Latitude"
              name="latitude"
              value={values.latitude}
              onChange={onChange}
              error={errors.latitude}
            />
            <FormContainer.FormElement
              title="Longitude"
              name="longitude"
              value={values.longitude}
              onChange={onChange}
              error={errors.longitude}
            />
            <FormContainer.FormElement
              title="Zoom"
              name="zoom"
              value={values.zoom}
              onChange={onChange}
              error={errors.zoom}
            />
          </>
        )}

        <div className={styles.result}>
          <FormContainer.SubmitButton title="Generate Link" onClick={submit} />
          <input value={result} type="text" readOnly />
          <div className={styles.qr}>
            {!!result && <QRCode value={result} size={150} />}
          </div>
        </div>
      </form>
    </div>
  );
};

export default LinkGenerator;
