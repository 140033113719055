import React from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const IconButton = ({
  icon, name, onClick, isSelected,
}) => {
  return (
    <button type="button" className={`${styles['icon-button']} transparent ${isSelected ? styles.selected : ''}`} onClick={onClick}>
      <Image src={icon} height={45} width={45} minWidth={45} />
      <div>{name}</div>
    </button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default IconButton;
