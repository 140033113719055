import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { DirectionsOutlined, LanguageOutlined } from '@material-ui/icons';
import Image from 'components/Image';
import { useSelector } from 'react-redux';
import { mapShop } from 'assets';
import { getImageDownloadURL } from 'services/firebase';
import { createMarkerWithoutIcon, flyToPoint } from 'services/mapbox';
import styles from './styles.module.scss';

function FlyShopsTab() {
  const { map } = useSelector((state) => state.map);
  const streamFlyShops = useSelector((state) => state.map.streamFlyShops);
  const [data, setData] = useState(new Array(streamFlyShops?.length));
  const [loading, setLoading] = useState(false);
  const [curMarker, setCurMarker] = useState(null);

  useEffect(() => {
    const fetchIcons = async () => {
      setLoading(true);
      const newData = [];
      for (const shop of streamFlyShops) {
        newData.push(`FlyShops/icon_${shop.GID}_cropped.png`);
      }
      try {
        const responses = await Promise.allSettled(newData.map((url) => getImageDownloadURL(url)));
        setData(responses);
      } catch (error) {
        console.log('Error in fetching icons', error);
      }
      setLoading(false);
    };

    fetchIcons();
  }, [streamFlyShops]);

  useEffect(() => {
    return () => {
      if(curMarker) {
        curMarker.remove();
      }
    };
  }, [curMarker]);

  const handleCardClick = (index) => {
    const lng = streamFlyShops[index]?.long;
    const lat = streamFlyShops[index]?.lat;
    const point = [lng, lat];
    const newMarker = createMarkerWithoutIcon({ lngLat: { lat, lng } }, map);
    setCurMarker(newMarker);
    flyToPoint(map, point);
  };

  return (
    <div className={styles.container}>
      {streamFlyShops.length <= 0
        && (
          <div className={styles.emptyMessage}>
            No fly shops nearby
          </div>
        )}
      {loading && streamFlyShops.length > 0 && <div className={styles.loader} />}
      {!loading && streamFlyShops.length > 0 && streamFlyShops?.map((shop, index) => {
        return (
          <div className={styles.popup}
            role="button"
            tabIndex={0}
            onClick={() => handleCardClick(index)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleCardClick(index);
              }
            }}
            key={shop.id}
          >
            <div className={styles.content}>
              <Image alt={shop.title} src={data[index]?.value || mapShop} width="55" objectFit="contain" />
              <div className={styles.text}>
                <h5>{shop.Title}</h5>
                <h6>{shop.Address}</h6>
              </div>
            </div>
            <div className={styles.buttons}>
              <Button label="Directions" className={styles.button} iconElement={DirectionsOutlined} onClick={(e) => { e.stopPropagation(); window.open(shop['Google Maps URL']); }} />
              <Button label="Website" className={styles.button} iconElement={LanguageOutlined} onClick={(e) => { e.stopPropagation(); window.open(shop.Website, '_blank'); }} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default FlyShopsTab;
